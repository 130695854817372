import { AxiosError, AxiosResponse } from "axios";
import classNames from 'classnames';
import moment from 'moment';
import { DefaultButton, Modal, PrimaryButton, Stack } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from 'src/popups/actions';
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ActionMenu, Label, Loader, SpintrUser } from "src/ui";
import { FillHeight, Scrollable } from "src/ui/components";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import {
    FormTokenizedObjectInput
} from "src/ui/components/Forms";
import ConfirmDialog from 'src/ui/components/Forms/ConfirmDialog';
import ErrorMessagebar from 'src/ui/components/Messagebars/ErrorMessagebar';
import ModalHeader from 'src/ui/components/Modal/ModalHeader';
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { Style } from 'src/ui/helpers';
import { scrollToTop } from 'src/utils';
import "./GroupMembersView.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";

interface IProps {
    group: any;
    currentUser: any;
    match?: any;
    history: any;
    dispatch?: any;
}

interface IState {
    showInviteDialog: boolean;
    invites: any[],
    isLoading: boolean;
    saveError?: string[];
    showConfirmDialog: boolean;
}

class GroupMembersView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    constructor(props) {
        super(props);

        const showInviteDialog = window.location.href.indexOf("/invite") > -1;

        this.state = {
            showInviteDialog,
            invites: [],
            isLoading: false,
            showConfirmDialog: false
        };
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.match &&
            !!prevProps.match &&
            this.props.match.path.indexOf("/invite") > -1 &&
            prevProps.match.path.indexOf("/invite") === -1) {
            this.setState({
                showInviteDialog: true
            });
        }
    }

    sendInvites = () => {
        this.setState({
            showInviteDialog: false,
            isLoading: true
        }, () => {
            let invites = [];

            for (let i of this.state.invites) {
                if (i.isEmail) {
                    invites.push({
                        email: i.name
                    });
                } else {
                    invites.push({
                        id: i.key
                    });
                }
            }

            api.post(`/api/groupmembers/${this.props.group.id}/multiinvite`, invites).then(() => {
                this.setState({
                    isLoading: false
                }, () => {
                    this.setState({ invites: [] })
                    this.props.history.push({
                        pathname: "/groups/" + this.props.group.id + "/members",
                    });
                });
            }).catch((error: AxiosError) => {
                this.setState({
                    saveError: error.response.data.errorlist || ["TeknisktFel"],
                    isLoading: false
                }, () => {
                    scrollToTop();
                });
            });
        });
    }

    closeInviteModal = () => {

        const close = () => {
            this.setState({
                showInviteDialog: false,
                invites: []
            }, () => {
                this.props.history.push({
                    pathname: "/groups/" + this.props.group.id + "/members",
                });
            });
        }

        if (this.state.invites && this.state.invites.length > 0) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }

    }

    renderInviteModal = () => {
        return (
            <Modal isOpen={this.state.showInviteDialog} className="spintr-modal modalWithPopupHeader" onDismiss={this.closeInviteModal}>
                <PopupHeader
                    text={localize("BjudIn")}
                    onClose={this.closeInviteModal} />
                <div>
                    <FormTokenizedObjectInput
                        types={[
                            SpintrTypes.UberType.User,
                            SpintrTypes.UberType.Role,
                            SpintrTypes.UberType.Department,
                            SpintrTypes.UberType.Office,
                            SpintrTypes.UberType.TargetGroup,
                        ]}
                        items={this.state.invites}
                        label={localize("SokEfterNamnEllerEpost")}
                        allowEmailAddresses={true}
                        onChange={(invites) => {
                            this.setState({
                                invites: invites,
                            });
                        }}
                    />

                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                        <DefaultButton onClick={this.closeInviteModal} text={localize("Avbryt")} />
                        <PrimaryButton
                            onClick={() => {
                                this.setState({ showConfirmDialog: true });
                            }}
                            text={localize("Spara")}
                        />
                    </Stack>
                </div>
            </Modal>
        );
    }

    renderInviteDialog() {
        return (
            <CustomDialog
                title={localize("BjudIn")}
                show={this.state.showInviteDialog}
                primaryButtonDisabled={!this.state.invites || this.state.invites.length === 0}
                children={
                    <div>
                        <FormTokenizedObjectInput
                            types={[
                                SpintrTypes.UberType.User,
                                SpintrTypes.UberType.Role,
                                SpintrTypes.UberType.Department,
                                SpintrTypes.UberType.Office,
                                SpintrTypes.UberType.TargetGroup
                            ]}
                            items={this.state.invites}
                            label={localize("SokEfterNamnEllerEpost")}
                            allowEmailAddresses={true}
                            onChange={(invites) => {
                                this.setState({
                                    invites: invites
                                });
                            }}
                        />
                    </div>
                }
                onDismiss={() => {
                    const close = () => {
                        this.setState({
                            showInviteDialog: false
                        }, () => {
                            this.props.history.push({
                                pathname: "/groups/" + this.props.group.id + "/members",
                            });
                        });
                    }

                    if (this.state.invites && this.state.invites.length > 0) {
                        this.props.dispatch(setConfirmPopup({
                            isOpen: true,
                            message: localize("UnsavedChangesWarning"),
                            onConfirm: close
                        }));
                    } else {
                        close();
                    }
                }}
                onConfirm={() => {
                    this.setState({ showConfirmDialog: true })
                }} />
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }

        const isGroupAdmin = !!this.props.group.members.find(m =>
            m.id === this.props.currentUser.id &&
            m.isAdministrator);

        const canInvite = !this.props.group.disableManualMembership &&
            !this.props.currentUser.isGroupUser &&
            (
                isGroupAdmin ||
                this.props.group.availability === 1 ||
                this.props.group.membersCanInvite
            );

        return (
            <div className="GroupMembersView GroupContentView">
                <div>
                    {!!this.state.saveError && this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            style={{
                                marginBottom: Style.getSpacingStr(3)
                            }}
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({
                                    saveError: [],
                                });
                            }}
                        />
                    )}
                    <SpintrList
                        ref={this.listRef}
                        buttons={
                            canInvite ? [
                                {
                                    key: "add",
                                    text: localize("BjudIn"),
                                    onClick: () => {
                                        this.setState({
                                            showInviteDialog: true
                                        });
                                    },
                                    iconProps: { iconName: "Add" },
                                    className: "commandBarAddButton",
                                },
                            ] :
                                null
                        }
                        fetch={(skip, take, columnId, isAscending, searchQuery) => {
                            return new Promise((resolve, reject) => {
                                api.get(`/api/v1/groupmembers`, {
                                    params: {
                                        groupId: this.props.group.id,
                                        isAscending: isAscending,
                                        orderByColumn: columnId,
                                        searchText: searchQuery,
                                        take: take,
                                        skip: skip,
                                    },
                                }).then((response: AxiosResponse) => {
                                    resolve({
                                        data: response.data.members,
                                        totalCount: response.data.total
                                    });
                                }).catch((error: AxiosError) => {
                                    this.setState({
                                        saveError: error.response.data.errorlist || ["TeknisktFel"],
                                        isLoading: false
                                    }, () => {
                                        scrollToTop();
                                    });
                                });
                            });
                        }}
                        columns={[
                            {
                                key: 1,
                                minWidth: 36,
                                maxWidth: 36,
                                onRender: (item) => {
                                    return <SpintrUser
                                        id={item.id}
                                        imageUrl={item.image}
                                        hideText
                                        name={item.name} />;
                                },
                            },
                            {
                                key: 2,
                                name: localize("Namn"),
                                fieldName: "name",
                                minWidth: 100,
                                onRender: (item) => {
                                    return (
                                        <div>
                                            <span className={classNames({ "fc-grey": item.status === 1 })}>
                                                {item.name}{" "}
                                                {item.status === 1 && <span>({localize("Inbjuden")})</span>}
                                            </span>
                                            <Label as="div" size="body-3">{localize("SenasteAktivitetIGruppen")}: {moment(item.project.lastActivity).fromNow()}</Label>
                                        </div>
                                    );
                                },
                            },
                            {
                                key: 3,
                                name: localize("Roll"),
                                fieldName: "instanceId",
                                minWidth: 100,
                                onRender: (item) => {
                                    return (
                                        <span>
                                            {
                                                item.instanceId === 1 ?
                                                    localize("Administrator") :
                                                    item.instanceId === 2 ?
                                                        localize("Medlem") :
                                                        localize("Extern")
                                            }
                                        </span>
                                    );
                                },
                            },
                            {
                                key: 4,
                                name: localize("Organisation"),
                                fieldName: "instanceDomainName",
                                minWidth: 100,
                                onRender: (item) => {
                                    return (
                                        <span>
                                            {
                                                item.instanceDomainName
                                            }
                                        </span>
                                    );
                                },
                            },
                            {
                                key: 5,
                                minWidth: 40,
                                onRender: (item: any) => {
                                    if (!this.props.group.members.find(m => m.id === this.props.currentUser.id && m.isAdministrator)) {
                                        return null;
                                    }

                                    let items = [];

                                    if (item.status !== 1) {
                                        items.push({
                                            text: item.project.isAdministrator ?
                                                localize('GorTillMedlem') :
                                                localize('GorTillAdministrator'),
                                            onClick: () => {
                                                const model = {
                                                    membershipId: item.project.membershipId,
                                                    status: item.project.isAdministrator ?
                                                        1 :
                                                        2
                                                };

                                                api.put("/api/groupmembers/" + item.project.membershipId, model).then(() => {
                                                    this.listRef.current.fetch();
                                                }).catch((error: AxiosError) => {
                                                    this.setState({
                                                        saveError: error.response.data.errorlist || ["TeknisktFel"],
                                                        isLoading: false
                                                    }, () => {
                                                        scrollToTop();
                                                    });
                                                });
                                            },
                                        });
                                    }

                                    items.push({
                                        text: localize('TaBort'),
                                        onClick: () => {
                                            const fn = () => {
                                                api.delete("/api/groupmembers/" + item.project.membershipId).then(() => {
                                                    this.listRef.current.fetch();
                                                }).catch((error: AxiosError) => {
                                                    this.setState({
                                                        saveError: error.response.data.errorlist || ["TeknisktFel"],
                                                        isLoading: false
                                                    }, () => {
                                                        scrollToTop();
                                                    });
                                                });
                                            }

                                            this.props.dispatch(setConfirmPopup({
                                                isOpen: true,
                                                title: localize("TaBort"),
                                                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                                                onConfirm: fn
                                            }));
                                        },
                                    });

                                    return (
                                        <ActionMenu
                                            categories={[
                                                {
                                                    items: items
                                                },
                                            ]}
                                        />
                                    );
                                },
                            },
                        ]}
                        orderByColumn={"instanceId"}
                    />
                    {
                        this.renderInviteModal()
                    }
                    <ConfirmDialog
                        show={this.state.showConfirmDialog}
                        title={localize("SkapaInnehall")}
                        message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("BjudaInTillGrupp").toLowerCase())}
                        onDismiss={() => {
                            this.setState({ showConfirmDialog: false });
                        }}
                        onConfirm={() => {
                            this.setState({ showConfirmDialog: false }, () => {
                                this.sendInvites()
                            });
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    group: state.groups.group,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(GroupMembersView);