import React, { Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { UserHovercard } from "src/ui/components";
import decodeHtmlEntities from "./decodeHtmlEntities";

const processText = (text: string) => {
    const urlPattern = /((www\.|(http|https|ftp|news|file)+\:\/\/)[&#95;.a-z0-9-_]+(?:\.)?[a-z0-9\/&#95;:@=.+?!,##%&()~_-]*[^.|\'|\# |!|\(|?|,| |>|<|;|\)])/gmi;
    const hashtagPattern = /(^|\B)#([^(?!.:?\/\)\()\s]+)/g;

    text = decodeHtmlEntities(text);

    text = text.replace(new RegExp('&lt;br /&gt;', 'ig'), '<br />');
    text = text.replace(new RegExp('&lt;br />', 'ig'), '<br />');

    if (text.match(hashtagPattern)) {
        text = text.replace(hashtagPattern, (s) => {
            const decodedS = decodeHtmlEntities(s);

            if (decodedS.match(hashtagPattern)) {
                return `<a class='notranslate' href='/search?q=${encodeURIComponent(decodedS)}'>${decodedS}</a>`;
            } else {
                return s;
            }
        });
    }

    if (text.match(urlPattern)) {
        text = text.replace(urlPattern, function (url) {
            if (url.match(/^www/i)) {
                url = 'http://' + url;
            }

            return `<a class="notranslate" href="${url}" title="${localize("BesokLank")}" target="_blank">${url}</a>`;
        });
    }

    return text;
}

export function stripInteractiveText(text: string): string {
    const matchPattern = /\[link@(.+?)@link\](.+?)\[\/link]/ig;

    return text.replace(matchPattern, (_, __, inner) => {
        return inner;
    })
}


export default function formatInteractiveText (text: string) {
    text = processText(text);

    const splitPattern = /(\[link@(?:.+?)@link\](?:.+?)\[\/link])/ig;
    const matchPattern = /\[link@(.+?)@link\](.+?)\[\/link]/ig;

    const parts: ReactNode[] = text.split(splitPattern).map((part, i) => {
        const match = matchPattern.exec(part);
        part = part.replace(/(\r\n|\n)/g, "<br />");

        if (!match) {
            return (
                <span
                    dangerouslySetInnerHTML={{ __html: part }} 
                    key={i}
                />
            );
        }
        
        const url = match[1];
        const text = match[2];
        const isUser = url.indexOf('/goto/1/') > -1;

        if (isUser) {
            const userId = parseInt(url.split('/').pop(), 10);

            return (
                <UserHovercard key={i} userId={userId}>
                    <Link to={url}>
                        {
                            decodeHtmlEntities(text)
                        }
                    </Link>
                </UserHovercard>
            )
        }

        return (
            <Link key={i} to={url}>
                {
                    decodeHtmlEntities(text)
                }
            </Link>
        );
    });

    return (
        <Fragment children={parts} />
    );
};