import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { IApplicationState } from "src/spintr/index";
import { SpintrTypes } from "src/typings";
import TeaserBoxContainer from "./TeaserBoxContainer";

interface ITeaserBoxesProps {
    teaserboxesLoading?: boolean;
    items?: Spintr.ITeaserBoxItem[];
    dispatch?: (action: Action) => void;
    currentlyShowingTeaserboxes?: number[];
    blogsEnabled?: boolean;
    blogsOnly?: boolean;
    dataWidgetsOnlyIfMoreThanOneOfEach?: boolean;
    nonDataWidgetsOnlyIfMoreThanOneOfEach?: boolean;
}

interface IState {

}

class TeaserBoxLoader extends Component<ITeaserBoxesProps, IState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render(): ReactNode {
        const items = this.getItems();

        if (!items ||
            items.length === 0) {
            return null;
        }

        const classNames = ["TeaserBoxes"];

        if (this.props.blogsOnly) {
            classNames.push("blogs-only");
        }

        return (
            <div className={classNames.join(" ")}>
                {this.renderTeaserBoxes(items)}
            </div>
        );
    }

    getItems() {
        let items;

        if (this.props.currentlyShowingTeaserboxes) {
            items = this.props.items.filter((t) => {
                return !this.props.currentlyShowingTeaserboxes.includes(t.id);
            });
        } else {
            items = this.props.items;
        }

        if (!this.props.blogsEnabled) {
            items = items.filter((i) => i.type !== SpintrTypes.TeaserBoxType.Blog);
        }

        if (this.props.blogsOnly) {
            items = items.filter((i) => i.type === SpintrTypes.TeaserBoxType.Blog);
        } else {
            items = items.filter((i) => i.type !== SpintrTypes.TeaserBoxType.Blog);

            let dataWidgets = items.filter((t) => t.type === SpintrTypes.TeaserBoxType.DataWidget);
            let nonDataWidgets = items.filter((t) => t.type !== SpintrTypes.TeaserBoxType.DataWidget);

            if (dataWidgets.length > 1 &&
                nonDataWidgets.length > 1) {
                items = this.props.dataWidgetsOnlyIfMoreThanOneOfEach ?
                    dataWidgets :
                    nonDataWidgets;
            } else if (this.props.dataWidgetsOnlyIfMoreThanOneOfEach) {
                items = [];
            }
        }

        return items;
    }

    public renderTeaserBoxes(items: any): ReactNode {
        return (
            <div>
                <TeaserBoxContainer items={items} isLoading={this.props.teaserboxesLoading && (!items || items.length === 0)} />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: ITeaserBoxesProps): ITeaserBoxesProps => ({
    ...props,
    items: state.teaserboxes.items || [],
    teaserboxesLoading: state.teaserboxes.loading,
    currentlyShowingTeaserboxes: state.ui.currentlyShowingTeaserboxes,
    blogsEnabled: state.app.items.some((app) => app.enabled && app.id === 2),
});

export default connect(mapStateToProps)(TeaserBoxLoader);
