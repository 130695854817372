import classNames from "classnames";
import React, { Component, ReactNode } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { MessageFetchType } from "src/chat/message-types";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IConfirmPopupState } from "src/popups/reducer";
import Conversation from "../../Conversation";
import {
    BoundQueryMessagesHandler,
    markAsRead,
    MarkAsReadHandler,
    queryMessages,
    QueryMessagesHandler,
    setTabState,
    SetTabStateHandler
} from "../../redux";
import { ChatTabContent } from "../ChatTabContent";
import { ChatTabContext, IChatTabContext } from "../ChatTabContext";
import { ChatTabHeader } from "../ChatTabHeader";
import "./ChatTab.scss";

interface IDispatchProps {
    markAsRead: MarkAsReadHandler;
    queryMessages: QueryMessagesHandler | BoundQueryMessagesHandler;
    setTabState: SetTabStateHandler;
    setConfirmPopup: (params: IConfirmPopupState) => void;
}

interface IOwnProps {
    conversation: Conversation;
}

interface IStateProps {
    currentUser: Spintr.IUser;
    lastRead: { [messageId: number]: number[] };
    minimized: boolean;
    oldestMessageId?: number;
}

type Props = IDispatchProps & IOwnProps & IStateProps;

interface IState {
    isLoading: boolean;
}

const initialState: IState = {
    isLoading: false,
}

class ChatTab extends Component<Props, IState> {
    private tabContext: IChatTabContext;
    protected chatTabContentRef: any;

    constructor(props: Props) {
        super(props);

        this.state = initialState;

        this.loadMore = this.loadMore.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onToggle = this.onToggle.bind(this);

        this.tabContext = {
            currentUser: this.props.currentUser,
            lastRead: this.props.lastRead,

            clearUnread: () => {
                if (!this.props.conversation.unread) {
                    return;
                }

                this.props.markAsRead(
                    this.props.conversation.id,
                    this.props.conversation.unread,
                );
            },

            loadMore: this.loadMore,
        };
    }

    public componentDidUpdate(): void {
        if (this.tabContext.lastRead !== this.props.lastRead)  {
            this.tabContext.lastRead = this.props.lastRead;
        }
    }

    public render(): ReactNode {
        const classes = ["ChatTab", "pop-box-shadow"];
        if (this.props.minimized) {
            classes.push("minimized");
        }

        const { conversation } = this.props;

        return (
            <div className={classNames(...classes)}>
                <ChatTabContext.Provider value={this.tabContext}>
                    <ChatTabHeader
                        images={conversation.imageUrl}
                        minimized={this.props.minimized}
                        title={conversation.title}
                        onClose={this.onClose}
                        onToggle={this.onToggle}
                        unread={conversation.unread}
                    />
                    <ChatTabContent
                        //@ts-ignore
                        ref={ref => this.chatTabContentRef = ref}
                        conversationId={conversation.id}
                        lastRead={this.props.lastRead}
                        minimized={this.props.minimized}
                    />
                </ChatTabContext.Provider>
            </div>
        );
    }

    protected loadMore(): void {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true}, async () => {
            try {
                const promise = (this.props.queryMessages as BoundQueryMessagesHandler)({
                    conversationId: this.props.conversation.id,
                    fetchType: MessageFetchType.Messages,
                    take: 10,
                    maxId: this.props.oldestMessageId,
                });

                const data = await promise;
                console.log(data);
            } catch (err) {
                console.log(err);
            } finally {
                this.setState({ isLoading: false });
            }
        });
    }

    protected onClose(): void {
        let close = () => {
            this.props.setTabState(this.props.conversation.id, false);
        }

        if (this.chatTabContentRef &&
            this.chatTabContentRef.composerHasText &&
            this.chatTabContentRef.composerHasText()) {
            this.props.setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            });

            return;
        }

        close();
    }

    protected onToggle(): void {
        const nextState = !this.props.minimized;
        this.props.setTabState(
            this.props.conversation.id, null, nextState,
        );

        if (!nextState || !this.props.conversation.unread) {
            return;
        }

        this.props.markAsRead(
            this.props.conversation.id,
            this.props.conversation.unread,
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, ownProps) => ({
        currentUser: {
            id: state.profile.active.id,
            imageUrl: state.profile.active.images.Message,
            info: "",
            name: state.profile.active.name,
            type: 1,
            typeName: "",
        },
        
        lastRead: Object.keys(ownProps.conversation.lastRead)
                .filter((k) => !isNaN(+k))
                .map((k) => parseInt(k, 10))
                .reduce((acc, userId) => {
                    const messageId = ownProps.conversation.lastRead[userId];
                    if (!messageId) {
                        return acc;
                    }

                    acc[messageId] = acc[messageId] || [];
                    acc[messageId].push(userId);
                    return acc;
                }, {} as { [messageId: number]: number[]}),

        minimized: state.chat.conversations.tabIds.some(
            (tab) => tab.id === ownProps.conversation.id && tab.minimized
        ),

        oldestMessageId: state.chat.messages
            .filter(m => m.conversationId === ownProps.conversation.id)
            .reduce<number | undefined>((acc, m) => {
                if (!acc) {
                    return m.id;
                }

                return m.id < acc ? m.id : acc;
            }, undefined),
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    markAsRead,
    queryMessages,
    setTabState,
    setConfirmPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTab);