import { AxiosError, AxiosResponse } from "axios";
import { TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Breadcrumbs, ContentWithSubmenu, PageHeader } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import { scrollToTop, validateRequiredTextField } from "src/utils";
import "./WikiArticleSectionEditView.scss";
import WikiStructure from "./WikiStructure";
import api from "src/spintr/SpintrApi";
import SplitForm from "src/ui/components/SplitForm/SplitForm";

interface IRouteParams {
    articleId: string;
    sectionIndex?: string;
    sectionId?: string;
}
interface IProps extends RouteComponentProps<IRouteParams> {
    isAdmin: boolean;
    isEditor: boolean;
    isSmallViewMode?: boolean;
    currentUserId: number;
    restrictWikis?: boolean;
}

interface IState {
    isLoading: boolean;
    wiki: any;
    article: any;
    section: any;
    hasExtendedRights: boolean;
    showDeleteDialog: boolean;
    saveError: string[];
    positions: any[];
    originalPositions: any[];
    enableFieldValidation?: boolean;
}

class WikiArticleSectionEditView extends Component<IProps, IState> {
    private isNew = !!!this.props.match.params.sectionId;
    private tinyRef = React.createRef<any>();

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasExtendedRights: this.isNew || this.props.isAdmin || this.props.isEditor,
            wiki: {},
            article: {},
            section: { name: "", id: 0 },
            showDeleteDialog: false,
            saveError: [],
            positions: [],
            originalPositions: [],
        };
    }

    componentDidMount() {
        if (this.props.restrictWikis) {
            if (!this.props.isAdmin && !this.props.isEditor) {
                this.props.history.goBack();
                return;
            }
        }

        if (this.isNew) {
            this.fetchArticle(this.props.match.params.articleId);
        }

        if (!this.isNew) {
            api.get(`/api/v1/wikis/section/${this.props.match.params.sectionId}`).then((response: AxiosResponse) => {
                response.data.name = response.data.title;

                this.setState((prevState) => ({
                    section: response.data,
                    hasExtendedRights:
                        prevState.hasExtendedRights || response.data.editor.id === this.props.currentUserId,
                }));

                this.fetchArticle(response.data.article.id);
            });
        }
    }

    fetchArticle(articleId) {
        api.get(`/api/v1/wikis/article/${articleId}`).then((response: AxiosResponse) => {
            const sections = response.data.wiki.sections as any[];

            const index = this.props.match.params.sectionIndex
                ? parseInt(this.props.match.params.sectionIndex, 10)
                : sections.length;

            sections.splice(index, 0, {
                title: localize("NySektion"),
                id: 0,
            });

            this.setState((prevState) => ({
                article: response.data.wiki,
                wiki: response.data.wiki.wiki,
                originalPositions: response.data.wiki.sections,
                positions: response.data.wiki.sections,
                isLoading: false,
            }));
        });
    }

    componentDidUpdate() {}

    formFooterButtons() {
        return [
            ...(!this.isNew && this.state.hasExtendedRights
                ? [
                      {
                          text: localize(this.state.section.published ? "TaBort" : "Aterstall"),
                          onClick: () => {
                              if (this.state.section.published) {
                                  this.setState({
                                      showDeleteDialog: true,
                                  });
                                  return;
                              }
                              // restoring not supported?
                          },
                      },
                  ]
                : []),
        ];
    }

    private onCancelClick = () => {
        this.props.history.push({
            pathname: "/" + this.state.article.url.aliasurl,
        });
    };

    private onSaveClick = () => {
        this.setState({
            enableFieldValidation: true,
        });
        this.setState({ isLoading: true });

        if (this.isNew) {
            let saveData = {
                name: this.state.section.name,
                content: this.tinyRef.current.getContent(),
                articleid: this.props.match.params.articleId.toString(),
                editorid: this.props.currentUserId,
                published: true,
            };

            api.post(`/api/v1/wikiarticles/section`, saveData)
                .then((response: AxiosResponse) => {
                    const sections = this.state.positions.map((section, idx) => ({
                        parentId: 0,
                        id: section.id,
                        position: idx,
                    }));

                    const newSection = sections.find((s) => !s.id);
                    newSection.id = response.data.id;

                    api.put(`/api/wikis/section/positions`, { sections }).then(() => {
                        this.props.history.push({
                            pathname: "/" + this.state.article.url.aliasurl,
                        });
                    });
                })
                .catch((error: AxiosError) => {
                    scrollToTop();
                    this.setState(
                        (prevState) => ({
                            section: { ...prevState.section, content: saveData.content },
                            saveError: error.response.data.errorlist || ["TeknisktFel"],
                            isLoading: false,
                        }),
                        () => {
                            scrollToTop();
                        }
                    );
                });
            return;
        }

        let saveData = {
            name: this.state.section.name,
            content: this.tinyRef.current.getContent(),
            articleid: this.state.section.article.id,
            editorid: this.state.section.editor.id,
            id: this.state.section.id,
            published: true,
        };

        api.put(`/api/v1/wikis/section/${this.state.section.id}`, saveData)
            .then(() => {
                const sections = this.state.positions.map((section, idx) => ({
                    parentId: 0,
                    id: section.id,
                    position: idx,
                }));

                api.put(`/api/wikis/section/positions`, { sections }).then(() => {
                    this.props.history.push({
                        pathname: "/" + this.state.article.url.aliasurl,
                    });
                });
            })
            .catch((error: AxiosError) => {
                this.setState(
                    (prevState) => ({
                        section: { ...prevState.section, content: saveData.content },
                        saveError: error.response.data.errorlist || ["TeknisktFel"],
                        isLoading: false,
                    }),
                    () => {
                        scrollToTop();
                    }
                );
            });
    };

    private onChangePositions = (result) => {
        this.setState({
            positions: result,
        });
    };

    public render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const { article, section, wiki } = this.state;

        return (
            <div className="wiki-article-section-edit-view">
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <CustomDialog
                    show={this.state.showDeleteDialog}
                    message={localize("ArDuPaSakerAttDuVillTaBortDennaWiki") + localize("Sektion").toLowerCase() + "?"}
                    onConfirm={() => {
                        this.setState({ isLoading: true, showDeleteDialog: false });
                        api.delete("/api/v1/wikis/delete/section", { params: { id: section.id } })
                            .then((response) => {
                                this.props.history.push({ pathname: "/" + article.url.aliasurl });
                            })
                            .catch(() => {
                                // TODO: Error
                                this.setState({ isLoading: false });
                            });
                    }}
                    onDismiss={() => {
                        this.setState({
                            showDeleteDialog: false,
                        });
                    }}
                />
                <SplitForm
                    headerTitle={this.isNew ? localize("SkapaWikisektion") : localize("RedigeraWikisektion")}
                    displayMandatoryText
                    additionalSettings={[
                        {
                            title: localize("Struktur"),
                            content: (
                                <WikiStructure
                                    onChangePositions={this.onChangePositions}
                                    positions={this.state.originalPositions}
                                />
                            ),
                        },
                    ]}
                >
                    <FormControl>
                        <TextField
                            label={localize("RubrikPaSektion")}
                            value={section.name}
                            onChange={(ev, val) => {
                                this.setState((prevState) => ({
                                    section: {
                                        ...prevState.section,
                                        name: val,
                                    },
                                }));
                            }}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    <FormControl>
                        <TinyEditorComponent
                            ref={this.tinyRef}
                            id={section.id.toString()}
                            label={localize("Innehall")}
                            content={section.content}
                            required
                            aria-required={true}
                            validateOnLoad={!!this.state.enableFieldValidation}
                        />
                    </FormControl>
                </SplitForm>

                <FormFooterBar
                    top={!this.props.isSmallViewMode}
                    onRenderLeft={
                        this.props.isSmallViewMode
                            ? undefined
                            : () => (
                                  <Breadcrumbs
                                      displayInstance
                                      items={[
                                          {
                                              key: "",
                                              text: localize("Wikis"),
                                              link: "/wikis",
                                          },
                                          {
                                              key: wiki.id,
                                              text: wiki.title,
                                              link: `/goto/${wiki.id}`,
                                          },
                                          {
                                              key: article.id,
                                              text: article.name,
                                              link: "/" + article.url.aliasurl,
                                          },
                                          {
                                              key: "wikiSection",
                                              text: localize(this.isNew ? "NySektion" : "RedigeraSektion"),
                                          },
                                      ]}
                                  />
                              )
                    }
                    buttons={this.formFooterButtons()}
                    onCancelClick={this.onCancelClick}
                    onSaveClick={this.onSaveClick}
                    saveText={localize(this.isNew ? "Publicera" : "Spara")}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        isSmallViewMode: state.ui.isSmallViewMode,
        currentUserId: state.profile.active.id,
        restrictWikis: state.instance.get("restrictWikis"),
    };
};

export default connect(mapStateToProps)(WikiArticleSectionEditView);
