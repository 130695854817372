import { ContentState, EditorState } from "draft-js";
import React, { Component, ReactNode } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { localize } from "src/l10n";
import { draftToHtml, uploadChatFile } from "src/utils";
import Message from "../../Message";
import { MessageFetchType } from "../../message-types";
import {
    addMessageRealtime,
    BoundQueryMessagesHandler,
    MessageReceivedHandler,
    queryMessages,
    QueryMessagesHandler,
    saveMessage,
    SaveMessageHandler
} from "../../redux";
import { ChatTabComposer } from "../ChatTabComposer";
import { ChatTabContext } from "../ChatTabContext";
import { ChatTabMessageContainer } from "../ChatTabMessageContainer";
import "./ChatTabContent.scss";

interface IDispatchProps {
    addMessageRealtime: MessageReceivedHandler;
    queryMessages: BoundQueryMessagesHandler | QueryMessagesHandler;
    saveMessage: SaveMessageHandler;
}

interface IOwnProps {
    conversationId: number;
    minimized: boolean;
    lastRead: { [messageId: number]: number[] };
    pageSize?: number;
    onMessageSent?: (message: Message) => void;
}

interface IStateProps {
    messages: Message[];
}

type Props = IDispatchProps & IOwnProps & IStateProps;

interface IState {
    editorState: EditorState;
    parentMessage?: any;
}

const defaultPageSize = 10;

class ChatTabContent extends Component<Props, IState> {
    public static contextType = ChatTabContext;
    public context: React.ContextType<typeof ChatTabContext>;
    public composerRef = React.createRef<ChatTabComposer>();

    constructor(props: Props) {
        super(props);

        this.state = {
            editorState: EditorState.createEmpty(),
        };

        this.onFilesSelected = this.onFilesSelected.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.onSend = this.onSend.bind(this);
        this.composerHasText = this.composerHasText.bind(this);
    }

    public componentDidMount(): void {
        const pageSize = this.props.pageSize || defaultPageSize;

        if (this.props.messages.length >= pageSize) {
            return;
        }

        if (this.props.conversationId < 1) {
            return;
        }

        (this.props.queryMessages as BoundQueryMessagesHandler)({
            conversationId: this.props.conversationId,
            fetchType: MessageFetchType.Messages,
            take: pageSize,
            flatMode: true
        }); // I had a finally here, but can't remember why
    }

    protected composerHasText(): boolean {
        if (this &&
            this.composerRef &&
            this.composerRef.current) {
            return this.composerRef.current.hasText();
        }

        return false;
    }

    public render(): ReactNode {
        if (this.props.minimized) {
            return null;
        }

        return (
            <div className="ChatTabContent">
                <div className="message-container">
                    <ChatTabMessageContainer
                        lastRead={this.props.lastRead}
                        messages={this.props.messages}
                        isResponding={!!this.state.parentMessage}
                        onRespondToMessage={(parentMessage: any) => {
                            this.setState({
                                parentMessage
                            });
                        }}
                    />
                </div>
                <div className="composer">
                    <ChatTabComposer
                        //@ts-ignore
                        ref={this.composerRef}
                        parentMessage={this.state.parentMessage}
                        onRemoveParentMessage={() => {
                            this.setState({
                                parentMessage: undefined
                            });
                        }}
                        editorState={this.state.editorState}
                        onChange={this.onMessageChange}
                        onFilesSelected={this.onFilesSelected}
                        onSend={this.onSend}
                        placeholder={localize("CHAT_NEW_MESSAGE")}
                    />
                </div>
            </div>
        );
    }

    protected onFilesSelected(files: File[]) {
        files.forEach(async (file) => {
            const message = Message
                .createLocal(
                    this.props.conversationId,
                    "",
                    this.context.currentUser,
                )
                .merge({
                    isUploading: true,
                    uploadProgress: 0,
                });

            this.props.addMessageRealtime(message, this.context.currentUser.id);

            const response = await uploadChatFile(
                this.props.conversationId,
                file,
                message.referenceId,
                (event) => {
                    // TODO: Dispatch progress message
                },
            );

            // TODO: Dispatch update message
        });
    }

    protected onMessageChange(editorState: EditorState) {
        this.setState({ editorState });
    }

    protected onSend(): void {
        const content = this.state.editorState.getCurrentContent();

        const editorState = EditorState.push(
            this.state.editorState,
            ContentState.createFromText(''),
            'remove-range'
        );

        this.setState(
            {
                editorState: EditorState.forceSelection(
                    editorState,
                    editorState.getCurrentContent().getSelectionAfter(),
                ),
            },
            () => {
                const textContent = draftToHtml(content, true).trim();
                if (textContent.length === 0) {
                    return;
                }

                const message = Message.createLocal(
                    this.props.conversationId,
                    textContent,
                    this.context.currentUser,
                    this.state.parentMessage
                );

                this.props.saveMessage(message, this.context.currentUser.id);

                this.setState({
                    parentMessage: undefined
                });

                if (this.props.onMessageSent) {
                    this.props.onMessageSent(message);
                }
            }
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, ownProps) => ({
        messages: state.chat.messages.filter(
            (m) => m.conversationId === ownProps.conversationId,
        ),
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    addMessageRealtime,
    queryMessages,
    saveMessage,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ChatTabContent);
