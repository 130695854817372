import { Modal } from "@fluentui/react";
import React, { useState } from "react";
import PopupHeader from "src/ui/components/PopupHeader";
import SocialComposerTypes from "../Composer/SocialComposerTypes";
import { localize } from "src/l10n";
import SocialComposerHeader from "../Composer/SocialComposerUserHeader";
import { SpintrUser } from "src/ui";
import SocialComposerTarget from "src/social-feed/components/SocialComposerTarget/SocialComposerTarget";
import { circleLarge } from "src/ui/helpers/style";
import Visage2ComposerTypeDropdown from "./Visage2ComposerTypeDropdown";

interface IProps {
    onClosing?: () => void;
    setFeedId: any;
    onPostUpdate: any;
    imageUrl: string;
    userName: string;
    feedId: number;
    post: Spintr.ISocialPostBase;
    hideTargetSelector: boolean;
}

const Visage2ComposerHeader = (props: IProps) => {
    return (
        <div className="Visage2ComposerHeader">
            <SpintrUser 
                name={props.userName} 
                imageUrl={props.imageUrl} 
                onRenderSecondaryText={props.hideTargetSelector ? undefined : () => {
                    return (
                        <div className="SocialComposerTargets">
                            <SocialComposerTarget setFeedId={props.setFeedId} feedId={props.feedId} />
                            {/* <Visage2ComposerTypeDropdown post={props.post} onPostUpdate={props.onPostUpdate} /> */}
                        </div>
                    )
                }}
                size={circleLarge}
            />
        </div>
    )
}

export default Visage2ComposerHeader;