import { TooltipHost } from "@fluentui/react";
import classNames from "classnames";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConversationsPanel } from "src/chat/components/ConversationsPanel";
import { localize } from "src/l10n";
import { NotificationList } from "src/notifications/components";
import ShortcutsPopupView from "src/shortcuts/ShortcutsPopupView";
import Color from "src/style/colors/Color";
import { SpintrTypes } from "src/typings";
import { Label, Scrollable, setDisplayAsOfflineInChat, UnstyledButton } from "src/ui";
import CreateWizard from "src/ui/components/CreateWizard";
import ContentWithUnreadIndicator from "src/ui/components/UnreadIndicator/ContentWithUnreadIndicator";
import { Style } from "src/ui/helpers";
import { mixpanelTrack } from "src/utils";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { startDeliverTrack } from "src/utils/spintrStartDeliverfunctions";
import { IApplicationState } from "../reducer";
import "./SpintrStaticLinks.scss";
import SpintrStaticLinksPopout from "./SpintrStaticLinksPopout";
import SpintrUserMenu from "./SpintrUserMenu";
import { markAllNotificationsAsRead } from "src/notifications/actions";
import api from "../SpintrApi";
import { clearUnreadEmails } from "src/profile/actions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface State {
    showCreateWizard: boolean;
    showShortcuts: boolean;
    showNotifications: boolean;
    showChat: boolean;
    conversationsIconsKey: number;
    shortcutsTitle: string;
    shortcutsEditMode: boolean;
}

interface Props extends RouteComponentProps {
    primaryFGColor: string;
    accentFGColor: string;
    instance?: any;
    isAdmin: boolean;
    isEditor: boolean;
    dispatch?: any;
    profile?: any;
    domain?: any;
    notificationsUnreadCount?: number;
    unreadCountPersonConversations?: number;
    currentUser?: any;
    disablePersonChat?: boolean;
    runIntroduction?: any;
    displayAsOfflineInChat: boolean;
    emailEnabled: boolean;
    googleConnected: boolean;
    office365Connected: boolean;
    exchangeWebmailUrl: string;
    unreadEmails?: number;
}

class SpintrStaticLinks extends Component<Props, State> {
    state = {
        showHelpCenter: false,
        showRecentActivity: false,
        showCreateWizard: false,
        showShortcuts: false,
        showNotifications: false,
        showChat: false,
        conversationsIconsKey: 1,
        shortcutsTitle: localize("Genvagar"),
        shortcutsEditMode: false,
    };

    protected chatIconAnimationRef?: any;
    protected notificationIconAnimationRef?: any;

    displayOfficeShortcuts =
        this.props.currentUser.office365Connected &&
        this.props.instance.get("office365Enabled") &&
        !this.props.instance.get("hideOffice365Shortcuts");

    displayTeams =
        this.props.currentUser.office365Connected &&
        this.props.instance.get("office365Enabled") &&
        this.props.instance.get("teamsTabEnabled") &&
        !this.props.currentUser.isGroupUser;

    hidePopouts() {
        this.setState({
            showNotifications: false,
            showChat: false,
            showCreateWizard: false,
            showShortcuts: false,
            shortcutsEditMode: false
        });
    }

    formatColor = (color: string) => {
        if (!color) {
            return "#EAEAEA";
        }

        if (color.length === 4) {
            color += color.substring(1);
        }

        return color.toUpperCase();
    };

    public setShortcutsTitle = (shortcutsTitle) => {
        this.setState({ shortcutsTitle })
    }

    public setShortcutsEditMode = (shortcutsEditMode) => {
        this.setState({ shortcutsEditMode })
    }

    private getFontColor(): spintrColors {
        let backgroundColor = "#FFFFFF";

        if (!!this.props.instance.get("useColorHeader")) {
            backgroundColor = this.props.instance.get("headerColor");
        }

        //@ts-ignore
        return Style.getHexFromSpintrColor(getLightOrDarkColorBasedOnColor(backgroundColor, "light-grey", "dark-grey"));
    }

    public render(): ReactNode {
        const activeColor = "#FFFFFF";
        const activeTextColor = Style.getHexFromSpintrColor("dark-grey");
        const localStorageId = "spintr_disableChatSound";
        const disableChatSound = localStorage.getItem("spintr_disableChatSound") === "true";

        return (
            <div className="SpintrStaticLinks">
                <UnstyledButton
                    ariaLabel={localize("LaggTillInnehall")}
                    data-cy="add-content"
                    onClick={() => {
                        this.setState({ showCreateWizard: true });
                    }}
                >
                    <div
                        className={classNames(`add-link link no-user-select icon-animation animation-rotate`, {
                            "add-link-AdminOrEditor": this.props.isAdmin || this.props.isEditor,
                        })}
                        id="add-link"
                        style={{
                            backgroundColor: this.props.instance.get("color"),
                        }}
                    >
                        <TooltipHost content={localize("LaggTillInnehall")}>
                            <Visage2Icon
                                icon="add"
                                size="big"
                                hexColor={"#FFFFFF"}
                            />
                        </TooltipHost>
                    </div>
                </UnstyledButton>
                {this.state.showCreateWizard && (
                    <SpintrStaticLinksPopout
                        alternativePosition
                        width={350}
                        headerText={localize("SkapaInnehall")}
                        onDismiss={this.hidePopouts.bind(this)}
                    >
                        <div className="PopoutFrame-content-inner">
                            <CreateWizard
                                modalLess
                                onDismiss={() => {
                                    this.setState({ showCreateWizard: false });
                                }}
                            />
                        </div>
                    </SpintrStaticLinksPopout>
                )}
                <div className="NotificationsChatShortcuts">
                    <UnstyledButton
                        ariaLabel={localize("Notiser")}
                        onClick={() => {
                            this.setState({
                                showNotifications: true,
                            });
                        }}
                    >
                        <div
                            className={`notifications-link link no-user-select svg-animation visage2-box-shadow icon-animation animation-pulse`}
                            id="notifications-link"
                            style={{
                                backgroundColor: activeColor,
                            }}
                            onMouseOver={() => {
                                if (!!this.notificationIconAnimationRef) {
                                    this.notificationIconAnimationRef?.play();
                                }
                            }}
                        >
                            <TooltipHost content={localize("Notiser")}>
                                <ContentWithUnreadIndicator
                                    active={this.state.showNotifications}
                                    count={this.props.notificationsUnreadCount}
                                    secondaryPlacement={true}
                                >
                                    <Visage2Icon
                                        icon="notification"
                                        //size="big"
                                        hexColor={activeTextColor} />
                                </ContentWithUnreadIndicator>
                            </TooltipHost>
                        </div>
                    </UnstyledButton>
                    {this.state.showNotifications && (
                        <SpintrStaticLinksPopout
                            alternativePosition
                            headerText={localize("Notiser")}
                            menuItems={
                                {
                                    items: [
                                        {
                                            text: localize("MarkeraAllSomLast"),
                                            onClick: () => {
                                                this.props.dispatch(markAllNotificationsAsRead());
                                            },
                                        },
                                    ]
                                }
                            }
                            onDismiss={this.hidePopouts.bind(this)}
                            footerButtonText={localize("VisaAlla") + " " + localize("Notiser").toLowerCase()}
                            onFooterButtonClick={() => {
                                this.props.history.push({
                                    pathname: "/notifications",
                                });
                            }}
                        >
                            <div className="PopoutFrame-content-inner">
                                <Scrollable>
                                    <NotificationList />
                                </Scrollable>
                            </div>
                        </SpintrStaticLinksPopout>
                    )}
                    {!this.props.disablePersonChat && (
                        <UnstyledButton
                            ariaLabel={localize("Chatt")}
                            onClick={() => {
                                this.setState({
                                    showChat: true,
                                });
                            }}
                        >
                            <div
                                className={`chat-link link no-user-select svg-animation visage2-box-shadow icon-animation animation-pulse`}
                                id="chat-link"
                                style={{
                                    backgroundColor: activeColor,
                                }}
                                onMouseOver={() => {
                                    if (!!this.chatIconAnimationRef) {
                                        this.chatIconAnimationRef?.play();
                                    }
                                }}
                            >
                                <TooltipHost content={localize("Chatt")} hidden={this.state.showChat}>
                                    <ContentWithUnreadIndicator
                                        active={this.state.showChat}
                                        count={this.props.unreadCountPersonConversations}
                                        secondaryPlacement={true}
                                    >
                                        <Visage2Icon
                                            icon="message"
                                            //size="big"
                                            hexColor={activeTextColor} />
                                    </ContentWithUnreadIndicator>
                                </TooltipHost>
                            </div>
                        </UnstyledButton>
                    )}
                    {this.state.showChat && (
                        <SpintrStaticLinksPopout
                            headerText={localize("Meddelanden")}
                            headerChildren=
                            {
                                this.props.emailEnabled && (
                                    <UnstyledButton className="SpintrStaticLinksCallout-header-icon visage-box-shadow" onClick={() => {
                                        this.props.dispatch(clearUnreadEmails());

                                        var url = this.props.googleConnected
                                            ? "https://mail.google.com"
                                            : this.props.exchangeWebmailUrl;

                                        if (this.props.office365Connected && !url) {
                                            url = "https://outlook.office365.com/owa";
                                        }

                                        if (url?.length > 0) {
                                            window.open(url);
                                        } else {
                                            alert(localize("OppnaDinEpostklientForAttLasa"));
                                        }
                                    }}>
                                        <ContentWithUnreadIndicator
                                            margin={10}
                                            count={this.props.unreadEmails}
                                            secondaryPlacement
                                        >
                                            <Visage2Icon icon="sms" size="medium" color="black" />
                                        </ContentWithUnreadIndicator>
                                    </UnstyledButton>
                                )
                            }
                            menuItems={
                                {
                                    items: [
                                        {
                                            text: disableChatSound ?
                                                localize("chat_sound_on") :
                                                localize("chat_sound_off"),
                                            onClick: () => {
                                                localStorage.setItem(localStorageId, disableChatSound ? "false" : "true");

                                                this.setState({
                                                    conversationsIconsKey: this.state.conversationsIconsKey + 1
                                                });
                                            },
                                        },
                                        {
                                            text: this.props.displayAsOfflineInChat ?
                                                localize("chat_show_as_online") :
                                                localize("chat_show_as_offline"),
                                            onClick: () => {
                                                api.put("/api/chat/setoffline", {
                                                    value: !this.props.displayAsOfflineInChat
                                                });

                                                this.props.dispatch(setDisplayAsOfflineInChat(!this.props.displayAsOfflineInChat));
                                            },
                                        },
                                    ],
                                }
                            }
                            onDismiss={this.hidePopouts.bind(this)}
                            onFooterButtonClick={() => {
                                this.props.history.push({
                                    pathname: "/messages",
                                });
                            }}
                        >
                            <div className="PopoutFrame-content-inner">
                                <ConversationsPanel openChatTabOnClick onConversationClick={() => {
                                    this.hidePopouts();
                                }} />
                            </div>
                        </SpintrStaticLinksPopout>
                    )}
                </div>
                {this.state.showShortcuts && (
                    <SpintrStaticLinksPopout
                        headerText={this.state.shortcutsTitle}
                        extraWide={!this.state.shortcutsEditMode && (this.displayOfficeShortcuts || this.displayTeams)}
                        onDismiss={this.hidePopouts.bind(this)}
                        width={(this.displayOfficeShortcuts || this.displayTeams) ? undefined : 420}>
                        <Scrollable>
                            <div className={classNames("", {
                                "PopoutFrame-content-inner": this.state.shortcutsEditMode
                            })}>
                                <ShortcutsPopupView
                                    extraWide={!this.state.shortcutsEditMode && (this.displayOfficeShortcuts || this.displayTeams)}
                                    setTitle={this.setShortcutsTitle}
                                    setEditMode={this.setShortcutsEditMode} />
                            </div>
                        </Scrollable>
                    </SpintrStaticLinksPopout>
                )}
                <UnstyledButton
                    className="shortcutsButtonWide"
                    style={{
                        borderRadius: 28,
                        backgroundColor: Style.getHexFromSpintrColor("visage2LightGray"),
                        color: Style.getHexFromSpintrColor("dark-grey")
                    }}
                    ariaLabel={localize("Genvagar")}
                    onClick={() => {
                        startDeliverTrack(SpintrTypes.InsightsType.OpenedShortcuts);
                        this.setState({
                            showShortcuts: !this.state.showShortcuts,
                        });

                        if (this.props.location.pathname === "/" && !this.state.showShortcuts) {
                            mixpanelTrack("Opened shortcuts");
                        }
                    }}
                >
                    <div className={`shortcuts-link no-user-select`} id="shortcuts-link">
                        <TooltipHost content={localize("Genvagar")} hidden={this.state.showShortcuts}>
                            <Visage2Icon icon="menu" color="dark-grey" />
                            <Label size="body-2" color="dark-grey">
                                {localize("Genvagar")}
                            </Label>
                            <Visage2Icon
                                color="dark-grey"
                                className="menu-button"
                                icon="arrow-down-1"
                                size="small" />
                        </TooltipHost>
                    </div>
                </UnstyledButton>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    primaryFGColor: state.instance.get("color"),
    accentFGColor: state.instance.get("accentColor"),
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    notificationsUnreadCount: state.notification.unread,
    unreadCountPersonConversations:
        (state.chat.conversations.items || []).filter((c: any) => c.type !== 3).length > 0
            ? (state.chat.conversations.items || []).filter((c: any) => c.type !== 3 && c.unread > 0).length
            : state.chat.conversations.unreadCountPersonConversations,
    disablePersonChat: state.instance.get("disablePersonChat"),
    displayAsOfflineInChat: state.ui.displayAsOfflineInChat,
    unreadEmails: state.profile.unreadEmails,
    googleConnected: state.profile.active.googleConnected,
    office365Connected: state.profile.active.office365Connected,
    exchangeWebmailUrl: state.instance.get("exchangeWebmailUrl"),
    emailEnabled:
        state.instance.get("enableExternalMailLink") != "false" &&
        (state.profile.active.googleConnected ||
            (state.profile.active.roles.includes("aduser") &&
                (state.instance.get("enableExchange") ||
                    (state.profile.active.office365Connected && state.instance.get("office365Enabled"))))),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SpintrStaticLinks));
