import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Dialog, DialogType } from '@fluentui/react/lib/Dialog';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { TextField } from '@fluentui/react/lib/TextField';
import { capitalizeFirstLetter, decodeHtmlEntities } from "src/utils";
import { debounce } from "src/utils";
import { Loader, SpintrUser } from "src/ui";
import { Style } from "src/ui/helpers";
import { connect } from "react-redux";
import { SpintrTypes } from "src/typings";
import api from "src/spintr/SpintrApi";
import PopupHeader from "../../PopupHeader";

interface IProps {
    editor: any,
    onClose: any,
    instance?: any,
    apps?: any
}

interface IState {
    activeType: any,
    types: any,
    isSearching: boolean,
    searchString: string,
    searchResult?: any
}

class TinyAtFunction extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        let types = [{
            text: localize("Fil"),
            key: 7,
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Files)
        }, {
            text: localize("Anvandare"),
            key: 1,
            enabled: true
        }, {
            text: localize("Grupp"),
            key: 26,
            enabled: true
        }, {
            text: capitalizeFirstLetter(localize("Kalenderpost")),
            key: 11,
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Calendars)
        }, {
            text: localize("Blogg"),
            key: 21,
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Blogs)
        }, {
            text: capitalizeFirstLetter(localize("Wiki")),
            key: 15,
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Wikis)
        }, {
            text: capitalizeFirstLetter(localize("Wikiartikel")),
            key: 16,
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Wikis)
        }, {
            text: localize("Sida2"),
            key: 9,
            enabled: true
        }, {
            text: localize("Nyhet"),
            key: 10,
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.News)
        }].sort((a, b) => {
            if (a.text < b.text) {
                return -1;
            } else {
                return 1;
            }
        });

        types = types.filter(t => t.enabled);

        this.state = {
            types,
            activeType: types[0],
            isSearching: false,
            searchString: ""
        };
    }

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    search() {
        this.setState({
            isSearching: true
        }, () => {
            api.get("/api/v1/search?objectType=" + this.state.activeType.key + "&query=" + this.state.searchString).then((response) => {
                this.setState({
                    searchResult: response.data,
                    isSearching: false
                });
            });
        });
    }

    debouncedSearch = debounce(() => this.search(), 500);

    itemClick(item) {
        const text = item.name;

        let text2 = "";

        if (text.indexOf('.pdf') >= 0 || text.indexOf('.jpg') >= 0 || text.indexOf('.png') >= 0 || text.indexOf('.gif') >= 0) {
            text2 = ' <a href="/api/servefile/' + item.id + '?show=true" target="_blank">(' + localize("Visa") + ')</a>';
        }

        this.props.editor.insertContent('<span><a href="/goto/' + item.id + '">' + text + text2 + '</a> </span>');

        this.props.onClose();
    }

    public render(): ReactNode {
        return (
            <Dialog
                hidden={false}
                containerClassName="dialogWithPopupHeader"
                onDismiss={this.props.onClose}
                modalProps={{
                    isBlocking: true,
                    allowTouchBodyScroll: true
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: localize("Sok"),
                    showCloseButton: true,
                    closeButtonAriaLabel: localize("Stang")
                }}>
                <PopupHeader
                    text={localize("Sok")}
                    onClose={this.props.onClose} />
                <Dropdown
                    label={localize("ValjTyp")}
                    selectedKey={this.state.activeType.key}
                    onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                        this.setState({
                            activeType: item
                        });
                    }}
                    options={this.state.types}
                />
                <TextField
                    label={localize("Sok")}
                    value={this.state.searchString}
                    placeholder={localize("VOICE_SEARCH_TEXT")}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        this.setState({
                            searchString: newValue || ''
                        }, this.debouncedSearch);
                    }}
                />
                {
                    this.state.isSearching ?
                        <Loader /> :
                        (this.state.searchResult && this.state.searchResult.length > 0 ?
                            <div className="tiny-at-search-result general-row-break">
                                {
                                    this.state.searchResult.map(item => {
                                        return (
                                            <div key={item.id} style={{ marginTop: Style.getSpacing(4) }}>
                                                <a className="SpintrUser-autoheight" onClick={() => {
                                                    this.itemClick(item);
                                                }}>
                                                    <SpintrUser
                                                        subText={decodeHtmlEntities(item.caption)}
                                                        imageUrl={item.imageUrl}
                                                        name={item.name} />
                                                </a>
                                            </div>
                                        );
                                    })
                                }
                            </div> :
                            null)
                }
            </Dialog>
        );
    }

}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    apps: state.app
});

export default connect(mapStateToProps)(TinyAtFunction);
