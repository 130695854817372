import Axios from "axios";
import { DefaultButton, Image, Modal, PrimaryButton } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { localize, localizeFormat } from "src/l10n";
import { findMarketplaceApp, uninstallMarketplaceApp } from "src/marketplace/api";
import { InstallPopup, MarketplaceWidget } from "src/marketplace/components";
import { getSampleWidgetData } from "src/marketplace/utils";
import { setConfirmPopup } from "src/popups/actions";
import { Label, Loader, LocalizedString, SpintrUser } from "src/ui";
import {
    MarketplaceAppViewProps as Props,
    IMarketplaceAppViewComponentProps as OwnProps,
    IMarketplaceAppViewStateProps as StateProps,
    IMarketplaceAppViewState as State,
    IMarketplaceAppViewDispatchProps as DispatchProps
} from "./MarketplaceAppView.types"
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const initialState: State = {
    app: {
        authenticationMethod: 1,
        categoryId: "",
        description: "",
        installed: false,
        instructions: "",
        name: "",
        tags: [],
        widgets: []
    },
    isLoading: true,
    isShowingInstallPopup: false,
    widgets: [],
};

const MarketplaceAppView: React.FC<Props> = (props) => {
    const [state, setState] = useState<State>(initialState);
    const { history, setConfirmPopup } = props;
    const { app } = state;
    
    const appId = props.match?.params?.id || "";
    useEffect(() => {
        if (!appId) {
            return;
        }

        const cancelTokenSource = Axios.CancelToken.source();

        findMarketplaceApp(appId, cancelTokenSource.token).then(
            (app) => setState((s) => ({ ...s, app, isLoading: false })),
            () => history?.push("/marketplace"),
        );

        return () => cancelTokenSource.cancel();
    }, [appId, history, setState]);

    const onGoToLibraryClicked = useCallback(
        () => history?.push("/marketplace/library"),
        [history],
    );

    const toggleInstallPopup = useCallback(
        () => setState((s) => ({ ...s, isShowingInstallPopup: !s.isShowingInstallPopup})),
        [setState],
    );

    const uninstallApp = useCallback(
        async (): Promise<void> => {
        try {
            const success = await uninstallMarketplaceApp(
                state.app.id || ""
            );

            if (!success) {
                return;
            }
        } catch (_) {
            return;
        }

        setState((s) => ({
            ...s,
            app: {
                ...s.app,
                installed: false
            }
        }));
    }, [setState]);

    const onUninstallClicked = useCallback(() => {
        setConfirmPopup({
            isOpen: true,
            message: localize("ArDuSakerPaAttDuVillGoraDetta"),
            onConfirm: uninstallApp,
            title: localize("MARKETPLACE_UNINSTALL_APP"),
        });
    }, [setConfirmPopup, uninstallApp]);

    const renderInstallButton = useCallback(() => {
        const { byRequest, name } = state.app;

        if (byRequest) {
            return (
                <PrimaryButton
                    as="a"
                    className="button"
                    href={
                        "mailto:support@spintr.me?subject=Activate%20" + name
                    }
                    style={{
                        background: "#22234A",
                        borderColor: "#22234A",
                        display: "block",
                    }}
                    text={localize("CONTACT_SUPPORT")}
                />
            );
        }

        return (
            <PrimaryButton
                onClick={toggleInstallPopup}
                style={{
                    background: "#22234A",
                    borderColor: "#22234A",
                }}
                text={localizeFormat(
                    "MARKETPLACE_ACTIVATE_F",
                    name,
                )}
            />
        );
    }, [app]);

    const onAppInstalled = useCallback(() => {
        setState((s) => ({
            ...s,
            app: {
                ...s.app,
                installed: true,
            },
            isShowingInstallPopup: false,
        }));
    }, [setState]);
    
    const renderInstalledInformation = useCallback(() => (
        <div className="installed-box">
            <PrimaryButton
                className="library-button"
                onClick={onGoToLibraryClicked}
                text={localize("MARKETPLACE_GOTO_LIBRARY")}
                style={{
                    background: "#22234A",
                    borderColor: "#22234A",
                }}
                styles={{flexContainer: {
                    flexDirection: "row-reverse",
                }}}
                iconProps={{
                    iconName: "Forward",
                    
                }}
            />
            <DefaultButton
                className="uninstall-button"
                onClick={onUninstallClicked}
                style={{
                    background: "#FFF",
                    borderColor: "#FC5371",
                    color: "#FC5371",
                }}
                text={localize("MARKETPLACE_REMOVE_APP")}
            />
            <div className="information">
                {/* */}
            </div>
        </div>
    ), [onGoToLibraryClicked, onUninstallClicked]);

    const widgets = useMemo(() => (app?.widgets || []).map((widget) => {
        return (
            <div key={widget.id} className="widget-list-item">
                <div className="widget">
                    <MarketplaceWidget
                        data={{
                            data: widget.dummy || getSampleWidgetData(widget.displayType),
                            displayType: widget.displayType,
                            iconUrl: app.iconUrl,
                            name: widget.name,
                            variables: widget.dummy || {
                                text: localize("UNITS"),
                            },
                        }}
                    />
                </div>
                <Label
                    as="div"
                    className="description"
                    color="dark-grey"
                    size="body-1"
                >{widget.description}</Label>
            </div>
        );
    }), [app]);

    if (state.isLoading) {
        return <Loader />;
    }

    const createWidgetPath = `/marketplace/app/${app.id}/create-widget`;

    return (
        <div id="MarketplaceAppView">
            <div className="view-header">
                <div className="header-text">
                    <Link to="/marketplace">
                        <Label
                            as="h2"
                            className="app-title"
                            color="black"
                            size="h2"
                            weight="semi-bold"
                        >
                            <LocalizedString text="MARKETPLACE_HEADING" />
                        </Label>
                    </Link>
                </div>
            </div>
            <div className="view-body">
                <div className="information">
                    <div className="header-text">
                        <div className="app-identity">
                            <div className="image">
                                <Image
                                    alt={app.name}
                                    src={app.iconUrl}
                                />
                            </div>
                            <div className="text">
                                <div className="app-title">
                                    <Label
                                        as="span"
                                        color="dark-grey"
                                        size="h3"
                                        weight="medium"
                                    >
                                        {app.name}
                                    </Label>
                                </div>
                            </div>
                        </div>
                        {app.installed && (
                            <div className="installed-mark">
                                <Visage2Icon
                                    icon="checkbox_checked"
                                    type="outline"
                                    color="spintrGreen"
                                />
                                <Label as="span" color="spintrGreen" size="body-1">
                                    {localize("INSTALLED")}
                                </Label>
                            </div>
                        )}
                    </div>
                    <div
                        className="description"
                        dangerouslySetInnerHTML={{
                            __html: app.instructions
                        }}
                    />
                    {widgets?.length > 0 && (
                        <div className="widget-examples">
                            <div className="heading">
                                <Label
                                    as="div"
                                    size="h4"
                                    weight="medium"
                                    color="dark-grey"
                                >
                                    {localize("MARKETPLACE_WIDGET_EXAMPLES")}:
                                </Label>
                            </div>
                            <div className="widget-list">
                                {widgets}
                            </div>
                        </div>
                    )}
                </div>
                <div className="sidebar">
                    <div className="actions">
                        {state.app.installed && renderInstalledInformation()}
                        {!state.app.installed && renderInstallButton()}
                        <Modal
                            isBlocking={false}
                            isOpen={state.isShowingInstallPopup}
                            onDismiss={toggleInstallPopup}
                        >
                            <InstallPopup
                                app={state.app}
                                onDismiss={toggleInstallPopup}
                                onInstalled={onAppInstalled}
                            />
                        </Modal>
                    </div>
                    <div className="publisher section">
                        <Label
                            className="heading"
                            color="mid-grey"
                            size="body-2"
                            weight="medium"
                        >
                            {localize("MARKETPLACE_DEVELOPER")}
                        </Label>
                        <Label
                            color="dark-grey"
                            size="body-1"
                        >
                            <SpintrUser
                                name={app.developer.name}
                            />
                        </Label>
                    </div>
                    {props.isDeveloper && (
                        <div className="links section">
                            <Label
                                className="heading"
                                color="mid-grey"
                                size="body-2"
                                weight="medium"
                            >
                                {localize("Lankar")}
                            </Label>
                            <Label size="body-2">
                                <Link to={createWidgetPath}>
                                    {localize("MARKETPLACE_CREATE_WIDGET")}
                                </Link>
                            </Label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const ConnectedMarketplaceAppView = connect<StateProps, DispatchProps, OwnProps, Spintr.AppState>(
    (state) => ({
        isDeveloper: ((state.instance
            .get("developerAccounts") as Spintr.IMarketplaceDeveloperAccount[]) || [])
            .length > 0,
    }),
    ({ setConfirmPopup })
)(MarketplaceAppView);

export default withRouter(ConnectedMarketplaceAppView);
