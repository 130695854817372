import React, { FunctionComponent, MouseEvent, useCallback } from "react";
import { useSelector } from "react-redux";
import { getLinkTarget } from "src/shortcuts/utils";
import { Label } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./ShortcutList.scss";

const ShortcutList: FunctionComponent = () => {
    let shortcuts = useSelector(
        (state: Spintr.AppState) => state.shortcuts.items.filter(
            (shortcut) => !shortcut.highlight,
        ),
    );

    shortcuts = shortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

    const clickCallback = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            const target = event.target as HTMLElement;
            if (target.nodeName === "FORM") {
                (target as HTMLFormElement).submit();
            } else {
                const form = target.querySelector("form");
                if (form) {
                    form.submit();
                }
            }
        },
        []
    );

    return (
        <div className="ShortcutList">
            {shortcuts.map((shortcut) => (
                <div
                    className="shortcut"
                    key={"Shortcut.Id" + shortcut.id}
                >
                    {shortcut.formAction && (
                        <div
                            className="form-wrap"
                            onClick={clickCallback}
                            role="button"
                        >
                            <form
                                action={shortcut.formAction}
                                id={"shortcutFormID" + shortcut.id}
                                method="POST"
                                target={getLinkTarget(shortcut.url)}
                            >
                                <input
                                    name="formAction"
                                    type="hidden"
                                    value={shortcut.formAction}
                                />
                                <input
                                    name="foo"
                                    type="hidden"
                                    value="bar"
                                />
                                <input
                                    name={shortcut.usernameName}
                                    type="hidden"
                                    value={shortcut.usernameValue}
                                />
                                <input
                                    name={shortcut.passwordName}
                                    type="hidden"
                                    value={shortcut.passwordValue}
                                />
                                <div className="shortcut-inner">
                                    <Label>{shortcut.title}</Label>
                                </div>
                            </form>
                        </div>
                    )}
                    {!shortcut.formAction && (
                        <a
                            className="link"
                            href={shortcut.url}
                            target={getLinkTarget(shortcut.url)}
                        >
                            <div className="shortcut-inner">
                                <Label>{shortcut.title}</Label>
                            </div>
                        </a>
                    )}
                    <div className="arrow">
                        <Visage2Icon icon="arrow-right-3" />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ShortcutList;
