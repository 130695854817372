import { AxiosResponse } from "axios";
import classnames from "classnames";
import { INavLink, Stack } from "@fluentui/react";
import React, { Component, CSSProperties } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, Route, RouteComponentProps, Switch } from "react-router-dom";
import CalendarRootView from "src/calendar/components/CalendarRootView";
import { localize } from "src/l10n";
import { PageView } from "src/pages/views";
import { canUserCreatePages } from "src/privileges/utils";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ActionMenu, Breadcrumbs, Label, SpintrUser, Submenu } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import { Style } from "src/ui/helpers";
import generateUniqueId from "src/utils/uniqueId";
import OrganisationInviteView from "./OrganisationInviteView";
import OrganisationPeopleView from "./OrganisationPeopleView";
import OrganisationUnitEditView from "./OrganisationUnitEditView";
import OrganisationUnitStartView from "./OrganisationUnitStartView";
import "./OrganisationUnitView.scss";
import { OrganisationAdminFormView } from ".";
import api from "src/spintr/SpintrApi";

interface IRouteParams {
    unitId: string;
    pageId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isAdmin: boolean;
    isEditor: boolean;
    userCompanyId: number;
    userOfficeId: number;
    userDepartmentId: number;
    userDepartments: any[];
    userName: string;
    isSmallViewMode: boolean;
    instance: any;
    currentUser?: any;
    privileges?: any;
    enableCompanyLevel?: boolean;
}

interface IState {
    isLoading: boolean;
    unit: IUnitUnitView;
    isAdmin: boolean;
    isImpersonating: boolean;
}

interface IUnitUnitView extends Spintr.IUnit {
    location?: string;
}

interface ICalendarEventUnitView extends Spintr.ICalendarEvent {
    isMultipleDays: boolean;
}

class OrganisationUnitView extends Component<IProps, IState> {
    private unitId = Number(this.props.match.params.unitId);

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            unit: null,
            isAdmin: false,
            isImpersonating: false,
        };
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (
            (prevProps.location.pathname.includes("settings") || prevProps.location.pathname.includes("invite")) &&
            (this.props.location.pathname.endsWith(`v/${this.unitId}`) ||
                this.props.location.pathname.endsWith("members"))
        ) {
            // TODO: redux?
            this.setState({
                isLoading: true,
            });
            this.fetchUnit();
        }
    }

    componentDidMount() {
        this.fetchUnit();
    }

    fetchUnit = () => {
        api.get(`/api/v1/units/${this.unitId}`).then((response: AxiosResponse) => {
            let unit = response.data as IUnitUnitView;

            var hasCountry = !!unit.country;
            var hasCity = !!unit.city;

            if (hasCountry && hasCity) {
                unit.location = `${unit.city}, ${unit.country}`;
            } else if (hasCountry && !hasCity) {
                unit.location = unit.country;
            } else if (!hasCountry && hasCity) {
                unit.location = unit.city;
            } else {
                unit.location = null;
            }

            var isAdmin = this.props.isAdmin &&
                (this.unitId == this.props.userDepartmentId || this.unitId == this.props.userOfficeId || this.unitId == this.props.userCompanyId);

            this.setState({
                isLoading: false,
                unit,
                isAdmin,
            });
        });
    };

    truncateString = (value: string, maxLength: number): string => {
        if (value.length <= maxLength) {
            return value;
        }

        return value.slice(0, maxLength) + "...";
    };

    getSubmenuItems = (menuItems: Spintr.IUnitMenuItem[]) => {
        let items = [];

        for (let menuItem of menuItems) {
            let item: INavLink = {
                name: menuItem.title,
                url: menuItem.url,
                nodes: this.getSubmenuItems(menuItem.nodes),
                isExpanded: false,
                action: () => {
                    this.props.history.push({
                        pathname: menuItem.url,
                    });
                },
            };

            items.push(item);
        }

        return items;
    };

    renderHeader = (unit: IUnitUnitView) => {
        const style: CSSProperties = {
            backgroundImage: `url(${unit.coverArt})`,
        };

        return (
            <div className={classnames("unit-header", { ["with-image"]: unit.coverArt })} style={style}>
                <div className="gradient-overlay" />
                {this.state.isAdmin && (
                    <div className="unit-header-menu-wrapper">
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("Installningar"),
                                            onClick: () => {
                                                this.props.history.push({
                                                    pathname: `/organisation/v/${unit.path}/settings`,
                                                });
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />
                    </div>
                )}
                <Stack className="content" horizontal horizontalAlign="space-between" verticalAlign="end">
                    <SpintrUser
                        name={unit.name}
                        imageUrl={unit.imageUrl}
                        subText={unit.unitType == SpintrTypes.UnitType.Department ? unit.officeName : ""}
                        textColor={"#fff"}
                        size={80}
                        onRenderPrimaryText={() => (
                            <Link to={`/organisation/v/${this.unitId}`}>
                                <Label color="white" size="h4" as="h4">
                                    {this.state.unit.name}
                                </Label>
                            </Link>
                        )}
                    />
                    {!this.props.isSmallViewMode && (
                        <Stack
                            className="members"
                            horizontal
                            verticalAlign="center"
                            tokens={{ childrenGap: Style.getSpacing(3) }}
                        >
                            <Stack tokens={{ childrenGap: -9 }} horizontal>
                                {unit.firstUsers.map((firstUser) => (
                                    <SpintrUser
                                        name={firstUser.name}
                                        key={`${firstUser.name}-${generateUniqueId()}`}
                                        imageUrl={firstUser.imageUrl}
                                        size={28}
                                        hideText
                                    />
                                ))}
                            </Stack>

                            <Link to={`/organisation/v/${this.unitId}/members`}>
                                <Label size="body-2" as="span" color="white">
                                    {unit.userCount}{" "}
                                    {localize(unit.userCount == 1 ? "Medlem" : "Medlemmar").toLowerCase()}
                                </Label>
                            </Link>
                        </Stack>
                    )}
                </Stack>
            </div>
        );
    };

    renderSidebarInfoPart(title, renderContent) {
        return (
            <div className="sidebar-info-part">
                <Label size="small-2" color="dark-grey" weight="regular">
                    {title.toUpperCase()}
                </Label>
                <div className="sidebar-info-part-content">
                    <Label as="span" size="body-2">
                        {renderContent()}
                    </Label>
                </div>
            </div>
        );
    }

    renderSidebar = (unit: IUnitUnitView) => {
        const iframe = `<iframe className="map" aria-label="${localize(
            "Karta"
        )}" width="100%" height="300" frameborder="0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAVB0aZK9eoz3LbX7GrXZ7aKanoxG6ImFo&q=${
            unit.mapQuery
        }" allowfullscreen></iframe>`;

        return (
            <div className="sidebar">
                <div className="sidebar-info" role="complementary">
                    {unit.mapQuery && <div dangerouslySetInnerHTML={{ __html: iframe }} />}
                    <div className="summary">
                        <div className="info">
                            <Label className="info-title" title={unit.name}>
                                {unit.name}
                            </Label>
                            {unit.location &&
                                this.renderSidebarInfoPart(localize("Plats"), () => {
                                    return <span>{unit.location}</span>;
                                })}
                            {this.props.enableCompanyLevel && unit.unitType != SpintrTypes.UnitType.Company &&
                                this.renderSidebarInfoPart(localize("Foretag"), () => {
                                    return <span>{unit.companyName}</span>;
                                })}
                            {unit.unitType == SpintrTypes.UnitType.Department &&
                                this.renderSidebarInfoPart(localize("Kontor"), () => {
                                    return <span>{unit.officeName}</span>;
                                })}
                            {unit.address &&
                                unit.address.length > 0 &&
                                this.renderSidebarInfoPart(localize("Adress"), () => {
                                    return (
                                        <div>
                                            {unit.address.map((address, index) => {
                                                return (
                                                    <span>
                                                        {address + (index + 1 !== unit.address.length ? ", " : "")}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            {unit.phoneNumber &&
                                unit.phoneNumber.length > 0 &&
                                this.renderSidebarInfoPart(localize("Telefonnummer"), () => {
                                    return <span>{unit.phoneNumber}</span>;
                                })}
                            {unit.emailAddress &&
                                unit.emailAddress.length > 0 &&
                                this.renderSidebarInfoPart(localize("EMAIL_ADDRESS"), () => {
                                    return (
                                        <span className="raw-row-break">
                                            <a href={`mailto:${unit.emailAddress}`}>{unit.emailAddress}</a>
                                        </span>
                                    );
                                })}
                            {unit.message &&
                                unit.message.length > 0 &&
                                this.renderSidebarInfoPart(localize("Meddelande"), () => {
                                    return <span>{unit.message}</span>;
                                })}
                            {unit.websiteUrl &&
                                unit.websiteUrl.length > 0 &&
                                this.renderSidebarInfoPart(localize("Hemsida"), () => {
                                    return (
                                        <span className="raw-row-break">
                                            <a href={unit.websiteUrl} target="_blank">
                                                {unit.websiteUrl}
                                            </a>
                                        </span>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <Submenu
                    take={999}
                    title={localize("Meny")}
                    fetch={() => {
                        return new Promise(async (resolve, reject) => {
                            const items: INavLink[] = this.getSubmenuItems(unit.menuItems);

                            var isMember =
                                this.unitId == this.props.userDepartmentId ||
                                this.props.userDepartments.some(
                                    (d) => d.id == this.unitId || d.officeId == this.unitId
                                );

                            items.unshift({
                                id: -2,
                                name: localize("AllaAnstallda"),
                                url: `/organisation/v/${this.unitId}/members`,
                            });
                            if (
                                canUserCreatePages(this.props.privileges, this.props.currentUser, this.props.instance)
                            ) {
                                items.push({
                                    id: -1,
                                    create: true,
                                    name: localize("SkapaTextsida"),
                                    url: `/pages/create?parent=${unit.menuItemId}`,
                                });
                            }

                            if (unit.calendarId > -1 && isMember) {
                                items.unshift({
                                    name: localize("Kalender"),
                                    id: -2,
                                    url: `/organisation/v/${this.unitId}/calendar`,
                                });
                            }

                            resolve(items);
                        });
                    }}
                />
            </div>
        );
    };

    OrganisationCalendarView = (props: any) => {
        return <CalendarRootView unitId={this.state.unit.id} calendarId={this.state.unit.calendarId} />;
    };

    OrganisationUnitStartView = (props: any) => {
        return <OrganisationUnitStartView unit={this.state.unit} />;
    };

    OrganisationPeopleView = (props: any) => {
        return <OrganisationPeopleView history={this.props.history} unit={this.state.unit} />;
    };

    OrganisationUnitEditView = (props: any) => {
        var isOffice = this.state.unit.unitType == SpintrTypes.UnitType.Office;

        return (
            <OrganisationUnitEditView
                departmentId={!isOffice ? this.state.unit.id.toString() : null}
                officeId={(isOffice ? this.state.unit.id : this.state.unit.officeId).toString()}
            />
        );
    };

    pageView = (props: any) => {
        return <PageView hideSubmenu hideBreadcrumbs key={this.props.location.pathname} />;
    };

    private routes: Spintr.ISpintrRoute[] = [
        { path: "/organisation/v/:unitId", exact: true, component: this.OrganisationUnitStartView },
        { path: "/organisation/v/:unitId/members", exact: true, component: this.OrganisationPeopleView },
        { path: "/organisation/v/:unitId/calendar", exact: true, component: this.OrganisationCalendarView },
        { path: "/organisation/v/:unitId/calendar/:eventId", exact: true, component: this.OrganisationCalendarView },
        { path: "/organisation/v/:unitId/texter/:pageId+", exact: true, component: this.pageView },
        { path: "/organisation/v/:unitId/invite/:inviteId", exact: true, component: OrganisationInviteView },
        { path: "/organisation/v/:firstId/:secondId?/:thirdId?/settings", exact: true, component: OrganisationAdminFormView },
    ];

    renderComponent = (RoutedComponent: any, props: any = {}) => {
        return <RoutedComponent {...props} />;
    };

    renderContent = (unit: IUnitUnitView) => {
        return (
            <Switch>
                {this.routes.map((route) => (
                    <Route
                        exact={route.exact || false}
                        key={`AdminRootViewRoute.${route.path}`}
                        path={route.path}
                        render={this.renderComponent.bind(this, route.component)}
                    />
                ))}
            </Switch>
        );
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const { unit } = this.state;

        return (
            <div className="organisation-unit-view">
                <Helmet>
                    <title>{unit.name}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            text: localize("Organisation"),
                            key: 0,
                            link: "/organisation",
                        },
                        {
                            text: unit.name,
                            key: unit.id,
                            link: `/organisation/v/${unit.id}`,
                        },
                    ]}
                />
                {this.renderHeader(unit)}
                {!this.props.isSmallViewMode ? (
                    <Stack tokens={{ childrenGap: Style.getSpacing(4) }} horizontal>
                        <div className="content">{this.renderContent(unit)}</div>
                        {this.renderSidebar(unit)}
                    </Stack>
                ) : (
                    <>
                        {this.renderSidebar(unit)}
                        {this.renderContent(unit)}
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        userDepartmentId: state.profile.active.department.id,
        userDepartments: state.profile.active.departments,
        userOfficeId: state.profile.active.department.office.id,
        userCompanyId: state.profile.active.department.office.company.id,
        userName: state.profile.active.name,
        isSmallViewMode: state.ui.isSmallViewMode,
        privileges: state.privileges.data,
        currentUser: state.profile.active,
        instance: state.instance,
        enableCompanyLevel: state.instance.get("enableCompanyLevel"),
    };
};

export default connect(mapStateToProps)(OrganisationUnitView);
