import classnames from "classnames";
import { List } from "immutable";
import moment from "moment";
import {
    CommandBar,
    CommandBarButton, DefaultButton, ICommandBarItemProps, IContextualMenuItem,
    Modal, SearchBox, Stack
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { Action } from "redux";
import UploadsInProgressIndicator from "src/files/components/UploadsInProgressIndicator";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { addUploads, Breadcrumbs, Icon, Label, Loader, PageHeader, updateUpload } from "src/ui";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { debounce, isAnythingDirty, uniqueId } from "src/utils";
import { MediaFolderEditView } from ".";
import { fetchMediaContent, setMediaSearch } from "../media-actions";
import "./MediaFolderView.scss";
import api from "src/spintr/SpintrApi";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import PopupHeader from "src/ui/components/PopupHeader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    isLoading: boolean;
    isLoadingMore: boolean;
    folders: List<Spintr.IMediaFolder>;
    images: List<Spintr.IMediaImage>;
    match: any;
    totalCount: number;
    history: any;
    tagId: number;
    searchText: string;
    userCanEditAndRemove: boolean;
    userCanUpload: boolean;
    editableType: boolean;
    folderPath: Spintr.IBreadcrumbItem[];
    isSmallViewMode: boolean;
    dispatch: (action: Action) => void;
}

interface IState {
    sortingMode: ISortingMode;
    sortingAscending: boolean;
    searchText: string;
    searchExpanded: boolean;
    isCreateFolderOpen: boolean;
    isEditFolderOpen: boolean;
    isDraggingOver: boolean;
    isRemovingItem: boolean;
    displayUnsavedChangesPopup: boolean;
}

interface ISortingMode {
    id: number;
    name: string;
}

class MediaFolderView extends Component<IProps, IState> {
    private readonly rootClassName = "media-view";
    private sortingModes: ISortingMode[];
    private fileInput: HTMLInputElement;

    constructor(props: IProps) {
        super(props);

        this.sortingModes = [
            { id: 0, name: localize("Senaste") },
            { id: 1, name: localize("A-Z") },
        ];

        this.state = {
            sortingMode: this.sortingModes[0],
            sortingAscending: true,
            searchText: "",
            searchExpanded: !props.isSmallViewMode,
            isCreateFolderOpen: false,
            isEditFolderOpen: false,
            isDraggingOver: false,
            isRemovingItem: false,
            displayUnsavedChangesPopup: false
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch(skip?: number) {
        this.props.dispatch(
            fetchMediaContent(
                this.props.match.params.folderId || 0,
                skip,
                this.state.sortingMode.id,
                this.props.tagId,
                this.props.searchText
            )
        );
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            prevProps.match.params.folderId !== this.props.match.params.folderId ||
            prevProps.tagId !== this.props.tagId ||
            prevProps.searchText !== this.props.searchText
        ) {
            this.fetch();
        }
    }

    loadMore(): void {
        this.fetch(this.props.folders.count() + this.props.images.count());
    }

    renderActions(id, isFolder) {
        return (
            <StandardActionMenu
                canDelete={true}
                objectId={id}
                onDeleteClick={() => {
                    if (isFolder) {
                        this.props.dispatch(
                            setConfirmPopup({
                                isOpen: true,
                                title: localize("ArDuSakerPaAttDuVillTaBortMappen2"),
                                onConfirm: async () => {
                                    this.removeFolder(id);
                                },
                            })
                        );
                    } else {
                        this.props.dispatch(
                            setConfirmPopup({
                                isOpen: true,
                                title: localize("ArDuSakerPaAttDuVillRaderaDennaFil"),
                                onConfirm: async () => {
                                    this.removeFile(id);
                                },
                            })
                        );
                    }
                }} />
        )
    }

    renderFolder(folder: Spintr.IMediaFolder): JSX.Element {
        var style: React.CSSProperties = {};

        if (folder.coverImage) {
            style.backgroundImage = `url(${folder.coverImage})`;
            style.backgroundSize = "cover";
            style.backgroundPosition = "center";
            style.backgroundRepeat = "no-repeat";
        }

        return (
            <div className="item" key={folder.id}>
                <NavLink className="fc-dark-grey" to={`/media/folder/${folder.id}`} aria-label={folder.name}>
                    <div className="cover" style={style}>
                        {!folder.coverImage && <Visage2Icon icon="folder" />}
                    </div>
                </NavLink>
                <div className="info gutter-s">
                    <div className="name">
                        <NavLink className="fc-dark-grey" to={`/media/folder/${folder.id}`}>
                            <Visage2Icon className="folder-icon" icon="folder" />
                            <Label className="folder-icon-name" as="span" size="body-2" title={folder.name}>
                                {folder.name}
                            </Label>
                        </NavLink>
                    </div>

                    <div className="sub-info">
                        <Label as="span" size="small-1" color="mid-grey" weight="medium" className="count">
                            {folder.itemCount}{" "}
                            {folder.itemCount == 0 || folder.itemCount > 1
                                ? localize("Filer").toLowerCase()
                                : localize("Fil").toLowerCase()}
                        </Label>
                        <Label as="span" size="small-1" color="mid-grey" weight="regular" className="date">
                            {moment(folder.date).format("YYYY-MM-DD")}
                        </Label>
                    </div>
                </div>
                {
                    folder.userCanEditAndRemove && this.renderActions(folder.id, true)
                }
            </div>
        );
    }

    renderImage(image: Spintr.IMediaImage): JSX.Element {
        var style: React.CSSProperties = {};

        if (image.imageUrl || image.videoThumbnailUrl) {
            style.backgroundImage = `url(${image.imageUrl || image.videoThumbnailUrl})`;
        }

        return (
            <div className="item" style={{
                ...style,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }} key={image.id}>
                <NavLink className="fc-white" to={`/media/image/${image.id}`}>
                    <div style={{ paddingTop: "56.25%" }}></div>
                    <div
                        className="info gutter-s"
                        style={{
                            background: "linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
                        }}
                    >
                        <div className="name">
                            <Label as="span" weight="medium" size="body-2" title={image.displayName || image.name}>
                                {image.displayName || image.name}
                            </Label>
                        </div>

                        <div className="sub-info">
                            <Label
                                as="span"
                                size="small-1"
                                color="grey"
                                weight="medium"
                                className="authorName"
                                title={image.authorName}
                            >
                                {image.authorName}
                            </Label>
                            <Label as="span" size="small-1" color="grey" weight="regular" className="date">
                                {moment(image.date).format("YYYY-MM-DD")}
                            </Label>
                        </div>
                    </div>
                </NavLink>
                {
                    this.props.userCanEditAndRemove && this.renderActions(image.id, false)
                }
            </div>
        );
    }

    setSort(value: number): void {
        this.setState(
            {
                sortingMode: this.sortingModes[value],
            },
            () => {
                this.fetch();
            }
        );
    }

    removeDragOver(event: React.DragEvent<HTMLDivElement>): void {
        event.stopPropagation();
        event.preventDefault();

        this.setState({
            isDraggingOver: false,
        });
    }

    renderHeader(state: IState): JSX.Element {
        return (
            <PageHeader title={this.props.folderPath &&
                this.props.folderPath.length > 0 &&
                this.props.folderPath[0] &&
                this.props.folderPath[0].text ?
                this.props.folderPath[0].text :
                localize("Mediabank")} />
        )
    }

    fetchSearch() {
        this.props.dispatch(setMediaSearch(this.state.searchText));
    }

    debouncedFetchSearch = debounce(() => this.fetchSearch(), 500);

    searchEvent(event: React.ChangeEvent, value: string): void {
        this.setState(
            {
                searchText: value,
            },
            this.debouncedFetchSearch
        );
    }

    onFilesSelected(filesInput: FileList) {
        let files = Array.from(filesInput);
        let baseTicket = new Date().getTime().toString();
        var uploadedImages = [];

        var uploadImage = (file: File) => {
            let body = new FormData();
            let ticket = baseTicket + uniqueId();

            body.append("type", "1");
            body.append("file", file);
            body.append("ticket", ticket);

            const config = {
                onUploadProgress: (progressEvent) => {
                    this.props.dispatch(updateUpload({
                        uploadId: file.lastModified,
                        total: progressEvent.total,
                        loaded: progressEvent.loaded
                    }));
                },
            };

            return api.post("/api/uploads", body, config).then(() => {
                uploadedImages.push({
                    tickets: [ticket],
                    displayName: file.name,
                });
            });
        };

        var uploadVideo = (file: File) => {
            let body = new FormData();

            const config = {
                onUploadProgress: (progressEvent) => {
                    this.props.dispatch(updateUpload({
                        uploadId: file.lastModified,
                        total: progressEvent.total,
                        loaded: progressEvent.loaded
                    }));
                },
            };

            body.append("type", "2");
            body.append("galleryId", this.props.match.params.folderId);
            body.append("displayName", file.name);
            body.append("flag", "1");
            body.append("file", file);

            return api.post("/api/azureupload", body, config);
        };

        var registerImages = () => {
            if (uploadedImages.length == 0) {
                this.fetch();
                return;
            }

            api
                .post("/api/images", {
                    galleryId: this.props.match.params.folderId,
                    images: uploadedImages,
                    flag: 1
                })
                .then(() => {
                    this.fetch();
                });
        };

        var runUploadQueue = (files: File[]) => {
            let p = [];

            for (let file of files) {
                if (file.type.startsWith("image")) {
                    p.push(uploadImage(file));
                } else if (file.type.startsWith("video")) {
                    p.push(uploadVideo(file));
                }
            }

            Promise.all(p).then(() => {
                registerImages();
            });
        };

        let uploads = [];

        for (const file of files) {
            if (file.type.startsWith("image") || file.type.startsWith("video")) {
                uploads.push({
                    uploadId: file.lastModified,
                    total: file.size,
                    loaded: 0
                });
            }
        }

        this.props.dispatch(addUploads(uploads));

        runUploadQueue(files);
    }

    commandBarSearch(state: IState): ICommandBarItemProps {
        return {
            key: "search",
            onRender: (a, b) => {
                if (!state.searchExpanded) {
                    return (
                        <CommandBarButton
                            iconProps={{ iconName: "Search" }}
                            onClick={() => this.setState({ searchExpanded: true })}
                        />
                    );
                }

                return (
                    <SpintrSearch
                        value={this.state.searchText}
                        onChange={this.searchEvent.bind(this)} />
                )
            },
        };
    }

    commandBarAddFolder(state: IState): ICommandBarItemProps {
        return {
            key: "add",
            text: this.props.isSmallViewMode ? "" : localize("SkapaNyMapp"),
            onClick: () => {
                this.setState({ isCreateFolderOpen: true });
            },
            iconProps: { iconName: "Add" },
            className: "commandBarAddButton",
            onRenderIcon: () => {
                return (
                    <Visage2Icon
                        icon={"add"}
                        size="big"
                        color={"white"} />
                )
            }
        };
    }

    commandBarUpload(state: IState): ICommandBarItemProps {
        return {
            key: "upload",
            text: this.props.isSmallViewMode ? "" : localize("LaddaUpp"),
            iconProps: { iconName: "Upload" },
            onClick: () => {
                this.fileInput.click();
            },
            onRenderIcon: () => {
                return (
                    <Visage2Icon
                        color="light-blue"
                        icon={"document-upload"} />
                )
            }
        };
    }

    commandBarEditFolder(state: IState): ICommandBarItemProps {
        return {
            key: "edit",
            text: localize("RedigeraMapp"),
            onClick: () => {
                this.setState({
                    isEditFolderOpen: true,
                });
            },
        };
    }

    removeFolder(id) {
        this.setState({
            isRemovingItem: true
        }, () => {
            api.delete(`/api/v1/galleries/${id}`).then(() => {
                if (id === this.props.match.params.folderId) {
                    this.props.history.push({
                        pathname: `/media`,
                    });
                } else {
                    this.setState({
                        isRemovingItem: false
                    }, () => {
                        this.fetch();
                    });
                }
            });
        });
    }

    removeFile(id) {
        this.setState({
            isRemovingItem: true
        }, () => {
            api.delete(`/api/images/${id}`).then(() => {
                this.setState({
                    isRemovingItem: false
                }, () => {
                    this.fetch();
                });
            });
        });
    }

    commandBarRemoveFolder(state: IState): ICommandBarItemProps {
        return {
            key: "remove",
            text: localize("TaBortMapp"),
            onClick: () => {
                this.props.dispatch(
                    setConfirmPopup({
                        isOpen: true,
                        title: localize("ArDuSakerPaAttDuVillTaBortMappen2"),
                        onConfirm: async () => {
                            this.removeFolder(this.props.match.params.folderId);
                        },
                    })
                );
            },
        };
    }

    renderCommandBar(state: IState): JSX.Element {
        return (
            <div>
                <CommandBar
                    className="CommandBar-GrowSearch"
                    items={[
                        /*
                        {
                            key: "divider",
                            itemType: ContextualMenuItemType.Divider,
                            onRender: () => <VerticalDivider className="divider" />,
                        },
                        */
                        this.commandBarSearch(state),
                        ...(!this.props.match.params.folderId || this.props.userCanUpload
                            ? [this.commandBarAddFolder(state)]
                            : []),
                        ...(this.props.userCanUpload ? [this.commandBarUpload(state)] : []),
                    ]}
                    overflowItems={[
                        ...(this.props.editableType && this.props.userCanEditAndRemove
                            ? [this.commandBarEditFolder(state), this.commandBarRemoveFolder(state)]
                            : []),
                    ]}
                    farItems={[
                        {
                            key: "sort",
                            text: localize("Sortering"),
                            subMenuProps: {
                                className: "media-sort",
                                items: this.sortingModes.map((sortingMode) => {
                                    return {
                                        key: sortingMode.name,
                                        text: sortingMode.name,
                                        onClick: () => {
                                            this.setSort(sortingMode.id);
                                        },
                                        style: {
                                            fontWeight: sortingMode.id == this.state.sortingMode.id ? 700 : "initial",
                                        }, // supposedly deprecated in favor of styles prop, but it doesn't exist??
                                    } as IContextualMenuItem;
                                }),
                            },
                        },
                    ]}
                />
            </div>
        );
    }

    renderResponsiveCommandBar(state: IState): JSX.Element {
        return (
            <div>
                <Breadcrumbs
                    items={[
                        {
                            key: "0",
                            text: localize("Mediabank"),
                            link: "/media",
                        },
                        ...this.props.folderPath,
                    ]}
                />
                {this.renderHeader(state)}
                <CommandBar
                    items={[]}
                    overflowItems={[
                        ...(this.props.editableType && this.props.userCanEditAndRemove
                            ? [this.commandBarEditFolder(state), this.commandBarRemoveFolder(state)]
                            : []),
                    ]}
                    farItems={[
                        this.commandBarSearch(state),
                        this.commandBarAddFolder(state),
                        ...(this.props.userCanUpload ? [this.commandBarUpload(state)] : []),
                    ]}
                />
            </div>
        );
    }

    renderItems(): JSX.Element {
        if (this.props.isLoading || this.state.isRemovingItem) {
            return <Loader />;
        }

        return (
            <div className="items">
                {this.props.folders.map((folder) => {
                    return this.renderFolder(folder);
                })}
                {this.props.images.map((image) => {
                    return this.renderImage(image);
                })}
            </div>
        );
    }

    renderNoContent(): JSX.Element {
        return (
            <Stack
                verticalAlign="center"
                horizontalAlign="center"
                styles={{
                    root: { minHeight: 190 },
                }}
            >
                {this.props.searchText.length > 0 ? (
                    <Label>{localize("IngaTraffar")}</Label>
                ) : this.props.userCanUpload ? (
                    <>
                        <Label as="p">{localize("SlappDinaBilderEllerVideoklipp")}</Label>
                        <div>
                            <Label as="span">{localize("Eller_small")}</Label>&nbsp;
                            <a
                                href="javascript:void(0);"
                                onClick={() => {
                                    this.fileInput.click();
                                }}
                            >
                                {localize("KlickaHar").toLowerCase()}
                            </a>
                            &nbsp;
                            <Label as="span">{localize("ForAttLaddaUpp")}</Label>
                        </div>
                    </>
                ) : (
                    <Label>{localize("DuSaknarRattigheterTillAttLaddaUppTillDetHarAlbumet")}</Label>
                )}
            </Stack>
        );
    }

    renderFooterButtons(): JSX.Element {
        return (
            <>
                {!this.props.match.params.folderId || this.props.userCanUpload ? (
                    <DefaultButton
                        onClick={() => {
                            this.setState({ isCreateFolderOpen: true });
                        }}
                        text={`${localize("SkapaNyMapp")}`}
                    />
                ) : null}
                {this.props.userCanUpload ? (
                    <DefaultButton
                        onClick={() => {
                            this.fileInput.click();
                        }}
                        text={`${localize("LaddaUpp")}`}
                    />
                ) : null}
            </>
        );
    }

    renderUnsavedChangesDialog() {
        return (
            <CustomDialog
                message={localize("UnsavedChangesWarning")}
                show={!!this.state.displayUnsavedChangesPopup}
                confirmMessage={localize("Ok")}
                onDismiss={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    });
                }}
                onConfirm={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    }, () => {
                        this.setState({
                            isCreateFolderOpen: false,
                            isEditFolderOpen: false
                        });
                    });
                }} />
        )
    }

    closeModal() {
        if (isAnythingDirty()) {
            this.setState({
                displayUnsavedChangesPopup: true
            });
        } else {
            this.setState({
                isCreateFolderOpen: false,
                isEditFolderOpen: false
            });
        }
    }

    render() {
        const state = this.state;

        return (
            <div className={this.rootClassName}>
                <UploadsInProgressIndicator />
                {this.props.isSmallViewMode && (
                    <>
                        {this.renderResponsiveCommandBar(state)}
                    </>
                )}
                {!this.props.isSmallViewMode && (
                    <>
                        {this.renderHeader(state)}
                        {this.renderCommandBar(state)}
                    </>
                )}
                <Modal
                    className="spintr-modal modalWithPopupHeader"
                    allowTouchBodyScroll
                    isOpen={state.isCreateFolderOpen || state.isEditFolderOpen}
                    //styles={{ main: { padding: 20 } }}
                    onDismiss={this.closeModal.bind(this)}
                >
                    <PopupHeader
                        text={state.isEditFolderOpen && this.props.match.params.folderId > 0 ? localize("RedigeraMapp") : localize("SkapaMapp")}
                        onClose={this.closeModal.bind(this)} />
                    <MediaFolderEditView
                        parentId={this.props.match.params.folderId}
                        folderId={state.isEditFolderOpen ? this.props.match.params.folderId : null}
                        close={this.closeModal.bind(this)}
                        isModal
                    />
                </Modal>
                <div
                    className={classnames("drop", {
                        "is-dragging-file-over": state.isDraggingOver,
                    })}
                    onDrop={(event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        if (!this.props.userCanUpload) {
                            return;
                        }

                        this.setState({
                            isDraggingOver: false,
                        });

                        this.onFilesSelected(event.dataTransfer.files);
                    }}
                    onDragOver={(event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        if (!this.props.userCanUpload) {
                            return;
                        }

                        this.setState({
                            isDraggingOver: true,
                        });
                    }}
                    onDragExit={this.removeDragOver.bind(this)}
                    onDragLeave={this.removeDragOver.bind(this)}
                    onDragEnd={this.removeDragOver.bind(this)}
                >
                    {this.renderItems()}
                    {!this.props.isLoading && this.props.folders.count() == 0 && this.props.images.count() == 0
                        ? this.renderNoContent()
                        : null}
                </div>
                <Stack horizontal tokens={{ childrenGap: 12 }} styles={{ root: { marginTop: 12 } }}>
                    {!this.props.isLoading &&
                        !this.props.isLoadingMore &&
                        !this.state.isRemovingItem &&
                        this.props.totalCount > this.props.folders.count() + this.props.images.count() ? (
                        <DefaultButton
                            text={`${localize("VisaFler")} (${this.props.totalCount - (this.props.folders.count() + this.props.images.count())
                                })`}
                            onClick={() => this.loadMore()}
                        />
                    ) : null}
                </Stack>
                {this.props.isLoadingMore ? <Loader /> : null}
                <input
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    type="file"
                    multiple
                    accept="image/*,video/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                        this.onFilesSelected(e.target.files);
                    }}
                />
                {
                    this.renderUnsavedChangesDialog()
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,

        isLoading: state.media.media.get("isLoadingMedia"),
        isLoadingMore: state.media.media.get("isLoadingMoreMedia"),
        folders: state.media.media.get("folders"),
        images: state.media.media.get("images"),
        totalCount: state.media.media.get("totalCount"),
        tagId: state.media.media.get("tagId"),
        searchText: state.media.media.get("searchText"),
        userCanEditAndRemove: state.media.media.get("userCanEditAndRemove"),
        userCanUpload: state.media.media.get("userCanUpload"),
        editableType: state.media.media.get("editableType"),
        folderPath: state.media.media.get("folderPath"),
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default withRouter(connect(mapStateToProps)(MediaFolderView));
