import { PrimaryButton } from "@fluentui/react";
import classNames from "classnames";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { Breadcrumbs, ContentWithInfoPanel, Label, Loader, PageInfoPanel, setDisableWrapperBackground, setUseDocumentWidthMode } from "src/ui";
import BlogPost from "./BlogPost";
import "./BlogsPostView.scss";

interface IPageRouteParams {
    path: string;
}

interface IBlogsPostViewProps extends RouteChildrenProps<IPageRouteParams> {
    match: any;
    appMode: boolean;
    dispatch?: any;
    post?: any;
    isPreview?: boolean;
    goBackToFormCallback?: any;
}

interface IBlogsPostViewState {
    post: any;
    isLoading: boolean;
}

class BlogsPostView extends Component<IBlogsPostViewProps, IBlogsPostViewState> {
    constructor(props: IBlogsPostViewProps) {
        super(props);
        this.state = {
            post: props.post ? props.post : {},
            isLoading: !props.post,
        };
    }

    private getByRoute = (route, ignoreLoading?) => {
        let params = { route: route };
        this.setState({ isLoading: ignoreLoading ? false : true }, () => {
            api
                .get("/api/v1/routes", { params })
                .then((response) => {
                    const post = response.data as Spintr.IBlogPost;
                    this.setState({ post }, () => {
                        this.setState({ isLoading: false });
                        if (post.mediaUploadType === 1 && !(post.mediaUploadStatus === 3)) {
                            setTimeout(() => {
                                this.getByRoute(route, true);
                            }, 10 * 1000);
                        }
                    });
                })
                .catch(() => {
                    let newRoute = route.split("/");
                    newRoute[0] = "blogs";
                    newRoute = newRoute.join("/");
                    params = { route: newRoute };
                    api
                        .get("/api/v1/routes", { params })
                        .then((response) => {
                            const post = response.data as Spintr.IBlogPost;
                            this.setState({ post }, () => {
                                this.setState({ isLoading: false });
                                if (post.mediaUploadType === 1 && !(post.mediaUploadStatus === 3)) {
                                    setTimeout(() => {
                                        this.getByRoute(route, true);
                                    }, 10 * 1000);
                                }
                            });
                        })
                        .catch(() => {
                            this.props.history.push("/404");
                        });
                });
        });
    };

    public componentDidMount = () => {
        if (!this.props.post) {
            const { blogSlug, postSlug } = this.props.match.params;
            this.getByRoute(`bloggar/${blogSlug}/${postSlug}`);
        }

        this.props.dispatch(setUseDocumentWidthMode(true));
        this.props.dispatch(setDisableWrapperBackground(true));
    };

    componentWillUnmount = () => {
        this.props.dispatch(setUseDocumentWidthMode(false));
        this.props.dispatch(setDisableWrapperBackground(false));
    }

    renderInfoPanel() {
        const { post } = this.state;

        return (
            <PageInfoPanel
                uberId={post.id}
                displayTags={true}
                displayLastEdited={true}
                displayReach={true}
                displayPublishers={post.showAuthor}
                publishers={[{ ...post.author, imageUrl: post.author.image, info: post.author.departmentName }]}
                isWide={false}
                tags={
                    post.tags &&
                    post.tags.map((tag) => {
                        if (typeof tag === "string") {
                            return tag;
                        } else {
                            return tag.text;
                        }
                    })
                }
            />
        )
    }

    public render = () => {
        const { blogSlug, postSlug } = this.props.match.params;
        const { post, isLoading } = this.state;

        if (isLoading) {
            return <Loader />
        }

        return (
            <ContentWithInfoPanel
                visagePageStyle
                template={1}
                renderInfoPanel={this.renderInfoPanel.bind(this)}
            >
                <div className={classNames("contentMiddle BlogsPostView", {
                    ["appMode"]: this.props.appMode
                })}>
                    {this.props.goBackToFormCallback && (
                        <div className="info-bar">
                            <Label size="body-2">{localize("PREVIEW_INFO")}</Label>
                            <PrimaryButton
                                onClick={this.props.goBackToFormCallback}
                            >
                                {localize("GaTillbakaTillRedigering")}
                            </PrimaryButton>
                        </div>
                    )}
                    <Helmet>
                        <title>{post.headline}</title>
                    </Helmet>
                    {!this.props.appMode &&
                        <Breadcrumbs
                            displayInstance
                            items={[
                                {
                                    text: localize("Bloggar"),
                                    key: 0,
                                    link: "/blogs",
                                },
                                {
                                    text: post.blog.name,
                                    key: post.blog.id,
                                    link: `/blogs/${blogSlug}`,
                                },
                                {
                                    text: post.headline,
                                    key: post.id,
                                    link: `/blogs/${blogSlug}/${postSlug}`,
                                },
                            ]}
                        />
                    }
                    <BlogPost
                        post={post}
                        actionMenuCallBack={(type) => {
                            switch (type) {
                                case "follow":
                                    this.setState((state) => ({ post: { ...state.post, isFollowing: true } }));
                                    break;
                                case "unfollow":
                                    this.setState((state) => ({ post: { ...state.post, isFollowing: false } }));
                                    break;
                                case "favourite":
                                    this.setState((state) => ({ post: { ...state.post, isFavourite: true } }));
                                    break;
                                case "unfavourite":
                                    this.setState((state) => ({ post: { ...state.post, isFavourite: false } }));
                                    break;
                                case "delete":
                                    this.props.history.goBack();
                            }
                        }}
                    />
                </div>
            </ContentWithInfoPanel>
        );
    };
}

const mapStateToProps = (state: IApplicationState) => ({
    appMode: state.ui.appMode,
});

export default withRouter(connect(mapStateToProps)(BlogsPostView));
