import { Icon } from "@fluentui/react";
import React, { FunctionComponent, MouseEvent, useCallback } from "react";
import { Label, SpintrUser } from "src/ui";
import "./ChatTabHeader.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    images: string[];
    minimized: boolean;
    title: string;
    unread?: number;

    onClose(): void;
    onToggle(): void;
}

const ChatTabHeader: FunctionComponent<IProps> = (props) => {
    const [extraClass, imageSize] = props.minimized
        ? [" small", 26]
        : ["", 28];

    const onClose = useCallback(
        (ev: MouseEvent<HTMLElement>) => {
            ev.preventDefault();
            ev.stopPropagation();

            props.onClose();
        }, [props.onClose]
    );

    return (
        <div
            className={
                "ChatTabHeader"
                + extraClass
                + (props.unread > 0
                    ? " unread"
                    : ""
                )
            }
            onClick={props.onToggle}
        >
            <div className="image">
                <SpintrUser
                    hideText={true}
                    key={"ChatTabImage" + imageSize}
                    imageUrl={props.images[0]}
                    name={props.title}
                    size={imageSize}
                />
            </div>
            <div className="title">
                <Label
                    as="span"
                    size={props.minimized ? "body-3" : "body-2"}
                    weight="medium"
                >
                    {props.title}
                </Label>
            </div>
            {props.unread > 0 && (
                <div className="unread-count">
                    <Label
                        as="span"
                        size="small-2"
                        weight="normal"
                    >
                        {props.unread}
                    </Label>
                </div>
            )}
            <div className="close-button" onClick={onClose}>
                <Visage2Icon icon="close-circle" />
            </div>
        </div>
    );
};

export default ChatTabHeader;
