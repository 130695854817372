import { AxiosResponse } from "axios";
import classnames from "classnames";
import moment from "moment";
import { CommandBar, FontIcon, ICommandBarItemProps } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Action } from "redux";
import { ShareModal } from "src/chat";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, Loader, setShouldReloadFavourites } from "src/ui";
import { ActionMenu, Reach, SpintrUser, UserHovercard } from "src/ui/components";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { getTimeOrDateFromDate, validZohoFormat } from "src/utils";
import { FileDownloadPopup } from "../components";
import { deleteFile } from "../file-actions";
import { setCurrentFolderId } from "../folder-actions";
import { setZohoPopup } from "../zoho-actions";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    id: number;
    source: Spintr.FolderRootSource;
    dispatch: (action: Action) => void;
    location: Location;
    isFileUser: boolean;
    isEditor: boolean;
    isAdmin: boolean;
    currentUserId: number;
    disableFileLocking: boolean;
    minifiedFilePageForUsers: boolean;
    enableZoho: boolean;
    hideShare: boolean;
    group?: any;
    history: any;
    isInTeamsApp?: boolean;
}

interface IState {
    isLoading: boolean;
    file: IFile;
    originalFile: IFile;
    versionList: any[];
    version: number;
    versions: any[];
    isFileDownloadOpen: boolean;
    isDeleteOpen: boolean;
    isShareModalOpen: boolean;
    showVersionsList: boolean;
    canShow: boolean;
}

interface IFile {
    date: string;
    dateStart: string;
    dateEnd?: string;
    description: string;
    edit: string;
    folder: IFileFolder;
    id: number;
    isFavourite: boolean;
    isFollowing: boolean;
    isGroupFile: boolean;
    isImage: boolean;
    isLocked: boolean;
    lockedBy?: IFileUser;
    lockedFrom: string;
    lockedTo: string;
    name: string;
    owner: IFileUser;
    path: string;
    size: string;
    tags: string[];
    type: string;
    typeId: number;

    lockedByUser: boolean;
}

interface IFileFolder {
    enableOnlineEdit: boolean;
    id: number;
    name: string;
}

interface IFileUser {
    id: number;
    name: string;
}

class FileDetailsView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            file: {} as IFile,
            originalFile: {} as IFile,
            isFileDownloadOpen: false,
            isDeleteOpen: false,
            showVersionsList: false,
            versions: [],
            versionList: [],
            version: 1,
            canShow: false,
            isShareModalOpen: false,
        };
    }

    componentDidMount() {
        this.fetchFile();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.fetchFile();
        }
    }

    fetchFile = () => {
        this.setState({
            isLoading: true,
        });

        api.get(`/api/files/${this.props.id}`).then((response: AxiosResponse) => {
            var file = response.data as IFile;

            file.lockedByUser = file.isLocked && file.lockedBy.id && file.lockedBy.id === this.props.currentUserId;

            var fileTypes = SpintrTypes.FileType;
            var canShowFileTypes = [fileTypes.Pdf, fileTypes.Jpg, fileTypes.Png, fileTypes.Gif];
            var canShow = canShowFileTypes.includes(file.typeId);

            this.fetchHistory(file);

            this.setState({
                isLoading: false,
                file: file,
                originalFile: file,
                canShow: canShow,
            });

            this.props.dispatch(setCurrentFolderId(response.data.folder.id));
        });
    };

    fetchHistory = (file: IFile) => {
        api.get(`/api/files/${file.id}/history`).then((response: AxiosResponse) => {
            var versionList = response.data.map((version, idx) => ({
                id: version.id,
                name: `#${idx + 1} ${version.name}`,
                date: moment(version.date).format("D MMM"),
                owner: version.owner.name,
            }));

            versionList.push({
                name: `#${versionList.length + 1} ${file.name}`,
                date: moment(file.date).format("D MMM"),
                owner: file.owner.name,
                current: true,
            });

            versionList.reverse();

            this.setState({
                versionList: versionList,
                versions: response.data,
                version: response.data.length === 0 ? 1 : response.data.length + 1,
            });
        });
    };

    openZoho = () => {
        this.props.dispatch(setZohoPopup(true, this.state.file.size, this.state.file.id, this.state.version));
    };

    download = (canWrite: boolean) => {
        if (this.props.disableFileLocking || !canWrite) {
            window.location.href = `/api/servefile/${this.state.file.id}`;
            return;
        }

        this.setState({
            isFileDownloadOpen: true,
        });
    };

    closeFileDownloadPopup = () => {
        this.setState({ isFileDownloadOpen: false });
    };

    close = () => {
        this.props.history.push({
            pathname: this.props.group
                ? `/groups/${this.props.group.id}/files/d${this.state.file.folder.id}`
                : `/files/${this.props.source}/d${this.state.file.folder.id}`,
        });
    };

    toggleShareModal = () => {
        this.setState((prevState) => ({
            isShareModalOpen: !prevState.isShareModalOpen,
        }));
    };

    getActionMenuItems = (file: IFile, isGroup: boolean, canWrite: boolean) : ICommandBarItemProps[] => {
        return [
            // {
            //     text: localize(file.isFavourite ? "TaBortFranFavoriter" : "LaggTillIFavoriter"),
            //     key: "1",
            //     onClick: file.isFavourite
            //         ? () => {
            //             api
            //                     .delete(`/api/v1/favorites/${file.id}`)
            //                     .then((response: AxiosResponse) => {
            //                         this.setState((prevState) => ({
            //                             file: {
            //                                 ...prevState.file,
            //                                 isFavourite: false,
            //                             },
            //                         }));
            //                         this.props.dispatch(setShouldReloadFavourites(true));
            //                     });
            //             }
            //         : () => {
            //             api
            //                     .post(`/api/v1/favorites`, { id: file.id })
            //                     .then((response: AxiosResponse) => {
            //                         this.setState((prevState) => ({
            //                             file: {
            //                                 ...prevState.file,
            //                                 isFavourite: true,
            //                             },
            //                         }));
            //                     });
            //             },
            //     icon: file.isFavourite ? "FavoriteStarFill" : "FavoriteStar",
            // },
            // {
            //     text: localize(file.isFollowing ? "StangAvNotiser" : "AktiveraNotiser"),
            //     key: "2",
            //     onClick: file.isFollowing
            //         ? () => {
            //             api
            //                     .delete(`/api/v1/follow/${file.id}`)
            //                     .then((response: AxiosResponse) => {
            //                         this.setState((prevState) => ({
            //                             file: {
            //                                 ...prevState.file,
            //                                 isFollowing: false,
            //                             },
            //                         }));
            //                     });
            //             }
            //         : () => {
            //             api
            //                     .post(`/api/v1/follow`, { id: file.id })
            //                     .then((response: AxiosResponse) => {
            //                         this.setState((prevState) => ({
            //                             file: {
            //                                 ...prevState.file,
            //                                 isFollowing: true,
            //                             },
            //                         }));
            //                     });
            //             },
            //     icon: "Flag",
            // },
            ...(!this.props.isInTeamsApp && (!file.isLocked || file.lockedByUser)
                ? [
                        ...(!isGroup && !this.props.hideShare
                            ? [
                                {
                                    text: localize("Dela"),
                                    key: "3",
                                    onClick: this.toggleShareModal,
                                    icon: "Share",
                                },
                            ]
                            : []),
                    ]
                : []),
            ...((!file.isLocked || file.lockedByUser) && canWrite ?
                [
                    {
                          text: localize("Redaktor"),
                          key: "5",
                          items: [
                              {
                                  text: localize("KopieraLank"),
                                  key: "6",
                                  onClick: () => {
                                      var fileUrl = `${window.location.protocol}//${window.location.host}/api/servefile/${file.id}`;

                                      // Creates a temporarly input field which hold the direct link to current file
                                      var tmpInput = document.createElement("input");
                                      document.body.appendChild(tmpInput);

                                      tmpInput.setAttribute("id", "servefile_id");
                                      // @ts-ignore
                                      document.getElementById("servefile_id").value = fileUrl;

                                      tmpInput.select();
                                      document.execCommand("copy");

                                      // Remove element, since it's no longer needed
                                      document.body.removeChild(tmpInput);
                                  },
                              },
                              {
                                  text: localize("LaggTillNyVersion"),
                                  key: "7",
                                  onClick: () => {
                                      this.props.history.push({
                                          pathname: `f${file.id}/version`,
                                      });
                                  },
                              },
                              ...(this.props.enableZoho &&
                              validZohoFormat(file.name) &&
                              file.folder.enableOnlineEdit &&
                              !this.props.isInTeamsApp
                                  ? [
                                        {
                                            text: localize("RedigeraDokumentet"),
                                            key: "8",
                                            onClick: this.openZoho,
                                        },
                                    ]
                                  : []),
                              {
                                  text: localize("RedigeraMetadata"),
                                  key: "9",
                                  onClick: () => {
                                      this.props.history.push({
                                          pathname: `f${file.id}/edit`,
                                      });
                                  },
                              },
                              {
                                  text: localize("TaBort"),
                                  key: "10",
                                  onClick: () => {
                                      this.setState({
                                          isDeleteOpen: true,
                                      });
                                  },
                              },
                          ],
                      },
                ] : []),
        ];
    }

    renderCommandBar = (file: IFile, canWrite: boolean, isGroup: boolean) => {
        const hideClose = this.props.minifiedFilePageForUsers && !canWrite;

        return (
            <CommandBar
                items={[
                    ...(!this.props.isInTeamsApp
                        ? [
                              {
                                key: "download",
                                text: localize("LaddaNed"),
                                onClick: () => this.download(canWrite),
                              },
                          ]
                        : []),
                    {
                        key: "options",
                        text: localize("Alternativ"),
                        subMenuProps: {
                            className: "displayOptions",
                            items: [
                                ...(!hideClose
                                    ? [
                                            {
                                                text: localize("Stang"),
                                                key: "0",
                                                icon: "Cancel",
                                                onClick: this.close,
                                            },
                                        ]
                                    : []),
                                ...(this.state.canShow && !this.props.isInTeamsApp
                                    ? [
                                          {
                                              key: "show",
                                              text: localize("Visa"),
                                              onClick: () => {
                                                  window.location.href = `/api/servefile/${file.id}?show=true`;
                                              },
                                          },
                                      ]
                                    : []),
                                ...(this.props.enableZoho && validZohoFormat(file.name) && file.folder.enableOnlineEdit && !this.props.isInTeamsApp
                                    ? [
                                          {
                                              key: "zoho",
                                              text: localize("RedigeraDokumentet"),
                                              onClick: this.openZoho,
                                          },
                                      ]
                                    : []),
                                {
                                    key: "edit",
                                    text: localize("LaggTillNyVersion"),
                                    onClick: () => {
                                        this.props.history.push({
                                            pathname: `f${file.id}/version`,
                                        });
                                    },
                                },
                                {
                                    key: "versiontoggle",
                                    text: localize(this.state.showVersionsList ? "DoljTidigareVersioner" : "VisaTidigareVersioner"),
                                    onClick: () => {
                                        this.setState((prevState) => ({
                                            showVersionsList: !prevState.showVersionsList,
                                        }));
                                    },
                                },
                                ...this.getActionMenuItems(file, isGroup, canWrite)
                            ],
                        },
                    },
                ]}
            />
        );
    };

    renderDataBox = (file: IFile) => {
        return (
            <div className="data-box">
                {this.renderInfoPart(localize("Titel"), file.name)}
                {!!file.description && this.renderInfoPart(localize("Beskrivning"), file.description)}
                {!!file.isLocked && this.renderInfoPart(localize("Utcheckad"), localize("Utcheckad") + " " + localize("Av_By").toLowerCase() + " " + file.lockedBy.name + " " + localize("Till").toLowerCase() + " " + getTimeOrDateFromDate(file.lockedTo))}
                {this.renderInfoPart(localize("Uppladdad"), getTimeOrDateFromDate(file.date))}
                {!!file.type && file.type !== "Undefined" && this.renderInfoPart(localize("Typ"), file.type)}
                {!!file.tags && file.tags.length > 0 && this.renderInfoPart(localize("Taggar"), undefined, () => {
                    return (
                        <Label className="tags" size="body-2">
                            {file.tags.map((tag) => (
                                // TODO: Correct search link
                                <Link key={tag} to={`/search/${tag}`}>
                                    {tag}
                                </Link>
                            ))}
                        </Label>
                    )
                })}
                {this.renderInfoPart(localize("Rackvidd"), undefined, () => {
                    return (
                        <Label size="body-2">
                            <Reach uberId={file.id} />
                        </Label>
                    )
                })}
                {!!file.size && this.renderInfoPart(localize("Storlek"), file.size + " KB")}
                {this.renderInfoPart(localize("UppladdadAv"), undefined, () => {
                    return (
                        <SpintrUser
                            id={file.owner.id}
                            name={file.owner.name}
                            subText={moment(file.date).format("LLL")} />
                    )
                })}
            </div>
        );
    };

    renderVersionList = () => {
        const state = this.state;
        return (
            <SpintrList
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    return new Promise((resolve, reject) => {
                        resolve({
                            data: state.versionList,
                            totalCount: state.versionList.length,
                        });
                    });
                }}
                columns={[
                    {
                        name: localize("Version"),
                        fieldName: "name",
                    },
                    {
                        name: localize("Datum"),
                        fieldName: "date",
                        minWidth: 50,
                        maxWidth: 80,
                    },
                    {
                        name: localize("UppladdadAv"),
                        fieldName: "owner",
                    },
                ]}
                onRowClick={(row) => {
                    var originalVersion;

                    if (row.current) {
                        originalVersion = state.originalFile;
                    } else {
                        originalVersion = state.versions.find((version) => version.id == row.id);
                    }
                    this.setState({
                        file: originalVersion,
                        showVersionsList: false,
                    });
                }}
                orderByColumn="name"
                disablePagination
                disableSort
                disableCommandBar
            />
        );
    };

    renderImage = () => {
        return (
            <div className="preview">
                {this.state.canShow && (
                    <img src={this.state.file.path} alt="" />
                )}
                {!this.state.canShow && (
                    <div className="icon-wrapper">
                        <Visage2Icon icon={"document-text"} size="extra-big" />
                    </div>
                    )}
            </div>
        )
    }

    renderInfoPart(title: string, content?: string, renderContent?) {
        return (
            <div className="FileDetailsView-InfoPart">
                <Label size="body-2" color="mid-grey">{title}</Label>
                <div className="FileDetailsView-InfoPartContent">
                    {!!content && <Label color="dark-grey" size="body-2">{content}</Label>}
                    {!content && !!renderContent && (
                        <div className="FileDetailsView-InfoPartContent-inner">
                            {renderContent()}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderInner = () => {
        const state = this.state;
        const { file } = state;

        var isGroup = this.props.location.pathname.includes("group");
        var canWrite = this.props.isFileUser || this.props.isEditor || this.props.isAdmin || isGroup;

        return (
            <>
                {this.renderImage()}
                {(!file.isLocked || file.lockedByUser) && this.renderCommandBar(file, canWrite, isGroup)}
                {!state.showVersionsList ? (
                    <>
                        {this.renderDataBox(file)}
                        <SocialBlock uberId={file.id} />
                    </>
                ) : (
                    this.renderVersionList()
                )}
                <FileDownloadPopup
                    isOpen={state.isFileDownloadOpen}
                    close={this.closeFileDownloadPopup}
                    file={file}
                />
                <CustomDialog
                    show={state.isDeleteOpen}
                    title={localize("ArDuSakerPaAttDuVillRaderaDennaFil") + "?"}
                    onDismiss={() => {
                        this.setState({
                            isDeleteOpen: false,
                        });
                    }}
                    onConfirm={() => {
                        this.setState({
                            isDeleteOpen: false,
                            isLoading: true,
                        });

                        this.props.dispatch(deleteFile(file.id, this.props.source));
                        // this.props.api.delete(`/api/files/${file.id}`).then(() => {
                        this.close();
                        // });
                    }}
                />
                {this.state.isShareModalOpen && <ShareModal objectId={file.id} onDismiss={this.toggleShareModal} />}
            </>
        );
    };

    render() {
        const state = this.state;

        return (
            <div
                className={classnames("file-details", {
                    isImage: state.file && state.file.isImage,
                })}
            >
                {state.isLoading ? <Loader /> : this.renderInner()}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        isFileUser: state.profile.active.isFileUser,
        disableFileLocking: state.instance.get("disableFileLocking"),
        minifiedFilePageForUsers: state.instance.get("minifiedFilePageForUsers"),
        enableZoho: state.instance.get("enableZoho"),
        hideShare: state.files.folders.get("hideShare"),
        currentUserId: state.profile.active.id,
        isInTeamsApp: state.ui.isInTeamsApp,
    };
};

export default withRouter(connect(mapStateToProps)(FileDetailsView));
