import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Label } from "src/ui";
import { decodeHtmlEntities } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./FavoriteList.scss";

const FavoriteList: FunctionComponent = () => {
    const favorites = useSelector(
        (state: Spintr.AppState) => state.favorites.items,
    );

    if (favorites.length === 0) {
        return null;
    }

    return (
        <div className="FavoriteList">
            {favorites.map((favorite) => (
                <div className="favorite" key={"favorite" + favorite.id}>
                    <Link
                        className="link"
                        to={favorite.url}
                    >
                        <div className="favorite-inner">
                            <Label>{decodeHtmlEntities(favorite.title)}</Label>
                        </div>
                    </Link>
                    <div className="arrow">
                        <Visage2Icon icon="arrow-right-3" />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FavoriteList;
