import { Pivot, PivotItem } from "@fluentui/react";
import { SearchBox } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ActivityLogPopupView from "src/activitylog/ActivityLogPopupView";
import { localize } from "src/l10n";
import { Shortcuts } from "src/shortcuts";
import { IApplicationState } from "src/spintr/reducer";
import { Label, Loader, Scrollable } from "src/ui";
import LoadMoreButton from "src/ui/components/Buttons/LoadMoreButton/LoadMoreButton";
import CreateWizard from "src/ui/components/CreateWizard";
import HelpCenter from "../HelpCenter";
import "./TeamsTools.scss";
import api from "src/spintr/SpintrApi";

interface IProps {
    currentUserId?: number;
    history?: any;
    instance?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    apps?: any;
}

interface IState {
    tabs: any;
}

class TeamsTools extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            tabs: this.getTabs()
        };

        for (const tab of this.state.tabs) {
            if (tab.fetch) {
                tab.fetch();
            }
        }
    }

    getTabs() {
        const enableActivityLog = this.props.apps.items.find((a) => a.name === "activitylog" && a.enabled);
        let tabs = [];

        tabs.push({
            id: 1,
            itemIcon: "Waffle",
            render: () => {
                return (
                    <div>
                        <Shortcuts
                            hideTitle={true}
                            userId={this.props.currentUserId}
                            disableButtons={false}
                            disableEdit={true}
                            fetchShortcuts
                            isInTeamsTools
                        />
                    </div>
                )
            }
        });

        tabs.push({
            id: 2,
            itemIcon: "Org",
            isLoading: true,
            footerButtonText: localize("GaTillIntranatskarta"),
            footerButtonClick: () => {
                this.props.history.push({
                    pathname: `/sitemap`,
                });
            },
            fetch: () => {
                api.get("/api/sitemap").then((response) => {
                    this.setState({
                        tabs: this.state.tabs.map(t => {
                            if (t.id === 2) {
                                return {
                                    ...t,
                                    isLoading: false,
                                    data: response.data
                                }
                            } else {
                                return t;
                            }
                        })
                    });
                });
            },
            render: () => {
                let tab = this.state.tabs.find(t => t.id === 2);

                let data = [];

                if (tab.data) {
                    if (!!tab.searchText && tab.searchText.length > 0) {
                        for (let c of tab.data) {
                            let machingItems = [];

                            if (c.items) {
                                for (let i of c.items) {

                                    if (i.name.toLowerCase().indexOf(tab.searchText.toLowerCase()) > -1) {
                                        machingItems.push(i);
                                    }
                                }
                            }

                            if (machingItems.length > 0) {
                                data.push({
                                    ...c,
                                    items: machingItems
                                });
                            }
                        }
                    } else {
                        data = tab.data;
                    }
                }

                return (
                    <div>
                        <div className="sitemap-search">
                            <SearchBox
                                role={"search"}
                                value={this.state.tabs.find(t => t.id === 2).searchText}
                                onChange={(event: React.ChangeEvent, searchText: string) => {
                                    this.setState({
                                        tabs: this.state.tabs.map(t => {
                                            if (t.id === 2) {
                                                return {
                                                    ...t,
                                                    searchText
                                                }
                                            } else {
                                                return t;
                                            }
                                        })
                                    });
                                }}
                                placeholder={localize("Sok") + "..."}
                            />
                        </div>
                        {
                            data.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="sitemap-left">
                                            <Label weight="medium">
                                                {
                                                    item.letter
                                                }
                                            </Label>
                                        </div>
                                        <div className="sitemap-right">
                                            {
                                                item.items.map((item2, index2) => {
                                                    return (
                                                        <div key={index2}>
                                                            <Link to={item2.url}>
                                                                <Label color="dark-grey" className="general-row-break">
                                                                    {
                                                                        item2.name
                                                                    }
                                                                </Label>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        });

        if (enableActivityLog) {
            tabs.push({
                id: 3,
                itemIcon: "History",
                footerButtonText: localize("VisaAktivitetslogg"),
                footerButtonClick: () => {
                    this.props.history.push({
                        pathname: `/activitylog`,
                    });
                },
                disableScroll: true,
                render: () => {
                    return (
                        <div className="activityLogWrapper">
                            <ActivityLogPopupView isInTeamsTools />
                        </div>
                    )
                }
            });
        }

        if (this.props.isAdmin || this.props.isEditor) {
            tabs.push({
                id: 4,
                itemIcon: "UnknownCall",
                footerButtonText: localize("GaTillHjalpcenter"),
                footerButtonClick: () => {
                    window.open("https://support.spintr.me", "_blank");
                },
                disableScroll: true,
                render: () => {
                    return (
                        <div className="helpCenterWrapper">
                            <HelpCenter isInTeamsTools />
                        </div>
                    )
                }
            });
        }

        tabs.push({
            id: 5,
            itemIcon: "CirclePlus",
            key: 0,
            render: () => {
                let tab = this.state.tabs.find(t => t.id === 5);

                return (
                    <div className="createWizard" key={tab.key}>
                        <CreateWizard modalLess={true} onDismiss={() => {
                            this.setState({
                                tabs: this.state.tabs.map(t => {
                                    if (t.id === 5) {
                                        return {
                                            ...t,
                                            key: t.key + 1
                                        }
                                    } else {
                                        return t;
                                    }
                                })
                            });
                        }} />
                        {
                            tab.displayModal && (
                                <CreateWizard onDismiss={() => {
                                    this.setState({
                                        tabs: this.state.tabs.map(t => {
                                            if (t.id === 5) {
                                                return {
                                                    ...t,
                                                    displayModal: false,
                                                    key: t.key + 1
                                                }
                                            } else {
                                                return t;
                                            }
                                        })
                                    });
                                }} />
                            )
                        }
                    </div>
                )
            }
        });

        return tabs;
    }

    render () {
        return (
            <div className={"TeamsTools tab-count-" + this.state.tabs.length}>
                 <Pivot>
                     {
                         this.state.tabs.map(t => {
                             const renderFooterButton = t.footerButtonText && t.footerButtonClick;

                             return (
                                <PivotItem key={t.itemIcon} itemIcon={t.itemIcon}>
                                    <div className={"TeamsTools-tabContent" + (renderFooterButton ? " hasFooterButton" : "")}>
                                        {
                                            t.isLoading && (
                                                <Loader />
                                            )
                                        }
                                        {
                                            !t.isLoading && !t.disableScroll && (
                                                <div className="Scrollable-wrapper hasScrollable">
                                                    <Scrollable>
                                                        {
                                                            t.render()
                                                        }
                                                    </Scrollable>
                                                </div>
                                            )
                                        }
                                        {
                                            !t.isLoading && t.disableScroll && (
                                                <div className="Scrollable-wrapper">
                                                    {
                                                        t.render()
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            renderFooterButton && (
                                                <LoadMoreButton text={t.footerButtonText} onClick={t.footerButtonClick} />
                                            )
                                        }
                                    </div>
                                </PivotItem>
                             )
                         })
                     }
                </Pivot>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        currentUserId: state.profile.active.id,
        instance: state.instance,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        apps: state.app
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(TeamsTools));
