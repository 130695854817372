import { Coachmark, DirectionalHint, TeachingBubbleContent } from '@fluentui/react';
import React, { Component, RefObject } from 'react';
import { localize } from 'src/l10n';
import { Label } from "src/ui";

interface IProps {
    refDiv: RefObject<HTMLDivElement>,
    text: string,
    coachmarkId?: string
}

interface IState {
    isCoachmarkVisible: boolean;
}

class SpintrCoachmark extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isCoachmarkVisible: false
        }
    }

    markAsSeen() {
        if (!this.props.coachmarkId) {
            return;
        }

        const localStorageId = "spintr_seenCoachmarks";

        let oldData = localStorage.getItem(localStorageId);
        let newData = [this.props.coachmarkId];

        if (oldData) {
            let formattedOldData = JSON.parse(oldData);

            if (formattedOldData.indexOf(this.props.coachmarkId) > -1) {
                return;
            }

            newData = [
                ...formattedOldData,
                ...newData
            ];
        }

        localStorage.setItem(localStorageId, JSON.stringify(newData));
    }

    componentDidMount() {
        if (this.props.coachmarkId) {
            const localStorageId = "spintr_seenCoachmarks";

            let data = localStorage.getItem(localStorageId);

            if (data) {
                let formattedData = JSON.parse(data);

                if (formattedData.indexOf(this.props.coachmarkId) > -1) {
                    return;
                }
            }
        }

        this.setState({
            isCoachmarkVisible: true
        });
    }

    render() {
        if (!this.state.isCoachmarkVisible ||
            !this.props.refDiv ||
            !this.props.refDiv.current) {
            return null;
        }

        return (
            <Coachmark
                target={this.props.refDiv.current}
                positioningContainerProps={{
                    directionalHint: DirectionalHint.bottomCenter
                }}
                ariaAlertText={localize("COACHMARK_ALERT_TEXT")}
                ariaDescribedBy="coachmark-desc1"
                ariaLabelledBy="coachmark-label1"
                ariaDescribedByText={localize("COACHMARK_DESCRIBED_BY_TEXT")}
                ariaLabelledByText={localize("COACHMARK_ARIA_LABELLED_BY_TEXT")}
                styles={{ pulsingBeacon: { pointerEvents: "none" } }}>
                <TeachingBubbleContent
                    headline={localize("COACHMARK_HEADLINE")}
                    hasCloseButton
                    closeButtonAriaLabel={localize("Stang")}
                    primaryButtonProps={{
                        text: localize("Ok"),
                        onClick: () => {
                            this.markAsSeen();

                            this.setState({
                                isCoachmarkVisible: false
                            });
                        }
                    }}
                    onDismiss={() => {
                        this.markAsSeen();

                        this.setState({
                            isCoachmarkVisible: false
                        });
                    }}
                >
                    <Label>
                        {
                            this.props.text
                        }
                    </Label>
                </TeachingBubbleContent>
            </Coachmark>
        )
    }
}

export default SpintrCoachmark;
