import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IApplicationState } from "src/spintr/reducer";
import { Label, SpintrUser } from "src/ui/components";
import { getHexFromSpintrColor } from "src/ui/helpers/style";
import { shortenString } from "src/utils";
import "./PrioItems.scss";

interface IProps {
    items: any;
    isSmallViewMode?: boolean;
}

class PrioItems extends Component<IProps> {
    render() {
        let rows = [];

        if (this.props.isSmallViewMode) {
            rows = [
                this.props.items
            ]
        } else if (this.props.items.length === 6) {
            rows = [
                this.props.items.slice(0, 3),
                this.props.items.slice(3, 6),
            ]
        } else if (this.props.items.length === 7) {
            rows = [
                this.props.items.slice(0, 4),
                this.props.items.slice(4, 7),
            ]
        } else if (this.props.items.length === 8) {
            rows = [
                this.props.items.slice(0, 4),
                this.props.items.slice(4, 8),
            ]
        } else {
            rows = [
                this.props.items
            ]
        }

        return (
            <div className={"PrioItems" + " PrioItemsCount-" + this.props.items.length}>
                {
                    rows.map((items, index) => {
                        return (
                            <div key={index}>
                                {
                                    items.map((item) => {
                                        return (
                                            <Link
                                                key={item.id}
                                                className="PrioItem zoomCoinOnHover"
                                                to={item.onClick ? null : item.url}
                                                onClick={() => {
                                                    if (item.onClick) {
                                                        item.onClick();
                                                    }
                                                }}>
                                                <div className="PrioItem-Inner">
                                                    <div className="PrioItem-Icon">
                                                        <SpintrUser
                                                            name={item.name}
                                                            imageUrl={item.imageUrl}
                                                            iconToDisplayInsteadOfInitials={item.icon}
                                                            size={80}
                                                            hideText={true} />
                                                    </div>
                                                    <Label
                                                        as="h2"
                                                        size="h4"
                                                        color="dark-grey"
                                                        weight="medium"
                                                        title={item.name}
                                                        className="prioItemTitle" style={{
                                                        color: getHexFromSpintrColor("dark-grey")
                                                    }}>
                                                        {item.name}
                                                    </Label>
                                                    <Label as="p" className="description" size="body-2" color="mid-grey" title={item.description}>
                                                        {
                                                            shortenString(item.description, 45, "...")
                                                        }
                                                    </Label>
                                                </div>
                                            </Link>
                                        );
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default connect(mapStateToProps)(PrioItems);
