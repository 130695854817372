import { Checkbox, DefaultButton, Modal, PrimaryButton, Stack } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { setEnableOnlineEdit } from "src/files/folder-actions";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui";
import { isAnythingDirty } from "src/utils";
import { FormControl, FormSection, FormTokenizedObjectInput } from "../Forms";
import PopupHeader from "../PopupHeader";
import "./RightsPopup.scss";

interface IProps {
    close: () => void;
    dispatch: (action: Action) => void;
    isOpen: boolean;
    file: any;
    folderName: string;
    folderId: string;
    source: string;
    enableCompany?: boolean;
    enableOnlineEdit: boolean;
    enableZoho: boolean;
    currentUserId: number;
}

interface IState {
    isLoading: boolean;
    targets: any[];
    enableOnlineEdit: boolean;
}

class RightsPopup extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            targets: [],
            enableOnlineEdit: props.enableOnlineEdit,
        };
    }

    componentDidMount() {
        api.get(`/api/v1/privileges/${this.props.folderId}`).then((response: AxiosResponse) => {
            this.setState({
                isLoading: false,
                targets: response.data.map((target) => ({
                    key: target.id,
                    id: target.id,
                    name: target.name,
                })),
            });
        });
    }

    save = () => {
        this.setState({
            isLoading: true,
        });

        let envelope = {
            targetIds: this.state.targets.map((target) => target.id),
            enableOnlineEdit: this.state.enableOnlineEdit,
        };

        api
            .put(`/api/v1/privileges/folder/${this.props.folderId}`, envelope)
            .then(() => {
                this.props.dispatch(
                    setEnableOnlineEdit(this.props.folderId, this.state.enableOnlineEdit, this.props.source)
                );
                this.props.close();
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });
    };

    renderInner = () => {
        return (
            <form>
                {this.props.enableZoho && (
                    <FormSection title={localize("TillaOnlineredigeringAvDokument")}>
                        <FormControl>
                            <Checkbox
                                label={localize("TillaOnlineredigeringAvDokument")}
                                checked={this.state.enableOnlineEdit}
                                onChange={(ev, value) => {
                                    this.setState({
                                        enableOnlineEdit: value,
                                    });
                                }}
                            />
                        </FormControl>
                    </FormSection>
                )}
                <FormSection title={localize("SynligFor")}>
                    <FormTokenizedObjectInput
                        showAllWhenEmpty
                        items={this.state.targets}
                        label=""
                        types={[
                            SpintrTypes.UberType.User,
                            SpintrTypes.UberType.Group,
                            SpintrTypes.UberType.Department,
                            SpintrTypes.UberType.Office,
                            SpintrTypes.UberType.Role,
                            SpintrTypes.UberType.TargetGroup,
                            ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
                        ]}
                        onChange={(targets) => {
                            this.setState({
                                targets: targets.map((target) => ({
                                    name: target.name,
                                    id: target.key,
                                    imageUrl: target.imageUrl,
                                    key: target.key
                                })),
                            });
                        }}
                    />
                </FormSection>
                <FormSection>
                    <Label size="body-2">{localize("RIGHTS_INHERIT_DESCRIPTION")}</Label>
                </FormSection>
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                    <DefaultButton text={localize("Avbryt")} onClick={this.onClose.bind(this)} />
                    <PrimaryButton text={localize("Spara")} onClick={this.save} />
                </Stack>
            </form>
        );
    };

    onClose = () => {
        const close = () => {
            this.props.close();
        }

        if (isAnythingDirty()) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} containerClassName="spintr-modal rights-popup modalWithPopupHeader">
                <PopupHeader
                    text={localize("Rattigheter") + " " + localize("For").toLowerCase() + " " + this.props.folderName}
                    onClose={this.onClose.bind(this)} />
                {this.state.isLoading ? <Loader /> : this.renderInner()}
            </Modal>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        enableCompany: state.instance.get("enableCompanyLevel"),
        enableZoho: state.instance.get("enableZoho"),
        currentUserId: state.profile.active.id,
    };
};

export default connect(mapStateToProps)(RightsPopup);
