import React, { CSSProperties, useEffect, useState } from "react";
import "./SocialPostText.scss";
import { decodeHtmlEntities, formatInteractiveText } from "src/utils";
import { Label } from "src/ui";
import TranslateButton from "src/ui/components/TranslateButton";

interface IProps {
    post: Spintr.ISocialPostBase;
}

const getText = (post: Spintr.ISocialPostBase) => {
    return post.Text ?? post.postText ?? post.question ?? "";
}

const defaultFonts: string[] = [
    "\"Eloquia\", sans-serif",
    "\"Merriweather\", serif",
    "\"Indie Flower\", cursive",
    "\"Pacifico\", cursive",
];

const SocialPostTextInner = (props) => {
    if (props.post.background) {
        const style: CSSProperties = {
            backgroundImage: `linear-gradient(${props.post.background.start.color} 0%, ${props.post.background.stop.color} 100%)`
        };

        const fontStyle: CSSProperties = {
            fontFamily: defaultFonts[props.post.font - 1],
            lineHeight: "35px"
        };

        return (
            <div className="status-frame ignore-custom-font">
                <div className="stage-wrapper">
                    <div className="stage" style={style}>
                        <Label 
                            as="div"
                            color="white"
                            weight="medium"
                            size="h3"
                            centerText
                            style={fontStyle}
                            className="general-row-break interactive-text feed-post-text">
                                {formatInteractiveText(
                                    decodeHtmlEntities(props.text)
                                )}
                        </Label>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Label 
            as="div"
            color="dark-grey"
            size="body-2"
            className="general-row-break interactive-text feed-post-text">
                {formatInteractiveText(
                    decodeHtmlEntities(props.text)
                )}
        </Label>
    )
}

const SocialPostText = (props: IProps) => {
    const [text, setText] = useState(getText(props.post));

    useEffect(() => setText(getText(props.post)), [props.post.Text]);

    if (!text) {
        return null;
    }

    return (
        <>
            <SocialPostTextInner post={props.post} text={text} />
            <TranslateButton 
                authorLanguage={props.post.UserLanguage} 
                uberId={props.post.Id} 
                text={props.post.Text}
                onTranslateFn={(translatedtext: string) => {
                    setText(translatedtext);
                }} />
        </>
    )
}

export default SocialPostText;