import React, { Component, CSSProperties, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { decodeHtmlEntities } from "src/utils";
import { UnstyledButton } from "./Buttons";
import Label from "./Label/Label";
import "./TranslateButton.scss";

const languageCodes = ["sv", "nb", "en", "de", "fi", "da"];

export const getLanguageCodeFromSpintrLanguage = (spintrLanguage: number) => {
    return languageCodes[spintrLanguage - 1];
};

export const getSpintrLanguageFromLanguageCode = (languageCode: string) => {
    return languageCodes.indexOf(languageCode) + 1;
}


interface IProps {
    userLanguage: number;
    authorLanguage?: number | string;
    text?: string;
    texts?: object;
    onTranslateFn: (translatedText: string, key?: string) => any;
    enableTranslator: boolean;
    uberId: number;
    textColor?: spintrColors;
}

interface IState {
    isTranslated: boolean;
    originalTexts?: object;
}

class TranslateButton extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isTranslated: false,
        };
    }

    componentDidMount = () => {
        const autoTranslate = false;
        if (autoTranslate) {
            this.translatePostText();
        }
    };

    translatePostText = async () => {
        const userLanguageCode = getLanguageCodeFromSpintrLanguage(this.props.userLanguage);

        if (this.props.text) {
            try {
                const translation = (await api.post("/api/translate", {
                    uberId: this.props.uberId,
                    text: decodeHtmlEntities(this.props.text),
                    from: "",
                    to: userLanguageCode,
                })) as any;

                const translatedText = translation.data;
                this.setState({ isTranslated: true });
                this.props.onTranslateFn(translatedText);
            } catch (_) {
                /* ignored */
            }
        } else if (this.props.texts) {
            for (const [key, value] of Object.entries(this.props.texts)) {
                this.setState({ originalTexts: { ...this.state.originalTexts, [key]: value } });
                try {
                    const translation = (await api.post("/api/translate", {
                        uberId: this.props.uberId,
                        text: value,
                        from: "",
                        to: userLanguageCode,
                        type: parseInt(key, 10),
                    })) as any;

                    const translatedText = translation.data;
                    this.setState({ isTranslated: true });
                    this.props.onTranslateFn(translatedText, key);
                } catch (_) {
                    /* ignored */
                }
            }
        }
    };

    resetTranslation = () => {
        this.setState({ isTranslated: false });
        if (this.props.text) {
            this.props.onTranslateFn(this.props.text);
        } else {
            for (const [key, value] of Object.entries(this.state.originalTexts)) {
                this.props.onTranslateFn(value, key);
            }
            this.setState({ isTranslated: false });
        }
    };

    render = () => {
        let authorLanguage = this.props.authorLanguage;
        
        if (typeof(authorLanguage) === "string") {
            authorLanguage = getSpintrLanguageFromLanguageCode(authorLanguage)
        }

        if (!this.props.enableTranslator) { // || authorLanguage === this.props.userLanguage
            return null;
        }

        return this.state.isTranslated ? (
            <UnstyledButton className="translate-button" onClick={this.resetTranslation}>
                <Label size="body-3" color={this.props.textColor ?? "mid-grey"}>
                    {localize("SeOriginal")}
                </Label>
            </UnstyledButton>
        ) : (
            <UnstyledButton className="translate-button" onClick={this.translatePostText}>
                <Label size="body-3" color={this.props.textColor ?? "mid-grey"}>
                    {localize("SeOversattning")}
                </Label>
            </UnstyledButton>
        );
    };
}
const mapStateToProps = (state, props) => ({
    ...props,

    userLanguage: Number(state.profile.active.settings.language),
    enableTranslator: state.instance.get("enableTranslator"),
});

export default connect(mapStateToProps)(TranslateButton);
