import React, { ReactNode } from "react";
import { SpintrTypes } from "src/typings";
import { SocialBlogPost } from "../SocialBlogPost";
import { SocialFile } from "../SocialFile";
import { SocialGroup } from "../SocialGroup";
import { SocialImage } from "../SocialImage";
import { SocialImagePost } from "../SocialImagePost";
import { SocialNews } from "../SocialNews";
import { SocialPage } from "../SocialPage";
import { SocialPageUpdate } from "../SocialPageUpdate";
import { SocialPoll } from "../SocialPoll";
import { SocialWikiArticle } from "../SocialWikiArticle";
import "./SocialPostContentItem.scss";
import { SocialBookmark } from "../SocialBookmark";
import { ExternalFile } from "src/chat";

const UberType = SpintrTypes.UberType;

interface IProps {
    post: Spintr.ISocialPostBase;
    isShare?: boolean;
}

const getPostComponent = (post: Spintr.ISocialPostBase, isShare?: boolean): ReactNode => {
    switch (post.Type) {
        case UberType.File:
            return <SocialFile post={post as Spintr.ISocialFile} />;
        case UberType.TextPage:
            return (
                <SocialPage
                    isShare={isShare}
                    post={post as Spintr.ISocialTextPage}
                />
            );
        case UberType.NewsArticle:
            return (
                <SocialNews
                    isShare={isShare}
                    post={post as Spintr.ISocialNewsArticle}
                />
            );
        case UberType.Image:
            return <SocialImage post={post as Spintr.ISocialImage} />;
        case UberType.ImagePost:
            return <SocialImagePost post={post as Spintr.ISocialImagePost} />;
        case UberType.WikiArticle:
            return <SocialWikiArticle post={post as Spintr.ISocialWikiArticle} />;
        case UberType.BlogPost:
            return <SocialBlogPost post={post as Spintr.ISocialBlogPost} />
        case UberType.Group:
            return <SocialGroup post={post as Spintr.ISocialGroup} />;
        case UberType.Poll:
            return <SocialPoll post={post as Spintr.ISocialPoll} />;
        case UberType.TextPageUpdate:
            return <SocialPageUpdate post={post as Spintr.ISocialPageUpdate} />;
        case UberType.Bookmark:
            return <SocialBookmark post={post as Spintr.ISocialStatusBookmark} />;
        case UberType.ExternalFile:
            if (!!post.externalFiles && post.externalFiles.length > 0) {
                console.log(post.externalFiles);
                return (
                    <div>
                        {post.externalFiles.map((file: any, index: number) => {
                            return (
                                <ExternalFile externalId={file.externalId} source={file.source} key={index} />
                            )
                        })}
                    </div>
                )
            }
    }

    return null;
}

const SocialPostContentItem = (props: IProps) => {
    return (
        <div className="SocialPostContentItem">
            {getPostComponent(props.post, props.isShare)}
        </div>
    )
}

export default SocialPostContentItem;