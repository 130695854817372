import moment from "moment";
import React, { Component } from "react";
import { Pie } from 'react-chartjs-2';
import { connect } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, SpintrUser } from "src/ui/components";
import "./RightColumnDashboard.scss";

interface IProps {
    instance: any;
    isInHeader?: boolean;
}

interface IState {
    isLoading: boolean;
    data?: any;
}

class RightColumnDashboard extends Component<IProps, IState> {
    ref = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        let to = moment.utc().toDate();
        let from = moment.utc().add(-1, 'months').toDate();

        api.get("/api/dashboardobjects/data", {
            params: {
                from,
                to
            }
        }).then(response => {
            if (response &&
                response.data &&
                response.data.items &&
                response.data.items.length > 0) {
                this.setState({
                    isLoading: false,
                    data: response.data.items.map((item) => {
                        return {
                            ...item,
                            data: item.jsonData ?
                                JSON.parse(item.jsonData) :
                                null
                        }
                    })
                });
            }
        }).catch(() => {

        });
    }

    private options = {
        aspectRatio: 2,
        plugins: {
            legend: {
                display: false
            }
        }
    };

    getCategories() {
        if (!this.state.data) {
            return null;
        }

        let categories = [];

        for (let item of this.state.data) {
            let c = categories.find(c => c.name === item.category);

            if (c) {
                c.items.push(item);
            } else {
                categories.push({
                    name: item.category,
                    items: [item]
                });
            }
        }

        return categories.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }

            if (a.name > b.name) {
                return 1;
            }

            return 0;
        });
    }

    itemTypes = [{
        render: (i) => {
            return null;
        }
    }, {
        render: (i) => {
            if (!i.data ||
                !i.data.value ||
                !i.data.max) {
                if (this.props.isInHeader) {
                    return null;
                }

                return (
                    <div key={i.id} className="DashboardItem">
                        <Label size="body-2">
                            {
                                localize("DetFinnsIngetInnehall")
                            }
                        </Label>
                    </div>
                );
            }

            const data = {
                labels: ["", ""],
                datasets: [{
                    data: [parseInt(i.data.value), (parseInt(i.data.max) - parseInt(i.data.value))],
                    backgroundColor: [this.props.instance.get("color"), "#FFFFFF"],
                    hoverBackgroundColor: []
                }]
            };

            return (
                <div key={i.id} className="DashboardItem type1">
                    <Label as="div" size="small-1" role="heading" ariaLevel={1}>
                        {
                            i.title
                        }
                    </Label>
                    <div className="PieWrapper">
                        <div className="Pie">
                            <Pie data={data} options={this.options} />
                        </div>
                        <Label as="p" size="h2">
                            {
                                (Math.round(((parseInt(i.data.value) / parseInt(i.data.max)) * 100) * 10) / 10) + "%"
                            }
                        </Label>
                    </div>
                </div>
            )
        }
    }, {
        render: (i) => {
            if (!i.data ||
                !i.data.items ||
                i.data.items.length === 0) {
                if (this.props.isInHeader) {
                    return null;
                }

                return (
                    <div key={i.id} className="DashboardItem">
                        <Label size="body-2">
                            {
                                localize("DetFinnsIngetInnehall")
                            }
                        </Label>
                    </div>
                );
            }

            return (
                <div key={i.id} className="DashboardItem type2">
                    <Label as="p" size="small-1">
                        {
                            i.title
                        }
                    </Label>
                    {
                        i.data.items.map((item, index) => {
                            return (
                                <div className="DashboardSubItem" key={item.id + "_" + index}>
                                    <div className="DashboardSubItem-left">
                                        <SpintrUser
                                            size={30}
                                            imageUrl={item.imageUrl}
                                            name={item.name}
                                            onRenderSecondaryText={() => {
                                                return (
                                                    <div>
                                                        <Label color={item.current.value > item.total.value ? "green" : "red"} as="div" size="small-2">
                                                            {
                                                                item.current.value + " " + item.current.text
                                                            }
                                                        </Label>
                                                        <Label as="div" size="small-2" role="heading" ariaLevel={1}>
                                                            {
                                                                item.total.value + " " + item.total.text
                                                            }
                                                        </Label>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="DashboardSubItem-right">
                                        <Label color={item.current.value > item.total.value ? "green" : "red"} size="body-2">
                                            {
                                                (Math.round(((parseInt(item.current.value) / parseInt(item.total.value)) * 100) * 10) / 10) + "%"
                                            }
                                        </Label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }, {
        render: (i) => {
            if (!i.data ||
                i.data.sum == undefined ||
                i.data.sum == null ||
                !i.data.text) {
                if (this.props.isInHeader) {
                    return null;
                }

                return (
                    <div key={i.id} className="DashboardItem">
                        <Label size="body-2">
                            {
                                localize("DetFinnsIngetInnehall")
                            }
                        </Label>
                    </div>
                );
            }

            return (
                <div key={i.id} className="DashboardItem type3">
                    <Label as="p" size="small-1">
                        {
                            i.title
                        }
                    </Label>
                    <div className="Numbers">
                        <Label as="p" size="h2">
                            {
                                i.data && i.data.sum && (
                                    i.data.sum
                                )
                            }
                        </Label>
                        <Label as="p" size="body-2">
                            {
                                i.data && i.data.text && (
                                    i.data.text
                                )
                            }
                        </Label>
                    </div>
                </div>
            )
        }
    }, {
        render: (i) => {
            return null;
        }
    }];

    render() {
        const categories = this.getCategories();

        if (!categories ||
            categories.length === 0) {
            return null;
        }

        return (
            <div className={"RightColumnDashboard" + (this.props.isInHeader ? " isInHeader" : "")} ref={this.ref}>
                {
                    categories.map((c, index) => {
                        return (
                            <div className="DashboardCategory" key={index}>
                                {
                                    c.name && c.name !== "-1" && (
                                        <div className="widget-header">
                                            <Label as="h4"
                                                size="body-3"
                                                weight="medium"
                                                uppercase>
                                                {
                                                    c.name
                                                }
                                            </Label>
                                        </div>
                                    )
                                }
                                <div className="DashboardCategory-content">
                                    {
                                        c.items.map(i => {
                                            return this.itemTypes[i.type].render(i);
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        instance: state.instance
    };
};

export default connect(mapStateToProps)(RightColumnDashboard);