import moment from "moment";
import { localize } from "src/l10n";

export const isSameDay = (a: Date, b: Date) =>
    a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();

export const isToday = (date: Date) => isSameDay(date, new Date());

export const isMultipleDays = (event: Spintr.ICalendarEvent): boolean => {
    const start = event.start instanceof Date ? event.start : new Date(event.start);
    const end = event.end instanceof Date ? event.end : new Date(event.end);

    return !isSameDay(start, end);
};

export const isNow = (event: Spintr.ICalendarEvent): boolean => {
    const now = new Date();
    const start = new Date(event.start);
    const end = new Date(event.end);

    now.setSeconds(0, 0);
    if (event.allDay) {
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
    }

    return event.start < now && event.end > now;
};

export const getDateString = (event): string => {
    if (isMultipleDays(event)) {
        if (event.allDay) {
            return `${moment(event.start).format("L")} - ${moment(event.end).format("L")}`;
        } else {
            return `${moment(event.start).format("L")} ${moment(event.start).format("LT")} - ${moment(event.end).format(
                "L"
            )} ${moment(event.end).format("LT")}`;
        }
    } else {
        if (event.allDay) {
            return moment(event.start).format("LL");
        } else {
            return `${moment(event.start).format("LL")} ${moment(event.start).format("LT")} - ${moment(event.end).format("LT")}`;
        }
    }
};

export const getAvailableSeatsString = (event: any): string => {
    if (!event.seats) {
        return "";
    }

    let attendees = [];

    if (event.participants) {
        attendees = event.participants.filter((p) => p.status === 1);
    } else if (event.attendees) {
        attendees = event.attendees.filter((a) => a.status === 1);
    }

    let count = event.seats - attendees.length;

    if (count < 0) {
        count = 0;
    }

    return ` (${count} ${localize("CALENDAR_SEATS_LEFT")})`;
};

export const isRegistrationAvailable = (event) => {
    let accepted = 0;

    if (event.participants) {
        accepted = event.participants
            .filter((participant) => participant.status === 1)
            .length;
    } else {
        accepted = event.attendees.length;
    }

    console.log (event , accepted);

    if (event.seats <= accepted) {
        return false;
    }

    if (new Date() > event.end) {
        return false;
    }

    if (!event.registrationClose) {
        return true;
    }

    try {
        return new Date() < new Date(event.registrationClose);
    } catch (e) {
        return true;
    }
};

export const formatEventCopy = (event, currentUserId) => {
    let newAttendees = [];

    if (event.invited) {
        newAttendees.push(...event.invited.filter((i) => i.id !== currentUserId));
    }

    if (event.attendees) {
        newAttendees.push(...event.attendees.filter((i) => i.id !== currentUserId && !newAttendees.includes(i)));
    }

    newAttendees = newAttendees.map((na) => ({
        name: na.name,
        id: na.id,
        key: na.id,
        image: na.image,
        info: "",
    }));

    return {
        ...event,
        id: 0,
        uberId: 0,
        isCopy: true,
        owner: currentUserId,
        title: `(${localize("Kopia")}) ${event.title}`,
        eventHasExpired: false,
        newAttendees,
    };
};
