import classnames from "classnames";
import { Button, Checkbox, Dropdown, PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, ContentImageViewerAndSelector, PageHeader } from "src/ui";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput, MandatoryText } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { isAnythingDirty, scrollToTop, validateRequiredTextField } from "src/utils";
import { renderAdminTitle } from "../common/Title";
import "./AdminShortcutsEditView.scss";
import api from "src/spintr/SpintrApi";
import classNames from "classnames";

interface IProps {
    history: any;
    match: any;
    dropdownEditing?: boolean;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    pageId: number;
    closeEditingCallback: any;
    imageUrl?: string;
    dispatch?: any;
    enableCompany?: boolean;
}

interface IState {
    isNew: boolean;
    isLoading: boolean;
    shortcut: any;
    saveError: string[];
    whichTarget: number;
    convertToForm: boolean;
    gotTargets: boolean;
    targetsCopy: any;
    enableFieldValidation?: boolean;
}

class AdminShortcutsEditView extends Component<IProps, IState> {
    private isNew = false;

    constructor(props) {
        super(props);

        this.state = {
            isNew: false,
            isLoading: true,
            saveError: [],
            whichTarget: 1,
            convertToForm: false,
            gotTargets: false,
            targetsCopy: [],

            shortcut: {
                forDepartment: 0,
                formAction: "",
                highlight: true,
                id: 0,
                passwordName: "",
                passwordValue: "",
                targets: [],
                title: "",
                url: "",
                usernameName: "",
                usernameValue: "",
                imageTicket: {},
            },
        };
    }

    initPage(shortcut) {
        let isForm = false;
        let targetType = 0;
        if (shortcut.formAction) {
            isForm = true;
        }
        if (this.isNew) {
            targetType = 1;
        } else {
            targetType =
                shortcut.targets.length == 0
                    ? 0
                    : shortcut.targets.length == 1 && shortcut.targets[0].id == this.props.currentUserId
                        ? 1
                        : 2;
        }

        this.setState({
            isNew: shortcut.isNew,
            isLoading: shortcut.isLoading,
            whichTarget: targetType,
            convertToForm: isForm,
            shortcut: {
                forDepartment: shortcut.forDepartment,
                formAction: shortcut.url,
                highlight: shortcut.highlight,
                id: shortcut.id,
                passwordName: shortcut.passwordName,
                passwordValue: shortcut.passwordValue,
                targets: shortcut.targets,
                title: shortcut.title,
                description: shortcut.description,
                url: shortcut.url,
                usernameName: shortcut.usernameName,
                usernameValue: shortcut.usernameValue,
                imageUrl: shortcut.imageUrl,
            },
            saveError: [],
        });
    }
    componentDidMount() {
        let pageId;
        if (this.props.pageId || this.props.pageId === 0) {
            pageId = this.props.pageId;
            if (pageId === 0) {
                this.isNew = true;
            }
        } else {
            if (window.location.href.indexOf("admin/shortcuts/create") > -1) {
                this.isNew = true;
            } else {
                this.isNew = false;
                pageId = window.location.href.split("/").reverse()[1];
            }
        }

        if (this.isNew) {
            this.initPage({
                isNew: true,
                isLoading: false,
                whichTarget: 1,
                convertToForm: false,
                forDepartment: 0,
                formAction: "",
                highlight: false,
                id: 0,
                passwordName: "",
                passwordValue: "",
                targets: [],
                title: "",
                description: "",
                url: "",
                usernameName: "",
                usernameValue: "",
            });
        } else {
            let fetchPage = () => {
                api.get("/api/shortcuts/" + pageId).then((response) => {
                    if (response.data.targets.length > 0) {
                        this.setState({ gotTargets: true });
                    }
                    this.setState({ isNew: false });
                    this.initPage(response.data);
                });
            };

            fetchPage();
        }
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    editSavedTargets = () => {
        let currentTargets = [...this.state.shortcut.targets];

        let newArray = [];

        currentTargets.map((o) => {
            this.state.targetsCopy.map((i) => {
                if (o.name == i.name) {
                    {
                        newArray.push({ id: i.id, name: i.name, key: i.key });
                    }
                }
            });
        });

        let mergedArray = newArray.concat(currentTargets);
        let targets = mergedArray.filter((item) => item.id != undefined)
        this.setState((prevState) => ({
            shortcut: {
                ...prevState.shortcut,
                targets,
            },
        }));
        return targets;
    }

    confirmDeleteShortcut = (callback?) => {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                message: localize("VillDuVerkligenTaBortGenvagen"),
                onConfirm: () => {
                    api.delete(`/api/v1/shortcuts/${this.state.shortcut.id}`).then((response) => {
                        if (callback && typeof callback === "function") callback();
                    });
                },
            })
        );
    };

    onSaveClick = async (callback = undefined) => {
        this.setState(
            {
                enableFieldValidation: true,
                isLoading: true,
            },
            async () => {
                let saveErrors = [];
                let shortcut = { ...this.state.shortcut };

                if (!shortcut.title) {
                    saveErrors.push("DuMasteFyllaIEnTitel");
                }

                if (!shortcut.url) {
                    saveErrors.push("DuMasteFyllaIEnUrl");
                }

                if (this.state.targetsCopy.length > 0) {
                    shortcut.targets = this.editSavedTargets();
                }

                if (this.state.whichTarget === 1 || (!this.props.isAdmin && !this.props.isEditor)) {
                    shortcut.targets = [
                        {
                            id: this.props.currentUserId,
                            name: this.props.currentUserName,
                            type: SpintrTypes.UberType.User,
                            key: this.props.currentUserId,
                            info: this.props.currentUserDepartmentName,
                            imageUrl: this.props.currentUserImageUrl,
                        },
                    ];
                } else if (this.state.whichTarget === 0) {
                    shortcut.targets = [
                        {
                            id: 0,
                            name: localize("Alla"),
                        },
                    ];
                }

                let url = shortcut.url;

                if (this.state.convertToForm) {
                    shortcut.formAction = url;
                } else {
                    shortcut.formAction = null;
                    shortcut.passwordName = null;
                    shortcut.passwordValue = null;
                    shortcut.usernameName = null;
                    shortcut.usernameValue = null;
                }

                if (saveErrors.length > 0) {
                    this.setState(
                        {
                            saveError: saveErrors,
                            isLoading: false,
                        },
                        () => {
                            scrollToTop();
                        }
                    );

                    return;
                }

                if (shortcut.id) {
                    await api.put("/api/shortcuts/" + shortcut.id, shortcut).then((_response) => {
                        if (callback && typeof callback === "function") callback();
                    });
                } else {
                    await api
                        .post("/api/shortcuts", shortcut)
                        .then(function (_response) {
                            if (callback) {
                                callback();
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }

                if (!this.props.dropdownEditing) {
                    this.props.history.goBack();
                }
            }
        );
    };

    render() {
        const { dropdownEditing } = this.props;

        if (this.state.isLoading) {
            return <SpintrLoader />;
        }
        let showSearchBar = false;
        if (this.state.whichTarget == 2) {
            showSearchBar = true;
        }
        const shortcut = this.state.shortcut;

        return (
            <div
                className={classNames("AdminShortcutsEditView", {
                    isPopup: this.props.dropdownEditing
                })}
            >
                {renderAdminTitle("Genvagar")}
                {
                    !dropdownEditing && (
                        <Breadcrumbs useGlobalBreadcrumbs
                            displayInstance
                            items={[
                                {
                                    text: localize("Administration"),
                                    key: 2,
                                    link: "/admin",
                                },
                                {
                                    text: localize("Genvagar"),
                                    key: 3,
                                    link: "/admin/shortcuts",
                                },
                                {
                                    text: this.isNew ? localize("LaggTillNyGenvag") : localize("RedigeraGenvagen"),
                                    key: 4,
                                    link: window.location.pathname,
                                },
                            ]}
                        />
                    )
                }
                {
                    !dropdownEditing ? (
                        <PageHeader
                            title={this.isNew ? localize("LaggTillNyGenvag") : localize("RedigeraGenvagen")}
                            displayMandatoryText />
                    ) : this.isNew && <MandatoryText />
                }
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <form>
                    <FormControl>
                        <TextField
                            className="textField"
                            label={localize("Titel")}
                            value={shortcut.title}
                            onChange={(ev, val) => {
                                this.setState((prevState) => ({
                                    shortcut: {
                                        ...prevState.shortcut,
                                        title: val,
                                    },
                                }));
                            }}
                            maxLength={64}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            className="textField"
                            label={"Url"}
                            value={shortcut.url}
                            onChange={(ev, val) => {
                                this.setState((prevState) => ({
                                    shortcut: {
                                        ...prevState.shortcut,
                                        url: val,
                                    },
                                }));
                            }}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                </form>
                {(this.props.isAdmin || this.props.isEditor) && (
                    <div>
                        <form>
                            <FormControl>
                                <Dropdown
                                    label={localize("AnpassaMalgrupp")}
                                    selectedKey={this.state.whichTarget}
                                    onChange={(_e, v) => {
                                        this.setState((state) => ({
                                            ...state,
                                            whichTarget: Number(v.key),
                                        }));
                                    }}
                                    options={[
                                        { key: 0, text: localize("Alla") },
                                        { key: 1, text: localize("Personlig") },
                                        { key: 2, text: localize("Sok") },
                                    ]}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                            {showSearchBar ? (
                                <FormSection>
                                    <FormTokenizedObjectInput
                                        items={shortcut.targets}
                                        types={[
                                            SpintrTypes.UberType.User,
                                            SpintrTypes.UberType.Department,
                                            SpintrTypes.UberType.Office,
                                            SpintrTypes.UberType.Group,
                                            SpintrTypes.UberType.Role,
                                            SpintrTypes.UberType.TargetGroup,
                                            ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
                                        ]}
                                        label={""}
                                        onChange={(targets) => {
                                            if (this.state.gotTargets) {
                                                this.setState({ targetsCopy: shortcut.targets, gotTargets: false });
                                            }

                                            this.setState((prevState) => ({
                                                shortcut: {
                                                    ...prevState.shortcut,
                                                    targets: targets.map((e) => {
                                                        return {
                                                            id: e.key,
                                                            name: e.name,
                                                            type: SpintrTypes.UberType.User,
                                                            key: e.key,
                                                            info: e.subText,
                                                            imageUrl: e.imageUrl,
                                                        };
                                                    }),
                                                },
                                            }));
                                        }}
                                    />
                                </FormSection>
                            ) : null}
                        </form>
                    </div>
                )}
                <form>
                    <FormControl>
                        <Checkbox
                            label={localize("Prioriterad")}
                            checked={shortcut.highlight}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                this.setState({
                                    shortcut: {
                                        ...this.state.shortcut,
                                        highlight: checked,
                                    },
                                });
                            }}
                        />
                    </FormControl>
                    {shortcut.highlight ? (
                        <>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("Beskrivning")}
                                    value={shortcut.description}
                                    onChange={(ev, val) => {
                                        this.setState((prevState) => ({
                                            shortcut: {
                                                ...prevState.shortcut,
                                                description: val,
                                            },
                                        }));
                                    }}
                                    maxLength={128}
                                    // validateOnFocusIn
                                    // validateOnFocusOut
                                    // validateOnLoad={!!this.state.enableFieldValidation}
                                    // onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>
                            <div className="image">
                                <ContentImageViewerAndSelector
                                    renderSizeString={() => {
                                        return `${localize("OptimalBildStorlek")}: 40x40px <br />`;
                                    }}
                                    cropImage
                                    cropAspect={1 / 1}
                                    imageUrl={shortcut.imageUrl ? shortcut.imageUrl : shortcut.imageTicket}
                                    placeholderString={localize("ValjBild")}
                                    editMode={true}
                                    onChange={(result) => {
                                        this.setState({
                                            shortcut: {
                                                ...this.state.shortcut,
                                                imageUrl: result.thumbnailUrl,
                                                ImageTicket: {
                                                    remove: false,
                                                    ticket: result.ticket,
                                                },
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </>
                    ) : null}
                </form>
                <form>
                    <FormControl>
                        <Checkbox
                            label={localize("KonverteraTillFormular")}
                            checked={this.state.convertToForm}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                this.setState({
                                    ...this.state,
                                    convertToForm: checked,
                                });
                            }}
                        />
                    </FormControl>
                    {this.state.convertToForm ? (
                        <div>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("AnvandarnamnNamn")}
                                    value={shortcut.usernameName}
                                    onChange={(ev, val) => {
                                        this.setState((prevState) => ({
                                            shortcut: {
                                                ...prevState.shortcut,
                                                usernameName: val,
                                            },
                                        }));
                                    }}
                                    maxLength={64}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("AnvandarnamnVarde")}
                                    value={shortcut.usernameValue}
                                    onChange={(ev, val) => {
                                        this.setState((prevState) => ({
                                            shortcut: {
                                                ...prevState.shortcut,
                                                usernameValue: val,
                                            },
                                        }));
                                    }}
                                    maxLength={64}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("LosenordNamn")}
                                    value={shortcut.passwordName}
                                    onChange={(ev, val) => {
                                        this.setState((prevState) => ({
                                            shortcut: {
                                                ...prevState.shortcut,
                                                passwordName: val,
                                            },
                                        }));
                                    }}
                                    maxLength={64}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("LosenordVarde")}
                                    value={shortcut.passwordValue}
                                    onChange={(ev, val) => {
                                        this.setState((prevState) => ({
                                            shortcut: {
                                                ...prevState.shortcut,
                                                passwordValue: val,
                                            },
                                        }));
                                    }}
                                    maxLength={64}
                                />
                            </FormControl>
                        </div>
                    ) : null}
                </form>
                {dropdownEditing ? (
                    <div className="buttons">
                        <FormControl>
                            <Button
                                disabled={shortcut.id === 0}
                                onClick={() => {
                                    this.confirmDeleteShortcut(this.props.closeEditingCallback);
                                }}
                            >
                                {localize("TaBort")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                onClick={() => {
                                    let fn = () => {
                                        this.props.closeEditingCallback();
                                    };

                                    if (isAnythingDirty()) {
                                        this.props.dispatch(
                                            setConfirmPopup({
                                                isOpen: true,
                                                message: localize("UnsavedChangesWarning"),
                                                onConfirm: fn,
                                            })
                                        );
                                    } else {
                                        fn();
                                    }
                                }}
                            >
                                {localize("Avbryt")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <PrimaryButton
                                onClick={() => {
                                    this.onSaveClick(this.props.closeEditingCallback);
                                }}
                            >
                                {localize("Spara")}
                            </PrimaryButton>
                        </FormControl>
                    </div>
                ) : (
                    <FormFooterBar
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick}
                        saveText={localize("Spara")}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        currentUserName: state.profile.active.name,
        currentUserDepartmentName: state.profile.active.department.name,
        currentUserImageUrl: state.profile.active.images["250x999"],
        enableCompany: state.instance.get("enableCompanyLevel"),
    };
};

export default withRouter(connect(mapStateToProps)(AdminShortcutsEditView));
