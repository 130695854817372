import { TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui";
import { FormControl, FormFooterBar, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { scrollToTop, validateRequiredTextField } from "src/utils";
import "./ToDoListEditView.scss";
import api from "src/spintr/SpintrApi";

interface Props extends RouteChildrenProps {
    currentUser: any;
    match: any;
    history: any;
    enableCompany?: boolean;
}

interface State {
    isLoading: boolean;
    list: any;
    saveSuccess: boolean;
    saveError: string[];
    enableFieldValidation?: boolean;
}

class TodoListEditView extends Component<Props, State> {
    state = {
        isLoading: false,
        list: {
            id: 0,
            targets: [
                {
                    id: this.props.currentUser.id,
                    type: 1,
                    name: this.props.currentUser.name,
                    typeName: "Person",
                },
            ],
        } as any,
        saveSuccess: false,
        saveError: [],
        enableFieldValidation: false
    };

    onCancel = () => {
        this.props.history.goBack();
    };

    onSubmit = () => {
        const { list } = this.state;

        this.setState({
            isLoading: true,
            enableFieldValidation: true
        }, () => {
            api
                .post("/api/v1/todos/list", list)
                .then((response) => {
                    this.props.history.push({
                        pathname: `/todo/list/${response.data.id}`,
                    });
                })
                .catch((saveError) => {
                    scrollToTop();
                    this.setState({ saveError: saveError.response.data.errorlist, isLoading: false });
                });
        });
    };

    componentDidMount = () => {
        const { listId } = this.props.match.params;
        if (listId) {
            this.setState({ isLoading: true }, () => {
                api.get(`/api/v1/todos/list?listId=${listId}`).then((response) => {
                    this.setState({ list: response.data, isLoading: false });
                });
            });
        }
    };

    render() {
        const { list, saveSuccess, saveError } = this.state;
        const { listId } = this.props.match.params;

        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }

        return (
            <div className="TodoListEditView">
                <Helmet>
                    <title>{localize("AttGora")}</title>
                </Helmet>
                {saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                )}
                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}
                <Label as="h1" size="h2">
                    {listId ? localize("RedigeraLista") : localize("SkapaNyLista")}
                </Label>
                <form>
                    <FormControl>
                        <TextField
                            label={localize("Namn")}
                            value={list.name}
                            onChange={(_e, name) => {
                                this.setState((state) => ({ list: { ...state.list, name } }));
                            }}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    <FormControl>
                        <FormTokenizedObjectInput
                            types={[
                                SpintrTypes.UberType.Department,
                                SpintrTypes.UberType.Office,
                                SpintrTypes.UberType.Role,
                                SpintrTypes.UberType.User,
                                ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
                            ]}
                            items={list.targets.map(t => {
                                return {
                                    ...t,
                                    id: t.id ? t.id : t.key,
                                    key: t.key ? t.key : t.id
                                }
                            })}
                            label={localize("RiktadTill")}
                            onChange={(t) => {
                                this.setState((state) => ({
                                    list: {
                                        ...state.list,
                                        targets: t.map((e) => {
                                            return {
                                                name: e.name,
                                                info: e.subText,
                                                id: e.key,
                                                imageUrl: e.imageUrl
                                            };
                                        }),
                                    },
                                }));
                            }}
                        />
                    </FormControl>
                </form>
                <FormFooterBar onCancelClick={this.onCancel} onSaveClick={this.onSubmit} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,

    currentUser: state.profile.active,
    enableCompany: state.instance.get("enableCompanyLevel"),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(TodoListEditView));
