import { Icon } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import Color from "src/style/colors/Color";
import { ActionMenu, Label } from "src/ui";
import "./VisageFilterButton.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IState { }

interface IProps {
    categories: Spintr.IActionMenuCategory[];
    labelText?: string;
    instanceColor?: string;
    onMenuDismissed?: any;
    grey?: boolean;
    inSocialFeed?: boolean;
}

class VisageFilterButton extends React.Component<IProps, IState> {
    protected buttonRef?;

    constructor(props) {
        super(props);

        this.state = {};
    }

    public render() {
        const classNames = ["VisageFilterButtonWrapper"];

        if (this.props.grey) {
            classNames.push("VisageFilterButtonWrapper-grey")
        }

        return (
            <div className={classNames.join(" ")}>
                <div className="VisageFilterButtonLabel">
                    <Label color="dark-grey" size="body-1">
                        {this.props.inSocialFeed ? localize("SocialtFlode") : localize("Aktuellt")}
                    </Label>
                </div>
                {/* <div className="VisageFilterButtonLine" style={{
                    backgroundColor: Color.fromHex(this.props.instanceColor).customLighten(90).toString("hex")
                }} /> */}
                <div>
                    <ActionMenu
                        categories={this.props.categories}
                        gapSpace={20}
                        disableHoverEffect
                        onMenuDismissed={this.props.onMenuDismissed}
                        renderButton={() => {
                            return (
                                <div className="VisageFilterButton visage-box-shadow" onMouseEnter={() => { this.buttonRef?.play() }}>
                                    <div className="VisageFilterButton-left">
                                        <Visage2Icon icon="sort" color="mid-grey" />
                                    </div>
                                    <div className="VisageFilterButton-right">
                                        <Label className="no-user-select" size="body-2" color="mid-grey">
                                            {
                                                this.props.labelText || localize("Allt")
                                            }
                                        </Label>
                                        <div className="helper" />
                                    </div>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instanceColor: state.instance.get("color")
});

export default connect(mapStateToProps)(VisageFilterButton);