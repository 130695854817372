import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import { ISocialFeedState, SocialPostContainer } from "src/social-feed";
import api from "src/spintr/SpintrApi";
import SpintrLoader from "src/ui/components/Loader";
import "../SocialFeedContainer/SocialFeedContainer.scss";
import "./SocialPostView.scss";
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/reducer";

interface IPageRouteParams {
    statusId: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    socialFeed: ISocialFeedState;
}

interface IState {
    isLoading: boolean;
    hasReduxPost: boolean;
    post?: Spintr.ISocialImage; //FIXME ISocialPostBase
}

class SocialPostView extends Component<IProps, IState> {
    protected fetchTimeout?: ReturnType<typeof setTimeout>;

    constructor(props: IProps) {
        super(props);

        const item = this.getPostFromRedux();

        this.state = {
            isLoading: !item,
            hasReduxPost: !!item
        };
    }

    getPostFromRedux() {
        const id = parseInt(this.props.match.params.statusId);

        for (const [key, value] of Object.entries(this.props.socialFeed)) {
            if (value.entries) {
                const item = value.entries.find(x => x.Id === id);

                if (item) {
                    return item;
                }
            }
        }

        return null;
    }

    fetch = async () => {
        if (this.state.hasReduxPost) {
            return;
        }

        const id = this.props.match.params.statusId;
        await api
            .get(`/api/v1/status/${id}`)
            .then(async (response) => {
                if (this.videoIsUploading(response.data)) {
                    this.fetchTimeout = setTimeout(this.fetch, 5000);
                }

                this.setState({ post: response.data, isLoading: false });
            })
            .catch((err) => {
                this.props.history.push("/404");
            });
    };

    componentDidUpdate = (prevProps) => {
        if (
            this.props.match.params["statusId"] !== prevProps.match.params["statusId"] &&
            this.props.match.params["statusId"]
        ) {
            this.fetch();
        }
    };

    videoIsUploading = (post) => {
        const isImage: boolean =
            post.photos !== undefined && post.photos.length > 0 && !post.photos[0].videoThumbnailUrl;

        return !isImage && post.FileStatus >= 0 && post.FileStatus <= 2;
    };

    componentDidMount = async () => {
        await this.fetch();
    };

    componentWillUnmount() {
        if (this.fetchTimeout) {
            clearTimeout(this.fetchTimeout);
        }
    }

    protected getPostKey(item: Spintr.ISocialPostBase): string {
        return "SFC.Post." + item.Id + "." + (item.isFavourited ? "1" : "0") + "." + (item.isFollowing ? "1" : "0");
    }

    render() {
        if (this.state.isLoading) return <SpintrLoader />;

        const post = this.state.hasReduxPost ?
            this.getPostFromRedux() :
            this.state.post;

        return (
            <div className="SocialPostView page-margin-bottom">
                <div className="socialFeed">
                    <div className="SpintrSocialFeed">
                        <div className="social-post-feed">
                            <SocialPostContainer
                                key={this.getPostKey(post)}
                                post={post}
                                onDoneEditing={() => {
                                    this.fetch();
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    socialFeed: state.socialFeed
});

export default connect(mapStateToProps)(SocialPostView);