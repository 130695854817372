import RecordFactory from "src/utils/RecordFactory";

const defaultValues: Spintr.IConversation = {
    deleted: false,
    group: null,
    id: -1,
    imageUrl: [],
    lastMessage: null,
    lastRead: {},
    message: "",
    participants: [],
    title: "",
    type: 1,
    unread: 0,
};

let tempId = -2;
class Conversation extends RecordFactory(defaultValues) implements Spintr.IConversation {
    public static createLocal(initialData: Partial<Spintr.IConversation>): Conversation {
        return new Conversation({
            ...initialData,

            id: isNaN(initialData.id) || initialData.id > 0
                ? initialData.id
                : tempId--,
        });
    }
}

export default Conversation;