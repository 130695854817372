import React from "react";
import KeyPointIndicatorWidget from "./KeyPointIndicatorWidget";
import "./KeyPointIndicatorWidget.scss";

interface IProps {
    config?: any;
    data: any;
    variables: { [key: string]: string };
}

const MultiKeyPointIndicatorWidget = (props: IProps) => {
    const vars = props.variables || {};

    return (
        <div className="MultiKeyPointIndicatorWidget">
            {props.data.map((item: any, index: number) => {
                return (
                    <KeyPointIndicatorWidget
                        key={index}
                        primaryValue={
                            item.value
                        }
                        secondaryValue={
                            vars.text || props.data?.text || ""
                        }
                        variables={vars}
                        difference={item.difference}
                        config={props.config}
                        title={
                            !!vars.titles && index < vars.titles.length ?
                                vars.titles[index] :
                                undefined
                        }
                        isMultiRow
                    />
                )
            })}
        </div>
    )
}

export default MultiKeyPointIndicatorWidget;
