import React, { Component, CSSProperties } from 'react';
import { connect } from 'react-redux';
import { MarketplaceWidget } from 'src/marketplace';
import { SpintrTypes } from 'src/typings';
import { Style } from "src/ui/helpers";
import TinyFormattedContent from '../Tiny/displayment/TinyFormattedContent';
import { TeaserBox } from 'src/teaser-box/components';
import PageCalendar from '../PageCalendar';
import ExpandableField from '../ExpandableField';
import {
    Icon,
    PrimaryButton
} from "@fluentui/react";
import "src/ui/components/UberContent/EditableContentSections/links.scss"
import { Link, RouteComponentProps } from 'react-router-dom';
import Direkten from '../Direkten';
import PagePreamble from '../PagePreamble';
import UserList from '../UserList';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps extends RouteComponentProps {
    uberContent: any;
    attachedFiles?: any;
    attachedFolders?: any;
    attachedSharePointListSearches?: any;
    language?: string;
    isSmallViewMode?: boolean;
}

const linkIsInternal = (url: string, location) => {
    try {
        if (url.startsWith("/")) {
            return { isInternal: true, url };
        } else if (new URL(url).origin === window.location.origin) {
            return { isInternal: true, url: new URL(url).pathname };
        } else {
            return { isInternal: false, url: url };
        }
    } catch {
        return { isInternal: false, url: url };
    }
};

class UberContentView extends Component<IProps> {
    render() {
        return (
            <div>
                {this.props.uberContent.uberContentRows.map((ucr) => {
                    return (
                        <div
                            key={ucr.index}
                            className="contentSection"
                            style={{
                                marginBottom: Style.getSpacingStr(5),
                            }}
                        >
                            {ucr.uberContentColumns.map((ucc) => {
                                const columnCount = ucr.uberContentColumns.length;
                                let isMultipleColumns = columnCount === 2 || columnCount > 2 && !this.props.isSmallViewMode;

                                let style: CSSProperties = {
                                    width: isMultipleColumns
                                        ? `calc((100% - ${Style.getSpacingStr(5 * (columnCount - 1))}) / ${columnCount})`
                                        : "100%",
                                    marginRight: isMultipleColumns
                                        ? ucc.index < columnCount - 1
                                            ? Style.getSpacingStr(5)
                                            : 0
                                        : 0,
                                    display: isMultipleColumns ? "inline-block" : "block",
                                    verticalAlign: "top",
                                };

                                let content = ucc.content;

                                if (ucc.type === SpintrTypes.UberContentSectionType.Image &&
                                    content &&
                                    content.length > 0) {
                                        let contentObj;

                                        if (content[0] === "{") {
                                            contentObj = JSON.parse(content);

                                            content = contentObj.content;
                                        }

                                        content = `<img alt="" style="max-width: 100%" src="${content}"
                                            ${contentObj?.externalAuthorName ? `data-external-author-name="${contentObj.externalAuthorName}"` : ``}
                                            ${contentObj?.externalAuthorUrl ? `data-external-author-url="${contentObj.externalAuthorUrl}"`: ``}
                                            ${contentObj?.externalPhotoUrl ? `data-external-photo-url="${contentObj.externalPhotoUrl}"` : ``}
                                            ${contentObj?.externalMediaflowId ? `data-external-mediaflow-id="${contentObj.externalMediaflowId}"` : ``}
                                        />`;
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.DataWidget) {
                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="data-widget"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <MarketplaceWidget
                                                    id={content}
                                                    autoWidth
                                                    displayBorder
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.TeaserBox) {
                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-teaser-box"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <TeaserBox
                                                    id={content}
                                                    showBorder
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.Calendar) {
                                    const inlineCalendarPattern = /(?:class="redactorInlineFolder)\s([a-z]*-[0-9]*)">([A-Za-zÀ-ÖØ-öø-ÿ\d\s]*)/igm;

                                    let match, calendarId, calendarName;

                                    while ((match = inlineCalendarPattern.exec(ucc.content))) {
                                        calendarId = parseInt(match[1].match(/(\d+)/)[0]);

                                        if (isNaN(calendarId)) {
                                            continue;
                                        }

                                        calendarName = match[2];
                                    };

                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-calendar-event-list"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <PageCalendar
                                                    id={calendarId}
                                                    columnCount={columnCount}
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.ExpandableField) {
                                    let contentObject = { text: "", title: "" };

                                    if (ucc.content) {
                                        try {
                                            contentObject = JSON.parse(ucc.content)
                                        } catch {
                                            contentObject = { text: "", title: "" };
                                        }
                                    }

                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-expandable-field"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <ExpandableField title={contentObject.title} text={contentObject.text} />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.Link) {
                                    if (!content || content.length === 0) return null;
                                    let { url, title } = JSON.parse(content)
                                    const internal = linkIsInternal(url, this.props.location);

                                    const isInternal = internal.isInternal
                                    if (isInternal) {
                                        url = internal.url
                                    }

                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-link-block"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                {
                                                    isInternal ?
                                                        <Link to={url}>
                                                            <div className="fs-body-2 InTextPageLinkButton" style={{ color: "#787CDD" }}> 
                                                                {title} 
                                                                <Visage2Icon style={{ marginLeft: 8 }} icon="arrow-right-1" hexColor="#787CDD" />
                                                            </div>
                                                        </Link> :
                                                        <a rel="noopener noreferrer" target="_blank" href={url} style={{ color: "#787CDD" }}>
                                                            <div className="fs-body-2 InTextPageLinkButton">
                                                                {title}
                                                                <Visage2Icon style={{ marginLeft: 8 }} icon="arrow-right-1" hexColor="#787CDD" />
                                                            </div>
                                                        </a>
                                                }
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.UserList) {
                                        return (
                                        <div key={ucc.index} style={style}>
                                            <UserList data={content}/>
                                        </div>
                                    );
                                }

                                if (ucc.type == SpintrTypes.UberContentSectionType.Direkten) {
                                    return (
                                        <div
                                            key={ucc.index}
                                            style={style}
                                        >
                                            <Direkten category={content} />
                                        </div>
                                    );
                                }

                                if (ucc.type == SpintrTypes.UberContentSectionType.Preamble) {
                                    return (
                                        <div key={ucc.index} style={style}>
                                            <PagePreamble 
                                                content={content}
                                                language={this.props.language} />
                                        </div>
                                    );
                                }

                                return (
                                    <div key={ucc.index} style={style}>
                                        <TinyFormattedContent
                                            content={content}
                                            attachedFiles={this.props.attachedFiles}
                                            attachedFolders={this.props.attachedFolders}
                                            attachedSharePointListSearches={this.props.attachedSharePointListSearches}
                                            language={this.props.language} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        )
    }
}


const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,

    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(UberContentView);
