import axios, { AxiosInstance } from "axios";

export let versionString = "";

// IE11 cache fix
axios.defaults.headers.common["If-Modified-Since"] = 0;

const api: AxiosInstance = axios.create();

api.interceptors.response.use(request => {
    if (!!request.headers["x-login-page"]) {
        window.location.href = "/logga-in";
    }

    if (!!request.headers["x-version"]) {
        versionString = request.headers["x-version"];
    }

    return request;
}, error => {
     if (error.request.responseURL.includes("sign-in?return")) {
        const currentPath = window.location.pathname;
        const encodedPath = encodeURIComponent(currentPath);

        // will reload the entire app, should be a rare occurrence though - probably fine?
        window.location.replace("/sign-in?return=" + encodedPath);
    }

    return Promise.reject(error);
});

export default api;
