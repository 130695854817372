import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { getLinkTarget } from "src/shortcuts/utils";
import { SpintrUser } from "src/ui";
import "./HighlightedShortcuts.scss";

const HighlightedShortcuts: FunctionComponent = () => {
    let highlights = useSelector(
        (state: Spintr.AppState) => state.shortcuts.items.filter(
            (shortcut) => shortcut.highlight,
        ),
    );

    highlights = highlights.sort((a, b) => (a.title > b.title ? 1 : -1));

    if (highlights.length === 0) {
        return null;
    }

    return (
        <div className="HighlightedShortcuts">
            {highlights.map(shortcut => (
                <div
                    className="shortcut"
                    key={"Shortcut.Id" + shortcut.id}
                >
                    {shortcut.formAction && (
                        <div className="form-wrap">
                            <form
                                action={shortcut.formAction}
                                id={"shortcutFormID" + shortcut.id}
                                method="POST"
                                target={getLinkTarget(shortcut.url)}
                            >
                                <input
                                    name="formAction"
                                    type="hidden"
                                    value={shortcut.formAction}
                                />
                                <input
                                    name="foo"
                                    type="hidden"
                                    value="bar"
                                />
                                <input
                                    name={shortcut.usernameName}
                                    type="hidden"
                                    value={shortcut.usernameValue}
                                />
                                <input
                                    name={shortcut.passwordName}
                                    type="hidden"
                                    value={shortcut.passwordValue}
                                />
                                <SpintrUser
                                    size={40}
                                    name={shortcut.title}
                                    squareImage={true}
                                    hideText={true}
                                    iconToDisplayInsteadOfInitials={"export-3"}
                                    imageUrl={shortcut.imageUrl}
                                />
                                <div className="shortcut-inner">
                                    {shortcut.title}
                                </div>
                            </form>
                        </div>
                    )}
                    {!shortcut.formAction && (
                        <a
                            className="link"
                            href={shortcut.url}
                            target={getLinkTarget(shortcut.url)}
                        >
                            <SpintrUser
                                size={40}
                                name={shortcut.title}
                                squareImage={true}
                                hideText={true}
                                iconToDisplayInsteadOfInitials={"export-3"}
                                imageUrl={shortcut.imageUrl}
                            />
                            <div className="shortcut-inner">
                                {shortcut.title}
                            </div>
                        </a>
                    )}
                </div>
            ))
            }
        </div>
    )
};

export default HighlightedShortcuts;
