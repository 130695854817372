import { Image, Pivot, PivotItem } from "@fluentui/react";
import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { FavoriteList, FavoriteLoader } from "src/favorites";
import { localize } from "src/l10n";
import {
    HighlightedShortcuts,
    ShortcutList,
    ShortcutLoader
} from "src/shortcuts";
import { getLinkTarget } from "src/shortcuts/utils";
import { Loader } from "src/ui";
import "./ResponsiveLinks.scss";

const officeShortcuts = [
    {
        imageUrl: "/images/teams.svg",
        name: "Teams",
        url: "https://teams.microsoft.com/",
    },
    {
        imageUrl: "/images/onenote.svg",
        name: "OneNote",
        url: "https://www.office.com/launch/onenote",
    },
    {
        imageUrl: "/images/word.svg",
        name: "Word",
        url: "https://www.office.com/launch/word",
    },
    {
        imageUrl: "/images/excel.svg",
        name: "Excel",
        url: "https://www.office.com/launch/excel",
    },
    {
        imageUrl: "/images/powerpoint.svg",
        name: "PowerPoint",
        url: "https://www.office.com/launch/powerpoint",
    },
];

const ResponsiveLinks: FunctionComponent = () => {
    const shortcutsText = useMemo(() => localize("Genvagar"), []);
    const favoritesText = useMemo(() => localize("Favoriter"), []);

    const stateProps = useSelector(
        (state: Spintr.AppState) => ({
            hasFavorites: state.favorites.items.length > 0,
            hasShortcuts: state.shortcuts.items.some(
                (shortcut) => !shortcut.highlight,
            ),
            isLoading: state.shortcuts.isLoading && state.favorites.isLoading,
            showO365: 
                state.profile.active.office365Connected &&
                state.instance.get("office365Enabled") &&
                !state.instance.get("hideOffice365Shortcuts")
        }),
    );
    
    return (
        <div className="ResponsiveLinks">
            <ShortcutLoader />
            <FavoriteLoader />
            {!stateProps.showO365 && (
                <div className="office365">
                    {officeShortcuts.map(shortcut => (
                        <div
                            className="shortcut"
                            key={"Shortcut.O365" + shortcut.name}
                        >
                            <a
                                className="link"
                                href={shortcut.url}
                                target={getLinkTarget(shortcut.url)}
                            >
                                <div className="image">
                                    <Image
                                        alt={shortcut.name}
                                        src={shortcut.imageUrl} 
                                    />
                                </div>
                                <div className="shortcut-inner">
                                    {shortcut.name}
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            )}
            <HighlightedShortcuts />
            {(stateProps.hasShortcuts || stateProps.hasFavorites) && (
                <Pivot>
                    {stateProps.hasShortcuts && (
                        <PivotItem
                            headerText={shortcutsText}
                            title={shortcutsText}
                        >
                            <ShortcutList />
                        </PivotItem>
                    )}
                    {stateProps.hasFavorites && (
                        <PivotItem
                            headerText={favoritesText}
                            title={favoritesText}
                        >
                            <FavoriteList />
                        </PivotItem>
                    )}
                </Pivot>
            )}
            {stateProps.isLoading && <Loader />}
        </div>
    )
};

export default ResponsiveLinks;