import moment from 'moment';
import { DefaultButton, FontIcon, PrimaryButton, Separator, Stack, StackItem } from '@fluentui/react';
import React, { Component, ReactNode } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Action } from 'redux';
import { SocialBlock } from 'src/interactions/components';
import { localize } from 'src/l10n';
import { setConfirmPopup } from 'src/popups/actions';
import { IApplicationState } from 'src/spintr/reducer';
import { SpintrTypes } from 'src/typings';
import { ActionMenu, Label, Loader, setShouldReloadFavourites, SpintrUser, UnstyledButton, UserHovercard } from 'src/ui';
import { FormTokenizedObjectInput } from 'src/ui/components/Forms';
import TinyFormattedContent from 'src/ui/components/Tiny/displayment/TinyFormattedContent';
import { setCalendarPopup, toggleCalendarEventDeleted } from '../actions';
import CalendarAPI from '../calendar-api';
import { formatEventCopy, getAvailableSeatsString, getDateString, isRegistrationAvailable } from '../util';
import './CalendarEventPopupView.scss';
import api from 'src/spintr/SpintrApi';
import CalendarEmailAttendeesPopup from './CalendarEmailAttendeesPopup';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps {
    event?: any;
    startDate?: Date;
    endDate?: Date;
    hideCalendarSelector?: boolean;
    errorList?: string[];
    isLoading?: boolean;
    preSelectedCalendarId?: number;
    currentUserId?: number;
    isAdmin: boolean;
    isEditor: boolean;
    enableCompany?: boolean;
    // TODO: preselect calendar
    dispatch: (action: Action) => void;
    dismiss: () => void;
}

interface IState {
    displayInviteForm: boolean;
    displayAllAttendees: boolean;
    newAttendees: any[];
    isInviting: boolean;
    event?: any;
    displayEmailModal?: boolean;
}

class CalendarEventPopupView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            displayInviteForm: false,
            displayAllAttendees: false,
            newAttendees: [],
            isInviting: false,
            event: props.event,
        };
    }

    private displayAllAttendees = () => {
        this.setState({
            displayAllAttendees: true,
        });
    };

    private toggleInviteForm = () => {
        this.setState((prevState) => ({
            displayInviteForm: !prevState.displayInviteForm,
            newAttendees: [],
        }));
    };

    private sendInvites = async () => {
        this.setState({ isInviting: true });

        const newAttendeeIds = this.state.newAttendees.map((newAttendee) => newAttendee.key);
        await CalendarAPI.sendInvites(newAttendeeIds, this.state.event.id, this.state.event.exchangeId);

        this.setState({ isInviting: false, newAttendees: [], displayInviteForm: false });
    };

    private toggleEmailAttendeesPopup = () => {
        this.setState((prevState) => ({
            displayEmailModal: !prevState.displayEmailModal,
        }));
    }

    private toggleFavourite = async () => {
        const { event } = this.state;

        if (event.isFavourite) {
            await api.delete(`/api/v1/favorites/${event.id}`);
            this.setState((prevState) => ({
                event: {
                    ...prevState.event,
                    isFavourite: false,
                },
            }));
        } else {
            await api.post(`/api/v1/favorites`, { id: event.id });
            this.setState((prevState) => ({
                event: {
                    ...prevState.event,
                    isFavourite: true,
                },
            }));
        }
        this.props.dispatch(setShouldReloadFavourites(true)) 
    };

    private toggleFollow = async () => {
        const { event } = this.state;

        if (event.isFollowing) {
            await api.delete(`/api/v1/follow/${event.id}`);
            this.setState((prevState) => ({
                event: {
                    ...prevState.event,
                    isFollowing: false,
                },
            }));
        } else {
            await api.post(`/api/v1/follow`, { id: event.id });
            this.setState((prevState) => ({
                event: {
                    ...prevState.event,
                    isFollowing: true,
                },
            }));
        }
    };

    private leaveEvent = async () => {
        const { event } = this.state;

        // this.setState({
        //     isLoading: true,
        // });

        if (event.id) {
            await CalendarAPI.leaveEvent(this.props.currentUserId, event.id);
        } else if (event.exchangeId) {
            await CalendarAPI.joinRejectExchangeEvent(event.exchangeId, false, "");
        } else if (event.googleId) {
            await CalendarAPI.joinRejectGoogleEvent(event.googleid, false);
        }

        // TODO: don't reload event?
        //this.fetchEvent();
    };

    private joinEvent = async () => {
        const { event } = this.state;

        // this.setState({
        //     isLoading: true,
        // });

        if (event.id) {
            await CalendarAPI.joinEvent(this.props.currentUserId, event.id, true);
        } else if (event.exchangeId) {
            await CalendarAPI.joinRejectExchangeEvent(event.exchangeId, true, "");
        } else if (event.googleId) {
            await CalendarAPI.joinRejectGoogleEvent(event.googleId, true);
        }


        // TODO: don't reload event?
        //this.fetchEvent();
    };

    private deleteEvent = async () => {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("ArDuSakerPaAttDuVillTaBortDennaPost") + "?",
                onConfirm: async () => {
                    this.props.dispatch(toggleCalendarEventDeleted(this.state.event));
                    this.props.dismiss();
                },
            })
        );
    };

    private inviteOnChange = (users) => {
        this.setState((prevState) => ({
            newAttendees: users.map((u) => ({
                ...u,
                text: u.name,
                image: u.imageUrl,
                info: u.subText,
            })),
        }));
    };

    private renderAttendee = (attendee) => {
        return (
            <div key={attendee.id}>
                <Link to={`/profile/${attendee.id}`} className="left">
                    <UserHovercard userId={attendee.id}>
                        <SpintrUser name={attendee.name} imageUrl={attendee.image} hideText />
                    </UserHovercard>
                </Link>
            </div>
        );
    };

    public render(): ReactNode {
        const { event } = this.state;

        if (this.props.isLoading || !event) {
            return <Loader />;
        }

        const isExchange = !!(event.exchangeId || event.googleId);

        var attendeeList = [];

        if (event.attendees) {
            attendeeList = attendeeList.concat(event.attendeeList);
        }

        if (event.invited) {
            attendeeList = attendeeList.concat(event.invited);
        }

        if (event.rejected) {
            attendeeList = attendeeList.concat(event.rejected);
        }

        const showAttendees = attendeeList.length > 0;

        let showOpenInvite = false;
        let hasJoined = false;
        let hasInvite = false;
        let showLeave = false;
        let showJoin = false;

        if (event.attendees) {
            hasJoined = event.attendees.some((attendee) => attendee.id === this.props.currentUserId);
        }
        if (event.invited) {
            hasInvite = event.invited.some((invited) => invited.id === this.props.currentUserId);
        }

        // TODO: can be simplified
        if (event.calendarType === SpintrTypes.CalendarType.Project) {
            showOpenInvite = false;
            showLeave = hasJoined;
            showJoin = !hasJoined;
        } else {
            if (hasInvite) {
                showOpenInvite = false;
                showLeave = false;
                showJoin = false;
            } else {
                if (event.invitesOnly) {
                    if (this.props.currentUserId === event.owner) {
                        showJoin = true;
                        showOpenInvite = true;
                        showLeave = false;
                        if (hasJoined) {
                            showLeave = true;
                            showJoin = false;
                        }
                    } else {
                        showOpenInvite = false;
                        showLeave = false;
                        showJoin = false;
                        if (hasJoined) {
                            showLeave = true;
                        }
                    }
                } else {
                    if (hasJoined) {
                        showOpenInvite = true;
                        showLeave = true;
                        showJoin = false;
                    } else {
                        showOpenInvite = false;
                        showLeave = true;
                        showJoin = true;
                    }
                }

                if (event.exchangeId || event.googleId) {
                    if (event.isOrganizer) {
                        showOpenInvite = false;
                        showLeave = false;
                        showJoin = false;
                    } else if (event.hasAccepted) {
                        showOpenInvite = false;
                        showLeave = true;
                        showJoin = false;
                    } else {
                        showJoin = true;
                        showOpenInvite = false;
                        showLeave = false;
                    }
                }
            }
        }

        const exchangeStatus =
            !event.exchangeId && !event.googleId
                ? ""
                : event.isOrganizer
                ? localize("Arrangor")
                : event.hasAccepted
                ? localize("Kommer")
                : event.hasDeclined
                ? localize("KommerInte")
                : localize("Inbjuden");

        return (
            <div className="calendar-event-popup-view">
                <Helmet>
                    <title>{localize("Kalender")}</title>
                </Helmet>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                    <StackItem grow={1}>
                        <Label size="h2">{event.title}</Label>
                    </StackItem>
                    <ActionMenu
                        categories={[
                            {
                                items: [
                                    ...(!isExchange
                                        ? [
                                              {
                                                  text: localize(
                                                      event.isFavourite ? "TaBortFranFavoriter" : "LaggTillIFavoriter"
                                                  ),
                                                  onClick: this.toggleFavourite,
                                                  icon: event.isFavourite ? "FavoriteStarFill" : "FavoriteStar",
                                              },
                                              {
                                                  text: localize(
                                                      event.isFollowing ? "StangAvNotiser" : "AktiveraNotiser"
                                                  ),
                                                  onClick: this.toggleFollow,
                                                  icon: "Flag",
                                              },
                                          ]
                                        : []),
                                    {
                                        text: localize("Skrivutsidan"),
                                        onClick: () => {
                                            // TODO: Print
                                        },
                                    },
                                    { text: localize("Stang"), onClick: this.props.dismiss },
                                    ...(showLeave && !event.eventHasExpired
                                        ? [
                                              {
                                                  text: localize("Lamna"),
                                                  onClick: this.leaveEvent,
                                              },
                                          ]
                                        : []),
                                    ...(showJoin && isRegistrationAvailable(event)
                                        ? [
                                              {
                                                  text: localize("GaMedEvent"),
                                                  onClick: this.joinEvent,
                                              },
                                          ]
                                        : []),
                                    {
                                        text: localize("Kopiera"),
                                        onClick: () => {
                                            const copiedEvent = formatEventCopy(event, this.props.currentUserId);

                                            this.props.dispatch(
                                                setCalendarPopup({
                                                    isOpen: true,
                                                    event: copiedEvent,
                                                    eventId: 0,
                                                    editMode: true,
                                                })
                                            );
                                        },
                                    },
                                    ...(((this.props.isEditor ||
                                        this.props.isAdmin ||
                                        (
                                            this.props.currentUserId === event.owner ||
                                            ((event.exchangeId || event.googleId) && event.isOrganizer)
                                        ))) && event.attendees.filter((attendee) => attendee.id !== this.props.currentUserId).length > 0 ? [{
                                            text: localize("SkickaEpostTillDeltagare"),
                                            onClick: this.toggleEmailAttendeesPopup,
                                        }] : [])
                                ],
                            },
                            ...(((this.props.isEditor ||
                            this.props.isAdmin ||
                            (
                                this.props.currentUserId === event.owner ||
                                ((event.exchangeId || event.googleId) && event.isOrganizer)
                            )) &&
                            ((!event.exchangeId && !event.googleId) || event.isOrganizer))
                                ? [
                                      {
                                          title: localize("Redaktor"),
                                          items: [
                                              {
                                                  // or calendar.allowEdit?
                                                  text: localize("Redigera"),
                                                  onClick: () => {
                                                      this.props.dispatch(
                                                          setCalendarPopup({
                                                              isOpen: true,
                                                              event,
                                                              eventId: event.id,
                                                              editMode: true,
                                                          })
                                                      );
                                                  },
                                              },
                                              {
                                                  text: localize("TaBort"),
                                                  onClick: this.deleteEvent,
                                              },
                                          ],
                                      },
                                  ]
                                : []),
                        ]}
                    />
                    <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.props.dismiss}>
                        <Visage2Icon icon="close-circle" />
                    </UnstyledButton>
                </Stack>
                <Separator />
                <div className="data-box">
                    {isExchange && (
                        <div>
                            <Label className="left" weight="medium">
                                {localize("Status")}
                            </Label>
                            <Label className="right">{exchangeStatus}</Label>
                        </div>
                    )}
                    <div>
                        <Label className="left" weight="medium">
                            {localize("Tid")}
                        </Label>
                        <Label className="right">{getDateString(event)}</Label>
                    </div>
                    <div>
                        <Label className="left" weight="medium">
                            {localize("Plats")}
                        </Label>
                        <Label className="right">{event.place}</Label>
                    </div>
                    <div>
                        <Label className="left" weight="medium">
                            {localize("Kalender")}
                        </Label>
                        <Label className="right">{event.calendarTitle}</Label>
                    </div>
                    {!!event.seats && (
                        <div>
                            <Label className="left" weight="medium">
                                {localize("AntalPlatser")}
                            </Label>
                            <Label className="right">{event.seats}</Label>
                        </div>
                    )}
                    {event.registrationClose && (
                        <div>
                            <Label className="left" weight="medium">
                                {localize("CALENDAR_ACTIVITY_LAST_REGISTRATION")}
                            </Label>
                            <Label className="right">
                                {moment(event.registrationClose).format("L")}{" "}
                                {moment(event.registrationClose).format("LT")}
                            </Label>
                        </div>
                    )}
                </div>

                {event.text && event.text.length > 0 && (
                    <div className="content">
                        <Label weight="medium">{localize("Beskrivning")}</Label>
                        <TinyFormattedContent content={event.text} />
                    </div>
                )}

                {!this.state.displayInviteForm && showAttendees && (
                    <>
                        {!isExchange && (
                            <>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                                    <Label weight="medium">
                                        {localize("Deltagare_stor")} {getAvailableSeatsString(event)}
                                    </Label>
                                    {!event.eventHasExpired &&
                                        showOpenInvite &&
                                        showLeave &&
                                        (!event.calendarType ||
                                            event.calendarType !== SpintrTypes.CalendarType.Project) && (
                                            <UnstyledButton onClick={this.toggleInviteForm}>
                                                <Label weight="medium">{localize("BjudIn")}</Label>
                                            </UnstyledButton>
                                        )}
                                </Stack>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                                    {event.attendees.slice(0, 1).map((attendee) => this.renderAttendee(attendee))}
                                    {event.attendees.length > 1 && !this.state.displayAllAttendees && (
                                        <UnstyledButton onClick={this.displayAllAttendees}>
                                            <Label>{localize("VisaAlla")}</Label>
                                        </UnstyledButton>
                                    )}
                                    {this.state.displayAllAttendees &&
                                        event.attendees.slice(1).map((attendee) => this.renderAttendee(attendee))}
                                </Stack>
                            </>
                        )}
                    </>
                )}
                {this.state.displayInviteForm && !this.state.isInviting && (
                    <>
                        <FormTokenizedObjectInput
                            items={this.state.newAttendees}
                            types={[
                                SpintrTypes.UberType.User,
                                SpintrTypes.UberType.Role,
                                SpintrTypes.UberType.Department,
                                SpintrTypes.UberType.Office,
                                ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
                            ]}
                            onChange={this.inviteOnChange}
                        />
                        <Stack horizontal tokens={{ childrenGap: 6 }}>
                            <DefaultButton text={localize("Avbryt")} onClick={this.toggleInviteForm} />
                            <PrimaryButton
                                text={localize("BjudIn")}
                                onClick={this.sendInvites}
                                disabled={this.state.newAttendees.length === 0}
                            />
                        </Stack>
                    </>
                )}
                {this.state.displayInviteForm && this.state.isInviting && <Loader />}
                {isExchange && showAttendees && (
                    <>
                        <Label weight="medium">
                            {localize("Deltagare_stor")} {getAvailableSeatsString(event)}
                        </Label>
                        {event.attendees.map((attendee) => (
                            <Label key={attendee.id}>{attendee.name}</Label>
                        ))}
                    </>
                )}

                {hasJoined && !isExchange && <SocialBlock uberId={event.id} />}

                {this.state.displayEmailModal && (
                    <CalendarEmailAttendeesPopup event={event} dismiss={this.toggleEmailAttendeesPopup} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        currentUserId: state.profile.active.id,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        enableCompany: state.instance.get("enableCompanyLevel"),
    };
};

export default connect(mapStateToProps)(CalendarEventPopupView);
