import moment from "moment";
import { CommandBar, DefaultButton, Icon, Modal, PrimaryButton, Stack } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { SpintrTypes } from "src/typings";
import { Label, Loader, PageHeader, SpintrUser, UnstyledButton, UserHovercard } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormControl, TokenizedObjectInput } from "src/ui/components/Forms";
import { capitalizeFirstLetter, isAnythingDirty } from "src/utils";
import { FormSection } from "../ui/components/Forms";
import AbsenceEditView from "./AbsenceEditView";
import "./AbsenceView.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    history: any;
    instance?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    currentUserId?: number;
    dispatch?: any;
    isWebViewMode: boolean;
}

interface IState {
    isLoading: boolean;
    showInfoDialog: boolean;
    target: any;
    item?: any;
    user?: any;
    users?: any;
    editId: number;
    dateFrom: any;
    dateTo: any;
    daysInMonth: any;
}

class AbsenceView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showInfoDialog: false,
            target: [],
            editId: -1,
            dateFrom: moment.utc().startOf("month"),
            dateTo: moment.utc().startOf("month").add(1, "month"),
            daysInMonth: moment.utc().daysInMonth(),
        };
    }

    goToNextMonth() {
        let newDateFrom = this.state.dateFrom.clone().add(1, "month");
        let newDateTo = this.state.dateTo.clone().add(1, "month");
        let newDaysInMonth = this.state.dateFrom.clone().add(1, "month").daysInMonth();

        this.setState(
            {
                dateFrom: newDateFrom,
                dateTo: newDateTo,
                daysInMonth: newDaysInMonth,
                isLoading: true,
            },
            () => {
                this.fetch();
            }
        );
    }

    goToPreviousMonth() {
        let newDateFrom = this.state.dateFrom.clone().add(-1, "month");
        let newDateTo = this.state.dateTo.clone().add(-1, "month");
        let newDaysInMonth = this.state.dateFrom.clone().add(-1, "month").daysInMonth();

        this.setState(
            {
                dateFrom: newDateFrom,
                dateTo: newDateTo,
                daysInMonth: newDaysInMonth,
                isLoading: true,
            },
            () => {
                this.fetch();
            }
        );
    }

    fetch() {
        let department = 0;

        if (this.state.target && this.state.target.length > 0) {
            department = this.state.target[0].id;
        }

        let params = {
            take: 9999,
            dateFrom: this.state.dateFrom.toDate(),
            dateTo: this.state.dateTo.toDate(),
            department,
        };

        api
            .get("/api/absence", {
                params: params,
            })
            .then((response) => {
                this.setState({
                    isLoading: false,
                    users: response.data.users,
                });
            });
    }

    componentDidMount() {
        this.fetch();
    }

    renderFormModal() {
        if (this.state.editId < 0) {
            return null;
        }

        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.editId > -1}
                onDismiss={() => {
                    const close = () => {
                        this.setState({ editId: -1 });
                    }

                    if (isAnythingDirty()) {
                        this.props.dispatch(setConfirmPopup({
                            isOpen: true,
                            message: localize("UnsavedChangesWarning"),
                            onConfirm: close
                        }));
                    } else {
                        close();
                    }
                }}
            >
                <PopupHeader
                    text={this.state.editId === 0
                        ? localize("LaggTillFranvaroperiod")
                        : this.state.editId > 0
                            ? localize("Redigera") + " " + localize("Franvaro").toLowerCase()
                            : ""}
                    onClose={() => {
                        const close = () => {
                            this.setState({ editId: -1 });
                        }

                        if (isAnythingDirty()) {
                            this.props.dispatch(setConfirmPopup({
                                isOpen: true,
                                message: localize("UnsavedChangesWarning"),
                                onConfirm: close
                            }));
                        } else {
                            close();
                        }
                    }} />
                <AbsenceEditView
                    id={this.state.editId}
                    isModal
                    onCancel={() => {
                        const close = () => {
                            this.setState(
                                {
                                    editId: -1,
                                },
                                () => {
                                    this.setState(
                                        {
                                            item: null,
                                            user: null,
                                            isLoading: true,
                                        },
                                        () => {
                                            this.fetch();
                                        }
                                    );
                                }
                            );
                        }

                        if (isAnythingDirty()) {
                            this.props.dispatch(setConfirmPopup({
                                isOpen: true,
                                message: localize("UnsavedChangesWarning"),
                                onConfirm: close
                            }));
                        } else {
                            close();
                        }
                    }}
                />
            </Modal>
        );
    }

    public render(): ReactNode {
        const canEdit =
            this.state.user &&
            (this.state.user.id === this.props.currentUserId || this.props.isAdmin || this.props.isEditor);

        return (
            <div className="AbscenceView">
                <Helmet>
                    <title>{localize("Franvaro")}</title>
                </Helmet>
                <div>
                    <div>
                        <PageHeader title={localize("Franvaro")} helpTextId={8} />
                    </div>
                    {this.state.showInfoDialog && (
                        <CustomDialog
                            hideTitle={true}
                            hidePrimaryBtn={true}
                            show={this.state.showInfoDialog}
                            hideSecondaryBtn={true}
                            children={
                                <div className="AbscenceView-popup">
                                    <SpintrUser imageUrl={this.state.user.image} name={this.state.user.name} />
                                    <Label as="h1" size="h2">
                                        {this.state.item.title}
                                    </Label>
                                    <Label>{this.state.item.text}</Label>
                                    <Label size="small-1">
                                        {moment.utc(this.state.item.dateFrom).format("L") +
                                            " - " +
                                            moment.utc(this.state.item.dateTo).format("L")}
                                    </Label>
                                    <Label>{localize(this.state.item.typeName)}</Label>
                                </div>
                            }
                            footerChildren={
                                <div className="AbscenceView-popup-footer">
                                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                        {canEdit && (
                                            <DefaultButton
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            showInfoDialog: false,
                                                        },
                                                        () => {
                                                            api
                                                                .post(
                                                                    "/api/absence/" +
                                                                    this.state.item.id +
                                                                    "/toggledeleted"
                                                                )
                                                                .then(() => {
                                                                    this.setState(
                                                                        {
                                                                            item: null,
                                                                            user: null,
                                                                            isLoading: true,
                                                                        },
                                                                        () => {
                                                                            this.fetch();
                                                                        }
                                                                    );
                                                                });
                                                        }
                                                    );
                                                }}
                                                text={localize("TaBort")}
                                            />
                                        )}
                                        {canEdit && (
                                            <DefaultButton
                                                onClick={() => {
                                                    this.setState({
                                                        showInfoDialog: false,
                                                        editId: this.state.item.id,
                                                    });
                                                }}
                                                text={localize("Redigera")}
                                            />
                                        )}
                                        <PrimaryButton
                                            onClick={() => {
                                                this.setState({
                                                    showInfoDialog: false,
                                                });
                                            }}
                                            text={localize("Stang")}
                                        />
                                    </Stack>
                                </div>
                            }
                            onDismiss={() => {
                                this.setState({
                                    showInfoDialog: false,
                                });
                            }}
                            onConfirm={() => () => {
                                this.setState({
                                    showInfoDialog: false,
                                });
                            }}
                        />
                    )}
                    <div className="commandBar">
                        <CommandBar
                            items={[
                                {
                                    key: "add",
                                    text: localize("LaggTillFranvaroperiod"),
                                    iconOnly: this.props.isWebViewMode,
                                    onClick: () => {
                                        this.setState({
                                            editId: 0,
                                        });
                                    },
                                    iconProps: { iconName: "Add" },
                                    className: "commandBarAddButton",
                                },
                            ]}
                        />
                        <div style={{ maxWidth: 500, minWidth: 200 }} className="SearchField">
                            <FormSection>
                                <FormControl>
                                    <span className="absenceSearch">
                                        <TokenizedObjectInput
                                            itemLimit={1}
                                            types={[SpintrTypes.UberType.Department]}
                                            items={this.state.target}
                                            placeholder={localize("SokAvdelning")}
                                            onChange={(targets) => {
                                                this.setState(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        target: targets.map((e) => {
                                                            return {
                                                                name: e.name,
                                                                info: e.subText,
                                                                type: SpintrTypes.UberType.User,
                                                                id: e.key,
                                                                imageUrl: e.imageUrl,
                                                            };
                                                        }),
                                                    }),
                                                    () => {
                                                        this.setState(
                                                            {
                                                                item: null,
                                                                user: null,
                                                                isLoading: true,
                                                            },
                                                            () => {
                                                                this.fetch();
                                                            }
                                                        );
                                                    }
                                                );
                                            }}
                                        />
                                    </span>

                                </FormControl>
                            </FormSection>

                        </div>

                    </div>
                    {this.renderTimeline()}
                </div>
                {this.renderFormModal()}
            </div>
        );
    }

    renderTimelineContent() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        if (!this.state.users || this.state.users.length === 0) {
            return (
                <div className="spintr-list-empty-list">
                    <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                        {localize("IngaPoster")}
                    </Label>
                </div>
            );
        }

        const dates = [];

        for (let i = 1; i < this.state.daysInMonth + 1; i++) {
            dates.push(i);
        }

        return (
            <div className="AbsenceTimeline-Content">
                <div className="AbsenceTimeline-Content-left">
                    <div>
                        <div>
                            <Label color="white">{" a"}</Label>
                        </div>
                    </div>
                    {this.state.users.map((u, i) => {
                        return (
                            <div className="AbsenceTimeline-Content-Row" key={i}>
                                <div>
                                    <UserHovercard userId={u.id}>
                                        <Label size="body-2">{u.name}</Label>
                                    </UserHovercard>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="AbsenceTimeline-Content-right">
                    {dates.map((d, i) => {
                        return (
                            <div className="AbsenceTimeline-header-date" key={d}>
                                <Label size="small-1">{d}</Label>
                            </div>
                        );
                    })}
                    {this.state.users.map((u, i) => {
                        return (
                            <div className="AbsenceTimeline-Content-Row" key={i}>
                                {dates.map((d, i) => {
                                    return (
                                        <div className="AbsenceTimeline-Content-Column" key={d}>
                                            {this.renderActivity(d, u)}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderActivity(date, user) {
        let referenceDate = this.state.dateFrom.clone().set("date", date);

        for (let item of user.items) {
            const dateFrom = moment.utc(item.dateFrom);
            const dateTo = moment.utc(item.dateTo);

            if (
                referenceDate.isSame(dateFrom) ||
                referenceDate.isSame(dateTo) ||
                referenceDate.isBetween(dateFrom.subtract(1, "day"), dateTo)
            ) {
                return (
                    <div
                        className="AbsenceTimeline-activity"
                        onClick={() => {
                            this.setState({
                                item: item,
                                showInfoDialog: true,
                                user: user,
                            });
                        }}
                        style={{
                            backgroundColor: item.type.color
                                ? item.type.color.length === 7
                                    ? item.type.color
                                    : "#" + item.type.color
                                : this.props.instance.get("color"),
                        }}
                    ></div>
                );
            }
        }

        return null;
    }

    renderTimeline() {
        return (
            <div className="AbsenceTimeline">
                <div className="AbsenceTimeline-header no-user-select">
                    <UnstyledButton ariaLabel={localize("LEFT")} onClick={this.goToPreviousMonth.bind(this)}>
                        <Visage2Icon icon="arrow-left-2" />
                    </UnstyledButton>
                    <Label className="AbsenceTimeline-header-label" as="div" size="h4" role="heading">
                        <p>
                            {capitalizeFirstLetter(this.state.dateFrom.format("MMMM")) +
                                " " +
                                this.state.dateFrom.format("YYYY")}
                        </p>
                    </Label>
                    <UnstyledButton ariaLabel={localize("RIGHT")} onClick={this.goToNextMonth.bind(this)}>
                        <Visage2Icon icon="arrow-right-3" />
                    </UnstyledButton>
                </div>
                {this.renderTimelineContent()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        instance: state.instance,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        isWebViewMode: state.ui.isWebViewMode
    };
};

export default connect(mapStateToProps)(AbsenceView);
