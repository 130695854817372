import Immutable, { List } from "immutable";
import { produce } from "immer";

import {
    QUERY_FILES_BY_FOLDER_PENDING,
    QUERY_FILES_BY_FOLDER_REJECTED,
    QUERY_FILES_BY_FOLDER_FULFILLED,
    SET_SEARCH_TEXT,
    NEW_FOLDER,
    SAVE_NEW_FOLDER,
    SAVE_NEW_FOLDER_PENDING,
    SAVE_NEW_FOLDER_FULFILLED,
    SAVE_NEW_FOLDER_REJECTED,
    ABORT_NEW_FOLDER,
    DELETE_FOLDER,
    DELETE_FOLDER_PENDING,
    DELETE_FOLDER_REJECTED,
    DELETE_FOLDER_FULFILLED,
    DELETE_FILE,
    DELETE_FILE_PENDING,
    DELETE_FILE_REJECTED,
    DELETE_FILE_FULFILLED,
    SAVE_NEW_FILE_PENDING,
    SAVE_NEW_FILE_REJECTED,
    SAVE_NEW_FILE_FULFILLED,
    SELECT_ITEM,
    UPLOADED_FILE,
    SET_RENAMING_ITEM,
    UPDATE_FOLDER_FULFILLED,
    UPDATE_FILE_FULFILLED,
    FAVOURITE_ITEM_FULFILLED,
    SET_SELECTED_ITEMS,
    FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_FULFILLED,
    FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_PENDING,
} from "./file-action-types";
import moment from "moment";
import { localize } from "src/l10n";
import { fileTypeToName } from "./utils";
import { removeLikeRealtime } from "src/interactions/actions";

export interface IFileFileState {
    currentParams: IQueryParams;
    currentSource?: string;
    currentId?: string;

    items: any[];
    queryItems: IQueryItems;
    searchText: string;
    totalCount: number;
    isLoadingNewFile: boolean;
    selectedItems: Spintr.IFileBrowserFile[];
    sharePointAttachedFiles: ISharePointAttachedFiles;
}

interface IQueryParams {
    orderByColumn: string;
    isAscending: boolean;
}

interface IQueryItems {
    errors: IError;
    isLoading: boolean;
}

interface IError {
    exists: boolean;
}

interface ISharePointAttachedFiles {
    isLoading: boolean;
    searches: any[];
}

export const initialState: IFileFileState = {
    currentParams: { orderByColumn: "", isAscending: true },
    items: [],
    queryItems: {
        errors: { exists: false },
        isLoading: false,
    },
    searchText: "",
    totalCount: 0,
    isLoadingNewFile: false,
    selectedItems: [],
    sharePointAttachedFiles: {
        // TODO: Move this? More related to pages than files
        isLoading: false,
        searches: [],
    },
};

const queryFilesByFolderPending = (state, action, draft) => {
    draft.queryItems = {
        errors: initialState.queryItems.errors,
        folderId: action.meta.folderId,
        isLoading: true,
    };
};

const queryFilesByFolderRejected = (state, action, draft) => {
    draft.queryItems = {
        ...state.queryItems,
        isLoading: false,
        errors: { exists: true },
    };
    draft.items = [];
    draft.totalCount = 0;
};

const queryFilesByFolderFulfilled = (state, action, draft) => {
    var files = action.payload.data.data;

    const { folderId, source } = action.meta;
    const nextParams: IQueryParams = {
        isAscending: action.meta.isAscending,
        orderByColumn: action.meta.orderByColumn
    };

    files = files.map((file) => ({
        ...file,
        date: file.date ? moment(file.date).format("L") : null,
        typeName: file.isDirectory ? localize("Mapp") : localize(fileTypeToName[file.type], true),
    }));

    const paramsDidChange = (
        nextParams.isAscending !== state.currentParams.isAscending ||
        nextParams.orderByColumn !== state.currentParams.orderByColumn ||
        folderId !== state.currentFolderId ||
        source !== state.currentSource
    );

    if (!paramsDidChange) {
        draft.items = state.items.concat(
            files.filter((f1) => !!f1 && state.items.every((f2) => f2.id !== f1.id)),
        );
    } else {
        draft.items = files;
    }
    
    draft.queryItems = {
        ...state.queryItems,
        errors: {
            exists: false,
        },
        isLoading: false,
    };

    /*
     * Update params so that we can detect changes next time around
     */
    draft.currentParams = nextParams;
    draft.currentFolderId = folderId;
    draft.currentSource = source;

    draft.totalCount = action.payload.data.totalCount;
    if (action.payload.data.totalCount === 0 && files.length > 0 && action.payload.data.hasMore) {
        draft.totalCount = draft.items.length + 1;
    }
};

const fileReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case QUERY_FILES_BY_FOLDER_PENDING:
                return queryFilesByFolderPending(state, action, draft);

            case QUERY_FILES_BY_FOLDER_REJECTED:
                return queryFilesByFolderRejected(state, action, draft);

            case QUERY_FILES_BY_FOLDER_FULFILLED:
                return queryFilesByFolderFulfilled(state, action, draft);

            case SET_SEARCH_TEXT:
                draft.searchText = action.payload;
                draft.items = [];
                break;

            case NEW_FOLDER:
                var newItem = { isDirectory: true, newFolder: true };

                draft.items = [newItem, ...state.items];
                break;

            case ABORT_NEW_FOLDER:
                draft.items = draft.items.filter((i) => !i.newFolder);
                break;

            case DELETE_FOLDER_FULFILLED:
                draft.items = draft.items.filter((i) => i.id != action.meta);
                draft.selectedItems = initialState.selectedItems;
                break;

            case DELETE_FILE_PENDING:
                draft.queryItems = {
                    ...state.queryItems,
                    isLoading: true,
                };
                break;

            case DELETE_FILE_FULFILLED:
                draft.items = draft.items.filter((i) => i.id != action.meta);
                draft.selectedItems = initialState.selectedItems;
                draft.queryItems = {
                    ...state.queryItems,
                    isLoading: false,
                };
                break;

            case SAVE_NEW_FOLDER_PENDING:
                var item = draft.items.find((i) => i.newFolder);
                item.isLoading = true;
                item.name = action.meta.name;
                break;

            case SAVE_NEW_FOLDER_FULFILLED:
                var item = draft.items.find((i) => i.newFolder);
                item.id = action.payload.data.id;
                item.name = action.payload.data.name;
                item.newFolder = false;
                item.isLoading = true;
                break;

            case SAVE_NEW_FOLDER_REJECTED:
                draft.items = draft.items.filter((i) => !i.newFolder);
                break;

            case SAVE_NEW_FILE_PENDING:
                draft.isLoadingNewFile = true;
                break;

            case SAVE_NEW_FILE_REJECTED:
                draft.isLoadingNewFile = false;
                break;

            case SAVE_NEW_FILE_FULFILLED:
                draft.isLoadingNewFile = false;
                break;

            case SELECT_ITEM:
                const index = draft.selectedItems.findIndex((item) => action.meta.id === item.id);
                if (index > -1) {
                    draft.selectedItems.splice(index, 1);
                } else {
                    draft.selectedItems.push(action.meta);
                }
                break;

            case SET_SELECTED_ITEMS:
                draft.selectedItems = action.meta;
                break;

            case UPLOADED_FILE:
                draft.items.unshift(action.meta);

                break;

            case SET_RENAMING_ITEM:
                var item = draft.items.find((i) => i.id === action.meta);
                if (!item) {
                    break;
                }
                item.renaming = !item.renaming;

                break;

            case UPDATE_FOLDER_FULFILLED:
                if (action.meta != action.payload.data.parent.id) {
                    // folder was moved
                    draft.items = draft.items.filter((i) => i.id != action.payload.data.id);

                    break;
                }

                var item = draft.items.find((i) => i.id == action.payload.data.id);
                item.name = action.payload.data.name;
                item.renaming = false;
                break;

            case UPDATE_FILE_FULFILLED:
                let parent = action.payload.data.folder || action.payload.data.parentReference;

                if (action.meta != parent.id) {
                    // file was moved
                    draft.items = draft.items.filter((i) => i.id != action.payload.data.id);

                    break;
                }

                var item = draft.items.find((i) => i.id == action.payload.data.id);
                item.name = action.payload.data.name;
                item.renaming = false;
                break;

            case FAVOURITE_ITEM_FULFILLED:
                var item = draft.items.find((i) => i.id == action.meta);
                break;

            case FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_PENDING:
                draft.sharePointAttachedFiles = {
                    isLoading: true,
                    searches: [],
                };
                break;

            case FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_FULFILLED:
                draft.sharePointAttachedFiles = {
                    isLoading: false,
                    searches: action.payload.data,
                };
                break;

            default:
                return state;
        }
    });

export default fileReducer;
