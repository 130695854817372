import { Image, ImageFit } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { getFileIconUrl } from 'src/office365/utils';
import { Label, SpintrUser } from "src/ui";
import { decodeHtmlEntities } from "src/utils";
import "./SearchResultObjectHit.scss";

interface IProps {
    item?: Spintr.ISearchResult;
    officeItem?: Spintr.IOffice365FileSearchItem;
    onClick?(item: any): void;
}

const AOrLink = (props) => {
    const isInternal = !props.to || props.to.startsWith(window.location.origin) || props.to.startsWith("/");

    if (isInternal) {
        return <Link {...props}>{props.children}</Link>
    } else {
        return <a href={props.to} className={props.className} target="_blank">{props.children}</a>
    }
}

const SearchResultObjectHit: FunctionComponent<IProps> = (props) => {
    const item = props.item ?
        props.item :
        props.officeItem;

    const { onClick } = props;

    const onItemClick = () => {
        if (!onClick) {
            return;
        }

        onClick(item);
    }

    const text = decodeHtmlEntities(item.caption, false);

    const officeIconSrc = getFileIconUrl(item);

    return (
        <AOrLink onClick={onItemClick} to={item.url} className={"SearchResultObjectHit type" + item.type + (!!item.imageUrl ? " has-image" : "")}>
           {!!item.imageUrl && item.type !== 1 && item.type !== 3 && item.type !== 6 && (
                <div className="image">
                    <AOrLink onClick={onItemClick} to={item.url}>
                        {!!item.imageUrl && (
                            <Image
                                className="hit-image"
                                height={55}
                                imageFit={ImageFit.cover}
                                src={item.imageUrl}
                                width={80}
                            />
                        )}
                    </AOrLink>
                </div>
            )}
            {item.type === 1 && (
                <div className="user-image">
                    <SpintrUser
                        hideText={true}
                        imageUrl={item.imageUrl}
                        name={item.name}                        
                    />
                </div>
            )}
            <div className="content">
                {item.type !== 3 && item.type !== 6 && item.type !== 7 && item.type !== 12 && (
                    <div className={item.type !== 1 ? "name" : "user-name"}>
                        {!!props.officeItem && (
                            <img src={officeIconSrc} alt="" />
                        )}
                        <Label size="h6" as="span" color="dark-grey">
                            {item.name}
                        </Label>
                    </div>
                )}                
                {item.type !== 1 && (
                    <div className={item.type !== 3 && item.type !== 6 && item.type !== 7 && item.type !== 12 ? "type" : "no-title"}>
                        <Label size="body-3" weight="medium" uppercase color="visageGray3">
                            {!!props.item && localize(`Ubertype${item.type}_0_4`)}
                            {!!props.officeItem && "SharePoint"}
                        </Label>
                    </div>
                )}                
                {!!text && item.type !== 7 && item.type !== 12 && (
                    <div className="description">
                        <Label size="body-2" color="mid-grey">
                            {decodeHtmlEntities(text)}
                        </Label>
                    </div>
                )}
                {(item.type === 7 || item.type === 12) && (
                    <div className="description">
                        <Label size="body-2" color="mid-grey">
                            {item.name}
                        </Label>
                    </div>
                )}
            </div>            
        </AOrLink>
    );
};

export default SearchResultObjectHit;
