import classnames from "classnames";
import addClass from "dom-helpers/addClass";
import removeClass from "dom-helpers/removeClass";
import scrollbarSize from "dom-helpers/scrollbarSize";
import getWidth from "dom-helpers/width";
import dompurify from "dompurify";
import moment from "moment";
import { DefaultButton, Icon, Modal, Separator } from "@fluentui/react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import localize from "src/l10n/localize";
import { IApplicationState } from "src/spintr/reducer";
import { Label } from "src/ui";
import { FormControl } from "src/ui/components/Forms";
import { capitalizeFirstLetter, isAnythingDirty, truncate } from "src/utils";
import { renderUnsavedChangesDialog } from "src/utils/renderUnsavedChangesDialog";
import { joinEvent, leaveEvent, setCalendarPopup } from "../actions";
import CalendarAPI from "../calendar-api";
import { getAvailableSeatsString, getDateString, isRegistrationAvailable } from "../util";
import CalendarEventView from "./CalendarEventView";
import "./SpintrAgenda.scss";
import { navigate } from "./utils/constants";
import * as dates from "./utils/dates";
import { inRange } from "./utils/eventLevels";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const sanitizer = dompurify.sanitize;

const renderDateSpan = (event) => {
    return getDateString(event);
};

const renderDateBox = (event) => {
    return (
        <div className="date-box">
            <Label className="date">{moment(event.start).format("DD")}</Label>
            <Label className="month">{capitalizeFirstLetter(moment(event.start).format("MMM"))}</Label>
        </div>
    );
};

const renderAttendingButtons = (dispatch, userId, event) => {
    const hasJoined = event.attendees.some((attendee) => attendee.id === userId && attendee.status === 1);

    return (
        <div className="attending-buttons">
            <FormControl>
                <DefaultButton
                    styles={{ icon: { color: "green" }, root: { marginRight: "6px" } }}
                    disabled={!hasJoined}
                    iconProps={!hasJoined && { iconName: "Accept" }}
                    onClick={async () => {
                        const response = await CalendarAPI.leaveEvent(userId, event.id);
                        dispatch(leaveEvent(event.id, userId, response));
                    }}
                >
                    {localize("KommerInte")}
                </DefaultButton>
            </FormControl>
            <FormControl>
                <DefaultButton
                    styles={{ icon: { color: "green" } }}
                    iconProps={hasJoined && { iconName: "Accept" }}
                    disabled={hasJoined || !isRegistrationAvailable(event)}
                    onClick={async () => {
                        const response = await CalendarAPI.joinEvent(userId, event.id, true);
                        dispatch(joinEvent(event.id, userId, response));
                    }}
                >
                    {localize("Kommer")}
                </DefaultButton>
            </FormControl>
        </div>
    );
};

const renderParticipants = (event) => {
    return (
        // FIXME: LINK
        <>
            <Label size="body-3" className="participants">
                {
                    event.attendees.filter((a) => {
                        return a.status === 1;
                    })?.length
                }{" "}
                {localize("Deltagare")}
            </Label>
            <Label size="body-3" className="seats-left">
                {getAvailableSeatsString(event)}
            </Label>
        </>
    );
};

function SpintrAgenda({ selected, getters, accessors, localizer, components, length, date, events }) {
    const headerRef = useRef(null);
    const dateColRef = useRef(null);
    const timeColRef = useRef(null);
    const contentRef = useRef(null);
    const tbodyRef = useRef(null);
    const selectUserId = (state: IApplicationState) => state.profile.active.id;
    const currentUserId = useSelector(selectUserId);
    const dispatch = useDispatch();
    const [showEvent, setShowEvent] = useState(undefined);
    const [displayUnsavedChangesPopup, setDisplayUnsavedChangesPopup] = useState(false);
    const location = useLocation();
    const isGroupView = location.pathname.indexOf("/groups/") > -1;

    useEffect(() => {
        _adjustHeader();
    });

    const openEvent = (event) => {
        if (event.exchangeId || event.googleId) {
            dispatch(setCalendarPopup({ isOpen: true, event }));
        } else {
            setShowEvent(event);
        }
    };

    const renderAgendaItems = () => {
        return events.map((event, idx) => {
            return (
                <div className="agenda-item" key={"agenda-item" + "_" + idx}>
                    <div
                        onClick={() => {
                            openEvent(event);
                        }}
                        className={classnames("top", {
                            ["with-image"]: event.imageUrl,
                        })}
                        style={{
                            backgroundImage: event.imageUrl && `url(${event.imageUrl})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                    >
                        <div className="gradient-overlay">
                            {renderDateBox(event)}
                            <div className="text">
                                <Label className="title" as="h3" size="h3">
                                    {event.title}
                                </Label>
                                <Label className="date-span" as="p" size="body-3">
                                    {renderDateSpan(event)}
                                </Label>

                                {event.place && event.place !== "" && event.place !== "-" && (
                                    <Label as="p" size="body-3">
                                        {event.place}
                                    </Label>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="middle">
                        {event.text && (
                            <Label size="body-2" className="description general-row-break">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: truncate(sanitizer(event.text, { ALLOWED_TAGS: [] }), 250),
                                    }}
                                ></p>
                            </Label>
                        )}

                        {event.registrationClose && (
                            <>
                                <Label
                                    className="calendarEventHeader"
                                    as="div"
                                    role="heading"
                                    size="small-2"
                                    color="dark-grey"
                                    weight="medium"
                                    style={{
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {localize("CALENDAR_ACTIVITY_LAST_REGISTRATION")}
                                </Label>
                                <Label as="p" size="body-3">
                                    {moment(event.registrationClose).format("L")}{" "}
                                    {moment(event.registrationClose).format("LT")}
                                </Label>
                            </>
                        )}
                    </div>
                    <Separator className="separator" />
                    <div className="bottom">
                        {renderAttendingButtons(dispatch, currentUserId, event)}
                        {renderParticipants(event)}
                    </div>
                </div>
            );
        }, []);
    };

    const _adjustHeader = () => {
        if (!tbodyRef.current) return;

        let header = headerRef.current;
        let firstRow = tbodyRef.current.firstChild;

        if (!firstRow) return;

        let isOverflowing = contentRef.current.scrollHeight > contentRef.current.clientHeight;

        let _widths = [];
        let widths = _widths;

        _widths = [getWidth(firstRow.children[0]), getWidth(firstRow.children[1])];

        if (widths[0] !== _widths[0] || widths[1] !== _widths[1]) {
            dateColRef.current.style.width = _widths[0] + "px";
            timeColRef.current.style.width = _widths[1] + "px";
        }

        if (isOverflowing) {
            addClass(header, "rbc-header-overflowing");
            header.style.marginRight = scrollbarSize() + "px";
        } else {
            removeClass(header, "rbc-header-overflowing");
        }
    };

    let { messages } = localizer;
    let end = dates.add(date, length, "day");

    let range = dates.range(date, end, "day");

    events = events
        .filter((event) => inRange(event, date, end, accessors))
        .filter((e) => {
            if (!e.delted) return e;
        });

    events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

    return (
        <div
            className={classnames("SpintrAgenda", {
                ["group-view"]: isGroupView,
            })}
        >
            {showEvent && (
                <Modal
                    className="spintr-modal CalendarEventViewModal"
                    isOpen={showEvent !== undefined}
                    onDismiss={() => {
                        if (isAnythingDirty()) {
                            return setDisplayUnsavedChangesPopup(true);
                        }

                        setShowEvent(undefined);
                    }}
                >
                    <CalendarEventView
                        id={showEvent.id}
                        onDismiss={() => {
                            setShowEvent(undefined);
                        }}
                    />
                </Modal>
            )}
            {events.length !== 0 ? (
                <>{renderAgendaItems()}</>
            ) : (
                <span className="rbc-agenda-empty">
                    <Visage2Icon icon={"calendar"} /> {messages.noEventsInRange}
                </span>
            )}

            {renderUnsavedChangesDialog(
                displayUnsavedChangesPopup,
                () => {
                    setDisplayUnsavedChangesPopup(false);
                },
                () => {
                    setDisplayUnsavedChangesPopup(false);
                    setShowEvent(undefined);
                }
            )}
        </div>
    );
}

SpintrAgenda.propTypes = {
    events: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    length: PropTypes.number.isRequired,

    selected: PropTypes.object,

    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,
};

SpintrAgenda.defaultProps = {
    length: 30,
};

SpintrAgenda.range = (start, { length = SpintrAgenda.defaultProps.length }) => {
    let end = dates.add(start, length, "day");
    return { start, end };
};

SpintrAgenda.navigate = (date, action, { length = SpintrAgenda.defaultProps.length }) => {
    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -length, "day");

        case navigate.NEXT:
            return dates.add(date, length, "day");

        default:
            return date;
    }
};

SpintrAgenda.title = (start, { length = SpintrAgenda.defaultProps.length, localizer }) => {
    let end = dates.add(start, length, "day");
    return localizer.format({ start, end }, "agendaHeaderFormat");
};

export default SpintrAgenda;
