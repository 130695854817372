import { Pivot, PivotItem } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import RightColumnDashboard from "src/admin/views/AdminDashboard/RightColumnDashboard";
import { changeHeaderText } from "src/admin/views/AdminSidebar";
import { contexts } from "src/admin/views/AdminSidebarContexts";
import { localize } from "src/l10n";
import { setRightColumnItems } from "src/right-column/actions";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import RightColumnTags from "src/tags/RightColumnTags";
import { TeaserBox } from "src/teaser-box";
import { SpintrTypes } from "src/typings";
import { setCurrentlyShowingTeaserboxes, setRightColumnVisible, StandardErrorBoundary } from "src/ui";
import Birthdays from "src/ui/components/Birthdays/Birthdays";
import OperatingInfo from "src/ui/components/OperatingInfo/OperatingInfo";
import Todos from "src/ui/components/Todos/Todos";
import { uniqueId } from "src/utils";
import { TeamsTools } from "../TeamsTools";
import "./RightColumn.scss";

interface IProps {
    setRightColumnVisible?: (visible: boolean) => void;
    setCurrentlyShowingTeaserboxes?: any;
    currentlyShowingTeaserboxes?: number[];
    rightColumnVisible?: boolean;
    currentUserId?: number;
    history?: any;
    location?: any;
    match?: any;
    dispatch?: any;
    instance?: any;
    smallViewMode?: boolean;
    isInTeamsApp?: boolean;
    sidebars?: any[];
    hasFetchedSidebars?: boolean;
    apps?: any;
}

interface IState {
    activeContext: string;
    ignoredTeaserboxes: number[];
}

class RightColumn extends Component<IProps, IState> {
    private _isMounted : boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            activeContext: this.getContextFromPath(props.location.pathname),
            ignoredTeaserboxes: [],
        };
    }

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    fetchSidebars() {
        if (this.props.hasFetchedSidebars) {
            this.setActiveContext();

            return;
        }

        api.get("/api/v1/sidebars?take=0").then((response) => {
            if (!this._isMounted) {
                return;
            }

            const items = response.data.map((d) => {
                return {
                    ...d,
                    formattedData: JSON.parse(d.data),
                };
            });

            this.props.dispatch(setRightColumnItems(items));

            setTimeout(() => {
                if (!this._isMounted) {
                    return;
                }

                this.setActiveContext();
            }, 500);
        });
    }

    componentDidMount() {
        this._isMounted = true;

        this.fetchSidebars();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getContextFromPath(pathname) {
        let activeContext = "";

        if (pathname.indexOf("/create") > -1 || pathname.indexOf("/edit/") > -1) {
            return activeContext;
        }

        for (const [key, value] of Object.entries(contexts)) {
            for (const route of value.routes) {
                if (pathname === route || (route !== "/" && pathname.indexOf(route) === 0)) {
                    activeContext = key;
                }
            }
        }

        return activeContext;
    }

    shouldTeamsToolsBeRendered() {
        const hideTeamsTools = this.props.instance.get('hideTeamsTools');

        if (hideTeamsTools) {
            return false;
        }

        const teamsToolsContexts = ["", "/"];

        let renderTeamsTools = this.props.isInTeamsApp &&
            teamsToolsContexts.indexOf(window.location.pathname) > -1;

        return renderTeamsTools;
    }

    setActiveContext() {
        if (!this._isMounted) {
            return;
        }

        if (this.props.location.pathname.startsWith("/nyheter")) {
            // AC-1044 ignore redirected urls that have sidebar to prevent infinite crash
            return;
        }

        this.setState(
            {
                activeContext: this.getContextFromPath(this.props.location.pathname),
            },
            () => {
                let activeSidebar = this.getSidebarFromContext(this.state.activeContext);


                let renderTeamsTools = this.shouldTeamsToolsBeRendered();

                if (renderTeamsTools) {
                    if (!this.props.rightColumnVisible) {
                        this.props.setRightColumnVisible(true);
                    }

                    return;
                }

                if (this.props.rightColumnVisible && !activeSidebar) {
                    this.props.setRightColumnVisible(false);
                } else if (!this.props.rightColumnVisible && activeSidebar) {
                    this.props.setRightColumnVisible(true);
                }
            }
        );
    }

    getSidebarFromContext(context) {
        if (context === "admin" || context === "wikis" || context === "files") {
            return null;
        }

        return this.props.sidebars.find((s) => s.contexts.indexOf(context) > -1);
    }

    shouldComponentUpdate = (nextProps : IProps, nextState : IState) => {
        const nextContext = this.getContextFromPath(nextProps.location.pathname);
        const nextSidebar = this.getSidebarFromContext(nextContext);
        const currentSidebar = this.getSidebarFromContext(this.state.activeContext);

        const sidebarWillChange =
            !!nextSidebar !== !!currentSidebar ||
            (!!nextSidebar && !!currentSidebar && nextSidebar.id !== currentSidebar.id);

        const sidebarsListWillChanged = nextProps.sidebars.length !== this.props.sidebars.length;
        const rightColumnVisibleWillChange = nextProps.rightColumnVisible !== this.props.rightColumnVisible;
        const ignoredTeaserboxesWillChange = nextState.ignoredTeaserboxes !== this.state.ignoredTeaserboxes;

        return sidebarWillChange || sidebarsListWillChanged || rightColumnVisibleWillChange || ignoredTeaserboxesWillChange;
    };

    componentDidUpdate(prevProps: IProps): void {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setActiveContext();
        }

        const activeSidebar = this.getSidebarFromContext(this.state.activeContext);

        if (activeSidebar) {
            const currentlyShowingTeaserboxes = [];

            for (const item of activeSidebar.formattedData) {
                if (item.widgets) {
                    for (const widget of item.widgets) {
                        const widgetName = widget.name ? widget.name : widget.label;

                        if (widgetName === "teaserbox") {
                            const boxId = parseInt(
                                ((widget || {}).config || {}).boxId,
                                10
                            );

                            if (!isNaN(boxId)) {
                                currentlyShowingTeaserboxes.push(boxId);
                            }
                        }
                    }
                } else {
                    const widgetName = item.name ? item.name : item.label;

                    if (widgetName === "teaserbox") {
                        currentlyShowingTeaserboxes.push(
                            parseInt(item.boxId, 10)
                        );
                    }
                }
            }

            this.props.dispatch(this.props.setCurrentlyShowingTeaserboxes(currentlyShowingTeaserboxes));
        } else {
            this.props.dispatch(this.props.setCurrentlyShowingTeaserboxes([]));
        }
    }

    widgetTypes = {
        teaserbox: {
            render: (item, isGroup) => {
                if (!item.config && !item.boxId) {
                    return null;
                }

                const boxId: number = item.config && item.config.boxId ? item.config.boxId : item.boxId;
                if (!boxId || isNaN(boxId) || this.state.ignoredTeaserboxes.includes(boxId)) {
                    return null;
                }

                return (
                    <div className="RightColumn-TeaserBoxes">
                        <TeaserBox
                            id={boxId}
                            onFetchError={() => {
                                if (!this._isMounted) {
                                    return;
                                }

                                this.setState((prevState) => ({
                                    ignoredTeaserboxes: [...prevState.ignoredTeaserboxes, boxId]
                                }))
                            }}
                        />
                    </div>
                );
            },
        },

        // favourites: {
        //     render: (item, isGroup) => {
        //         return <Favourites fetchFavourites hideTitle={isGroup} />;
        //     },
        // },

        // shortcuts: {
        //     render: (item, isGroup) => {
        //         let renderTeamsTools = this.shouldTeamsToolsBeRendered();

        //         if (renderTeamsTools) {
        //             return null;
        //         }

        //         return (
        //             <Shortcuts
        //                 hideTitle={isGroup}
        //                 userId={this.props.currentUserId}
        //                 disableButtons={false}
        //                 fetchShortcuts
        //             />
        //         );
        //     },
        // },

        birthdays: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Birthdays)) {
                    return null;
                }

                return (
                    <Birthdays
                        disableButtons={false}
                        hideTitle={isGroup}
                        userId={this.props.currentUserId}
                    />
                );
            },
        },

        operatinginfo: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.OperatingInfo)) {
                    return null;
                }

                return (
                    <OperatingInfo
                        hideTitle={isGroup}
                        disableButtons={false}
                    />
                );
            },
        },

        calendar: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Calendars)) {
                    return null;
                }

                return <div>Calendar</div>;
            },
        },

        dashboard: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Dashboard)) {
                    return null;
                }

                return <RightColumnDashboard />
            },
        },

        todo: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Todos)) {
                    return null;
                }

                return <Todos hideTitle={isGroup} />;
            },
        },
        groups: {
            render: () => {
                // TODO: Fix this

                return null;
            },
        },
        grouponline: {
            render: () => {
                // TODO: Fix this

                return null;
            },
        },
        widget: {
            render: (item, index) => {
                if (!item || !item.widgets || !item.widgets.length) {
                    return null;
                }

                let renderTeamsTools = this.shouldTeamsToolsBeRendered();

                if (renderTeamsTools) {
                    item.widgets = item.widgets.filter(w => w.name !== "shortcuts");
                }

                if (item.widgets.length < 2) {
                    return (
                        item.widgets &&
                        item.widgets.map((w, idx) => {
                            if (!w || !w.name) {
                                return null;
                            }

                            const widget = this.widgetTypes[w.name];
                            if (!widget || !widget.render) {
                                return null;
                            }

                            return <div key={`widget-${idx}`}>{widget.render(w)}</div>;
                        })
                    );
                } else {
                    return (
                        <Pivot
                            overflowBehavior={"menu"}
                            key={`pivot-${index + "-" + item.id}`}
                        >
                            {item.widgets &&
                                item.widgets.map((w) => {
                                    if (!w || !w.name) {
                                        return null;
                                    }

                                    const widget = this.widgetTypes[w.name];
                                    if (!widget || !widget.render) {
                                        return null;
                                    }

                                    return (
                                        <PivotItem key={uniqueId()} headerText={localize(changeHeaderText(w.name))}>
                                            <div key={uniqueId()}>{widget.render(w, true)}</div>
                                        </PivotItem>
                                    );
                                })}
                        </Pivot>
                    );
                }
            },
        },
    };

    renderSidebar(sidebar) {
        let widgets = sidebar.formattedData;

        return (
            <div className="RightColumn-widgets">
                {widgets.map((w, i) => {
                    let widgetId = w.name;
                    if (!widgetId) widgetId = "widget";

                    const foundWidgetType = this.widgetTypes[widgetId];

                    if (!foundWidgetType) {
                        console.log("couldn't find widget type: " + widgetId); //TODO: Remove this

                        return null;
                    }

                    return foundWidgetType.render(w, i);
                })}
                {
                    this.isAppEnabled(SpintrTypes.SpintrApp.Tags) && (
                        <RightColumnTags />
                    )
                }
            </div>
        );
    }

    public render(): ReactNode {
        let renderTeamsTools = this.shouldTeamsToolsBeRendered();
        let renderSidebar = true;

        if (
            !this.props.rightColumnVisible ||
            !this.props.sidebars ||
            this.props.sidebars.length === 0 ||
            !this.state.activeContext
        ) {
            renderSidebar = false;
        };

        const activeSidebar = this.getSidebarFromContext(this.state.activeContext);

        if (!activeSidebar || this.props.smallViewMode) {
            renderSidebar = false;
        }

        if (!renderSidebar &&
            !renderTeamsTools) {
            return null;
        }

        return (
            <div className="right-column-wrapper">
                <div className="RightColumn">
                    <StandardErrorBoundary>
                        {renderTeamsTools && (
                            <TeamsTools />
                        )}
                        {renderSidebar && this.renderSidebar(activeSidebar)}
                    </StandardErrorBoundary>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,

    currentUserId: state.profile.active.id,
    currentlyShowingTeaserboxes: state.ui.currentlyShowingTeaserboxes,
    rightColumnVisible: (state.ui.rightColumn || {}).isVisible,
    instance: state.instance,
    smallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.TabletPortrait,
    isInTeamsApp: state.ui.isInTeamsApp,
    sidebars: state.rightColumn.items,
    hasFetchedSidebars: state.rightColumn.hasFetchedItems,
    apps: state.app
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ setRightColumnVisible, setCurrentlyShowingTeaserboxes }, dispatch),
    };
}

const ConnectedRightColumn = connect(mapStateToProps, mapDispatchToProps)(RightColumn);

const ConnectedRightColumnWithRouter = withRouter(ConnectedRightColumn)

export default ConnectedRightColumnWithRouter;
