import { default as classnames, default as classNames } from "classnames";
import { ProgressIndicator, Separator } from "@fluentui/react";
import { Modal } from "@fluentui/react/lib/components/Modal/Modal";
import { Image } from "@fluentui/react/lib/Image";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { RouteChildrenProps, withRouter } from "react-router";
import SocialBlock from "src/interactions/components/SocialBlock";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ContentImageViewerAndSelector, ContentWithInfoPanel, Loader, PageHeader, PageInfoPanel } from "src/ui";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import { Label } from "../../ui";
import BlogsActionMenu from "./BlogsActionMenu";
import "./BlogsPostView.scss";
import moment from "moment";
import CategoryButton from "src/ui/components/Buttons/CategoryButton/CategoryButton";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import api from "src/spintr/SpintrApi";
import { fetchInformationFeed } from "src/spintr/components/InformationFeed/actions";
import { Action } from "redux";
import { fetchTeaserBoxes } from "src/teaser-box/actions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const CarouselModal = (props) => {
    return (
        <Modal isOpen={true} onDismiss={props.dismissCallback}>
            <div>
                <Carousel
                    width="700px"
                    dynamicHeight
                    showThumbs={false}
                    showStatus={false}
                    selectedItem={props.images.findIndex((i) => {
                        return i.id === props.clickedImage;
                    })}
                >
                    {props.images.map((i) => {
                        return (
                            <div>
                                <img src={i.imageUrl} alt="" />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </Modal>
    );
};

interface IProps extends RouteChildrenProps {
    post: any;
    actionMenuCallBack: any;
    history: any;
    match: any;
    appMode: boolean;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    isPreview?: boolean;
    dispatch?: (action: Action) => void;
}

interface IState {
    post: any;
    showImageCarousel: boolean;
    clickedImage: number;
    displayDeleteDialog: boolean;
    isLoading: boolean;
}
class BlogPost extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            post: {},
            showImageCarousel: false,
            clickedImage: undefined,
            displayDeleteDialog: false,
            isLoading: false
        };
    }

    listCategories = () => {
        return (
            <CategoryButton id={0} label={this.props.post.blog.name} />
        )
    }

    renderDeleteDialog() {
        return (
            <CustomDialog
                show={this.state.displayDeleteDialog}
                title={localize("RaderaInnehall")}
                message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase())}
                onConfirm={() => {
                    this.setState(
                        {
                            isLoading: true,
                        },
                        async () => {
                            await api.delete("/api/blogposts/" + this.props.post.id);

                            this.setState(
                                {
                                    displayDeleteDialog: false,
                                    isLoading: false,
                                },
                                () => {
                                    let params = {} as any;

                                    params.take = 10;
                                    params.skip = 0;

                                    this.props.dispatch(fetchTeaserBoxes());
                                    this.props.dispatch(fetchInformationFeed(false, { params }));
                                    this.props.history.goBack();
                                }
                            );
                        }
                    );
                }}
                onDismiss={() => {
                    this.setState({
                        displayDeleteDialog: false,
                    });
                }}
            />
        );
    }

    public render = () => {
        const { post, appMode } = this.props;
        const { blogSlug } = this.props.match.params;
        const { postSlug } = this.props.match.params;

        if (!post) {
            return <Loader />;
        }

        const videoIsUploading = post.mediaUploadType === 1 && !(post.mediaUploadStatus === 3);

        const isAuthor = post.author.id === this.props.currentUserId;
        // FIXME: blog doesn't have coauthors here
        const isCoauthor = post.blog && post.blog.coauthors && post.blog.coauthors.some((ca) => ca.id === this.props.currentUserId);

        return (
            <div className={classNames("blogPost", {
                ["appMode"]: appMode
            })
            }>
                <PageHeader
                    title={post.headline}
                    size="big"
                    standardActionMenuProps={this.props.isPreview ? undefined : {
                        canAddToFavourites: true,
                        canFollow: true,
                        canShare: true,
                        canDelete: this.props.isAdmin || this.props.isEditor || isAuthor || isCoauthor,
                        canEdit: this.props.isAdmin || this.props.isEditor || isAuthor || isCoauthor,
                        objectId: post.id,
                        onEditClick: () => {
                            this.props.history.push({
                                pathname: `/blogs/${blogSlug}/${postSlug}/edit`,
                            });
                        },
                        onDeleteClick: () => {
                            this.setState({
                                displayDeleteDialog: true,
                            });
                        },
                        isFollowing: post.isFollowing,
                        isFavourite: post.isFavourite,
                    }}
                />
                <div className="categoriesAndTime">
                    {this.listCategories()}
                    <div className="time">
                        <Visage2Icon icon="calendar" color="mid-grey" />
                        <Label color="mid-grey" size="body-2">
                            {moment(post.publishDate).format("LL")}
                        </Label>
                    </div>
                </div>
                {!!post.image && (
                    <div>
                        <ContentImageViewerAndSelector
                            imageUrl={post.image}
                            editMode={false}
                            cropAspect={SpintrTypes.ImageCropAspect.Wide}
                            autoHeight
                            blur
                        />
                    </div>
                )}
                {videoIsUploading && (
                    <div className="VideoUploadProgress" style={{ marginBottom: "1em" }}>
                        <ProgressIndicator
                            label={localize("BearbetningPagar")}
                            description={post.mediaUploadProgress > 0
                                ? `${post.mediaUploadProgress}% ${localize("Bearbetad").toLowerCase()}`
                                : `${localize("BearbetningForbereds")}`}
                            percentComplete={post.mediaUploadProgress > 0 && post.mediaUploadProgress / 100}
                        />
                    </div>
                )}
                {post.media && (
                    <div>
                        <video
                            className={classnames(["player azuremediaplayer amp-default-skin amp-big-play-centered"], {
                                "podcast": post.type === SpintrTypes.BlogPostType.Podcast
                            })} controls>
                            <source src={post.media} />
                        </video>
                    </div>
                )}
                <div
                    className={classnames(["postContent"], {
                        "noImage": !post.image,
                    })}
                >
                    <TinyFormattedContent content={post.text} />
                </div>
                {!!post.uberImages && post.uberImages.length > 0 && (
                    <div className="attached-images">
                        {
                            post.uberImages.map((img) => {
                                return (
                                    <Image
                                        alt=""
                                        onClick={() => {
                                            this.setState({ showImageCarousel: true, clickedImage: img.id });
                                        }}
                                        key={img.id}
                                        src={img.imageUrl}
                                        width="100px"
                                        height="100px"
                                        style={{ display: "inline-block" }}
                                    />
                                );
                            })
                        }
                    </div>
                )}
                {post.allowComments && (
                    <div>
                        <Separator className="content-separator" />
                        <SocialBlock
                            uberId={post.id}
                            onCommentIconClick={() => {
                                this.props.history.push({
                                    pathname: `/blogs/${blogSlug}/${postSlug}`,
                                });
                            }}
                        />
                    </div>
                )}
                {this.renderDeleteDialog()}
            </div>
        );
    };
}

const mapStateToProps = (state: IApplicationState) => ({
    appMode: state.ui.appMode,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    currentUserId: state.profile.active.id,
});

export default withRouter(connect(mapStateToProps)(BlogPost));
