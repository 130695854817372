import axios, { CancelTokenSource } from "axios";
import classnames from "classnames";
import {
    ColumnActionsMode, CommandBar,
    DefaultButton,
    DetailsRow,
    DetailsRowFields, IColumn, ICommandBarItemProps, IContextualMenuProps,
    IDetailsRowFieldsProps,
    IDetailsRowProps,
    IDragDropEvents, IGroup, IIconProps, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost
} from "@fluentui/react";
import React, { PureComponent } from "react";
import { localize } from "src/l10n";
import { ActionMenu, Label, Loader } from "src/ui";
import { Style } from "src/ui/helpers";
import { debounce } from "src/utils";
import SpintrPagination from "../Pagination/SpintrPagination";
import "./SpintrList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import SpintrLoader from "../Loader";
import SpintrSearch from "./SpintrSearch";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import Visage2FullScreenHeader from "../Visage2FullScreenView/Visage2FullScreenHeader";

declare type ListType =
    | "list"
    | "cards";

export interface ISpintrListProps {
    fetch: any;
    data?: any; // used with redux
    isLoading?: boolean; // used with redux
    take?: number;
    actionMenu?: Spintr.IActionMenuCategory[];
    columns: any[];
    buttons?: ICommandBarItemProps[];
    orderByColumn: string;
    history?: any;
    onRowClick?(item: any): void;
    disableSort?: boolean;
    disableCommandBar?: boolean;
    disablePagination?: boolean;
    disableSearch?: boolean;
    hideHeader?: boolean;
    farItems?: any[];
    selectionMode?: any;
    selection?: any;
    isDescending?: boolean;
    emptyContent?: any;
    dragDropEvents?: IDragDropEvents;
    onRenderRow?: any;
    optionItems?: IContextualMenuProps;
    ariaLabel?: string;
    initialSearchText?: string;
    onColumnHeaderClick?(ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void;
    listType?: ListType;
    renderCard?: any;
    renderShimmerCard?: any;
    infiniteScroll?: boolean;
    isGroupedList?: boolean;
    optionItemsTitle?: string;
    searchText?: string;
    renderCommandBarAsHeader?: boolean;
}

interface IState {
    isLoading: boolean;
    searchText: string;
    skip: number;
    take: number;
    orderByColumn: string;
    isAscending: boolean;
    data: any;
    totalCount: number;
    activePage: number;
    groups?: IGroup[];
}

function onRenderDetailsHeader(props, defaultRender?) {
    return defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip: (tooltipHostProps) => {
            return <TooltipHost {...tooltipHostProps} />;
        },
    });
}

class SpintrList extends PureComponent<ISpintrListProps, IState> {
    private cancelTokenSource: CancelTokenSource;
    private lastPromise: React.MutableRefObject<boolean | null> = React.createRef();

    constructor(props: ISpintrListProps) {
        super(props);

        this.state = this.getInitialState(props);
    }

    getInitialState = (props?: ISpintrListProps) => {
        return {
            activePage: 0,
            data: [],
            isAscending: props.isDescending ? false : true,
            isLoading: this.props.isLoading !== undefined ? this.props.isLoading : true,
            orderByColumn: props.orderByColumn,
            searchText: this.props.initialSearchText || "",
            skip: 0,
            take: props.take || 10,
            totalCount: 0,
        }
    }

    componentDidMount(): void {
        this.onInitialization();
    }

    onInitialization() {
        this.fetch();

        if (this.props.infiniteScroll) {
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    componentWillUnmount(): void {
        if (this.props.infiniteScroll) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        const hasMore = this.state.data?.length < this.state.totalCount;

        if (!hasMore ||
            this.state.isLoading) {
            return;
        }

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const diff = height - winScroll;

        if (diff < 100) {
            this.setState({
                activePage: this.state.activePage + 1,
            }, () => {
                this.fetch(true);
            });
        }
    }

    isScrollbarVisible = () => {
        const scrollbarVisible = document.body.clientHeight > (document.documentElement.clientHeight + 10);
        return scrollbarVisible;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.listType === "cards" && prevState.isLoading !== this.state.isLoading) {
            const hasNext = this.state.data?.length < this.state.totalCount

            if (!this.state.isLoading && hasNext && !this.isScrollbarVisible()) {
                // console.log("Fetching more becacuse, ", this.isScrollbarVisible())
                this.fetch(true);
            }

        }

        if (this.props.data) {
            this.setState({
                data: this.props.data.data,
                totalCount: this.props.data.totalCount,
                isLoading: this.props.isLoading != undefined ? this.props.isLoading : this.state.isLoading,
            });
        }

        if (prevProps.searchText !== this.props.searchText) {
            this.searchEvent(undefined, this.props.searchText);
        }
    }

    getSearchText = () => this.state.searchText;

    reFetch(): void {
        this.setState(
            {
                activePage: 0,
            },
            () => this.fetch()
        );
    }

    applyNewData(data):void {
        this.setState({
            data: data.items,
            totalCount: data.totalCount,
        });
    }

    reset() {
        this.setState(this.getInitialState(this.props), this.onInitialization.bind(this));
    }

    fetch = async (fetchMore = false): Promise<any> => {
        if (typeof this.cancelTokenSource != typeof undefined) {
            this.cancelTokenSource.cancel("Operation canceled due to new request.");
        }

        this.cancelTokenSource = axios.CancelToken.source();

        this.setState(
            {
                isLoading: true,
            },
            () => {
                const fetch = this.props.fetch(
                    this.state.activePage * this.state.take,
                    this.state.take,
                    this.state.orderByColumn,
                    this.state.isAscending,
                    this.state.searchText,
                    this.cancelTokenSource.token,
                    fetchMore
                );

                if (fetch && fetch.then) {
                    this.lastPromise.current = fetch;
                    fetch.then((response) => {
                        if (!response && this.props.infiniteScroll) {
                            return this.handleScroll();
                        }

                        if (fetch !== this.lastPromise.current) {
                            // Protect against out of order network requests
                            return;
                        }

                        const groups: IGroup[] | undefined = this.props.isGroupedList
                            ? response.data.map((row: any) => row as IGroup)
                            : undefined;

                        this.setState({
                            data: response.data ? response.data : this.state.data,
                            groups,
                            isLoading: this.props.isLoading !== undefined ? this.props.isLoading : false,
                            totalCount: response.totalCount ? response.totalCount : this.state.totalCount,
                        }, () => {
                            if (this.props.infiniteScroll) {
                                this.handleScroll();
                            }
                        });
                    });
                }
            }
        );
    };

    renderActionMenu(): JSX.Element {
        return <ActionMenu categories={this.props.actionMenu} />;
    }

    debouncedFetchSearch = debounce(() => this.fetch(), 500);

    searchEvent = (event: React.ChangeEvent, value: string): void => {
        this.setState(
            {
                skip: 0,
                activePage: 0,
                searchText: !!value ? value : "",
            },
            this.debouncedFetchSearch
        );
    };

    onPageChange = (page: number): void => {
        this.setState(
            {
                activePage: page,
            },
            () => this.fetch()
        );
    };

    rowFieldAs = (props) => {
        return (
            <span data-selection-disabled={true}>
                <DetailsRowFields {...props} />
            </span>
        );
    };

    cardLoader = () => {
        return <div className="SpintrList-cards">
            {
                Array.from(Array(this.props.take).keys()).map((item: any, index: number) => {
                    return this.props.renderShimmerCard(index);
                })
            }
        </div>
    }

    render(): JSX.Element {
        const allSelected =
            this.props.selection && this.props.selection._selectedItems?.length === this.props.selection._items?.length;

        const filterIcon: IIconProps = { iconName: 'Search' };

        return (
            <div
                aria-label={this.props.ariaLabel}
                className={classnames("spintr-list", {
                    isEmpty: !this.state.isLoading && this.state.data.length == 0,
                    disableSort: this.props.disableSort,
                    renderCommandBarAsHeader: this.props.renderCommandBarAsHeader
                })}
            >
                {this.props.renderCommandBarAsHeader && (
                    <Visage2FullScreenHeader
                        buttons={this.props.buttons}
                        optionItems={this.props.optionItems}
                        farItems={this.props.farItems}
                        actionMenu={this.props.actionMenu}
                        searchEvent={this.searchEvent}
                    />
                )}
                {!this.props.disableCommandBar && (
                    <CommandBar
                        className={
                            "spintr-list-command-bar" + (this.props.disableSearch ? "" : " CommandBar-GrowSearch")
                        }
                        items={[
                            ...(!this.props.disableSearch
                                ? [
                                    {
                                        key: "search",
                                        onRender: () => {
                                            return (
                                                <SpintrSearch
                                                    classes="searchBox"
                                                    value={this.state.searchText}
                                                    onChange={this.searchEvent} />
                                            )
                                        },
                                    },
                                ]
                                : []),
                            ...(this.props.optionItems
                                ? [
                                    {
                                        key: "alternativ",
                                        name: this.props.optionItemsTitle ?? localize("Alternativ"),
                                        subMenuProps: {
                                            ...this.props.optionItems,
                                            isBeakVisible: false
                                        },
                                    },
                                ]
                                : []),
                            ...(this.props.buttons ? [...this.props.buttons.map((b: any) => {
                                return {
                                    ...b,
                                    onRenderIcon: () => {
                                        if (!b.iconProps) {
                                            return null;
                                        }

                                        return (
                                            <Visage2Icon
                                                icon={b.iconProps.iconName}
                                                size={b.iconProps.iconName.toLowerCase() === "add" ? "big" :"medium"}
                                                color={b.className.indexOf("commandBarAddButton") > -1 ? "white" : "light-blue"} />
                                        )
                                    }
                                }
                            })] : []),
                        ]}
                        farItems={[
                            ...(this.props.farItems
                                ? this.props.farItems
                                : [
                                    ...(this.props.actionMenu
                                        ? [
                                            {
                                                key: "actionMenu",
                                                text: "",
                                                iconProps: { iconName: "More" },
                                                onRender: () => {
                                                    return this.renderActionMenu();
                                                },
                                            },
                                        ]
                                        : []),
                                ]),
                        ]}
                    />
                )}
                {
                    (!this.props.listType || this.props.listType === "list") && (
                        <ShimmeredDetailsList
                            onRenderDetailsHeader={onRenderDetailsHeader}
                            compact={true}
                            // checkboxVisibility={CheckboxVisibility.always}
                            onItemInvoked={this.props.onRowClick ? this.props.onRowClick : null}
                            isHeaderVisible={this.props.hideHeader ? false : true}
                            enableShimmer={this.state.isLoading && !this.props.infiniteScroll}
                            selectionMode={this.props.selectionMode ? this.props.selectionMode : SelectionMode.none}
                            selection={this.props.selection ? this.props.selection : undefined}
                            ariaLabelForSelectionColumn={localize("Markera")}
                            ariaLabelForSelectAllCheckbox={allSelected ? localize("AvmarkeraAlla") : localize("MarkeraAlla")}
                            checkButtonAriaLabel={localize("Markera")}
                            onColumnHeaderClick={
                                !this.props.disableSort
                                    ? (event, column) => {
                                        this.setState(
                                            (prevState) => ({
                                                orderByColumn: column.fieldName,
                                                isAscending:
                                                    prevState.orderByColumn == column.fieldName
                                                        ? !this.state.isAscending
                                                        : true,
                                            }),
                                            () => {
                                                this.props?.onColumnHeaderClick && this.props.onColumnHeaderClick(event, column)
                                                this.reFetch()
                                            }
                                        );
                                    }
                                    : undefined
                            }
                            onRenderRow={this.props.onRenderRow ? this.props.onRenderRow : this.renderRow.bind(this)}
                            columns={this.props.columns.map((column) => {
                                return {
                                    ...column,
                                    columnActionsMode: column.columnActionsMode !== undefined
                                        ? column.columnActionsMode
                                        : this.props.disableSort
                                            ? ColumnActionsMode.disabled
                                            : !column.name
                                                ? ColumnActionsMode.disabled
                                                : ColumnActionsMode.clickable,
                                    key: column.key ? column.key : this.props.columns.indexOf(column),
                                    minWidth: column.minWidth || 100,
                                    isSorted: this.state.orderByColumn == column.fieldName,
                                    isSortedDescending: !this.state.isAscending,
                                    headerClassName: "custom-header-styling",
                                    ...(column.onRender
                                        ? {
                                            onRender: (item) => column.onRender(item, this),
                                        }
                                        : {}),
                                };
                            })}
                            items={this.state.data}
                            groups={this.state.groups}
                            dragDropEvents={this.props.dragDropEvents}
                            selectionPreservedOnEmptyClick
                        />
                    )
                }
                {
                    this.props.listType === "cards" && (
                        <div>
                            {
                                this.state.isLoading && this.state.data.length === 0 ? this.cardLoader() :
                                    <InfiniteScroll
                                        className="SpintrList-cards"
                                        dataLength={this.state.data?.length || 0}
                                        next={() => this.fetch(true)}
                                        hasMore={this.state.data?.length < this.state.totalCount}
                                        loader={<this.cardLoader />}
                                    >
                                        {this.state.data.map((item: any, index: number) => {
                                            return this.props.renderCard(item, index);
                                        })}
                                    </InfiniteScroll>
                            }
                        </div>
                    )
                }
                {!this.state.isLoading && this.state.data.length == 0 && this.props.emptyContent && (
                    <>{this.props.emptyContent}</>
                )}
                {!this.state.isLoading && this.state.data.length == 0 && !this.props.emptyContent ? (
                    <div className="textNoTodo">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2">
                            {localize("IngaPoster")}
                        </Label>
                    </div>
                ) : null}
                {!this.props.disablePagination && !this.props.infiniteScroll && (
                    <SpintrPagination
                        pageSize={this.state.take}
                        totalCount={this.state.totalCount}
                        activePage={this.state.activePage}
                        onPageChange={this.onPageChange}
                    />
                )}
                {this.state.isLoading && this.props.infiniteScroll && (
                    <ShimmeredDetailsList
                        items={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                        enableShimmer={true}
                        selectionMode={SelectionMode.none}
                        isHeaderVisible={false}
                        columns={this.props.columns.map((column) => {
                            return {
                                ...column,
                                columnActionsMode: column.columnActionsMode
                                    ? column.columnActionsMode
                                    : this.props.disableSort
                                        ? ColumnActionsMode.disabled
                                        : !column.name
                                            ? ColumnActionsMode.disabled
                                            : ColumnActionsMode.clickable,
                                key: column.key ? column.key : this.props.columns.indexOf(column),
                                minWidth: column.minWidth || 100,
                                isSorted: this.state.orderByColumn == column.fieldName,
                                isSortedDescending: !this.state.isAscending,
                                headerClassName: "custom-header-styling",
                                ...(column.onRender
                                    ? {
                                        onRender: (item) => column.onRender(item, this),
                                    }
                                    : {}),
                            };
                        })}
                    />
                )}
                {!this.state.isLoading && this.props.infiniteScroll && this.state.data?.length < this.state.totalCount && (
                    <Stack className="load-more" horizontal tokens={{ childrenGap: 12 }} styles={{ root: { marginTop: 12 } }}>
                        <DefaultButton
                            text={`${localize("VisaFler")} (${this.state.totalCount - (this.state.data?.length)})`}
                            onClick={() => this.setState(
                                { activePage: this.state.activePage + 1, },
                                () => this.fetch(true),
                            )}
                        />
                    </Stack>
                )}
            </div>
        );
    }

    private renderRow(props: IDetailsRowProps) {
        return <DetailsRow rowFieldsAs={this.renderRowFields} {...props} />;
    }

    private renderRowFields(props: IDetailsRowFieldsProps) {
        return (
            <span data-selection-disabled={true}>
                <DetailsRowFields {...props} />
            </span>
        );
    }
}

export default SpintrList;
