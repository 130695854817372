import { Shimmer, ShimmerElementsGroup, ShimmerElementType, Stack } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { PrimaryButton } from "@fluentui/react/lib/components/Button/PrimaryButton/PrimaryButton";
import Modal from "@fluentui/react/lib/Modal";
import { TextField } from "@fluentui/react/lib/TextField";
import React, { Component } from "react";
import { connect } from "react-redux";
import localize from "src/l10n/localize";
import { setConfirmPopup } from "src/popups/actions";
import { Label, SpintrUser } from "src/ui";
import { isAnythingDirty } from "src/utils";
import FormControl from "../Forms/FormControl";
import SpintrLoader from "../Loader";
import ModalHeader from "../Modal/ModalHeader";
import "./Birthdays.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "../PopupHeader";

interface Props {
    disableButtons: boolean;
    items?: any;
    userId: number;
    hideTitle?: boolean;
    dispatch?: any;
}

interface State {
    isLoading: boolean;
    birthdays: any;
    showAll: boolean;
    showModal: boolean;
    user: any;
    isSending: boolean;
    hasSent: boolean;
    message: string;
}

class Birthdays extends Component<Props, State> {
    state = {
        isLoading: true,
        birthdays: [],
        showAll: false,
        showModal: false,
        user: undefined,
        isSending: false,
        hasSent: false,
        message: "",
    };

    fetch = () => {
        this.setState({ isLoading: true }, () => {
            api.get("/api/v1/users/getbirthdays").then((response) => {
                this.setState({ birthdays: response.data, isLoading: false });
            });
        });
    };

    componentDidMount = () => {
        if (!this.props.items) {
            this.fetch();
        } else {
            this.setState({ birthdays: this.props.items });
        }
    };

    congratulate = (user) => {
        console.log("Congrats", user.id);
        this.setState({ showModal: true, user, message: localize("Grattis") + " " + user.firstName + "!" });
    };

    sendMessage = () => {
        let item = { feedId: this.state.user.feedId, message: this.state.message };

        this.setState({ isSending: true }, () => {
            api.post("/api/users/birthdaygreeting", item).then((response) => {
                this.setState({ isSending: false, hasSent: true, showModal: false });
            });
        });
    };

    renderUser = (user) => {
        return (
            <div
                className="user"
                onClick={() => {
                    this.congratulate(user);
                }}
            >
                <SpintrUser size={32} imageUrl={user.image} name={user.name} id={user.id} />
            </div>
        );
    };

    onDismiss = () => {
        const close = () => {
            this.setState({ showModal: false });
        }

        if (isAnythingDirty()) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }
    }

    congratulationModal = () => {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.showModal}
                onDismiss={this.onDismiss.bind(this)}
            >
                <PopupHeader
                    text={localize("SagGrattisDuOckså")}
                    onClose={this.onDismiss.bind(this)} />
                <div>
                    {this.state.isSending ? (
                        <SpintrLoader />
                    ) : (
                        <>
                            <FormControl>
                                <TextField
                                    styles={{ field: { minHeight: 100 } }}
                                    className="congrats-input"
                                    autoAdjustHeight={false}
                                    value={this.state.message}
                                    multiline={true}
                                    size={400}
                                    height={100}
                                    onChange={(_e, message) => {
                                        this.setState((state) => ({
                                            message,
                                        }));
                                    }}
                                />
                            </FormControl>

                            {/* 
                        {this.state.user?.message && (
                            <Label as="div" size="small-2">
                                {localize("DuHarRedanSkrivitTill") + ` ${this.state.user.firstName}`}
                            </Label>
                        )} */}

                            <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                <DefaultButton
                                    onClick={this.onDismiss.bind(this)}
                                    text={localize("Avbryt")}
                                />
                                <PrimaryButton
                                    onClick={() => {
                                        this.sendMessage();
                                    }}
                                    text={localize("Skicka")}
                                />
                            </Stack>
                        </>
                    )}
                </div>
            </Modal>
        );
    };

    getCustomElements() {
        return (
            <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                    { type: ShimmerElementType.line, width: '50px', height: 10, verticalAlign: "center" },
                ]}
            />
        );
    }

    render() {
        // if (this.state.isLoading) return <SpintrLoader />;
        if (this.state.isLoading) return <div className="Birthdays" style={{ width: "100%", height: 60, flexGrow: 1, textAlign: "center", backgroundColor: "white", alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}><Shimmer customElementsGroup={this.getCustomElements()} /></div>

        return (
            <div className="Birthdays">
                {this.congratulationModal()}

                {!this.props.hideTitle && (
                    <div className="widget-header">
                        <Label as="h4"
                            size="body-3"
                            weight="medium"
                            uppercase>
                            {localize("DagensFodelsedagar")}
                        </Label>
                    </div>
                )}

                {this.state.birthdays && this.state.birthdays.length > 0 ? (
                    this.state.birthdays.length > 2 && this.state.showAll ? (
                        <>
                            {this.state.birthdays.map((b) => {
                                return this.renderUser(b);
                            })}
                            <div
                                className="show show-less"
                                onClick={() => {
                                    this.setState({ showAll: false });
                                }}
                            >
                                <Label className="linkFGColor">{localize("VisaMindre")}</Label>
                            </div>
                        </>
                    ) : (
                        <>
                            {this.state.birthdays.slice(0, 2).map((b) => {
                                return this.renderUser(b);
                            })}
                            {this.state.birthdays.length > 2 && (
                                <div
                                    className="show show-more"
                                    onClick={() => {
                                        this.setState({ showAll: true });
                                    }}
                                >
                                    <Label className="linkFGColor">{localize("VisaMer")}</Label>
                                </div>
                            )}
                        </>
                    )
                ) : (
                    <div className="textNoBirthday">
                        <Label as="p" size="body-2" color="dark-grey">
                            {localize("IngaFodelsedagarIdag")}
                        </Label>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
    };
};

export default connect(mapStateToProps)(Birthdays);
