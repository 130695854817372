import { Icon, Separator, TooltipHost } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AdminShortcutsEditView from "src/admin/views/AdminShortcutsEditView";
import { fetchUserFavorites } from "src/favorites";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IActiveUserProfile } from "src/profile/reducer";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { fetchTeams } from "src/teams/actions";
import { SpintrTypes } from "src/typings";
import { SpintrUser, UnstyledButton, setShouldReloadFavourites } from "src/ui";
import LoadMoreButton from "src/ui/components/Buttons/LoadMoreButton/LoadMoreButton";
import { Label } from "src/ui/components/Label/Label";
import SpintrLoader from "src/ui/components/Loader";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import { circleSmall } from "src/ui/helpers/style";
import { uniqueId } from "src/utils";
import { startDeliverTrack } from "src/utils/spintrStartDeliverfunctions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./ShortcutsPopupView.scss";
import { fetchShortcuts } from "./actions";

interface Props {
    currentUser?: IActiveUserProfile;
    instance: any;
    isEditor: boolean;
    isAdmin: boolean;
    dispatch?: any;
    isLoadingShortcuts?: boolean;
    shortcuts?: any[];
    favorites?: any[];
    isLoadingFavorites?: boolean;
    teams?: any[];
    isLoadingTeams?: boolean;
    extraWide?: boolean;
    setTitle?: any;
    setEditMode?: any;
    shouldReloadFavorites: boolean;
}

interface State {
    isEditing: boolean;
    editId: number;
    limitTeams: boolean;
    searchValue: string;
}

class ShortcutsPopupView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditing: false,
            editId: 0,
            limitTeams: true,
            searchValue: ""
        };
    }

    shortcutsRef = React.createRef<HTMLDivElement>();

    displayOfficeShortcuts = this.props.currentUser.office365Connected &&
        this.props.instance.get("office365Enabled") &&
        !this.props.instance.get("hideOffice365Shortcuts");

    displayTeams = this.props.currentUser.office365Connected &&
        this.props.instance.get('office365Enabled') &&
        this.props.instance.get('teamsTabEnabled') &&
        !this.props.currentUser.isGroupUser;

    formrefs = [];

    fetchShortcuts = () => {
        this.props.dispatch(fetchShortcuts());
    };

    fetchFavorites = () => {
        this.props.dispatch(fetchUserFavorites());
    }

    fetchTeams = () => {
        this.props.dispatch(fetchTeams());
    }

    componentDidMount = () => {
        this.fetchShortcuts();

        if (!this.props.favorites || this.props.favorites.length === 0) {
            this.fetchFavorites();
        }

        if (this.displayTeams && (!this.props.teams || this.props.teams.length === 0)) {
            this.fetchTeams();
        }
    };

    renderOfficeShortcuts = () => {
        let officeShortcuts = [
            {
                name: "OneNote",
                imageUrl: "/images/onenote.svg",
                url: "https://www.office.com/launch/onenote",
            },
            {
                name: "Word",
                imageUrl: "/images/word.svg",
                url: "https://www.office.com/launch/word",
            },
            {
                name: "Excel",
                imageUrl: "/images/excel.svg",
                url: "https://www.office.com/launch/excel",
            },
            {
                name: "PowerPoint",
                imageUrl: "/images/powerpoint.svg",
                url: "https://www.office.com/launch/powerpoint",
            },
        ];

        if (!this.displayTeams) {
            officeShortcuts.push({
                name: "Teams",
                imageUrl: "/images/teams.svg",
                url: "https://teams.microsoft.com/",
            });
        }

        if (!!this.state.searchValue) {
            officeShortcuts = officeShortcuts.filter(s => s.name && s.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        return (
            <div className="officeShortcutsPanel">
                <div className="shortcutsHeader office">
                    <div className="leftWrapper">
                        <Icon iconName="OfficeLogo" />
                        <Label size="h6" weight="medium">Office 365</Label>
                    </div>

                    <Icon className="lastIcon icon-animation animation-pulse" iconName="VisageWindowNew20Regular" title={localize("Oppna")} onClick={() => {
                        window.open("https://www.office.com/", "_blank");
                    }} />
                </div>
                <div className="officeShortcuts">
                    {officeShortcuts
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((s) => {
                            return (
                                <a className="officeShortcut" key={uniqueId()} href={s.url} target="_blank" title={s.name} tabIndex={0}>
                                    <div className="nameWrapper">
                                        <div className="officeShortcutImage">
                                            <img src={s.imageUrl} alt={s.name} />
                                        </div>
                                        <Label size="body-2">{s.name}</Label>
                                    </div>
                                    <div className="addButton">
                                        <Visage2Icon icon={"add-circle"} />
                                    </div>
                                </a>
                            );
                        })}
                </div>
            </div>
        );
    };

    edit = (id) => {
        // this.closeEditing();
        this.props.setEditMode(true);
        this.setState({ editId: id, isEditing: true }, () => {
            if (id == 0) {
                this.props.setTitle(`${localize("Skapa")} ${localize("Genvag").toLowerCase()}`)
            } else {
                this.props.setTitle(`${localize("Redigera")} ${localize("Genvag").toLowerCase()}`)
            }
        });
    };

    canEdit = (s) => {
        const canEdit = this.props.isEditor || this.props.isAdmin;
        return canEdit || (s.targets && s.targets.length === 1 && s.targets[0].id === this.props.currentUser.id);
    };

    isUrlSameSite(url) {
        var matches = url.match(/^(https?:)?\/\/([^\/]+)/);

        if (!matches) {
            return true;
        }

        return window.location.host === matches[2];
    }

    getTargetFromUrl(url) {
        return this.isUrlSameSite(url) ? "_self" : "_blank";
    }

    renderHighlights = () => {
        let highlightedShortcuts = this.props.shortcuts.filter((sc) => {
            return !!sc.highlight;
        });

        highlightedShortcuts = highlightedShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            highlightedShortcuts = highlightedShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!highlightedShortcuts || highlightedShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="highlightedShortcuts">
                <div>
                    {highlightedShortcuts
                        .map((s, idx) => {
                            return (
                                <div key={uniqueId()} className="highlightedShortcut">
                                    {s.formAction !== undefined ? (
                                        <div
                                            className="linkItem"
                                            onClick={() => {
                                                this.formrefs[s.id].submit();
                                            }}
                                        >
                                            <form
                                                ref={(ref) => {
                                                    this.formrefs[s.id] = ref;
                                                }}
                                                method="POST"
                                                id={`shortcutform${s.id}`}
                                                action={s.formAction}
                                                target="_blank"
                                            >
                                                <input
                                                    name="formAction"
                                                    value={String(s.formAction)}
                                                    type="hidden"
                                                />
                                                <input name="foo" value="bar" type="hidden" />
                                                <input
                                                    name={s.usernameName}
                                                    value={s.usernameValue}
                                                    type="hidden"
                                                />
                                                <input
                                                    name={s.passwordName}
                                                    value={s.passwordValue}
                                                    type="hidden"
                                                />
                                                <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                                <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                            </form>
                                            <SpintrUser
                                                size={50}
                                                name={s.title}
                                                squareImage={true}
                                                hideText={true}
                                                iconToDisplayInsteadOfInitials={"export-3"}
                                                imageUrl={s.imageUrl}
                                            />
                                            <Label size="body-2">{s.title}</Label>
                                        </div>
                                    ) : (
                                        <a
                                            className="linkItem popupShortcut"
                                            href={s.url}
                                            target={this.getTargetFromUrl(s.url)}
                                            title={s.title}
                                            tabIndex={0}
                                            onClick={() => {
                                                startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut);
                                            }}
                                        >
                                            <SpintrUser
                                                size={50}
                                                name={s.title}
                                                squareImage={true}
                                                hideText={true}
                                                iconToDisplayInsteadOfInitials={"export-3"}
                                                imageUrl={s.imageUrl}
                                            />
                                            <Label size="body-2">{s.title}</Label>
                                        </a>
                                    )}
                                    {this.editButton(s)}
                                </div>
                            );
                        })}
                </div>
            </div>
        )
    }

    renderShortcuts = () => {
        let nonHighlightShortcuts = this.props.shortcuts.filter((sc) => {
            return !sc.highlight;
        });

        nonHighlightShortcuts = nonHighlightShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            nonHighlightShortcuts = nonHighlightShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (this.props.shortcuts.length === 0) {
            return (
                <Label centerText size="body-2" color="mid-grey">{localize("EMPTY_SHORTCUTS")}</Label>
            )
        }

        if (!nonHighlightShortcuts || nonHighlightShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="normalShortcuts">
                <Label size="h6" weight="semi-bold" className="leftPanelLabel">{localize("Genvagar")}</Label>
                <div className="normalShortcuts-inner">
                    {nonHighlightShortcuts
                        .map((s, idx) => {
                            return (
                                <div key={`shortcut-${idx}`} className="nonHighlightedShortcut">
                                    {s.formAction !== undefined && !!s.usernameName ? (
                                        <div
                                            className="shortcutLink"
                                            onClick={() => {
                                                //this.form.dispatchEvent(new Event("submit", { cancelable: true }));
                                                this.formrefs[s.id].submit();
                                            }}
                                        >
                                            <form
                                                ref={(ref) => {
                                                    this.formrefs[s.id] = ref;
                                                }}
                                                method="POST"
                                                id={`shortcutform${s.id}`}
                                                action={s.formAction}
                                                target="_blank"
                                            >
                                                <input name="formAction" value={String(s.formAction)} type="hidden" />
                                                <input name="foo" value="bar" type="hidden" />
                                                <input name={s.usernameName} value={s.usernameValue} type="hidden" />
                                                <input name={s.passwordName} value={s.passwordValue} type="hidden" />
                                                <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                                <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                                <Label size="body-2">{s.title}</Label>
                                            </form>
                                        </div>
                                    ) : (
                                        <a
                                            className="shortcutLink"
                                            href={s.url}
                                            target={this.getTargetFromUrl(s.url)}
                                            title={s.title}
                                            tabIndex={0}
                                        >
                                            <Label size="body-2">{s.title}</Label>
                                        </a>
                                    )}
                                    {this.editButton(s)}
                                </div>
                            );
                        })}
                </div>
            </div>
        )
    }
    renderNonHighlightShortcuts = () => {
        let nonHighlightShortcuts = this.props.shortcuts.filter((sc) => {
            return !sc.highlight;
        });

        nonHighlightShortcuts = nonHighlightShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            nonHighlightShortcuts = nonHighlightShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!nonHighlightShortcuts || nonHighlightShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="nonHighlightedShortcuts">
                <div>
                    {nonHighlightShortcuts
                        .map((s) => {
                            return (
                                <div key={uniqueId()} className="nonHighlightedShortcut">
                                    {s.formAction !== undefined && !!s.usernameName ? (
                                        <div
                                            className="shortcutLink"
                                            onClick={() => {
                                                //this.form.dispatchEvent(new Event("submit", { cancelable: true }));
                                                this.formrefs[s.id].submit();
                                            }}
                                        >
                                            <form
                                                ref={(ref) => {
                                                    this.formrefs[s.id] = ref;
                                                }}
                                                method="POST"
                                                id={`shortcutform${s.id}`}
                                                action={s.formAction}
                                                target="_blank"
                                            >
                                                <input name="formAction" value={String(s.formAction)} type="hidden" />
                                                <input name="foo" value="bar" type="hidden" />
                                                <input name={s.usernameName} value={s.usernameValue} type="hidden" />
                                                <input name={s.passwordName} value={s.passwordValue} type="hidden" />
                                                <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                                <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                                <Label size="body-2">{s.title}</Label>
                                            </form>
                                        </div>
                                    ) : (
                                        <a
                                            className="shortcutLink"
                                            href={s.url}
                                            target={this.getTargetFromUrl(s.url)}
                                            title={s.title}
                                            tabIndex={0}
                                        >
                                            <Label size="body-2">{s.title}</Label>
                                        </a>
                                    )}
                                    <Visage2Icon icon="arrow-right-3" />
                                    {this.editButton(s)}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    closeEditing = () => {
        this.setState({ isEditing: false }, () => {
            this.fetchShortcuts();
            this.props.setTitle(localize("Genvagar"))
            this.props.setEditMode(false);
        });
    };

    form: HTMLFormElement;

    renderTeams() {
        let { isLoadingTeams, teams } = this.props;
        let teamsToDisplay: any[] = [];
        if (!!this.state.searchValue) {
            teamsToDisplay = [...teams].filter(s => s.name && s.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        } else {
            for (let i = 0; i < teams.length; i++) {
                if (!this.state.limitTeams) {
                    teamsToDisplay.push(teams[i]);
                } else if (this.state.limitTeams && i < 5) {
                    teamsToDisplay.push(teams[i]);
                }
            }
        }

        return (
            <div className="teamsPanel spacingTop">
                <Separator className="separator" />
                <div className="shortcutsHeader teams">
                    <div className="leftWrapper">
                        <Icon iconName="TeamsLogo" />
                        <Label size="h6" weight="medium">Teams</Label>
                    </div>
                    <Icon className="lastIcon icon-animation animation-pulse" iconName="VisageWindowNew20Regular" title={localize("Oppna")} onClick={() => {
                        window.open("https://teams.microsoft.com", "_blank");
                    }} />
                </div>
                {
                    isLoadingTeams && (
                        <SpintrLoader />
                    )
                }
                {
                    !isLoadingTeams && (
                        <div className="teams-items">
                            {teamsToDisplay.map((t: any, index: number) => {
                                return (
                                    <a key={index} className="teams-item" href={t.webUrl} target="_blank">
                                        <SpintrUser
                                            name={t.name}
                                            size={circleSmall}
                                            subText={t.description}
                                            imageUrl={'data:image/jpeg;base64,' + t.photoBase64}
                                            unread={t.unreadCount}
                                        />
                                    </a>
                                )
                            })}
                            {!this.state.searchValue && this.state.limitTeams && teams.length > 5 && (
                                <LoadMoreButton
                                    text={localize("VisaAlla") + " teams".toLowerCase()}
                                    onClick={() => {
                                        this.setState({
                                            limitTeams: false
                                        })
                                    }}
                                />
                            )}
                        </div>
                    )
                }
            </div>
        )
    }

    renderLinks() {
        let links = [{
            name: localize("Intranatskarta"),
            url: "/sitemap",
            icon: "SplitObject"
        }, {
            name: localize("appTags"),
            url: "/tags",
            icon: "Tag"
        }];

        if (!!this.state.searchValue) {
            links = links.filter(s => s.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!links || links.length === 0) {
            return null;
        }

        return (
            <div className="links">
                {
                    links.map((link: any, index: number) => {
                        return (
                            <Link key={index} className="panel shortcuts-app-link" to={link.url}
                                onClick={() => { startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut); }}
                            >
                                <Icon iconName={link.icon} />
                                <Label size="body-2" className="link">{link.name}</Label>
                            </Link>
                        )
                    })
                }
            </div>
        )
    }

    deleteFavorite = async (favoriteId) => {
        await api.delete(`/api/v1/favorites/${favoriteId}`);
        // this.setState((prevState) => ({
        //     event: {
        //         ...prevState.event,
        //         isFavourite: false,
        //     },
        // }));

        this.props.dispatch(setShouldReloadFavourites(true));
        this.fetchFavorites();
    }

    deleteFavoriteButton = (favorite) => {
        return (
            <TooltipHost content={localize("TaBortFranFavoriter")}>
                <UnstyledButton
                    className="deleteFavorite"
                    title={localize("TaBortFranFavoriter")}
                    onClick={() => {
                        this.props.dispatch(setConfirmPopup({
                            isOpen: true,
                            title: localize("TaBortFranFavoriter"),
                            message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                            onConfirm: () => {
                                this.deleteFavorite(favorite.id);
                            }
                        }));
                    }}
                >
                    <Visage2Icon icon="trash" size="small" />
                </UnstyledButton>
            </TooltipHost>
        )
    }

    renderFavorites() {
        let favorites = [...this.props.favorites];

        if (!!this.state.searchValue) {
            favorites = favorites.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!favorites || favorites.length === 0) {
            return null;
        }

        return (
            <>
                <div className="favorites">
                    <Label weight="semi-bold" size="h6" className="leftPanelLabel">{localize("Favoriter")}</Label>
                    {favorites.map((item: any, index: number) => {
                        return (
                            <div key={`favorite-${index}`} className="favorite">
                                <Link
                                    onClick={() => { startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut); }}
                                    key={index}
                                    className={"link"}
                                    to={item.url}
                                >
                                    <Label size="body-2">{item.title}</Label>
                                </Link>

                                {this.deleteFavoriteButton(item)}
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }

    editMode = () => {
        let imageUrl = "";

        if (this.state.editId && this.props.shortcuts.includes(this.state.editId)) {
            imageUrl = this.props.shortcuts.find((s) => {
                return s.id === this.state.editId;
            }).imageUrl;
        }

        return (
            <div>
                <div className="shortcutsEditMode">
                    <AdminShortcutsEditView
                        imageUrl={imageUrl}
                        dropdownEditing={true}
                        pageId={this.state.editId}
                        closeEditingCallback={() => {
                            this.closeEditing();
                        }}
                    />
                </div>
            </div>
        )

    }

    rightPanel = () => {
        return (<div>
            <div className="rightPanel panel">
                {this.displayOfficeShortcuts && this.renderOfficeShortcuts()}
                {this.displayTeams && this.renderTeams()}
            </div>
            <div className="rightSpacer"></div>
        </div>
        )
    }

    leftPanel = () => {
        return (
            <div className="leftPanel panel">
                <div className="top">
                    <SpintrSearch
                        placeholder={`${localize("Sok")} ${localize("Genvag").toLowerCase()}`}
                        value={this.state.searchValue}
                        onChange={(_ev, query) => {
                            this.setState({
                                searchValue: !!query ? query : "",
                            });
                        }} />
                    <UnstyledButton
                        className="addButton"
                        onClick={() => {
                            this.edit(0)
                        }}>
                        <Visage2Icon className="icon-animation animation-rotate" icon="add-circle" />
                    </UnstyledButton>
                </div>
                {this.renderPrioritizedShortcuts()}
                {this.renderShortcuts()}
                {this.renderFavorites()}
            </div>
        )
    }

    editButton = (shortcut) => {
        if (!this.canEdit(shortcut)) {
            return null
        }

        return (
            <TooltipHost content={localize("RedigeraGenvagen")}>
                <UnstyledButton
                    className="edit"
                    title={localize("Redigera")}
                    onClick={() => {
                        this.edit(shortcut.id);
                    }}
                >
                    <Visage2Icon icon="edit" size="small" />
                </UnstyledButton>
            </TooltipHost>
        )
    }

    renderPrioritizedShortcuts = () => {
        let highlightedShortcuts = this.props.shortcuts.filter((sc) => {
            return !!sc.highlight;
        });

        highlightedShortcuts = highlightedShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            highlightedShortcuts = highlightedShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!highlightedShortcuts || highlightedShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="prioritized">
                <Label weight="semi-bold" size="h6" className="leftPanelLabel">{localize("PrioriteradeGenvagar")}</Label>
                <div className="prioritizedInner">
                    {highlightedShortcuts
                        .map((s, idx) => {
                            return (
                                s.formAction !== undefined ? (
                                    <div
                                        key={`linkItem-${idx}`}
                                        className="linkItem"
                                        onClick={() => {
                                            this.formrefs[s.id].submit();
                                        }}
                                    >
                                        <form
                                            ref={(ref) => {
                                                this.formrefs[s.id] = ref;
                                            }}
                                            method="POST"
                                            id={`shortcutform${s.id}`}
                                            action={s.formAction}
                                            target="_blank"
                                        >
                                            <input
                                                name="formAction"
                                                value={String(s.formAction)}
                                                type="hidden"
                                            />
                                            <input name="foo" value="bar" type="hidden" />
                                            <input
                                                name={s.usernameName}
                                                value={s.usernameValue}
                                                type="hidden"
                                            />
                                            <input
                                                name={s.passwordName}
                                                value={s.passwordValue}
                                                type="hidden"
                                            />
                                            <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                            <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                        </form>

                                        <SpintrUser size={32} name={s.title} imageUrl={s.imageUrl} subText={s.description} />

                                        {this.editButton(s)}
                                    </div>
                                ) : (
                                    <a
                                        key={`linkItem-${idx}`}
                                        className="prioritizedShortcut"
                                        href={s.url}
                                        target={this.getTargetFromUrl(s.url)}
                                        title={s.title}
                                        tabIndex={0}
                                        onClick={() => {
                                            startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut);
                                        }}
                                    >
                                        <SpintrUser size={32} name={s.title} imageUrl={s.imageUrl} subText={s.description} />

                                        {this.editButton(s)}
                                    </a>
                                )
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    render() {
        let imageUrl = "";

        if (this.state.editId && this.props.shortcuts.includes(this.state.editId)) {
            imageUrl = this.props.shortcuts.find((s) => {
                return s.id === this.state.editId;
            }).imageUrl;
        }

        if (this.props.isLoadingShortcuts || this.props.isLoadingFavorites) {
            return (
                <div className="SpintrLoader-wrapper">
                    <SpintrLoader />
                </div>
            );
        }

        if (this.state.isEditing) {
            return this.editMode()
        }

        else {
            return (
                <div className={"shortcutsLayer" + (this.props.extraWide ? " extra-wide" : "")}>
                    <div className="leftSide">
                        {this.leftPanel()}
                        {this.renderLinks()}
                    </div>
                    {this.props.extraWide &&
                        this.rightPanel()
                    }
                </div>
            )
        }
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    shortcuts: state.shortcuts.items,
    isLoadingShortcuts: state.shortcuts.isLoading,
    favorites: state.favorites.items,
    isLoadingFavorites: state.favorites.isLoading,
    teams: state.teams.items,
    isLoadingTeams: state.teams.isLoading,
    shouldReloadFavorites: state.ui.shouldReloadFavourites
});

export default connect(mapStateToProps)(ShortcutsPopupView);
