import {
    DefaultButton,
    Modal,
    PrimaryButton,
    Stack,
    TooltipHost
} from "@fluentui/react";
import axios from "axios";
import moment from "moment";
import React, { Component, RefObject, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { updateRemotePost } from "src/social-feed/actions";
import api from "src/spintr/SpintrApi";
import InteractiveTextInput from "src/spintr/components/InteractiveTextInput";
import { ActionMenu, Label, Loader, SpintrUser, UnstyledButton } from "src/ui";
import { getHexFromSpintrColor } from "src/ui/helpers/style";
import { formatInteractiveText, isAnythingDirty } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./PhotoBox.scss";

interface IProps {
    imageUrl?: string;
    id?: number;
    onClose: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    currentUserId?: number;
    history?: any;
    isSmallViewMode: boolean;
    dispatch?: any;
    samePostOnly?: boolean;
    post?: any;
}

interface IState {
    imageUrl?: string,
    imageMaxHeight: number,
    imageMaxWidth: number,
    isLoading: boolean,
    displaySocial: boolean,
    imageId?: number,
    imageData?: any,
    preFetchedImages: any,
    isEditingDescription?: boolean,
    isLoadingDescriptionChange?: boolean
}

class PhotoBox extends Component<IProps, IState> {
    protected textInputRef: RefObject<InteractiveTextInput>;

    constructor(props) {
        super(props);

        const displaySocial = !!props.id;

        this.state = {
            imageMaxHeight: Math.floor(window.innerHeight * 0.9),
            imageMaxWidth: Math.floor(window.innerWidth * 0.9) - (displaySocial && !props.isSmallViewMode ? 300 : 0) - 60,
            isLoading: false,
            displaySocial,
            imageId: props.id,
            preFetchedImages: []
        };

        this.textInputRef = createRef();
    }

    handleKeyDown = (event) => {
        switch (event.keyCode) {
            case 27: // ESCAPE
                event.preventDefault();
                this.props.onClose();

                break;
            case 37: // LEFT
                event.preventDefault();
                this.goToPreviousPhoto();

                break;
            case 39: // RIGHT
                event.preventDefault();
                this.goToNextPhoto();

                break;
            default:
                break;
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    componentDidMount() {
        if (this.props.id) {
            this.loadImageById(this.props.id);
        } else if (this.props.imageUrl) {
            this.loadImageByUrl();
        }

        document.addEventListener("keydown", this.handleKeyDown);
    }

    setActiveImage(imageData) {
        this.setState({
            isLoading: false,
            imageUrl: imageData.imageUrl,
            imageId: imageData.id,
            imageData: imageData,
            isEditingDescription: false
        }, () => {
            let surroundingImagesRequests = [];

            if (imageData.previousImageIdInGallery &&
                !this.state.preFetchedImages.find(i => i.id === imageData.previousImageIdInGallery)) {
                surroundingImagesRequests.push(api.get("/api/v1/images/" + imageData.previousImageIdInGallery + "?samePostOnly=" + (this.props.samePostOnly ? "true" : "false")));
            }

            if (imageData.nextImageIdInGallery &&
                !this.state.preFetchedImages.find(i => i.id === imageData.nextImageIdInGallery)) {
                surroundingImagesRequests.push(api.get("/api/v1/images/" + imageData.nextImageIdInGallery + "?samePostOnly=" + (this.props.samePostOnly ? "true" : "false")));
            }

            if (surroundingImagesRequests.length === 0) {
                return;
            }

            axios.all(surroundingImagesRequests).then(responses => {
                let fetchedImages = [];

                for (let response of responses) {
                    fetchedImages.push(response.data);
                }

                this.setState({
                    preFetchedImages: [
                        ...this.state.preFetchedImages,
                        ...fetchedImages
                    ]
                });
            });
        });
    }

    loadImageById(id) {
        this.setState({
            isLoading: true
        }, () => {
            let fetchedImage = this.state.preFetchedImages.find(i => i.id === id);

            if (!!fetchedImage) {
                this.setActiveImage(fetchedImage);
            } else {
                api.get("/api/v1/images/" + id + "?samePostOnly=" + (this.props.samePostOnly ? "true" : "false")).then(response => {
                    this.setActiveImage(response.data);
                });
            }
        });
    }

    loadImageByUrl() {
        let imageUrl = this.props.imageUrl;

        // Change into original image if thumbnail
        let pattern = new RegExp('.thumbnails/(.+)_[0-9]+_[0-9]+(.[a-zA-Z]+)$');

        imageUrl = imageUrl.replace(pattern, '$1$2');

        this.setState({
            imageUrl
        });
    }

    onClose = () => {
        const close = () => {
            this.props.onClose();
        }

        if (isAnythingDirty()) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }
    }

    renderCloseButton() {
        return (
            <UnstyledButton className="PhotoBox-close-button" onClick={this.onClose.bind(this)}>
                <TooltipHost content={localize("Stang")}>
                    <Visage2Icon icon="close-circle" />
                </TooltipHost>
            </UnstyledButton>
        )
    }

    goToPreviousPhoto() {
        if (this.state.isLoading ||
            !this.state.imageData ||
            !this.state.imageData.previousImageIdInGallery) {
            return;
        }

        this.loadImageById(this.state.imageData.previousImageIdInGallery);
    }

    goToNextPhoto() {
        if (this.state.isLoading ||
            !this.state.imageData ||
            !this.state.imageData.nextImageIdInGallery) {
            return;
        }

        this.loadImageById(this.state.imageData.nextImageIdInGallery);
    }

    rotate() {
        this.setState({
            isLoading: true
        }, () => {
            api.put("/api/v1/images/rotate/" + this.state.imageId + "?clockwise=true").then(response => {
                this.setState({
                    imageUrl: response.data.imageUrl,
                    imageData: {
                        ...this.state.imageData,
                        imageUrl: response.data.imageUrl
                    },
                    isLoading: false
                });
            });
        });
    }

    canUserEditPhoto() {
        if (!this.state.imageData) {
            return false;
        }

        const isImage = (!this.state.imageData.videoThumbnailUrl || this.state.imageData.videoThumbnailUrl.length === 0) &&
            (!this.state.imageData.videoUrl || this.state.imageData.videoUrl.length === 0);

        return !this.state.imageData.author.isInstagram &&
            isImage &&
            (this.state.imageData.author.id === 0 || this.props.isAdmin || this.props.isEditor);
    }

    renderImageHoverActions() {
        const canUserEditPhoto = this.canUserEditPhoto();

        return (
            <div className="PhotoBox-hover-actions">
                {
                    this.state.imageData &&
                    this.state.imageData.previousImageIdInGallery && (
                        <div className="PhotoBox-arrow-left no-user-select">
                            <span className="helper"></span>
                            <UnstyledButton onClick={this.goToPreviousPhoto.bind(this)}>
                                <TooltipHost content={localize("Foregaende")}>
                                    <Visage2Icon icon="arrow-left-2" color="white" />
                                </TooltipHost>
                            </UnstyledButton>
                        </div>
                    )
                }
                {
                    this.state.imageData &&
                    this.state.imageData.nextImageIdInGallery && (
                        <div className="PhotoBox-arrow-right no-user-select">
                            <span className="helper"></span>
                            <UnstyledButton onClick={this.goToNextPhoto.bind(this)}>
                                <TooltipHost content={localize("Nasta")}>
                                    <Visage2Icon icon="arrow-right-3" color="white" />
                                </TooltipHost>
                            </UnstyledButton>
                        </div>
                    )
                }
                {
                    this.state.imageData && (
                        <div className="PhotoBox-footer">
                            <div className="PhotoBox-footer-left">
                                <UnstyledButton onClick={() => {
                                    this.props.history.push({
                                        pathname: `/goto/${this.state.imageData.gallery.id}`,
                                    });
                                }}>
                                    <Label
                                        as="span"
                                        size="small-1"
                                        color="white">
                                        {
                                            this.state.imageData.gallery.name
                                        }
                                    </Label>
                                </UnstyledButton>
                                <Label
                                    as="span"
                                    className="PhotoBox-footer-index"
                                    size="small-1"
                                    color="white">
                                    {
                                        this.state.imageData.gallery.currentIndex +
                                        " " +
                                        localize("AvTotalt") +
                                        " " +
                                        this.state.imageData.gallery.imageCount
                                    }
                                </Label>
                            </div>
                            {
                                canUserEditPhoto && !this.state.isLoading && (
                                    <div className="PhotoBox-footer-right no-user-select">
                                        <UnstyledButton onClick={this.rotate.bind(this)}>
                                            <TooltipHost content={localize("RoteraMedsols")}>
                                                <Visage2Icon icon="rotate-right" color="white" />
                                            </TooltipHost>
                                        </UnstyledButton>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        )
    }

    renderMenu() {
        let items = [];

        items.push({
            text: localize("Stang"),
            onClick: () => {
                this.onClose();
            },
        });

        items.push({
            text: localize("LaddaHem"),
            onClick: () => {
                window.location.href = `/api/v1/servefile/${this.state.imageId}`;
            },
        });

        items.push({
            text: localize("GorTillProfilbild"),
            onClick: () => {
                api.put("/api/profiles/image", {
                    imageUrl: this.state.imageUrl,
                    uberId: this.state.imageId
                }).then(response => {
                    // TODO: ALERT
                });
            },
        });

        items.push({
            text: localize("AnvandSomOmslagsbild"),
            onClick: () => {
                api.put("/api/profiles/coverart", {
                    imageUrl: this.state.imageUrl,
                    uberId: this.state.imageId
                }).then(response => {
                    // TODO: ALERT
                });
            },
        });

        if (this.canUserEditPhoto()) {
            items.push({
                text: localize("AndraBeskrivning"),
                onClick: () => {
                    this.setState({
                        isEditingDescription: true
                    }, () => {
                        this.textInputRef.current.giveFocus();
                    });
                },
            });

            items.push({
                text: localize("TaBort"),
                onClick: () => {
                    this.setState({
                        isLoading: true
                    }, () => {
                        api.delete("/api/images/" + this.state.imageId).then(response => {
                            this.props.onClose();
                        });
                    });
                },
            });
        }

        return (
            <div className="PhotoBox-menu-wrapper">
                <ActionMenu
                    categories={[
                        {
                            items
                        },
                    ]}
                />
            </div>
        )
    }

    saveDescription() {
        const content = this.textInputRef.current.getContent();

        this.setState({
            isLoadingDescriptionChange: true
        }, () => {
            api.put("/api/images/" + this.state.imageId + "/changetext", {
                description: content
            }).then(response => {
                const formattedData = {
                    ...response.data,
                    Id: response.data.Id ? response.data.Id : response.data.id
                }

                this.props.dispatch(updateRemotePost(formattedData));

                this.setState({
                    imageData: {
                        ...this.state.imageData,
                        text: response.data.Text
                    },
                    isEditingDescription: false,
                    isLoadingDescriptionChange: false
                });
            });
        });
    }

    render() {
        let classNames = ["PhotoBox"];

        if (this.state.displaySocial) {
            classNames.push("PhotoBox-displaySocial")
        }

        if (this.props.isSmallViewMode) {
            classNames.push("isSmallViewMode");
        }

        const text = !!this.props.post ?
            this.props.post.Text :
            !!this.state.imageData ?
                this.state.imageData.text :
                ""

        return (
            <Modal
                isOpen={true}
                isClickableOutsideFocusTrap
                onDismiss={this.onClose.bind(this)}
                containerClassName={classNames.join(" ")}
            >
                <div className="PhotoBox-inner">
                    <div className="PhotoBox-photo-wrapper" style={{
                        //backgroundImage: "url(" + this.state.src + ")"
                    }}>
                        {
                            this.state.isLoading && (
                                <Loader />
                            )
                        }
                        {
                            <span className="helper"></span>
                        }
                        {
                            !this.state.isLoading && (
                                <img alt="" src={this.state.imageUrl} style={{
                                    maxHeight: this.state.imageMaxHeight,
                                    maxWidth: this.state.imageMaxWidth
                                }} />
                            )
                        }
                        {
                            this.renderImageHoverActions()
                        }
                        {
                            !this.state.displaySocial && (
                                this.renderCloseButton()
                            )
                        }
                    </div>
                    {
                        this.state.displaySocial && (
                            <div className="PhotoBox-social">
                                {
                                    this.state.imageData && (
                                        <div>
                                            <div className="PhotoBox-user">
                                                <SpintrUser
                                                    key={this.state.imageData.id}
                                                    id={this.state.imageData.author.id}
                                                    state={this.state.imageData.author.state}
                                                    imageUrl={this.state.imageData.author.imageUrl}
                                                    name={this.state.imageData.author.name}
                                                    textColor={getHexFromSpintrColor("dark-grey")}
                                                    secondaryTextColor={getHexFromSpintrColor("mid-grey")}
                                                    subText={moment(this.state.imageData.date).fromNow()} />
                                            </div>
                                            {
                                                !this.state.isEditingDescription &&
                                                text &&
                                                text.length > 0 && (
                                                    <Label
                                                        className="PhotoBox-social-text general-row-break"
                                                        as="p"
                                                        color="dark-grey"
                                                        size="body-2">
                                                        {formatInteractiveText(text)}
                                                    </Label>
                                                )
                                            }
                                            {
                                                this.state.isEditingDescription && (
                                                    <div>
                                                        {
                                                            this.state.isLoadingDescriptionChange && (
                                                                <Loader />
                                                            )
                                                        }
                                                        {
                                                            !this.state.isLoadingDescriptionChange && (
                                                                <div>
                                                                    <InteractiveTextInput
                                                                        initialContent={text}
                                                                        onEnter={this.saveDescription.bind(this)}
                                                                        placeholder={localize("LaggTillEnBeskrivning")}
                                                                        ref={this.textInputRef}
                                                                    />
                                                                    <div className="PhotoBox-EditButtons">
                                                                        <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                                                            <DefaultButton onClick={() => {
                                                                                this.setState({
                                                                                    isEditingDescription: false
                                                                                });
                                                                            }} text={localize("Avbryt")} />
                                                                            <PrimaryButton onClick={this.saveDescription.bind(this)} text={localize("Spara")} />
                                                                        </Stack>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {
                                    this.renderMenu()
                                }
                                {
                                    this.renderCloseButton()
                                }
                                <SocialBlock uberId={!!this.props.post ? this.props.post.Id : this.state.imageId} />
                            </div>
                        )
                    }
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(PhotoBox));
