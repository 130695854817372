import {
    QUERY_FILES_BY_FOLDER,
    SET_SEARCH_TEXT,
    NEW_FOLDER,
    SAVE_NEW_FOLDER,
    ABORT_NEW_FOLDER,
    SAVE_NEW_FILE,
    SELECT_ITEM,
    DELETE_FOLDER,
    DELETE_FILE,
    UPLOADED_FILE,
    SET_RENAMING_ITEM,
    UPDATE_FOLDER,
    UPDATE_FILE,
    FAVOURITE_ITEM,
    UNFAVOURITE_ITEM,
    SET_SELECTED_ITEMS,
    FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE,
} from "./file-action-types";
import api from "src/spintr/SpintrApi";

const baseUrl = "/api/files";

const queryFilesByFolder = (folderId, params) => ({
    meta: {
        ...(params || {}),
        folderId,
    },
    payload: api.get(baseUrl, {
        params: {
            folderId: folderId,
            ...params,
        },
    }),
    type: QUERY_FILES_BY_FOLDER,
});

const setSearchText = (text: string) => ({
    payload: text,
    type: SET_SEARCH_TEXT,
});

const newFolder = () => ({
    type: NEW_FOLDER,
});

const abortNewFolder = () => ({
    type: ABORT_NEW_FOLDER,
});

const saveNewFolder = (params) => ({
    meta: params || {},
    payload: api.post("/api/folders", params),
    type: SAVE_NEW_FOLDER,
});

const saveNewFile = (params) => ({
    meta: params || {},
    payload: api.post("/api/files/createempty", params),
    type: SAVE_NEW_FILE,
});

const selectItem = (item: Spintr.IFileBrowserFile) => ({
    meta: item,
    type: SELECT_ITEM,
});

const setSelectedItems = (items: Spintr.IFileBrowserFile[]) => ({
    meta: items,
    type: SET_SELECTED_ITEMS,
});

const deleteFolder = (folderId, params?) => ({
    meta: folderId,
    payload: api.delete(`/api/folders/${folderId}`),
    type: DELETE_FOLDER,
});

const deleteOfficeFolder = (folderId, source) => ({
    meta: folderId,
    payload: api.delete(`/api/files/${folderId}`, {
        params: {
            source: source,
        },
    }),
    type: DELETE_FOLDER,
});

const deleteFile = (id, source) => ({
    meta: id,
    payload: api.delete(`/api/files/${id}`, {
        params: {
            source: source,
        },
    }),
    type: DELETE_FILE,
});

const uploadedFile = (data) => ({
    meta: data,
    type: UPLOADED_FILE,
});

const setRenamingItem = (id) => ({
    meta: id,
    type: SET_RENAMING_ITEM,
});

const updateFolder = (params, currentFolderId) => ({
    meta: currentFolderId,
    payload: api.put(`/api/folders/${params.id}`, params),
    type: UPDATE_FOLDER,
});

const updateFile = (params, currentFolderId) => ({
    meta: currentFolderId,
    payload: api.put(`/api/files/${params.id}`, params),
    type: UPDATE_FILE,
});

const favouriteItem = (id) => ({
    meta: id,
    payload: api.post("/api/favourites", { id }),
    type: FAVOURITE_ITEM,
});

const unfavouriteItem = (id) => ({
    meta: id,
    payload: api.delete(`/api/favourites/${id}`),
    type: UNFAVOURITE_ITEM,
});

const fetchSharePointAttachedFilesForPage = (pageId) => ({
    meta: pageId,
    payload: api.get(`/api/v1/files/office365/attached/${pageId}`),
    type: FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE,
});

export {
    queryFilesByFolder,
    setSearchText,
    newFolder,
    saveNewFolder,
    abortNewFolder,
    deleteFolder,
    deleteOfficeFolder,
    deleteFile,
    saveNewFile,
    selectItem,
    setSelectedItems,
    uploadedFile,
    setRenamingItem,
    updateFolder,
    updateFile,
    favouriteItem,
    unfavouriteItem,
    fetchSharePointAttachedFilesForPage,
};
