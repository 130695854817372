import { Icon } from "@fluentui/react";
import { Persona, PersonaPresence, PersonaSize } from "@fluentui/react/lib/Persona";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import ContentWithUnreadIndicator from "./UnreadIndicator/ContentWithUnreadIndicator";
import UserHovercard from "./UserHovercard/UserHovercard";
import { circleLarge, circleMedium, circleSmall } from 'src/ui/helpers/style';
import "./SpintrUser.scss";
import { Style } from "../helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import classNames from "classnames";

interface IProps {
    id?: number; // Sending id displays hovercard
    name: string;
    imageUrl?: string;
    size?: number;
    subText?: string;
    nameLink?: string;
    subLink?: string;
    hideText?: boolean;
    hideImage?: boolean;
    squareImage?: boolean;
    onRenderPrimaryText?: any;
    onRenderSecondaryText?: any;
    onRenderOptionalText?: any;
    optionalText?: any;
    big?: boolean;
    textColor?: string;
    secondaryTextColor?: string;
    unread?: number;
    state?: number;
    imageInitials?: string;
    iconToDisplayInsteadOfInitials?: string;
    tabIndex?: number;
    firstNameOnly?: boolean;
    bigCoin?: boolean;
    onClick?: any;
    greyBackground?: boolean;
}

interface IState {
    displaySpintrHovercard: boolean;
}

class SpintrUser extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            displaySpintrHovercard: !!props.id,
        };
    }

    getBorderRadius() {
        return this.props.squareImage ? "5px" : "50%";
    }

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.onRenderSecondaryText?.() !== this.props.onRenderSecondaryText?.() ||
            nextProps.unread !== this.props.unread ||
            nextProps.imageUrl !== this.props.imageUrl ||
            nextProps.state !== this.props.state ||
            nextProps.iconToDisplayInsteadOfInitials !== this.props.iconToDisplayInsteadOfInitials ||
            nextProps.name !== this.props.name ||
            nextProps.greyBackground !== this.props.greyBackground
        );
    }

    getPersonaPresence(): PersonaPresence {
        if (!this.props.state) {
            return PersonaPresence.none;
        }

        if (this.props.state === 1) {
            return PersonaPresence.online;
        }

        if (this.props.state === 2) {
            return PersonaPresence.away;
        }

        if (this.props.state === 4) {
            return PersonaPresence.busy;
        }

        return PersonaPresence.none;
    }

    renderPersona() {
        const name = this.props.name || "";

        return (
            <Persona
                text={this.props.firstNameOnly ? name.split(" ")[0] : name}
                imageInitials={this.props.imageInitials}
                onRenderInitials={(props, render) => {
                    if (this.props.iconToDisplayInsteadOfInitials) {
                        return (
                            <Visage2Icon
                                className="SpintrUser-initialsIcon"
                                color="white"
                                icon={this.props.iconToDisplayInsteadOfInitials}
                            />
                        );
                    }

                    return render(props);
                }}
                presence={this.getPersonaPresence()}
                presenceColors={
                    {
                        available: "#20E3AA",
                        away: "#F1AE15",
                        busy: "#FC5371",
                        dnd: "",
                        offline: "",
                        oof: "",
                        background: ""
                    }
                }
                tabIndex={this.props.tabIndex}
                className={classNames("no-user-select", {
                    initialsGreyBackground: this.props.greyBackground,
                    primaryBGColorInitials: !this.props.greyBackground,
                    initialsIconInsteadOfInitials: this.props.iconToDisplayInsteadOfInitials,
                })}
                secondaryText={this.props.subText}
                size={this.props.big ? PersonaSize.size100 : circleSmall}
                coinSize={this.props.size || (this.props.bigCoin ? circleLarge : circleMedium)}
                imageUrl={this.props.imageUrl}
                imageAlt={this.props.name}
                //title={this.state.displaySpintrHovercard ? null : this.props.name}
                coinProps={{
                    styles: {
                        initials: {
                            color: "#FFFFFF",
                            borderRadius: this.getBorderRadius(),
                        },
                        image: {
                            borderRadius: this.getBorderRadius(),
                        },
                        imageArea: {
                            borderRadius: this.getBorderRadius(),
                        },
                    },
                }}
                hidePersonaDetails={this.props.hideText}
                styles={{
                    details: { lineHeight: "initial" },
                    primaryText: { color: this.props.textColor || Style.getHexFromSpintrColor("visageGray") },
                    secondaryText: { color: this.props.secondaryTextColor || this.props.textColor || Style.getHexFromSpintrColor("visageGray3") },
                    optionalText: { color: this.props.textColor || "initial" },
                    tertiaryText: { color: this.props.textColor || "initial" },
                }}
                // onRenderPersonaCoin={(props, render) => {
                //     if (this.props.hideImage) {
                //         return;
                //     }

                //     let renderCoin = () => {
                //         return this.props.nameLink ? (
                //             /^https?:\/\//.test(this.props.nameLink) ? ( // render external links as <a>
                //                 <a
                //                     href={this.props.nameLink}
                //                     target="_blank"
                //                     aria-label={localize("OPEN_LINK_NEW_TAB")}
                //                 >
                //                     {render(props)}
                //                 </a>
                //             ) : (
                //                 <Link onClick={this.props.onClick} to={this.props.nameLink} aria-label={localize("GaTillProfilsida")}>{render(props)}</Link>
                //             )
                //         ) : (
                //             render(props)
                //         );
                //     };

                //     if (this.props.unread) {
                //         return (
                //             <ContentWithUnreadIndicator count={this.props.unread}>
                //                 {renderCoin()}
                //             </ContentWithUnreadIndicator>
                //         );
                //     } else {
                //         return renderCoin();
                //     }
                // }}
                onRenderPrimaryText={(props, render) => {
                    if (this.props.onRenderPrimaryText) return this.props.onRenderPrimaryText();

                    if (this.props.nameLink) {
                        return /^https?:\/\//.test(this.props.nameLink) ? ( // render external links as <a>
                            <a href={this.props.nameLink} style={{ color: "inherit" }} target="_blank">
                                {render(props)}
                            </a>
                        ) : (
                            <Link onClick={this.props.onClick} to={this.props.nameLink} style={{ color: "inherit" }}>
                                {render(props)}
                            </Link>
                        );
                    } else {
                        if (!render) {
                            // no name, renders a person icon instead
                            return null;
                        }
                        return render(props);
                    }
                }}
                onRenderSecondaryText={(props, render) => {
                    if (this.props.onRenderSecondaryText) return this.props.onRenderSecondaryText();

                    if (!render) {
                        return;
                    }

                    if (this.props.subLink) {
                        return (
                            <Link to={this.props.subLink} style={{ color: "inherit" }}>
                                {render(props)}
                            </Link>
                        );
                    } else {
                        return render(props);
                    }
                }}
                onRenderOptionalText={(props, render) => {
                    if (this.props.onRenderOptionalText) return this.props.onRenderOptionalText();

                    if (!render) {
                        return;
                    }

                    if (this.props.subLink) {
                        return (
                            <Link to={this.props.subLink} style={{ color: "inherit" }}>
                                {render(props)}
                            </Link>
                        );
                    } else {
                        return render(props);
                    }
                }}
            />
        );
    }

    render() {
        if (this.state.displaySpintrHovercard) {
            return <UserHovercard userId={this.props.id}>{this.renderPersona()}</UserHovercard>;
        } else {
            return this.renderPersona();
        }
    }
}

export default SpintrUser;
