import { produce } from "immer";
import { Reducer } from "redux";

import { SocialFeedActionTypes } from "src/social-feed/action-types";
import { SpintrActionTypes } from "src/spintr/action-types";
import { InteractionActionTypes } from './action-types';
import { InteractionsAction } from "./actions";

export interface IObjectInteractionsState {
    comments: Spintr.IComment[];
    commentsFailed: boolean;
    commentsLoaded: boolean;
    commentsIsLoading: boolean;
    likers: any[];
    likersFailed: boolean;
    likersIsLoading: boolean;
    likersLoaded: boolean;

}
export interface IInteractionsState {
    [objectId: number]: IObjectInteractionsState;
}

const initialState: IInteractionsState = {};
const defaultObjectState: IObjectInteractionsState = {
    comments: [],
    commentsFailed: false,
    commentsIsLoading: false,
    commentsLoaded: false,
    likers: [],
    likersFailed: false,
    likersIsLoading: false,
    likersLoaded: false,
}

export const interactionsReducer: Reducer<IInteractionsState, InteractionsAction> =
    (state = initialState, action) => produce(state, (draft) => {
        switch (action.type) {
            case InteractionActionTypes.FetchCommentsPending:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    comments: [],
                    commentsFailed: false,
                    commentsIsLoading: true,
                    commentsLoaded: false,
                };
                break;

            case InteractionActionTypes.FetchCommentsRejected:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    comments: [],
                    commentsFailed: true,
                    commentsIsLoading: false,
                    commentsLoaded: false,
                };
                break;

            case InteractionActionTypes.FetchCommentsFulfilled:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    comments: action.payload.data,
                    commentsFailed: false,
                    commentsIsLoading: false,
                    commentsLoaded: true,
                };
                break;

            case InteractionActionTypes.SetComments:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    comments: action.meta.comments,
                    commentsFailed: false,
                    commentsIsLoading: false,
                    commentsLoaded: true,
                };
                break;

            case InteractionActionTypes.FetchLikersPending:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    likers: [],
                    likersFailed: false,
                    likersIsLoading: true,
                    likersLoaded: false,
                };
                break;

            case InteractionActionTypes.FetchLikersRejected:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    likers: [],
                    likersFailed: true,
                    likersIsLoading: false,
                    likersLoaded: false,
                };
                break;

            case InteractionActionTypes.FetchLikersFulfilled:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    likers: action.payload.data,
                    likersFailed: false,
                    likersIsLoading: false,
                    likersLoaded: true,
                };
                break;

            case InteractionActionTypes.SetLikers:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    likers: action.meta.likers,
                    likersFailed: false,
                    likersIsLoading: false,
                    likersLoaded: true,
                };
                break;

            case InteractionActionTypes.AddLike:
            case InteractionActionTypes.AddLikePending:
            case InteractionActionTypes.AddLikeFulfilled:
                if (state[action.meta.uberId]) {
                    if (state[action.meta.uberId].likers.some(
                        (liker) => liker.id === (action.meta.identity?.id || action.meta.user.id) && ((liker.likeType || 0) === (action.meta.user.likeType || 0))
                    )) {
                        break;
                    }

                    if (state[action.meta.uberId].likers.some(
                        (liker) => liker.id === (action.meta.identity?.id || action.meta.user.id) && ((liker.likeType || 0) !== (action.meta.user.likeType || 0))
                    )) {
                        draft[action.meta.uberId] = {
                            ...draft[action.meta.uberId],
                            likers: draft[action.meta.uberId].likers.map((liker: any) => {
                                return {
                                    ...liker,
                                    likeType: liker.id === (action.meta.identity?.id || action.meta.user.id) ?
                                        (action.meta.user.likeType || 0) :
                                        (liker.likeType || 0)
                                }
                            })
                        }

                        break;
                    }
                }

                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;
                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],

                    likers: draft[action.meta.uberId].likers.concat(
                        [(action.meta.identity || action.meta.user)]
                    )
                };
                break;

            case InteractionActionTypes.RemoveLike:
            case InteractionActionTypes.RemoveLikePending:
            case InteractionActionTypes.RemoveLikeFulfilled:
                draft[action.meta.uberId] = state[action.meta.uberId] || defaultObjectState;

                const removeLikeIndex = draft[action.meta.uberId].likers.findIndex(
                    (liker) => liker.id === (action.meta.identity?.id || action.meta.user.id) && ((liker.likeType || 0) === (action.meta.user.likeType || 0))
                )

                if (removeLikeIndex === -1) {
                    break;
                }

                const likersPostRemove = draft[action.meta.uberId].likers.slice();
                likersPostRemove.splice(removeLikeIndex, 1);

                draft[action.meta.uberId] = {
                    ...draft[action.meta.uberId],
                    likers: likersPostRemove
                };
                break;

            case InteractionActionTypes.AddComment:
            // case InteractionActionTypes.AddCommentFulfilled:
                const processComments = (comments: Spintr.IComment[]) => {
                    const result: Spintr.IComment[] = [];

                    for (const c of comments) {
                        if (result.find(i => i.id === c.id)) {
                            continue;
                        }

                        if (!c.likers) {
                            c.likers = [];
                        }

                        result.push(c);
                    }

                    return result.sort((a, b) => 
                        new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                }

                if (!draft[action.meta.comment.objectId] && action.meta.fromRealtime) {
                    break;
                }

                draft[action.meta.comment.objectId] = state[action.meta.comment.objectId] || defaultObjectState;
                draft[action.meta.comment.objectId] = {
                    ...draft[action.meta.comment.objectId],

                    comments: processComments([
                        ...draft[action.meta.comment.objectId].comments,
                        action.meta.comment
                    ])
                };
                break;

            case InteractionActionTypes.UpdateComment:
                draft[action.meta.comment.objectId] = state[action.meta.comment.objectId] || defaultObjectState;
                draft[action.meta.comment.objectId] = {
                    ...draft[action.meta.comment.objectId],

                    comments: draft[action.meta.comment.objectId].comments.map(c => {
                        if (c.id === action.meta.comment.id) {
                            return action.meta.comment;
                        } else {
                            return c;
                        }
                    })
                };
                break;

            case InteractionActionTypes.MarkCommentAsAnswer:
                let newState = {...state};

                Object.keys(newState).map((key) => {
                    const containsComment = newState[key].comments.find(x => x.id === action.meta.id);

                    if (containsComment) {
                        newState[key].comments = newState[key].comments.map((c: any) => {
                            return {
                                ...c,
                                isAnswer: c.id === action.meta.id ?
                                    action.meta.value :
                                    false
                            }
                        })
                    }
                });

                return newState;

            case InteractionActionTypes.RemoveComment:
            case InteractionActionTypes.RemoveCommentPending:
            case InteractionActionTypes.RemoveCommentFulfilled:
                let s = {...state};

                Object.keys(s).map((key) => {
                    s[key].comments = s[key].comments.filter(c => c.id !== action.meta.id);
                });

                return s;

            case SocialFeedActionTypes.RemotePostDeleted:
                if (state[action.id]) {
                    draft[action.id] = undefined;
                }

                if (action.uberType !== 6) {
                    break;
                }

                Object.keys(state)
                    .filter((key) => !isNaN(+key))
                    .map((key) => parseInt(key, 10))
                    .forEach((objectId) => {
                        if (!state[objectId]) {
                            // some race condition shit...
                            return;
                        }
                        const found = state[objectId].comments.some(
                            (comment) => (
                                comment.id === action.id 
                                ||
                                (comment.comments || []).some(
                                    (c) => c.id === action.id
                                )
                            )
                        );

                        if (!found) {
                            return;
                        }

                        draft[objectId] = {
                            ...state[objectId],

                            comments: state[objectId].comments
                                .filter((c) => c.id !== action.id)
                                .map((comment) => ({
                                    ...comment,

                                    comments: (comment.comments || []).filter(
                                        (c) => c.id !== action.id
                                    )
                                })),
                        };
                    });
                
                break;

            //case SpintrActionTypes.DeleteObjectPending:
        }
    });

export default interactionsReducer
