import { Modal } from "@fluentui/react";
import React, { Component } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IApplicationState } from "src/spintr/reducer";
import { isAnythingDirty } from "src/utils";
import { renderUnsavedChangesDialog } from "src/utils/renderUnsavedChangesDialog";
import CalendarEventView from "./CalendarEventView";
import CalendarView from "./CalendarView";

interface IRouteParams {
    eventId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isSmallViewMode: boolean;
    calendarId?: number;
    groupId?: number;
    unitId?: number;
}

interface IState {
    displayUnsavedChangesPopup: boolean;
}
class CalendarRootView extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            displayUnsavedChangesPopup: false
        }
    }

    public componentDidMount() {}

    private onDismiss = () => {
        if (isAnythingDirty()) {
            return this.setState({
                displayUnsavedChangesPopup: true
            });
        }
        
        const pathname = window.location.pathname.split("/");
        pathname.pop();
        this.props.history.push({
            pathname: pathname.join("/"),
        });
    };

    public render() {
        return (
            <div>
                <CalendarView
                    history={this.props.history}
                    calendarId={this.props.calendarId}
                    groupId={this.props.groupId}
                    unitId={this.props.unitId}
                />

                {this.props.match.params.eventId && (
                    <Modal
                        className="spintr-modal CalendarEventViewModal"
                        isOpen={true}
                        onDismiss={this.onDismiss}
                    >
                        <CalendarEventView
                            id={this.props.match.params.eventId}
                            onDismiss={this.onDismiss}
                        />
                    </Modal>
                )}
                
                {renderUnsavedChangesDialog(
                    this.state.displayUnsavedChangesPopup,
                    () => {
                        this.setState({
                            displayUnsavedChangesPopup: false,
                        });
                    },
                    () => {
                        this.setState(
                            {
                                displayUnsavedChangesPopup: false,
                            },
                            () => {
                                this.onDismiss();
                            }
                        );
                    }
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        events: state.calendar.events,
        activeCalendar: state.calendar.activeCalendar,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default withRouter(connect(mapStateToProps)(CalendarRootView));
