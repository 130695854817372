import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { fetchShortcuts, ShortcutAction } from "src/shortcuts/actions";

const ShortcutLoader: FunctionComponent = () => {
    const dispatch = useDispatch<Dispatch<ShortcutAction>>();

    const shortcutState = useSelector(
        (state: Spintr.AppState) => state.shortcuts,
    );

    useEffect(() => {
        if (shortcutState.isLoading || shortcutState.items.length > 0) {
            return;
        }

        dispatch(fetchShortcuts());
    }, []);

    return null;
};

export default ShortcutLoader;
