export const getLinkTarget = (url: string) => {
    if (!url) {
        return "_blank";
    }

    if (url[0] === "/" ||
        url.indexOf(window.location.origin) === 0) {
        return "_self";
    }

    return "_blank";
};