import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import Color from "src/style/colors/Color";
import { ActionMenu } from "src/ui";
import { Style } from "src/ui/helpers";
import "./SocialComposerTarget.scss";

interface IProps {
    instance?: any;
    currentUser?: any;
    dispatch?: any;
    filters?: any;
    apps?: any;
    feedId: number;
    setFeedId: any;
}

interface IState {
    offices?: any[],
    isDirty: boolean;
    activeOfficeOrDepartment?: any;
}

class SocialComposerTarget extends Component<IProps, IState> {
    private actionMenu = React.createRef<ActionMenu>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            isDirty: false,
            offices: this.getOffices(props),
            activeOfficeOrDepartment: this.getActiveOfficeOrDepartment(props)
        }
    }

    getActiveOfficeOrDepartment(props: IProps) {
        if (props.feedId === props.currentUser.feedId) {
            return undefined;
        }

        const offices = this.getOffices(props);

        for (const office of offices) {
            if (office.feedId === props.feedId) {
                return office;
            }

            if (!!office.departments) {
                for (const department of office.departments) {
                    if (department.feedId === props.feedId) {
                        return department;
                    }
                }
            }
        }

        return undefined;
    }

    getOffices(props: IProps) {
        if (!props.filters || !props.filters.units) {
            return [];
        }

        return [
            {
                id: props.currentUser.feedId,
                name: localize("AllaAvdelningar"),
            },
            ...props.filters.units.filter(f => "feedId" in f || f.departments?.filter(d => "feedId" in d)?.length > 0).map(o => {

                return {
                    ...o,
                    departments: o.departments.filter(d => "feedId" in d)
                }

            })
        ]
    }

    getCategories() {
        let categories: Spintr.IActionMenuCategory[] = [{
            items: this.state.offices.map((o, i) => {
                return {
                    key: o.id,
                    text: o.name,
                    title: o.name,
                    className: o.active ? "SocialComposerTarget-active-item" : "",
                    onClick: () => {
                        this.props.setFeedId(o.feedId);

                        this.setState({
                            isDirty: true,
                            offices: this.state.offices.map(office => {
                                return {
                                    ...office,
                                    active: office.id === o.id,
                                    departments: !office.departments ?
                                        office.departments :
                                        office.departments.map(department => {
                                            return {
                                                ...department,
                                                active: false
                                            }
                                        })
                                }
                            }),
                            activeOfficeOrDepartment: this.state.offices.find(office => o.id === office.id)
                        });
                    },
                    subMenuProps: !o.departments || o.departments.length === 0 ?
                        null :
                        {
                            items: [
                                ...("feedId" in o ? [o] : []),
                                ...o.departments.filter(f => "feedId" in f)
                            ].map((d, i) => {
                                return {
                                    key: d.id,
                                    text: d.name,
                                    title: d.name,
                                    className: d.active ? "SocialComposerTarget-active-item" : "",
                                    onClick: () => {
                                        this.props.setFeedId(d.feedId);

                                        this.setState({
                                            activeOfficeOrDepartment: d,
                                            offices: this.state.offices.map(office => {
                                                return {
                                                    ...office,
                                                    active: office.id === d.id,
                                                    departments: !office.departments ?
                                                        office.departments :
                                                        office.departments.map(department => {
                                                            return {
                                                                ...department,
                                                                active: department.id === d.id
                                                            }
                                                        })
                                                }
                                            }),

                                            isDirty: true
                                        });
                                    }
                                }
                            })
                        }
                }
            })
        },
        ];

        return categories;
    }

    getLabelText() {
        let labelText = localize("AllaAvdelningar");

        if (this.state.activeOfficeOrDepartment) {
            labelText = this.state.activeOfficeOrDepartment.name;
        }

        return labelText;
    }

    public render(): ReactNode {
        if (!this.state.offices || this.state.offices.length === 0) {
            return null;
        }

        return (
            <div className="SocialComposerTarget no-user-select">
                <div className="menuWrap">
                    <div className="actions">
                        <div className="icon">
                            <ActionMenu
                                ref={this.actionMenu}
                                categories={this.getCategories()}
                                label={this.getLabelText()}
                                labelColor="mid-grey"
                                iconColor={Style.getHexFromSpintrColor("mid-grey")}
                                icon="arrow-down-1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    filters: state.socialFeed.filters,
    apps: state.app
});

export default connect(mapStateToProps)(SocialComposerTarget);

