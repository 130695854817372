import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, StandardErrorBoundary } from '../../ui/components';
import { findGroupById } from '../actions';
import GroupView from './GroupView';

interface IProps {
    group?: any;
    groupId: number;
    dispatch?: any;
    history: any;
}

interface IState {

}

class GroupRootView extends Component<IProps, IState> {
    componentDidMount() : void {
        this.initGroup();
    }

    public componentDidUpdate(prevProps: IProps) : void {
        if (!!prevProps &&
            !!prevProps.groupId &&
            prevProps.groupId !== this.props.groupId) {
            this.initGroup();
        }
    }

    public initGroup() : void {
        this.props.dispatch(findGroupById(this.props.groupId));
    }

    render() {
        if (!this.props.group || !this.props.group.isLoaded || this.props.groupId !== this.props.group.id) {
            return (
                <Loader />
            );
        }

        if (this.props.group.failed) {
            // TODO: GO to 404

            return (
                <Loader />
            );
        }

        return (
            <StandardErrorBoundary>
                <GroupView
                    history={this.props.history}
                />
            </StandardErrorBoundary>
        )
    }

    shouldComponentUpdate (nextProps) {
        if (nextProps.groupId !== this.props.groupId ||
            nextProps.group !== this.props.group ||
            nextProps.group.isLoaded !== this.props.group.isLoaded ||
            nextProps.group.failed !== this.props.group.failed) {
            return true;
        }

        return false;
    }
}

const mapStateToProps = (state, props) => {
    let groupId = props.match.params.id;

    if (groupId.indexOf('-') > -1) {
        groupId = groupId.split('-')[0]; // Classic group support
    }

    groupId = parseInt(groupId, 0);

    return {
        ...props,
        groupId: groupId,
        group: state.groups.group
    }
}

export default connect(mapStateToProps)(GroupRootView);
