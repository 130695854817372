import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { localize } from 'src/l10n';
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from 'src/sidebar';
import { expandToActiveItem, setSidebarItems, setSidebarMode } from 'src/sidebar/actions';
import { IApplicationState } from 'src/spintr/reducer';
import api from 'src/spintr/SpintrApi';
import { SpintrTypes } from 'src/typings';
import { FileDetailsEditView, FileDetailsView } from '.';
import { Loader } from '../../ui';
import { FileBrowser, ZohoPopup } from '../components';
import FileBrowserHeader from '../components/FileBrowserHeader';
import { fetchRootFolders } from '../folder-actions';
import FileDetailsOffice365View from './FileDetailsOffice365View';
import './FilesRootView.scss';

interface IRouteParams {
    source: string;
    id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isLoading: boolean;
    roots: any;
    currentFolderId: number;
    searchText: string;
    isSmallViewMode: boolean;
    groupName?: string;
    history: any;
    source: Spintr.FolderRootSource;
    rootFolderId: string;
    dispatch: (action: Action) => void;
    instanceName?: string;
}

class FilesRootView extends Component<IProps> {
    private _isMounted: boolean = false;

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateSideBar();

        if (this.props.isLoading || this.props.roots.count()) {
            return;
        }

        this.props.dispatch(fetchRootFolders());
    }

    redirectToDefault() {
        const root = this.props.roots.find((r) => r.get("source") === SpintrTypes.FolderRootSource.Office365Public) || this.props.roots.first();

        return <Redirect to={`/files/${root.get("source")}/d${root.get("id")}`} />;
    }

    render() {
        return (
            <div className="FilesRootView">
                <Helmet>
                    <title>{localize("Filer")}</title>
                </Helmet>
                {this.renderInner()}
                <div className="spacer"></div>
            </div>
        );
    }

    updateSideBar() {
        api.get("/api/folders/rootfolders").then((response: any) => {
            if (!this._isMounted) {
                return;
            }

            let items: IVisageSidebarMenuItem[] = [{
                key: "files",
                name: localize("Filer"),
                icon: "Folder",
                activeIcon: "Folder",
                children: [],
                isExpanded: true,
                isLoading: false,
                moduleKey: "files",
            }];

            const officeSources = response.data.filter((s: any) =>
                s.source > SpintrTypes.FolderRootSource.SpintrGroup &&
                s.source !== SpintrTypes.FolderRootSource.Office365Group
            );

            const localSources = response.data.filter((s: any) =>
                s.source === SpintrTypes.FolderRootSource.SpintrPublic
            );

            const groupSources = response.data.filter((s: any) =>
                s.source === SpintrTypes.FolderRootSource.SpintrGroup ||
                s.source === SpintrTypes.FolderRootSource.Office365Group
            );

            if (!!officeSources && officeSources.length > 0) {
                items[0].children.push({
                    key: "office-category",
                    name: localize("Office365"),
                    children: officeSources.map((item: any) => {
                        return {
                            key: "office-source-" + item.id,
                            name: item.name,
                            route: "/files/" + item.source + "/d" + item.id,
                            routes: ["/files/" + item.source],
                            activeMode: VisageSidebarMenuItemActiveMode.relative
                        }
                    })
                })
            }

            if (!!localSources && localSources.length > 0) {
                items[0].children.push({
                    key: "local-files",
                    name: this.props.instanceName,
                    route: "/files/" + localSources[0].source + "/d" + localSources[0].id,
                    routes: ["/files/" + localSources[0].source],
                    activeMode: VisageSidebarMenuItemActiveMode.relative
                });
            }

            if (!!groupSources && groupSources.length > 0) {
                items[0].children.push({
                    key: "groups-category",
                    name: localize("Grupper"),
                    children: groupSources.map((item: any) => {
                        let route = "/files/" + item.source + "/d" + item.id;

                        return {
                            key: "groups-source-" + item.id,
                            name: item.name,
                            route,
                            routes: [route],
                            activeMode: VisageSidebarMenuItemActiveMode.relative
                        }
                    })
                })
            }

            this.props.dispatch(setSidebarItems(items, "files"));
            this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
            this.props.dispatch(expandToActiveItem());
        }).catch(() => { });
    }

    renderInner() {
        if (this.props.isLoading || (!this.props.roots.count() && !this.props.groupName)) {
            return <Loader />;
        }

        if (isNaN(this.props.source)) {
            return this.redirectToDefault();
        }

        var isFile = this.props.match.params.id.substr(0, 1) == "f";
        var isOffice365File = this.props.match.params.id.substr(0, 4) == "365f";
        var isVersion = this.props.location.pathname.includes("version");
        var isEdit = this.props.location.pathname.includes("edit");
        var fileId = this.props.match.params.id.substr(isFile ? 1 : 4);
        return (
            <div className="inner">
                <div
                    className={classnames("content", {
                        isFile: isFile || isOffice365File,
                        isSmallViewMode: this.props.isSmallViewMode,
                    })}
                >
                    <div className="header">
                        <FileBrowserHeader
                            id={
                                isFile || isOffice365File
                                    ? this.props.currentFolderId.toString()
                                    : this.props.match.params.id.substr(1)
                            }
                            rootFolderId={this.props.rootFolderId}
                            location={this.props.location}
                            source={this.props.source}
                            history={this.props.history}
                        />
                    </div>
                    <FileBrowser
                        id={
                            isFile || isOffice365File
                                ? this.props.currentFolderId.toString()
                                : this.props.match.params.id.substr(1)
                        }
                        rootFolderId={this.props.rootFolderId}
                        location={this.props.location}
                        history={this.props.history}
                        source={this.props.source}
                    />

                    {isFile && !isOffice365File && !isVersion && !isEdit && (
                        <FileDetailsView
                            id={this.props.match.params.id.substr(1)}
                            source={this.props.source}
                        />
                    )}
                    {isFile && isEdit && (
                        <FileDetailsEditView
                            id={this.props.match.params.id.substr(1)}
                            source={this.props.source}
                        />
                    )}
                    {isFile && isVersion && (
                        <FileDetailsEditView
                            id={this.props.match.params.id.substr(1)}
                            source={this.props.source}
                            isVersion
                        />
                    )}
                    {isOffice365File && (
                        <FileDetailsOffice365View
                            id={this.props.match.params.id.substr(4)}
                            source={this.props.source}
                        />
                    )}
                    <ZohoPopup />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,

    source: parseInt(props.match.params.source, 10),
    isLoading: state.files.folders.get("isLoadingRoots"),
    roots: state.files.folders.get("roots"),
    currentFolderId: state.files.folders.get("currentFolderId"),
    searchText: state.files.files.searchText,
    isSmallViewMode: state.ui.isSmallViewMode,
    rootFolderId: state.instance.get("rootFolderId"),
    instanceName: state.instance.get("name"),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(FilesRootView));
