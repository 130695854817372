import { IconButton, PrimaryButton } from "@fluentui/react";
import { Checkbox, DefaultButton, Modal, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { FileSelector } from "src/spintr/components";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, ContentImageViewerAndSelector, Icon, Label, Loader, PageHeader, UnstyledButton } from "src/ui";
import PlusSignButton from "src/ui/components/Buttons/PlusSignButton/PlusSignButton";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import { Style } from "src/ui/helpers";
import { scrollToTop, uniqueId, validateRequiredTextField } from "src/utils";
import "./BlogsPostEditView.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";
import ExpandableFormField from "src/ui/components/ExpandableFormField/ExpandableFormField";
import BlogsPostView from "./BlogsPostView";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import SplitForm from "src/ui/components/SplitForm/SplitForm";

interface IPageRouteParams {
    path: string;
}

interface IBlogsPostEditViewProps extends RouteChildrenProps<IPageRouteParams> {
    match: any;
    currentUser: any;
    isSmallViewMode?: boolean;
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
    dispatch?: any;
}

interface IBlogsPostEditViewState {
    post: Spintr.IBlogPost;
    isLoading: boolean;
    showModal: boolean;
    showImageSelector: boolean;
    showVideoSelector: boolean;
    showPodcastSelector: boolean;
    showImageAdder: boolean;
    imageUrl: string;
    saveError: string[];
    saveSuccess: boolean;
    video: any;
    podcast: any;
    blogName: string;
    showImageCropper: boolean;
    image: any;
    enableFieldValidation?: boolean;
    displayPreview: boolean;
}

class BlogsPostEditView extends Component<IBlogsPostEditViewProps, IBlogsPostEditViewState> {
    private isNew = !(this.props.match.params.blogSlug && this.props.match.params.postSlug);
    private adminView = window.location.href.includes("admin");

    constructor(props: IBlogsPostEditViewProps) {
        super(props);

        this.state = {
            post: {
                author: {
                    id: props.currentUser.id,
                },
                id: 0,
                isPublished: true,
                publishDate: new Date(),
                showAuthor: true,
                allowComments: true,
                isPreview: true,
                mediaUploadType: 0,
                uploadNewMedia: true,
                headline: "",
                text: "",
                tags: [],
                uberImageTickets: [],
                media: "",
            } as Spintr.IBlogPost,
            isLoading: true,
            showModal: false,
            showImageSelector: false,
            showVideoSelector: false,
            showPodcastSelector: false,
            showImageAdder: false,
            imageUrl: "",
            saveError: [],
            saveSuccess: false,
            video: undefined,
            podcast: undefined,
            blogName: "",
            showImageCropper: false,
            image: undefined,
            displayPreview: false,
        };
    }

    private _closeModal = () => {
        this.setState({ showModal: false, showImageSelector: false });
    };

    private removeVideo = () => {
        this.setState({ video: undefined });
    };

    private removePodcast = () => {
        this.setState({ podcast: undefined });
    };

    private removeMedia = () => {
        this.setState({
            post: { ...this.state.post, media: "", mediaUploadType: 0, mediaUploadProgress: 0, mediaUploadStatus: 0 },
        });
    };

    renderSmallPlusSign = () => {
        return (
            <form>
                <UnstyledButton
                    ariaLabel={localize("LaggTill")}
                    onClick={() => {
                        this.setState({ showImageAdder: true });
                    }}
                >
                    <div
                        style={{
                            backgroundColor: Style.getHexFromSpintrColor("light-grey"),
                            height: "40px",
                            textAlign: "center",
                            width: "40px",
                            display: "inline-block",
                            cursor: "pointer",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-block",
                                position: "relative",
                                transform: "translateY(-50%)",
                                msTransform: "translateY(-50%)",
                                top: "50%",
                            }}
                        >
                            <Visage2Icon icon="add-circle" color="light-blue" />
                        </div>
                    </div>
                </UnstyledButton>
            </form>
        );
    };

    renderColumnTypeSelector() {
        return (
            <Modal
                titleAriaId={"Select option"}
                isOpen={this.state.showModal}
                onDismiss={this._closeModal}
                isBlocking={false}
                containerClassName={"OptionsModal"}
            >
                <PopupHeader text={localize("Innehall")} onClose={this._closeModal} />
                <form>
                    <div className="OptionsWrapper">
                        <div className="createButtons">
                            <DefaultButton
                                className="createButton"
                                onClick={() => {
                                    this.setState((state) => ({
                                        showModal: false,
                                        showImageSelector: true,
                                        post: { ...state.post, type: SpintrTypes.BlogPostType.Image },
                                    }));
                                }}
                            >
                                <div
                                    style={{
                                        display: "inline-block",
                                        boxSizing: "border-box",
                                        textAlign: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                <Visage2Icon icon="gallery" color="dark-grey" />
                                            </div>
                                        </div>
                                        <Label as="span" size="h6" color="dark-grey">
                                            {localize("Bild")}
                                        </Label>
                                    </div>
                                </div>
                            </DefaultButton>
                            <DefaultButton
                                className="createButton"
                                onClick={() => {
                                    this.setState((state) => ({
                                        showModal: false,
                                        showVideoSelector: true,
                                        post: { ...state.post, type: SpintrTypes.BlogPostType.Video },
                                    }));
                                }}
                            >
                                <div
                                    style={{
                                        display: "inline-block",
                                        boxSizing: "border-box",
                                        textAlign: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                <Visage2Icon icon="video-square" color="dark-grey" />
                                            </div>
                                        </div>
                                        <Label as="span" size="h6" color="dark-grey">
                                            {localize("Video")}
                                        </Label>
                                    </div>
                                </div>
                            </DefaultButton>
                            <DefaultButton
                                className="createButton"
                                onClick={() => {
                                    this.setState((state) => ({
                                        showModal: false,
                                        showPodcastSelector: true,
                                        post: { ...state.post, type: SpintrTypes.BlogPostType.Podcast },
                                    }));
                                }}
                            >
                                <div
                                    style={{
                                        display: "inline-block",
                                        boxSizing: "border-box",
                                        textAlign: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "inline-block",
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                <Visage2Icon icon="sound" color="dark-grey" />
                                            </div>
                                        </div>
                                        <Label as="span" size="h6" color="dark-grey">
                                            {localize("Podcast")}
                                        </Label>
                                    </div>
                                </div>
                            </DefaultButton>
                        </div>
                    </div>
                </form>
            </Modal>
        );
    }

    public onCancel = () => {
        this.props.history.goBack();
    };

    public onSubmit = () => {
        this.setState({
            enableFieldValidation: true,
        });
        const { video, podcast, post } = this.state;

        let payload = {} as any;
        if (this.refs.tiny) {
            payload = {
                ...this.state.post,
                // @ts-ignore
                text: this.refs.tiny.getContent(),
            };
        } else {
            payload = this.state.post;
        }

        if (!video && !podcast) {
            // This is a normal post, normal upload
            this.setState({ isLoading: true, post: { ...post, text: payload.text } }, () => {
                api.post("/api/v1/blogposts", payload)
                    .then((response) => {
                        this.props.history.push({
                            pathname: response.data.url,
                        }); // FIXME: The server needs to return the correct URL
                        this.setState({ isLoading: false });
                    })
                    .catch((saveError) => {
                        scrollToTop();
                        this.setState({ isLoading: false, saveError: saveError.response.data.errorlist });
                    });
            });
        } else {
            // Set mediaUploadType and uploadNewMedia
            payload.mediaUploadType = 1;
            payload.uploadNewMedia = true;

            this.setState({ isLoading: true }, () => {
                api.post("/api/v1/blogposts", payload)
                    .then((response) => {
                        let body = new FormData();
                        let ticket = new Date().getTime().toString() + uniqueId();
                        body.append("ticket", ticket);
                        body.append("blogPostId", response.data.id);

                        if (this.state.video) {
                            body.append("type", "2");
                            body.append("file", this.state.video);
                            api.post("/api/v1/azureupload", body).then((r) => {
                                this.setState({ isLoading: false });
                            });
                        } else if (this.state.podcast) {
                            body.append("type", "8");
                            body.append("ticket", new Date().getTime().toString());
                            body.append("file", this.state.podcast);
                            api.post("/api/uploads", body).then((r) => {
                                this.setState({ isLoading: false });
                            });
                        }
                        this.props.history.push({
                            pathname: response.data.url,
                        }); // FIXME: The server needs to return the correct URL
                    })
                    .catch((saveError) => {
                        scrollToTop();
                        this.setState({ saveError: saveError.response.data.errorlist });
                    });
            });
        }
    };

    private getByRoute = (route) => {
        const params = { route: route };
        this.setState({ isLoading: true }, () => {
            api.get("/api/v1/routes", { params }).then((response) => {
                const post = response.data as Spintr.IBlogPost;
                post.publishDate = new Date(post.publishDate);
                post.publishEndDate = !post.publishEndDate ? undefined : new Date(post.publishEndDate);
                this.setState({ post, blogName: post.blog.name }, () => {
                    this.setState({ isLoading: false });
                });
            });
        });
    };

    public componentDidMount() {
        const { blogSlug } = this.props.match.params;
        const params = { route: `bloggar/${blogSlug}` };

        // Check if we are editing an existing post.
        const { postSlug } = this.props.match.params;
        if (!this.isNew) {
            this.getByRoute(`bloggar/${blogSlug}/${postSlug}`);
        } else {
            this.setState({ isLoading: true }, () => {
                api.get("/api/v1/routes", { params }).then((response) => {
                    const blog = response.data;
                    this.setState(
                        (state) => ({
                            post: {
                                ...state.post,
                                blog,
                                blogId: blog.id,
                            },
                            blogName: blog.name,
                        }),
                        () => {
                            this.setState({ isLoading: false });
                        }
                    );
                });
            });
        }
    }

    removeInlineImage = (ui) => {
        let { uberImageTickets } = this.state.post;
        uberImageTickets = uberImageTickets.filter((uit) => {
            if (ui !== uit) return ui;
        });
        this.setState({ post: { ...this.state.post, uberImageTickets } });
    };

    removeInlineImage2 = (ui) => {
        let { uberImages, uberImageTickets } = this.state.post;
        if (!uberImageTickets) uberImageTickets = [];
        uberImages = uberImages.filter((uit) => {
            if (ui === uit) {
                uberImageTickets.push({ id: uit.id, remove: true });
            } else {
                return uit;
            }
        });

        this.setState({ post: { ...this.state.post, uberImages, uberImageTickets } });
    };

    private onContentChanged(text: string) {
        this.setState({
            ...this.state,
            post: {
                ...this.state.post,
                text,
            },
        });
    }

    private toggleDisplayPreview = () => {
        this.setState(
            (prevState) => ({
                displayPreview: !prevState.displayPreview,
            }),
            () => {
                scrollToTop();
            }
        );
    };

    private renderFormFooterBar = () => (
        <FormFooterBar
            top={!this.props.isSmallViewMode}
            onRenderLeft={
                this.props.isSmallViewMode
                    ? undefined
                    : () => (
                          <Breadcrumbs
                              displayInstance
                              items={[
                                  ...(this.adminView
                                      ? [
                                            {
                                                text: localize("Administration"),
                                                key: 2,
                                                link: "/admin",
                                            },
                                            {
                                                text: localize("Bloggar"),
                                                key: 3,
                                                link: "/admin/blogs",
                                            },
                                        ]
                                      : []),
                                  {
                                      text: this.isNew ? localize("SkapaBloggInlägg") : localize("RedigeraBloggInlägg"),
                                      key: 4,
                                      link: window.location.pathname,
                                  },
                              ]}
                          />
                      )
            }
            onCancelClick={this.onCancel}
            onSaveClick={this.onSubmit}
            onPreviewClick={this.toggleDisplayPreview}
            confirmSave={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("PubliceraDetta").toLowerCase())}
        />
    );

    public render = () => {
        const { post, isLoading, saveSuccess, saveError, video, podcast } = this.state;
        const { blogSlug } = this.props.match.params;

        if (isLoading) {
            return <Loader />;
        }

        if (this.state.displayPreview) {
            return (
                <>
                    <BlogsPostView
                        post={{
                            ...post,
                            author: {
                                ...this.props.currentUser,
                                departmentName: this.props.currentUser.department.name,
                                image: this.props.currentUser.images["topBar"],
                            },
                        }}
                        isPreview
                        goBackToFormCallback={() => {
                            this.setState(
                                {
                                    displayPreview: false,
                                },
                                () => {
                                    scrollToTop();
                                }
                            );
                        }}
                    />
                    {this.renderFormFooterBar()}
                </>
            );
        }

        const isNew =
            window.location.href.indexOf(`admin/blogs/${blogSlug}/create/blogpost`) > -1 ||
            !(this.props.match.params.blogSlug && this.props.match.params.postSlug);

        return (
            <div className="blogs-post-edit-view">
                <Helmet>
                    <title>{localize("RedigeraBloggpost")}</title>
                </Helmet>
                {saveSuccess && (
                    <SuccessMessagebar
                        message={localize("BloggpostenHarSparats")}
                        onDismiss={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                )}

                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}
                <div className={(this.adminView ? "apply-padding" : "")}>
                <SplitForm
                    headerTitle={isNew ? localize("SkapaBloggInlägg") : localize("RedigeraBloggInlägg")}
                    displayMandatoryText
                    additionalSettings={[
                        {
                            title: localize("Metadata"),
                            content: (
                                <>
                                    <FormControl>
                                        <FormTokenizedObjectInput
                                            hideImage={true}
                                            enableNewItems={true}
                                            //@ts-ignore
                                            items={post.tags.map((tag) => {
                                                return {
                                                    name: tag,
                                                };
                                            })}
                                            label={localize("Taggar")}
                                            onChange={(tags) => {
                                                this.setState((state) => ({
                                                    post: {
                                                        ...state.post,
                                                        tags: tags.map((t) => {
                                                            return t.name;
                                                        }),
                                                    },
                                                }));
                                            }}
                                            showTags
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Checkbox
                                            label={localize("VisaAnsvarigUtgivare")}
                                            checked={post.showAuthor}
                                            onChange={(
                                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                checked?: boolean
                                            ) => {
                                                this.setState((state) => ({
                                                    post: {
                                                        ...state.post,
                                                        showAuthor: checked,
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                </>
                            ),
                        },
                        {
                            title: localize("Avancerat"),
                            content: (
                                <>
                                    <FormControl>
                                        <div className="horizontal-dates">
                                            <SpintrDatePicker
                                                ariaLabel={localize("Publiceringsdatum")}
                                                ariaRequired={true}
                                                label={localize("Publiceringsdatum")}
                                                value={post.publishDate}
                                                onChangeHandler={(date: Date) => {
                                                    this.setState((state) => ({
                                                        post: {
                                                            ...state.post,
                                                            publishDate: date,
                                                        },
                                                    }));
                                                }}
                                            />
                                            <SpintrDatePicker
                                                ariaLabel={localize("Slutdatum")}
                                                ariaRequired={true}
                                                label={localize("Slutdatum")}
                                                value={post.publishEndDate || null}
                                                isClearable={true}
                                                onChangeHandler={(date: Date) => {
                                                    this.setState((state) => ({
                                                        post: {
                                                            ...state.post,
                                                            publishEndDate: date,
                                                        },
                                                    }));
                                                }}
                                                minDate={post.publishDate}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormControl>
                                        <Checkbox
                                            label={localize("Publicerad")}
                                            checked={post.isPublished}
                                            onChange={(
                                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                checked?: boolean
                                            ) => {
                                                this.setState((state) => ({
                                                    post: {
                                                        ...state.post,
                                                        isPublished: checked,
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Checkbox
                                            label={localize("TillatKommentarer")}
                                            checked={post.allowComments}
                                            onChange={(
                                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                checked?: boolean
                                            ) => {
                                                this.setState((state) => ({
                                                    post: {
                                                        ...state.post,
                                                        allowComments: checked,
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                </>
                            ),
                        },
                    ]}
                >
                    <FormControl>
                        <TextField
                            ariaLabel={localize("SkrivEnRubrik")}
                            data-cy="blogpost-headline"
                            value={post.headline}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, headline?: string) => {
                                this.setState((state) => ({ post: { ...state.post, headline } }));
                            }}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    event.stopPropagation(); // Prevents page from reloading
                                    event.preventDefault();
                                }
                            }}
                            label={localize("Rubrik")}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    <FormControl>
                        {this.state.podcast && this.state.podcast.name}
                        {this.state.video && this.state.video.name}
                        {post.media && (
                            <div>
                                <video
                                    className="video-js vjs-default-skin vjs-big-play-centered"
                                    controls={true}
                                    data-setup="{}"
                                    height="auto"
                                    preload="metadata"
                                    style={{ width: "100%" }}
                                    width="auto"
                                >
                                    <source src={post.media} />
                                </video>
                                <PrimaryButton
                                    styles={{ root: { marginTop: "10px", marginBottom: "10px" } }}
                                    onClick={this.removeMedia}
                                >
                                    {localize("TaBortMedia")}
                                </PrimaryButton>
                            </div>
                        )}
                        {(post.image || this.state.showImageSelector) && (
                            <ContentImageViewerAndSelector
                                renderSizeString={() => {
                                    return `${localize("OptimalBildStorlek")}: 1000x340px <br />`;
                                }}
                                displayFileSelector={this.state.showImageSelector}
                                enableMediaflow={this.props.enableMediaflow}
                                enableUnsplash={this.props.enableUnsplash}
                                cropImage
                                imageUrl={post.image}
                                cropAspect={1000 / 340}
                                autoHeight={!!post.image}
                                blur
                                editMode={true}
                                onFileSelectorClose={() => {
                                    if (this.state.showImageSelector) {
                                        if (
                                            !this.state.post.image &&
                                            (!this.state.post.imageTicket || this.state.post.imageTicket.remove)
                                        ) {
                                            this.setState({
                                                showImageSelector: false,
                                            });
                                        }
                                    }
                                }}
                                onChange={(result) => {
                                    this.setState((state) => ({
                                        post: {
                                            ...state.post,
                                            image: result.thumbnailUrl,
                                            imageTicket: {
                                                ticket: result.ticket,
                                                remove: !!!result.ticket && !result.isUnsplash,
                                                mediaflowId: result.externalMediaflowId,
                                                hotlink: result.isUnsplash,
                                                image: result.thumbnailUrl,
                                            },
                                        },
                                        showImageSelector: false,
                                    }));
                                }}
                            />
                        )}
                        {this.state.showVideoSelector && (
                            <FileSelector
                                onClose={() => {
                                    this.setState({
                                        showVideoSelector: false,
                                    });
                                }}
                                onSelect={(data) => {
                                    this.setState((state) => ({
                                        showVideoSelector: false,
                                        video: data[0],
                                    }));
                                }}
                                allowMultipleFiles={false}
                                fileTypesString={"video/*"}
                                fileTypes={["mov", "avi", "mp4", "mpg", "wmv", "flv", "webm", "mkv", "ogv"]}
                                sourcesToDisplay={[0]}
                                fileUploadType={2}
                                customRequestUrl={"/api/v1/azureupload"}
                                handleUploadSeparately
                                hideMedia
                            />
                        )}
                        {this.state.showPodcastSelector && (
                            <FileSelector
                                hideMedia
                                onClose={() => {
                                    this.setState({
                                        showPodcastSelector: false,
                                    });
                                }}
                                onSelect={(data) => {
                                    this.setState((state) => ({
                                        showPodcastSelector: false,
                                        podcast: data[0],
                                    }));
                                }}
                                allowMultipleFiles={false}
                                fileTypesString={"audio/*"}
                                fileTypes={["mp3", "wav"]} // FIXME: Add more filetypes
                                sourcesToDisplay={[0]}
                                fileUploadType={8}
                                handleUploadSeparately
                            />
                        )}
                        {podcast && (
                            <UnstyledButton onClick={this.removePodcast} title={localize("TaBortMedia")}>
                                <Visage2Icon icon="trash" />
                            </UnstyledButton>
                        )}
                        {video && (
                            <UnstyledButton onClick={this.removeVideo} title={localize("TaBortMedia")}>
                                <Visage2Icon icon="trash" />
                            </UnstyledButton>
                        )}
                        {!post.image && !video && !podcast && post.media === "" && !this.state.showImageSelector && (
                            <div className="blogEditPlusSignButtonWrapper">
                                <PlusSignButton
                                    onClick={() => {
                                        this.setState({ showModal: true });
                                    }}
                                />
                            </div>
                        )}
                    </FormControl>
                    <FormControl>
                        <TinyEditorComponent
                            label={localize("BrodText")}
                            ref={"tiny"}
                            id={"23"}
                            enableMediaflow={this.props.enableMediaflow}
                            enableUnsplash={this.props.enableUnsplash}
                            content={post.text}
                            onChange={this.onContentChanged.bind(this)}
                        />
                    </FormControl>
                </SplitForm>
                </div>
                {this.renderColumnTypeSelector()}
                {this.renderFormFooterBar()}
            </div>
        );
    };
}

const mapStateToProps = (state, props) => ({
    ...props,
    instanceName: state.instance.name,
    currentUser: state.profile.active,
    isSmallViewMode: state.ui.isSmallViewMode,
    enableMediaflow: state.instance.get("enableMediaflow"),
    enableUnsplash: state.instance.get("enableUnsplash"),
});

export default withRouter(connect(mapStateToProps)(BlogsPostEditView));
