import { Checkbox, DatePicker, DayOfWeek, Pivot, PivotItem, StackItem, TextField } from "@fluentui/react";
import { DefaultButton, Dropdown, Modal, PrimaryButton, Stack } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, RouteChildrenProps, withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { FileSelector } from "src/spintr/components";
import { SpintrTypes } from "src/typings";
import {
    ActionMenu,
    ContentImageViewerAndSelector,
    Label,
    Loader,
    PageHeader,
    setUseDocumentWidthMode,
    SpintrUser,
    UnstyledButton,
} from "src/ui";
import { FormControl, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { Style } from "src/ui/helpers";
import { isAnythingDirty, uniqueId, validateRequiredTextField } from "src/utils";
import SuppliersBreadcrumbs from "./SuppliersBreadcrumbs";
import "./SupplierView.scss";
import api from "src/spintr/SpintrApi";
import { setConfirmPopup } from "src/popups/actions";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import moment from "moment";
import { updateSidebar } from "./utils";
import PopupHeader from "src/ui/components/PopupHeader";
import { CancelToken } from "axios";
import TokenizedInputFabric from "src/ui/components/Forms/TokenizedInputFabric";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import formatDatePickerDate from "src/utils/formatDatePickerDate";

interface IPageRouteParams {
    path: string;
    id: any;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    instance?: any;
    currentUser?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    appName?: string;
    isSmallViewMode?: boolean;
    dispatch?: any;
    isSupplier?: boolean;
}

interface IState {
    isLoading: boolean;
    isLoadingContent: boolean;
    isLoadingPopup: boolean;
    item?: any;
    saveError?: any;
    showAllTags?: boolean;
    fileCategories?: any;
    displayContactPersonModal: boolean;
    displayFileModal: boolean;
    displayFileUploadPopup: boolean;
    newFile?: any;
    spintrUserKey: number;
    supplierContactPerson?: Spintr.ISupplierContactPerson;
    fileCategoryId: number;
    selectedPivotKey: string;
    roles: any[];
    messages: Spintr.ISupplierMessage[];
    instructions?: string;
    displayMessageModal?: boolean;
    displayedMessage?: Spintr.ISupplierMessage;
    isEditingMessage?: boolean;
    isSavingMessage?: boolean;
    showArchivedFiles?: boolean;
    filesColumnId: string;
    filesIsAscending: boolean;
}

class SupplierView extends Component<IProps, IState> {
    private draggedItem: any = undefined;

    private readonly messageColumns = [{
        fieldName: "subject",
        name: localize("Titel"),
        onRender: (item: Spintr.ISupplierMessage) => (
            <a
                href="#open-message"
                onClick={(ev) => {
                    ev.preventDefault();

                    this.onMessageClick(item);
                }}
            >
                {item.subject}
            </a>
        )
    }, {
        fieldName: "text",
        minWidth: 320,
        name: localize("Text"),
    }, {
        name: localize("BifogadFil"),
        fieldName: "file",
        minWidth: 150,
        onRender: (item) => {
            if (!item.file) {
                return null;
            }

            return (
                <a href={"/api/v1/servefile/" + item.file.id} target="_blank">
                    {item.file.name}
                </a>
            )
        }
    }, {
        fieldName: "createDate",
        minWidth: 200,
        name: localize("Datum"),
        onRender: (item: Spintr.ISupplierMessage) => (
            <div>
                {moment(item.createDate).format("yyyy-MM-DD HH:mm")}
            </div>
        )
    }];

    private fileListRef = React.createRef<SpintrList>();
    private messageListRef = React.createRef<SpintrList>();

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isLoadingContent: true,
            isLoadingPopup: false,
            displayContactPersonModal: false,
            displayFileModal: false,
            displayFileUploadPopup: false,
            spintrUserKey: 0,
            fileCategoryId: 0,
            supplierContactPerson: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                text: "",
                imageUrl: "",
                roleId: 0,
                roles: [],
            },
            selectedPivotKey: this.props.isSupplier ? "contactPersons" : "information",
            roles: [],
            messages: [],
            filesColumnId: "index",
            filesIsAscending: true
        };

        /* Message methods */
        this.fetchMessages = this.fetchMessages.bind(this);
        this.onDismissMessage = this.onDismissMessage.bind(this);
        this.onMessageClick = this.onMessageClick.bind(this);
        this.onMessageFieldChange = this.onMessageFieldChange.bind(this);
        this.openMessageDismissWarning = this.openMessageDismissWarning.bind(this);
        this.saveMessage = this.saveMessage.bind(this);
    }

    public componentDidMount() {
        this.props.dispatch(setUseDocumentWidthMode(true));

        this.fetchItem();
        this.fetchRoles();
        this.fetchFileCategories();

        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
    }

    public componentWillUnmount() {
        this.props.dispatch(setUseDocumentWidthMode(false));
    }

    renderContent() {
        if (this.state.isLoadingContent) {
            return (
                <div className="SupplierView-Content">
                    <Loader />
                </div>
            )
        }

        return (
            <div className="SupplierView-Content">
                {this.state.selectedPivotKey === "information" && this.renderInformationTab()}
                {this.state.selectedPivotKey === "contactPersons" && this.renderContactPersonsTab()}
                {this.state.selectedPivotKey === "files" && this.renderFilesTab()}
                {this.state.selectedPivotKey === "messages" && this.renderMessagesTab()}
                {this.state.selectedPivotKey === "supplier-files" && this.renderExternalFilesTab()}
            </div>
        )
    }

    renderInstructionsModal() {
        if (!this.state.instructions) {
            return null;
        }

        return (
            <Modal
                className="spintr-modal uppliersFilesViewPopup modalWithPopupHeader"
                isOpen={!!this.state.instructions}
                onDismiss={() => {
                    this.setState({
                        instructions: undefined,
                    });
                }}
            >
                <PopupHeader
                    text={localize("INSTRUCTIONS")}
                    onClose={() => {
                        this.setState({
                            instructions: undefined,
                        });
                    }} />
                <div>
                    <Label>{this.state.instructions}</Label>
                </div>
            </Modal>
        )
    }

    renderMessagePopup() {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader supplier-message-modal"
                isOpen={this.state.displayMessageModal && !!this.state.displayedMessage}
                onDismiss={this.state.isEditingMessage
                    ? this.openMessageDismissWarning.bind(this)
                    : this.onDismissMessage.bind(this)
                }
            >
                <PopupHeader
                    text={
                        this.state.isEditingMessage
                            ? localize("chat_createmsg")
                            : localize("Meddelande")
                    }
                    onClose={this.state.isEditingMessage
                        ? this.openMessageDismissWarning.bind(this)
                        : this.onDismissMessage.bind(this)}
                />
                    <div>
                        {this.state.isEditingMessage
                            ? (
                                <FormControl>
                                    <TextField
                                        label={localize("Amne")}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                        onChange={this.onMessageFieldChange.bind(this)}
                                        value={this.state.displayedMessage?.subject || ""}
                                        name="subject"
                                    />
                                </FormControl>
                            )
                            : (
                                <Label
                                    as="div"
                                    className="title"
                                    size="h4"
                                    weight="medium"
                                >
                                    {this.state.displayedMessage?.subject}
                                </Label>
                            )}
                        {!this.state.isEditingMessage && (
                        <div className="messageInfo">
                            <Label
                                as="div"
                                className="date"
                                size="body-2"
                                color="visageGray3"
                            >
                                {localize("Skapad") + ": " + moment(this.state.displayedMessage?.createDate).format("LLL")}
                            </Label>
                            {!this.state.isEditingMessage && !!this.state.displayedMessage?.file && (
                                <Label
                                    size="body-2"
                                    color="visageGray3">
                                    {localize("BifogadFil") + ": "}
                                    <a href={"/api/v1/servefile/" + this.state.displayedMessage?.file.id} target="_blank">
                                        {this.state.displayedMessage?.file.name}
                                    </a>
                                </Label>
                            )}
                        </div>
                        )}
                        {this.state.isEditingMessage
                            ? (
                                <FormControl>
                                    <TextField
                                        label={localize("Text")}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                        multiline={true}
                                        style={{
                                            minHeight: 100
                                        }}
                                        name="text"
                                        onChange={this.onMessageFieldChange.bind(this)}
                                        value={this.state.displayedMessage?.text || ""}
                                    />
                                </FormControl>
                            )
                            : (
                                <Label
                                    as="div"
                                    className="text"
                                    size="body-1"
                                >
                                    {this.state.displayedMessage?.text}
                                </Label>
                            )
                        }
                    </div>
            </Modal>
        )
    }

    public render() {
        if (
            this.state.isLoading ||
            this.state.fileCategories === undefined ||
            !this.state.item
        ) {
            return <Loader />;
        }

        let tags = this.state.item.tags;
        let displayShowAllTagsButton = false;

        if (!this.state.showAllTags && tags.length > 4) {
            tags = tags.slice(0, 4);
            displayShowAllTagsButton = true;
        }

        return (
            <div className="SupplierView">
                <Helmet>
                    <title>{!!this.props.appName ? localize(this.props.appName) : localize("Foretagskatalogen")}</title>
                </Helmet>
                <div className="SupplierHeader">
                    {
                        !this.props.isSupplier && (
                            <div className="breadcrumbs-wrapper">
                                <SuppliersBreadcrumbs item={this.state.item} />
                            </div>
                        )
                    }
                    {this.state.item.imageUrl && (
                        <div className="SupplierHeader-Image">
                            <ContentImageViewerAndSelector
                                imageUrl={this.state.item.imageUrl}
                                editMode={false}
                                square={true}
                            />
                        </div>
                    )}

                    <div className="SupplierHeader-Content">
                        <PageHeader title={this.state.item.name} />
                        {!this.props.isSupplier && (
                            <div className={"SupplierTags"}>
                                {this.renderSupplierTagButton(() => {
                                    this.props.history.push({
                                        pathname: "/suppliers",
                                        search: "?categoryId=" + this.state.item.categoryId,
                                    });
                                }, this.state.item.categoryName)}
                                {tags.map((t) =>
                                    this.renderSupplierTagButton(
                                        () => {
                                            this.props.history.push({
                                                pathname: `/suppliers`,
                                                search: "?tagId=" + t.id,
                                            });
                                        },
                                        t.name,
                                        t.id
                                    )
                                )}
                                {displayShowAllTagsButton &&
                                    this.renderSupplierTagButton(() => {
                                        this.setState({
                                            showAllTags: true,
                                            spintrUserKey: this.state.spintrUserKey + 1,
                                        });
                                    }, localize("VisaAlla"))}
                            </div>
                        )}
                    </div>
                    <Pivot
                        overflowBehavior="menu"
                        selectedKey={this.state.selectedPivotKey}
                        onLinkClick={(item) => {
                            this.setState({ selectedPivotKey: item.props.itemKey });
                        }}
                    >
                        <PivotItem headerText={localize("Information")} itemKey="information" />
                        <PivotItem headerText={localize("Kontaktpersoner")} itemKey="contactPersons" />
                        {
                            !this.props.isSupplier && (
                                <PivotItem headerText={localize("AvtalOchDokument")} itemKey="files" />
                            )
                        }
                        {
                            (this.props.isSupplier) && (
                                <PivotItem headerText={localize("Meddelanden")} itemKey="messages" />
                            )
                        }
                        {
                            (this.props.isSupplier) && (
                                <PivotItem headerText={localize("SUPPLIER_FILES")} itemKey="supplier-files" />
                            )
                        }
                    </Pivot>
                </div>
                {this.renderInstructionsModal()}
                {this.renderContent()}
                {this.renderMessagePopup()}
                <Modal
                    className="spintr-modal modalWithPopupHeader"
                    isOpen={this.state.displayFileModal}
                    onDismiss={() => {
                        this.setState({
                            displayFileModal: false,
                        });
                    }}
                >
                    <PopupHeader
                        text={localize(this.state.newFile?.file?.id ? "Redigera" : "LaddaUpp")}
                        onClose={() => {
                            this.setState({
                                displayFileModal: false,
                                displayFileUploadPopup: false,
                                newFile: null,
                            });
                        }} />
                    <div>
                        {!this.state.newFile?.file?.id && (
                            <FormControl>
                                {!this.state.newFile?.file && (
                                    <PrimaryButton
                                        onClick={() => {
                                            this.setState({
                                                displayFileUploadPopup: true,
                                            });
                                        }}
                                    >
                                        {localize("ValjFil")}
                                    </PrimaryButton>
                                )}
                                {this.state.newFile?.file && (
                                    <div>
                                        <Label>{localize("SELECTED_FILE") + ": " + this.state.newFile.file.name}</Label>
                                        <FormControl>
                                            <PrimaryButton
                                                style={{ marginTop: Style.getSpacingStr(3) }}
                                                onClick={() => {
                                                    this.setState({
                                                        newFile: {
                                                            ...this.state.newFile,
                                                            file: null,
                                                        },
                                                    });
                                                }}
                                            >
                                                {localize("TaBortFilen")}
                                            </PrimaryButton>
                                        </FormControl>
                                    </div>
                                )}
                                {this.state.displayFileUploadPopup && (
                                    <FileSelector
                                        onClose={() => {
                                            this.setState({
                                                displayFileUploadPopup: false,
                                                displayFileModal: false,
                                            });
                                        }}
                                        onSelect={(data) => {
                                            this.setState({
                                                displayFileUploadPopup: false,
                                                newFile: {
                                                    file: data[0],
                                                    targets: [],
                                                    fileCategoryId: this.state.fileCategories && this.state.fileCategories.length > 0 ?
                                                        this.state.fileCategories[0].id :
                                                        0,
                                                },
                                            });
                                        }}
                                        allowMultipleFiles={false}
                                        handleUploadSeparately
                                        sourcesToDisplay={[0]}
                                        fileUploadType={9}
                                    />
                                )}
                            </FormControl>
                        )}
                        {this.state.newFile?.file && this.state.fileCategories?.length > 0 && (
                            <FormControl>
                                <Dropdown
                                    required
                                    aria-required
                                    label={localize("Filkategori")}
                                    selectedKey={this.state.newFile.fileCategoryId}
                                    onChange={(_e, v) => {
                                        this.setState((state) => ({
                                            newFile: {
                                                ...state.newFile,
                                                fileCategoryId: Number(v.key),
                                            },
                                        }));
                                    }}
                                    options={this.state.fileCategories.map((c) => {
                                        return {
                                            key: c.id,
                                            text: c.name,
                                        };
                                    })}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                        )}
                        {this.state.newFile?.file && (
                            <FormControl>
                                <FormTokenizedObjectInput
                                    aria-label={localize("SokOchLaggTill")}
                                    items={this.state.newFile.targets.map((tag) => {
                                        return {
                                            ...tag,
                                            text: tag.name || tag.text,
                                            key: tag.id || tag.key,
                                        };
                                    })}
                                    label={localize("SynligFor")}
                                    types={[
                                        SpintrTypes.UberType.TargetGroup,
                                        SpintrTypes.UberType.Role,
                                        SpintrTypes.UberType.Department,
                                        SpintrTypes.UberType.Office,
                                        SpintrTypes.UberType.Group,
                                        ...(this.props.instance.get("enableCompanyLevel")
                                            ? [SpintrTypes.UberType.Company]
                                            : []),
                                    ]}
                                    onChange={(targets) => {
                                        this.setState((state) => ({
                                            newFile: {
                                                ...this.state.newFile,
                                                targets: targets.map((t) => {
                                                    return {
                                                        ...t,
                                                        id: t.key,
                                                        text: t.name,
                                                        image: t.imageUrl,
                                                        info: t.subText,
                                                    };
                                                }),
                                            },
                                        }));
                                    }}
                                    showAllWhenEmpty
                                />
                            </FormControl>
                        )}
                        {this.state.newFile && !!this.state.newFile.file  && (
                            <FormControl label={localize("ARCHIVE_DATE")}>
                                <FormControl>
                                    <Checkbox
                                        checked={!!this.state.newFile?.archiveDate}
                                        label={localize("ARCHIVE_DATE_CHECKBOX")}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            this.setState({
                                                newFile: {
                                                    ...this.state.newFile,
                                                    archiveDate: checked ? new Date() : undefined
                                                },
                                            });
                                        }} />
                                </FormControl>
                                {
                                    !!this.state.newFile?.archiveDate && (
                                        <DatePicker
                                            firstDayOfWeek={DayOfWeek.Monday}
                                            strings={getDatePickerLanguageStrings()}
                                            formatDate={formatDatePickerDate}
                                            placeholder={localize("ValjDatum")}
                                            ariaLabel={localize("ValjDatum")}
                                            value={this.state.newFile?.archiveDate}
                                            onSelectDate={(date: Date) => {
                                                this.setState((prevState) => ({
                                                    newFile: {
                                                        ...prevState.newFile,
                                                        archiveDate: date,
                                                    },
                                                }))
                                            }}
                                        />
                                    )
                                }
                            </FormControl>
                        )}
                        {this.state.newFile?.file && (
                            <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                <DefaultButton
                                    onClick={() => {
                                        this.setState({
                                            displayFileModal: false,
                                            displayFileUploadPopup: false,
                                            newFile: null,
                                        });
                                    }}
                                    text={localize("Avbryt")}
                                />
                                <PrimaryButton
                                    onClick={this.state.newFile?.file?.id ? this.saveFile : this.uploadNewFile}
                                    text={localize(this.state.newFile?.file?.id ? "Spara" : "LaddaUpp")}
                                />
                            </Stack>
                        )}
                    </div>
                </Modal>
                <Modal
                    className="spintr-modal SupplierView-AddContact modalWithPopupHeader"
                    isOpen={this.state.displayContactPersonModal}
                    onDismiss={this.tryCloseContactPersonModal}
                >
                    <PopupHeader
                        text={localize(this.state.supplierContactPerson.id ? "RedigeraKontaktperson" : "LaggTillKontaktperson")}
                        onClose={this.tryCloseContactPersonModal} />
                    {this.state.isLoadingPopup && <Loader />}
                    {!this.state.isLoadingPopup && (
                        <div>
                            <div className="SupplierView-Image">
                                <ContentImageViewerAndSelector
                                    editMode
                                    height={128}
                                    placeholderString={localize("ValjBild")}
                                    onChange={this.onChangeContactPersonImage}
                                    imageUrl={this.state.supplierContactPerson.imageUrl}
                                />
                            </div>

                            <form>
                                <Stack
                                    horizontal={!this.props.isSmallViewMode}
                                    horizontalAlign="space-between"
                                    tokens={{ childrenGap: Style.getSpacing(3) }}
                                >
                                    <StackItem grow={1}>
                                        <FormControl>
                                            <TextField
                                                label={localize("Fornamn")}
                                                onChange={this.onChangeContactPerson}
                                                value={this.state.supplierContactPerson.firstName}
                                                name="firstName"
                                            />
                                        </FormControl>
                                    </StackItem>
                                    <StackItem grow={2}>
                                        <FormControl>
                                            <TextField
                                                label={localize("Efternamn")}
                                                onChange={this.onChangeContactPerson}
                                                value={this.state.supplierContactPerson.lastName}
                                                name="lastName"
                                            />
                                        </FormControl>
                                    </StackItem>
                                </Stack>
                                <Stack
                                    horizontal={!this.props.isSmallViewMode}
                                    horizontalAlign="space-between"
                                    tokens={{ childrenGap: Style.getSpacing(3) }}
                                >
                                    <StackItem grow={1}>
                                        <FormControl>
                                            <TextField
                                                label={localize("Telefonnummer")}
                                                onChange={this.onChangeContactPerson}
                                                value={this.state.supplierContactPerson.phoneNumber}
                                                name="phoneNumber"
                                            />
                                        </FormControl>
                                    </StackItem>
                                    <StackItem grow={2}>
                                        <FormControl>
                                            <TextField
                                                label={localize("Epost")}
                                                onChange={this.onChangeContactPerson}
                                                value={this.state.supplierContactPerson.email}
                                                name="email"
                                            />
                                        </FormControl>
                                    </StackItem>
                                </Stack>
                                <FormControl>
                                    <Checkbox
                                        label={localize("SUPPLIER_CAN_SIGN_IN")}
                                        checked={this.state.supplierContactPerson.canSignIn}
                                        onChange={(ev, checked) => {
                                            this.setState((state) => ({
                                                ...state,
                                                supplierContactPerson: {
                                                    ...state.supplierContactPerson,
                                                    canSignIn: checked,
                                                },
                                            }));
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Dropdown
                                        options={this.state.roles}
                                        label={localize("Roll")}
                                        selectedKeys={this.state.supplierContactPerson.roles.map(r => r.id)}
                                        onChange={this.onChangeContactPersonRole}
                                        multiSelect={true}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        label={localize("AnmarkningKommentarRegion")}
                                        onChange={this.onChangeContactPerson}
                                        value={this.state.supplierContactPerson.text}
                                        name="text"
                                    />
                                </FormControl>
                            </form>
                            <Stack
                                horizontal={true}
                                horizontalAlign={this.state.supplierContactPerson.id ? "space-between" : "end"}
                            >
                                {this.state.supplierContactPerson.id && (
                                    <DefaultButton onClick={this.onDeleteContactPerson} text={localize("TaBort")} />
                                )}
                                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                    <DefaultButton onClick={this.tryCloseContactPersonModal} text={localize("Avbryt")} />
                                    <PrimaryButton onClick={this.saveContactPerson} text={localize("Spara")} />
                                </Stack>
                            </Stack>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }

    private tryCloseContactPersonModal = () => {
        if (isAnythingDirty()) {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    message: localize("UnsavedChangesWarning"),
                    onConfirm: this.closeContactPersonModal,
                })
            );
        } else {
            this.closeContactPersonModal();
        }
    };

    private closeContactPersonModal = () => {
        this.setState({
            displayContactPersonModal: false,
        });

        // modal has a 267 ms long animation, clear data after that
        setTimeout(this.clearContactPerson, 267);
    };

    private clearContactPerson = () => {
        this.setState({
            supplierContactPerson: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                text: "",
                imageUrl: "",
                roleId: 0,
                roles: [],
            },
        });
    };

    private onChangeContactPerson = (e, v) => {
        const name = e.target.name;
        this.setState((prevState) => ({
            supplierContactPerson: {
                ...prevState.supplierContactPerson,
                [name]: v,
            },
        }));
    };

    private onChangeContactPersonRole = (e, v) => {
        let roles = [
            ...(this.state.supplierContactPerson?.roles || [])
        ];

        if (v.selected) {
            roles.push({
                id: v.key,
                name: v.text
            });
        } else {
            roles = roles.filter((r: any) => r.id !== v.key);
        }

        this.setState((prevState) => ({
            supplierContactPerson: {
                ...prevState.supplierContactPerson,
                roles,
            },
        }));
    };

    private onChangeContactPersonImage = (result) => {
        this.setState((prevState) => ({
            supplierContactPerson: {
                ...prevState.supplierContactPerson,
                imageTicket: result.ticket,
            },
        }));
    };

    private saveContactPerson = async () => {
        this.setState({
            isLoadingPopup: true
        }, () => {
            api.post(`/api/suppliers/${this.state.item.id}/contactpersons`, this.state.supplierContactPerson).then(() => {
                this.setState({
                    isLoadingPopup: false
                });

                this.fetchItem();
                this.closeContactPersonModal();
            }).catch(() => {
                this.setState({
                    isLoadingPopup: false
                });
            })
        });
    };

    private onDeleteContactPerson = () => {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                message: localize("ArDuSakerAttDuVillUtforaDennaAtgard"),
                onConfirm: this.deleteContactPerson,
            })
        );
    };

    private deleteContactPerson = async () => {
        await api.put(
            `/api/suppliers/${this.state.item.id}/contactpersons/${this.state.supplierContactPerson.id}/toggledelete`
        );

        this.fetchItem();
        this.closeContactPersonModal();
    };

    private handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
            isLoadingContent: false
        });
    };

    private renderContactPersons = (itemArray) => {
        return (
            <div className="SupplierUsers">
                {itemArray.map((u) => {
                    return (
                        <div className="SupplierUser" key={u.id}>
                            <SpintrUser
                                id={u.id}
                                key={u.id}
                                imageUrl={u.image}
                                name={u.name}
                                subText={u.departmentName}
                                size={42}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    private openRemoveFileModal = (item) => {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                message: localize("ArDuSakerPaAttDuVillTaBort") + " " + item.name + "?",
                onConfirm: () => {
                    this.setState(
                        {
                            isLoadingContent: true,
                        },
                        () =>
                            api.delete("/api/objects/" + item.id).then(() => {
                                this.fetchItem();
                            })
                    );
                },
            })
        );
    };

    private fetchItem = () => {
        const { id } = this.props.match.params;

        this.setState({
            isLoadingContent: true
        }, () => {
            api.get(`/api/suppliers/${id}`)
                .then((response) => {
                    const item = response.data;

                    if (item.files) {
                        for (let file of item.files) {
                            if (!!file.archiveDate) {
                                file.archiveDate = new Date(file.archiveDate);
                            }
                        }
                    }

                    this.setState({
                        item,
                        isLoading: false,
                        isLoadingContent: false
                    });
                })
                .catch(this.handleCatch.bind(this));
        });
    };

    private fetchFileCategories = () => {
        api.get("/api/suppliers/filecategories?includeDeleted=false&skip=0&take=999")
            .then((response) => {
                if (!response.data.items || response.data.items.length === 0) {
                    api.post("/api/suppliers/filecategories/0", {
                        id: 0,
                        name: localize("OvrigaFiler"),
                    })
                        .then(() => {
                            this.fetchFileCategories();
                        })
                        .catch(this.handleCatch.bind(this));
                } else {
                    this.setState({
                        fileCategories: response.data.items,
                    });
                }
            })
            .catch(this.handleCatch.bind(this));
    };

    private fetchRoles = async () => {
        try {
            const response = await api.get("/api/suppliers/roles");

            const roles = response.data.map((r) => ({
                key: r.id,
                text: r.name,
            }));

            this.setState({
                roles,
            });
        } catch (e) {
            this.handleCatch(e);
        }
    };

    private saveFile = () => {
        this.setState(
            {
                isLoadingContent: true
            },
            () => {
                var model = {
                    id: this.state.newFile.id,
                    categoryId: this.state.newFile.fileCategoryId,
                    targets: this.state.newFile.targets.map((t) => t.id || t.key).join(","),
                    archiveDate: this.state.newFile.archiveDate
                };

                api.put(`/api/suppliers/files/${this.state.newFile.id}`, model).then(() => {
                    this.setState(
                        {
                            displayFileModal: false,
                            newFile: null,
                        },
                        this.fetchItem
                    );
                });
            }
        );
    };

    private uploadNewFile = () => {
        this.setState(
            {
                isLoadingContent: true,
            },
            () => {
                let body = new FormData();

                let targets = "";

                if (this.state.newFile.targets) {
                    for (let t of this.state.newFile.targets) {
                        targets += t.key + ",";
                    }
                }

                body.append("type", "9");
                body.append("file", this.state.newFile.file, this.state.newFile.file.name);
                body.append("ticket", new Date().getTime().toString() + uniqueId());
                body.append("supplierId", this.state.item.id.toString());
                body.append("supplierFileCategoryId", this.state.newFile.fileCategoryId.toString());
                body.append("supplierFileTargets", targets);

                if (this.state.newFile.archiveDate) {
                    const d = Date.parse(this.state.newFile.archiveDate.toISOString()).toString();
                    body.append("archiveDate", d);
                }

                api.post("/api/uploads", body).then(() => {
                    this.setState(
                        {
                            displayFileModal: false,
                            displayFileUploadPopup: false,
                            newFile: null,
                        },
                        this.fetchItem
                    );
                }).catch(() => {});
            }
        );
    };

    private renderInformationTab = () => (
        <>
            {(this.props.isAdmin || this.props.isEditor) && (
                <Stack className="SupplierView-Info-Header" horizontal horizontalAlign="end">
                    <Label size="body-2" weight="medium">
                        <Link to={`/suppliers/${this.state.item.id}/edit`}>{localize("RedigeraLeverantor")}</Link>
                    </Label>
                </Stack>
            )}
            <div className="SupplierView-Info general-row-break ">
                <div className="SupplierView-Info-Categories">
                    <div className="SupplierView-Info-Category">
                        <Label size="h5" weight="medium">
                            {localize("Overgripande")}
                        </Label>
                        <div className="SupplierView-Info-Grid">
                            {this.state.item.organisationNumber && (
                                <div>
                                    {this.renderInfoTitle(localize("Organisationsnummer"))}
                                    <Label size="body-2">{this.state.item.organisationNumber}</Label>
                                </div>
                            )}
                            <div>
                                {this.renderInfoTitle(localize("SUPPLIERS_RESPONSIBLE"))}
                                <div>{this.renderContactPersons(this.state.item.users)}</div>
                            </div>
                            {this.state.item.articleManagers?.length > 0 && (
                                <div>
                                    {this.renderInfoTitle(localize("Artikelansvarig"))}
                                    <div>{this.renderContactPersons(this.state.item.articleManagers)}</div>
                                </div>
                            )}

                            {(this.state.item.addressTitle ||
                                this.state.item.address ||
                                this.state.item.zipCode ||
                                this.state.item.city) && (
                                    <div>
                                        {this.renderInfoTitle(localize("Postadress"))}
                                        {this.state.item.addressTitle && (
                                            <Label as="div" size="body-2">
                                                {this.state.item.addressTitle}
                                            </Label>
                                        )}
                                        {this.state.item.address && (
                                            <Label as="div" size="body-2">
                                                {this.state.item.address}
                                            </Label>
                                        )}
                                        {(this.state.item.city || this.state.item.zipCode) && (
                                            <Label size="body-2">
                                                {(this.state.item.zipCode ? this.state.item.zipCode + " " : "") +
                                                    this.state.item.city}
                                            </Label>
                                        )}
                                    </div>
                                )}
                            {this.state.item.text && (
                                <div>
                                    {this.renderInfoTitle(localize("Fritext"))}
                                    <Label size="body-2">{this.state.item.text}</Label>
                                </div>
                            )}
                        </div>
                    </div>

                    {(this.state.item.phone ||
                        this.state.item.email ||
                        this.state.item.fax ||
                        this.state.item.website) && (
                            <div className="SupplierView-Info-Category">
                                <Label size="h5" weight="medium">
                                    {localize("Kontaktuppgifter")}
                                </Label>

                                <div className="SupplierView-Info-Grid">
                                    {this.state.item.phone && (
                                        <div>
                                            {this.renderInfoTitle(localize("Telefonnummer"))}
                                            <Label as="div" size="body-2" className="raw-row-break">
                                                <a className={"linkFGColor"} href={`tel:${this.state.item.phone}`}>
                                                    {this.state.item.phone}
                                                </a>
                                            </Label>
                                        </div>
                                    )}

                                    {this.state.item.email && (
                                        <div>
                                            {this.renderInfoTitle(localize("Email"))}
                                            <Label as="div" size="body-2">
                                                <a className={"linkFGColor"} href={`mailto:${this.state.item.email}`}>
                                                    {this.state.item.email}
                                                </a>
                                            </Label>
                                        </div>
                                    )}
                                    {this.state.item.fax && (
                                        <div>
                                            {this.renderInfoTitle(localize("Fax"))}
                                            <Label as="div" size="body-2">
                                                {this.state.item.fax}
                                            </Label>
                                        </div>
                                    )}

                                    {this.state.item.website && (
                                        <div className="website">
                                            {this.renderInfoTitle(localize("Hemsida"))}
                                            <Label as="div" size="body-2">
                                                <a className={"linkFGColor"} href={this.state.item.website} target="_blank">
                                                    {this.state.item.website}
                                                </a>
                                            </Label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                    {this.state.item.extraInformation?.length > 0 && (
                        <div className="SupplierView-Info-Category">
                            <Label size="h5" weight="medium">
                                {localize("OvrigInformation")}
                            </Label>

                            <div className="SupplierView-Info-Grid">
                                {this.state.item.extraInformation.map((extraInformation, idx) => (
                                    <div key={idx}>
                                        <div>
                                            {this.renderInfoTitle(extraInformation.name)}
                                            <Label as="div" size="body-2">
                                                {extraInformation.text}
                                            </Label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );

    private renderContactPersonsTab = () => {
        let contacts = (this.state.item?.contactPersons || []).map((item: any) => {
            if (!item.roles || item.roles.length === 0) {
                item.roles.push({
                    id: 0,
                    name: localize("Ovriga"),
                    order: 1000
                });
            }

            return item;
        })

        let groupedContacts = (contacts as Spintr.ISupplierContactPerson[])
            .reduce((groups: any[], row: Spintr.ISupplierContactPerson) => {
                const roles = row.roles || [];
                if (roles.length === 0) {
                    roles.push("");
                }

                roles.forEach((role: any) => {
                    if (groups.every((g: any) => g.key !== role.name)) {
                        groups.push({
                            key: role.name,
                            people: [],
                            ...role,
                        });
                    }

                    const group = groups.find((g: any) => g.key === role.name);
                    group.people.push(row);
                });

                return groups;
            }, []);

        groupedContacts.sort(
            (a, b) => a.order === b.order ? a.id - b.id : a.order - b.order,
        );

        return (
            <>
                {(this.props.isAdmin || this.props.isEditor || this.props.isSupplier) && (
                    <Stack className="SupplierView-Info-Header" horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                        <DefaultButton onClick={() => {
                            const rows = [
                                [localize("Foretag"), localize("Namn"), localize("Roll"), localize("EMAIL_ADDRESS"), localize("PHONE_NUMBER")],
                                ...(this.state.item?.contactPersons || []).map((item: any) => {
                                    const role = (item.roles || []).map(x => x.name).join(", ");

                                    return [
                                        this.state.item.name,
                                        item.firstName + " " + item.lastName,
                                        role,
                                        item.email,
                                        item.phoneNumber,
                                    ];
                                })
                            ];

                            api.post("/api/files/generate-excel-file", rows).then((response) => {
                                const link = document.createElement("a");
                                link.setAttribute("href", response.data);
                                link.setAttribute("target", "_blank");
                                link.style.visibility = "hidden";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }).catch(() => {});
                        }}>
                            {localize("ExporteraLista")}
                        </DefaultButton>
                        <PrimaryButton onClick={(ev) => {
                            ev.preventDefault();

                            this.setState({
                                displayContactPersonModal: true,
                            });
                        }}>
                            {localize("LaggTillKontaktperson")}
                        </PrimaryButton>
                    </Stack>
                )}

                <div className="SupplierView-ContactPersons">
                    {groupedContacts.map((group) => (
                        <div className="role-group" key={group.key}>
                            <Label
                                as="div"
                                className="group-heading"
                                size="body-1"
                                weight="medium"
                            >
                                {group.name}
                            </Label>
                            <div className="people-in-role">
                                {group.people.map((contactPerson: Spintr.ISupplierContactPerson, index) => (
                                    <div className="SupplierView-ContactPerson" key={contactPerson.id}>
                                        <Stack horizontal tokens={{ childrenGap: Style.getSpacing(5) }}>
                                            <SpintrUser
                                                name={`${contactPerson.firstName} ${contactPerson.lastName}`}
                                                imageUrl={contactPerson.imageUrl}
                                                hideText
                                                size={60}
                                            />
                                            <div className="data">
                                                {(this.props.isAdmin || this.props.isEditor || this.props.isSupplier) && (
                                                    <ActionMenu
                                                        categories={[
                                                            {
                                                                items: [
                                                                    {
                                                                        text: localize("Redigera"),
                                                                        onClick: () => {
                                                                            this.setState({
                                                                                displayContactPersonModal: true,
                                                                                supplierContactPerson: contactPerson,
                                                                            });
                                                                        },
                                                                    },
                                                                    {
                                                                        text: localize("TaBort"),
                                                                        onClick: () => {
                                                                            this.setState(
                                                                                {
                                                                                    supplierContactPerson: contactPerson,
                                                                                },
                                                                                this.onDeleteContactPerson
                                                                            );
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        ]}
                                                    />
                                                )}

                                                <Label size="body-1" weight="medium" className="name">
                                                    {contactPerson.firstName} {contactPerson.lastName}
                                                </Label>

                                                {contactPerson.roles && (
                                                    <Label className="subText" size="body-3" color="visageGray3">
                                                        {contactPerson.roles?.map(role => role.name).join(", ")}
                                                    </Label>
                                                )}
                                                {contactPerson.phoneNumber && (
                                                    <Label className="subText" size="body-3" color="visageGray3">
                                                        {contactPerson.phoneNumber}
                                                    </Label>
                                                )}
                                                {contactPerson.email && (
                                                    <Label className="subText" size="body-3" color="visageGray3">
                                                        {contactPerson.email}
                                                    </Label>
                                                )}
                                                {contactPerson.text && (
                                                    <Label className="subText" size="body-3" color="visageGray3">
                                                        {contactPerson.text}
                                                    </Label>
                                                )}
                                            </div>
                                        </Stack>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    private renderMessagesTab(): ReactNode {

        return (
            <>
                <div className="SupplierView-Info">
                    <SpintrList
                        ref={this.messageListRef}
                        columns={this.messageColumns}
                        fetch={this.fetchMessages}
                        orderByColumn="createDate"
                        isDescending
                        onRowClick={this.onMessageClick}
                    // buttons={this.props.isAdmin || this.props.isEditor ? [
                    //     {
                    //         key: "add",
                    //         text: localize("chat_createmsg"),
                    //         onClick: () => {
                    //             this.setState((state) => ({
                    //                 ...state,
                    //                 displayMessageModal: true,
                    //                 displayedMessage: {
                    //                     supplierId: state.item?.id || 0,
                    //                     subject: "",
                    //                     text: "",
                    //                 },
                    //                 isEditingMessage: true,
                    //             }));
                    //         },
                    //         iconProps: { iconName: "Add" },
                    //         className: "commandBarAddButton",
                    //     },
                    // ] : []}
                    />
                </div>
            </>
        )
    }

    private openMessageDismissWarning(): void {
        if (isAnythingDirty()) {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    message: localize("UnsavedChangesWarning"),
                    onConfirm: this.onDismissMessage,
                })
            );
        } else {
            this.onDismissMessage();
        }
    }

    private onDismissMessage(): void {
        this.setState((state) => ({
            ...state,
            displayMessageModal: false,
            displayedMessage: undefined,
            isEditingMessage: undefined,
        }));
    }

    private onMessageClick(item: Spintr.ISupplierMessage): void {
        this.setState((state) => ({
            ...state,
            displayMessageModal: true,
            displayedMessage: item,
        }));
    }

    private onMessageFieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (!event?.target) {
            return;
        }

        const { name, value } = event.target;
        if (["subject", "text"].indexOf(name) === -1) {
            return;
        }

        this.setState((state) => ({
            ...state,
            displayedMessage: {
                ...state.displayedMessage || {
                    subject: "",
                    supplierId: state.item.id,
                    text: "",
                },
                [name]: value,
            }
        }));
    }

    private saveMessage(): void {
        if (this.state.isSavingMessage) {
            return;
        }

        const message = this.state.displayedMessage;

        /* REGION: Validation */

        if (!message) {
            return;
        }

        if (!message.subject?.length || message.subject.length < 3) {
            return;
        }

        if (!message.text?.length || message.text.length < 3) {
            return;
        }

        /* ENDREGION; */

        const isNew = message.id === undefined;
        const supplierId = this.state.item.id;

        this.setState(
            (state) => ({ ...state, isSavingMessage: true }),
            () => {
                const promise = isNew
                    ? api.post(`/api/v1/suppliers/${supplierId}/messages`, message)
                    : api.put(`/api/v1/suppliers/${supplierId}/messages/${message.id}`, message);

                promise.then(() => {
                    if (!!this.messageListRef &&
                        !!this.messageListRef.current) {
                        this.messageListRef.current.reFetch();
                    }

                    this.setState(
                        (state) => ({ ...state, isSavingMessage: false }),
                        () => {
                            this.onDismissMessage();
                        },
                    );
                }).catch(() => {
                    this.setState((state) => ({ ...state, isSavingMessage: false }));
                });
            },
        );
    }

    private async fetchMessages(
        skip: number,
        take: number,
        orderByColumn: string,
        isAscending: boolean,
        searchText: string,
        cancelToken: CancelToken,
        fetchMore: boolean
    ): Promise<any> {
        const { id } = this.props.match.params;

        try {
            const response = await api.get<any>(
                `/api/v1/suppliers/external-messages`, {
                cancelToken,
                params: {
                    isAscending,
                    orderByColumn,
                    searchText,
                    skip,
                    take,
                    supplierId: id
                },
            }
            );

            return {
                data: response.data.items,
                totalCount: response.data.totalCount,
            };
        } catch (_) {
            /* ignored */
        }

        return {
            data: [],
            totalCount: 0,
        };
    }

    private renderExternalFilesTab = () => {
        return (
            <div className="SupplierView-Info">
                <div className="SupplierView-Files">
                    <SpintrList
                        ref={this.fileListRef}
                        fetch={(skip, take, columnId, isAscending, searchQuery) => {
                            return new Promise((resolve, reject) => {
                                const { id } = this.props.match.params;

                                api
                                    .get("/api/v1/suppliers/external-files", {
                                        params: {
                                            orderByColumn: columnId,
                                            includeDeleted: false,
                                            isAscending: isAscending,
                                            searchText: searchQuery,
                                            take: take,
                                            skip: skip,
                                            supplierId: id
                                        },
                                    })
                                    .then((response: any) => {
                                        resolve({
                                            data: response.data.items,
                                            totalCount: response.data.totalCount
                                        });
                                    }).catch(() => {
                                        resolve({
                                            data: [],
                                            totalCount: 0
                                        });
                                    });
                            });
                        }}
                        orderByColumn="index"
                        take={9999}
                        columns={[
                            {
                                name: localize("Fil"),
                                fieldName: "name",
                                minWidth: 250,
                                onRender: (item) => {
                                    return (
                                        <a href={!!item.file ? `/api/servefile/${item.file.id}` : item.url} target="_blank">
                                            {item.displayName ? item.displayName : item.name}
                                        </a>
                                    )
                                },
                            },
                            {
                                name: localize("INSTRUCTIONS"),
                                fieldName: "instructions",
                                minWidth: 150,
                                onRender: (item) => {
                                    if (!item.instructions) {
                                        return null;
                                    }

                                    return (
                                        <UnstyledButton onClick={() => {
                                            this.setState({
                                                instructions: item.instructions
                                            })
                                        }}>
                                            <span className={"linkFGColor"}>
                                                {localize("Visa") + " " + localize("INSTRUCTIONS").toLowerCase()}
                                            </span>
                                        </UnstyledButton>
                                    )
                                },
                            },
                            {
                                name: localize("Datum"),
                                fieldName: "createDate",
                                minWidth: 250,
                                onRender: (item) => (
                                    <span>
                                        {moment(item.createDate).format("LLL")}
                                    </span>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }

    move(id: number, indexChange: number) {
        const files = this.getFiles();
        const insertIndex = files.map(x => x.id).indexOf(id) + indexChange;
        const item = files.find(x => x.id === id);
        const items = files.filter(x => x.id !== id);

        items.splice(insertIndex, 0, item);

        for (let i = 0; i < items.length; i++) {
            items[i].index = i;
        }

        let allFiles = [...items];

        for (let file of this.state.item.files) {
            if (!allFiles.find(x => x.id === file.id)) {
                allFiles.push(file);
            }
        }

        this.setState({
            item: {
                ...this.state.item,
                files: allFiles
            }
        }, () => {
            this.fileListRef.current.applyNewData({
                items,
                totalCount: files.length
            });

            api.post("/api/v1/suppliers/order-files/1", items.map(x => x.id)).then(() => {}).catch(() => {});
        });
    }

    getFiles = (searchQuery?: string) => {
        let files = this.state.item?.files.map((file) => ({
            ...file,
            formattedDate: moment(file.createDate).format("L"),
            categoryName: this.state.fileCategories.find((c) => c.id === file.categoryId)?.name,
            targetsString: file.targets?.length > 0 ? file.targets.map((t) => t.name).join(", ") : localize("Alla"),
        }));

        if (this.state.fileCategoryId > 0) {
            files = files.filter((f) => f.categoryId === this.state.fileCategoryId);
        }

        const now = new Date();

        if (this.state.showArchivedFiles) {
            files = files.filter((f: any) =>
                !!f.archiveDate && f.archiveDate < now
            );
        } else {
            files = files.filter((f: any) =>
                !f.archiveDate || f.archiveDate > now
            );
        }

        if (searchQuery) {
            files = files.filter((file) =>
                file.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        files = files.sort((a, b) => {
            if (a[this.state.filesColumnId] < b[this.state.filesColumnId]) {
                return this.state.filesIsAscending ? -1 : 1;
            }

            if (a[this.state.filesColumnId] > b[this.state.filesColumnId]) {
                return this.state.filesIsAscending ? 1 : -1;
            }

            return 0;
        });

        return files;
    }

    private renderFilesTab = () => {
        const categories = [];

        for (let c of this.state.fileCategories) {
            const files = [];

            for (let f of this.state.item.files) {
                if (f.categoryId === c.id) {
                    files.push(f);
                }
            }

            if (files.length > 0) {
                categories.push({
                    ...c,
                    files,
                });
            }
        }

        categories.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return (
            <div className="SupplierView-Info">
                <div className="SupplierView-Files">
                    <SpintrList
                        ref={this.fileListRef}
                        dragDropEvents={{
                            canDrag: (item: any) => {
                                return this.props.isAdmin || this.props.isEditor;
                            },
                            canDrop: (dropContext, dragContext) => {
                                return this.props.isAdmin || this.props.isEditor;
                            },
                            onDragStart: (item: any) => {
                                this.draggedItem = item;
                            },
                            onDragEnd: (item: any) => {
                                this.draggedItem = undefined;
                            },
                            onDrop: (targetItem?: any, event?: any) => {
                                if (targetItem.id === this.draggedItem.id) {
                                    return;
                                }

                                const files = this.getFiles();
                                const insertIndex = files.map(x => x.id).indexOf(targetItem.id)
                                const items = files.filter(x => x.id !== this.draggedItem.id);

                                items.splice(insertIndex, 0, this.draggedItem);

                                for (let i = 0; i < items.length; i++) {
                                    items[i].index = i;
                                }

                                let allFiles = [...items];

                                for (let file of this.state.item.files) {
                                    if (!allFiles.find(x => x.id === file.id)) {
                                        allFiles.push(file);
                                    }
                                }

                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        files: allFiles
                                    }
                                }, () => {
                                    this.fileListRef.current.applyNewData({
                                        items,
                                        totalCount: files.length
                                    });

                                    api.post("/api/v1/suppliers/order-files/1", this.state.item?.files.map(x => x.id)).then(() => {}).catch(() => {});
                                });
                            },
                        }}
                        fetch={(skip, take, columnId, isAscending, searchQuery) => {
                            return new Promise((resolve, reject) => {
                                this.setState({
                                    filesColumnId: columnId,
                                    filesIsAscending: isAscending
                                }, () => {
                                    const files = this.getFiles(searchQuery);

                                    resolve({ data: files, totalCount: files.length });
                                });
                            });
                        }}
                        columns={[
                            {
                                name: localize("Namn"),
                                fieldName: "name",
                                key: "name",
                                onRender: (item) => (
                                    <a href={`/api/servefile/${item.id}`} target="_blank">
                                        {item.name}
                                    </a>
                                ),
                            },
                            ...(this.state.showArchivedFiles ? [{
                                name: localize("ARCHIVE_DATE"),
                                fieldName: "archiveDate",
                                key: "archiveDate",
                                minWidth: 200,
                                onRender: (item) => {
                                    if (!item.archiveDate) {
                                        return null;
                                    }

                                    return (
                                        <span>
                                            {moment(item.archiveDate).format("L")}
                                        </span>
                                    )
                                },
                            }] : []),
                            {
                                name: localize("SynligFor"),
                                minWidth: 240,
                                fieldName: "targetsString",
                                key: "targetsString",
                            },
                            {
                                name: localize("Kategori"),
                                fieldName: "categoryName",
                                key: "categoryName",
                            },
                            {
                                name: localize("Skapad"),
                                fieldName: "formattedDate",
                                key: "formattedDate",
                            },
                            ...(this.props.isAdmin || this.props.isEditor
                                ? [
                                    {
                                        name: "",
                                        minWidth: 25,
                                        maxWidth: 25,
                                        onRender: (item) => {
                                            const itemindex = this.state.item.files.map(x => x.id).indexOf(item.id);
                                            const isFirst = itemindex === 0;
                                            const isLast = itemindex === (this.state.item.files.length - 1);

                                            let items = [];

                                            if (!isFirst) {
                                                items.push({
                                                    text: localize("FlyttaUpp"),
                                                    onClick: () => {
                                                        this.move(item.id, -1);
                                                    },
                                                });
                                            }

                                            if (!isLast) {
                                                items.push({
                                                    text: localize("FlyttaNer"),
                                                    onClick: () => {
                                                        this.move(item.id, 1);
                                                    },
                                                });
                                            }

                                            items.push({
                                                text: localize("Redigera"),
                                                onClick: () => {
                                                    this.setState({
                                                        displayFileModal: true,
                                                        newFile: {
                                                            ...item,
                                                            fileCategoryId: item.categoryId,
                                                            file: item,
                                                        },
                                                    });
                                                },
                                            });

                                            items.push({
                                                text: localize("TaBort"),
                                                onClick: () => this.openRemoveFileModal(item),
                                            });

                                            return (
                                                <ActionMenu
                                                    categories={[
                                                        {
                                                            items
                                                        },
                                                    ]}
                                                />
                                            )
                                        }
                                    },
                                ]
                                : []),
                        ]}
                        buttons={[
                            ...(this.state.filesColumnId !== "index" ? [{
                                key: "sortReset",
                                text: localize("RESET_SORT"),
                                onClick: () => {
                                    this.setState({
                                        filesColumnId: "index"
                                    }, () => {
                                        this.fileListRef.current.reset();
                                    });
                                },
                            }] : []),
                            {
                                key: "options",
                                text: localize("Alternativ"),
                                subMenuProps: {
                                    className: "displayOptions",
                                    items: [
                                        {
                                            key: "showArchived",
                                            text: localize("Visa") + " " + (this.state.showArchivedFiles ? localize("Aktuella") : localize("Arkiverade")).toLowerCase(),
                                            onClick: () => {
                                                this.setState({
                                                    showArchivedFiles: !this.state.showArchivedFiles
                                                }, () => {
                                                    this.fileListRef.current.fetch();
                                                });
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                key: "categories",
                                text: `${localize("Kategorier")}${this.state.fileCategoryId > 0
                                    ? ` - ${categories.find((category) => category.id === this.state.fileCategoryId)
                                        .name
                                    }`
                                    : ``
                                    }`,
                                subMenuProps: {
                                    items: [
                                        ...categories.map((c) => ({
                                            key: c.id,
                                            text: c.name,
                                            onClick: () => {
                                                this.setState(
                                                    {
                                                        fileCategoryId: c.id === this.state.fileCategoryId ? 0 : c.id,
                                                    },
                                                    this.fileListRef.current.fetch
                                                );
                                            },
                                            ...(this.state.fileCategoryId === c.id
                                                ? {
                                                    iconProps: {
                                                        iconName: "CheckMark",
                                                    },
                                                }
                                                : {}),
                                        })),
                                        {
                                            key: "all",
                                            text: localize("VisaAlla"),
                                            onClick: () => {
                                                this.setState({ fileCategoryId: 0 }, this.fileListRef.current.fetch);
                                            },
                                            ...(this.state.fileCategoryId === 0
                                                ? {
                                                    iconProps: {
                                                        iconName: "CheckMark",
                                                    },
                                                }
                                                : {}),
                                        },
                                    ],
                                },
                            },
                            ...(this.props.isAdmin || this.props.isEditor
                                ? [
                                    {
                                        key: "upload",
                                        text: localize("LaddaUpp"),
                                        onClick: () => {
                                            this.setState({
                                                displayFileModal: true,
                                                displayFileUploadPopup: true,
                                            });
                                        },
                                        iconProps: { iconName: "document-upload" },
                                        className: "commandBarAddButton",
                                    },
                                ]
                                : []),
                        ]}
                        orderByColumn="index"
                        take={9999}
                    />
                </div>
            </div>
        );
    };

    private renderInfoTitle = (text) => {
        return (
            <Label
                as="p"
                size="body-3"
                color="mid-grey"
                weight="medium"
                style={{
                    marginBottom: Style.getSpacingStr(1.5),
                }}
                uppercase
            >
                {text}
            </Label>
        );
    };

    private renderSupplierTagButton = (action, text, key?) => (
        <UnstyledButton className="SupplierTag primaryBGColor" onClick={action} key={key}>
            <Label
                size="body-2"
                color={
                    getLightOrDarkColorBasedOnColor(this.props.instance.get("color"), "white", "black") as spintrColors
                }
            >
                {text}
            </Label>
        </UnstyledButton>
    );
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isSupplier: state.profile.active.roles.indexOf("supplier") !== -1,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName"),
    isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.PhonePortrait,
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SupplierView));
