import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { Label, Loader, SpintrUser } from "src/ui";
import "./ProfilePosition.scss";

interface IProps {
    userId: number;
    currentUser?: any;
    instance?: any;
    enableCompanyLevel?: boolean;
}

interface IState {
    isLoading: boolean;
    data?: any;
    saveError?: any;
}

class ProfilePosition extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError &&
            saveError.response &&
            saveError.response.data &&
            saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };

    fetch = () => {
        this.setState({ isLoading: true }, () => {
            api.get(`/api/v1/profiles/${this.props.userId}/coworkers`).then((response) => {
                this.setState({
                    data: response.data,
                    isLoading: false
                });
            }).catch(this.handleCatch.bind(this));
        });
    }

    componentDidMount = () => {
        this.fetch();
    };

    renderUser(u) {
        return (
            <div key={u.id} className={"ProfilePosition-User" + (u.id == this.props.userId ? " ProfilePosition-Active-User" : "")}>
                <SpintrUser
                    id={u.id}
                    name={u.name}
                    imageUrl={u.imageUrl}
                    subText={u.department.name} />
            </div>
        )
    }

    renderUserCategory(users, name?: string) {
        if (users.length === 0) {
            return null;
        }

        return (
            <div className="ProfilePosition-UserCategory">
                {
                    name && name.length > 0 && (
                        <Label size="h4">
                            {
                                name
                            }
                        </Label>
                    )
                }
                {
                    users.map((u) => {
                        return this.renderUser(u);
                    })
                }
            </div>
        )
    }

    renderPosition() {
        let companyManagers = [];
        let officeManagers = [];
        let departmentManagers = [];
        let users = [];
        let companyName;
        let officeName;
        let departmentName;

        const user = this.state.data.find((u) => { if(u.id == this.props.userId) return u; });

        companyName = user?.department?.office?.company?.name;
        officeName = user?.department?.office?.name;
        departmentName = user?.department?.name;

        for (const u of this.state.data) {
            if (u.isCompanyManager || u.isOfficeManager || u.isDepartmentManager) {
                if (u.isCompanyManager) {
                    companyManagers.push(u);
                }
                if (u.isOfficeManager) {
                    officeManagers.push(u);
                }
                if (u.isDepartmentManager) {
                    departmentManagers.push(u);

                }
            } else {
                users.push(u);
            }
        }

        return (
            <div className="ProfilePosition">
                {
                    this.props.enableCompanyLevel &&
                        this.renderUserCategory(companyManagers, localize("LedareForX").replace("{0}", companyName))
                }
                {
                    this.renderUserCategory(officeManagers, localize("LedareForX").replace("{0}", officeName))
                }
                {
                    this.renderUserCategory(departmentManagers, localize("LedareForX").replace("{0}", departmentName))
                }
                {
                    this.renderUserCategory(users, localize("Medarbetare") + (!!departmentName ? (" " + localize("I") + " " + departmentName) : ""))
                }
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loader />
            )
        };

        return this.renderPosition();
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    currentUser: state.profile.active,
    instance: state.instance,
    enableCompanyLevel: state.instance.get("enableCompanyLevel"),
});

export default connect(mapStateToProps)(ProfilePosition);
