import React, { Component, ReactNode } from "react";
import { SpintrUser } from "src/ui";
import ITokenizedInputItem from "./ITokenizedInputItem";
import "./TokenizedInputSuggestion.scss";

export type TokenizedInputSuggestionClickHandler = 
    (item : ITokenizedInputItem) => void;

export interface ITokenizedInputSuggestionProps {
    item: ITokenizedInputItem;
    onClick: TokenizedInputSuggestionClickHandler;
    selected: boolean;
}

class TokenizedInputSuggestion extends Component<ITokenizedInputSuggestionProps> {
    constructor(props : ITokenizedInputSuggestionProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public onClick() : void {
        this.props.onClick(this.props.item);
    }

    public render() : ReactNode {
        const classNames : string[] = ["TokenizedInputSuggestion"];

        if (this.props.selected) {
            classNames.push("selected");
        }

        return (
            <div 
                className={classNames.join(" ")}
                onClick={this.onClick}
            >
                <div className="item">
                    <SpintrUser
                        name={this.props.item.name}
                        subText={this.props.item.subText}
                        imageUrl={this.props.item.imageUrl}
                        size={32}
                        hideImage
                    />
                </div>
            </div>
        )
    }
}

export default TokenizedInputSuggestion;