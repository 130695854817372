import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { getFileIconUrl } from 'src/office365/utils';
import api from "src/spintr/SpintrApi";
import { DivWithHoverActions, Label, Loader } from "src/ui";
import { ShareModal } from '../ShareModal';
import "./ExternalFile.scss";

interface IProps {
    externalId?: string,
    externalFile?: any,
    source?: any;
    hideShare?: boolean;
    useDownloadIcon?: boolean;
}

interface IState {
    externalFile?: any;
    isLoadingExternalFile?: boolean;
    externalFileFetchFailed?: boolean;
    hideExternalFileImage?: boolean;
    displayShareModal?: boolean;
}

class ExternalFile extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingExternalFile: !props.externalFile,
            externalFile: props.externalFile
        };
    }

    componentDidMount() {
        if (!this.props.externalFile) {
            const url = "/api/files/office365/" +
                this.props.externalId +
                "?source=" +
                this.props.source;

            api.get(url).then((response) => {
                this.setState({
                    isLoadingExternalFile: false,
                    externalFile: response.data
                });
            }).catch(() => {
                this.setState({
                    isLoadingExternalFile: false,
                    externalFileFetchFailed: true
                });
            });
        }
    }

    openFile() {
        if (this.props.externalFile &&
            this.props.externalFile.onOpen) {
            return this.props.externalFile.onOpen();
        }

        window.open(this.state.externalFile.webUrl, '_blank');
    }

    protected renderItem(item): ReactNode {
        const key = `o356sidebar.office-file.${item.id}`;
        const iconSrc = getFileIconUrl(item);

        const displayThumbnail = (
            item.thumbnailMedium &&
            item.thumbnailMedium.length > 0 &&
            !this.state.hideExternalFileImage
        );

        return (
            <div
                role="link"
                className="office-file"
                data-id={item.id}
                key={key}
                onClick={this.openFile.bind(this)}
            >
                <div className="external-file">
                    {displayThumbnail && (
                        <div className="image-preview">
                            <img
                                alt=""
                                data-id={item.id}
                                src={item.thumbnailMedium}
                                onError={() => {
                                    this.setState({
                                        hideExternalFileImage: true
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="details">
                    <div className="file-name">
                        <img src={iconSrc} alt="" />
                        <Label className="text" size="body-2">
                            {item.name}
                        </Label>
                    </div>
                    <Label className="author" size="small-1">
                        {item.createdBy.displayName}
                    </Label>
                    <div className="date">
                        <Label size="small-1">
                            {moment(item.created ? item.created : item.createdDateTime).format("YYYY-MM-DD")}
                        </Label>
                    </div>
                </div>
            </div>
        );
    }

    getActions() {
        let actions = [];

        if (!this.props.hideShare) {
            actions.push({
                icon: "share",
                title: localize("Dela"),
                onClick: () => {
                    this.setState({
                        displayShareModal: true
                    });
                }
            });
        }

        if (this.props.useDownloadIcon) {
            actions.push({
                icon: "arrow-down-2",
                title: localize("LaddaNed"),
                onClick: this.openFile.bind(this)
            });
        } else {
            actions.push({
                icon: "send-1",
                title: localize("Oppna"),
                onClick: this.openFile.bind(this)
            });
        }

        if (this.props.externalFile &&
            this.props.externalFile.onRemove) {
            actions.push({
                icon: "trash",
                title: localize("TaBort"),
                onClick: this.props.externalFile.onRemove
            });
        }

        return actions;
    }

    render() {
        if (this.state.isLoadingExternalFile) {
            return (
                <Loader />
            );
        }

        if (this.state.externalFileFetchFailed) {
            return (
                <div className="ExternalFile-error-message-wrapper">
                    <div className="spintr-list-empty-list">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                            {localize("FilenArBorttagenEllerSaHarduInteBehorighetAttSeDen")}
                        </Label>
                    </div>
                </div>
            );
        }

        if (!this.state.externalFile) {
            return null;
        }

        return (
            <div className="ExternalFile">
                <DivWithHoverActions actions={this.getActions()} displayActionsAtBottom={true}>
                    {
                        this.renderItem(this.state.externalFile)
                    }
                </DivWithHoverActions>
                {
                    this.state.displayShareModal ?
                        <ShareModal
                            objectId={this.props.externalId ?
                                this.props.externalId :
                                this.props.externalFile.id}
                            isExternalFile={true}
                            externalFileSource={this.props.source}
                            onDismiss={() => {
                                this.setState({
                                    displayShareModal: false
                                });
                            }}
                        /> :
                        null
                }
            </div>
        )
    }
}

export default withRouter(ExternalFile);
