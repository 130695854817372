import { DefaultButton, Separator, Stack, StackItem } from "@fluentui/react";
import classnames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IConfirmPopupState } from "src/popups/reducer";
import {
    AddToFavoriteHandler,
    RemoveFavoriteHandler,
    StartFollowingObjectHandler,
    StopFollowingObjectHandler,
    addToFavorite,
    removeFavorite,
    startFollowingObject,
    stopFollowingObject
} from "src/spintr/actions";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import {
    ActionMenu,
    ContentWithInfoPanel,
    Label,
    Loader,
    PageInfoPanel,
    UnstyledButton,
    UserHovercard,
    setShouldReloadFavourites
} from "src/ui";
import { FormControl } from "src/ui/components/Forms";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import UsersAPI from "src/users/users-api";
import { isAnythingDirty } from "src/utils";
import { print } from "src/utils/print";
import { renderUnsavedChangesDialog } from "src/utils/renderUnsavedChangesDialog";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ISetCalendarPopupParams } from "../action-types";
import { fetchCalendarEvent, joinEvent, leaveEvent, setCalendarPopup, toggleCalendarEventDeleted } from "../actions";
import CalendarAPI from "../calendar-api";
import { formatEventCopy, getAvailableSeatsString, getDateString, isRegistrationAvailable } from "../util";
import CalendarEmailAttendeesPopup from "./CalendarEmailAttendeesPopup";
import "./CalendarEventView.scss";

interface IProps {
    id: string;
    currentUserId: number;
    history: any;
    isAdmin: boolean;
    isEditor: boolean;
    isSmallViewMode: boolean;
    groupId: number;
    unitId: number;
    isLoading: boolean;
    event: any;
    setConfirmPopup: (params: IConfirmPopupState) => void;
    toggleCalendarEventDeleted: (event: any) => void;
    setCalendarPopup?: (params: ISetCalendarPopupParams) => void;
    fetchCalendarEvent: (params: any) => void;
    setShouldReloadFavourites: (param: boolean) => void;
    addToFavorite: AddToFavoriteHandler;
    removeFavorite: RemoveFavoriteHandler;
    startFollowingObject: StartFollowingObjectHandler;
    stopFollowingObject: StopFollowingObjectHandler;
    onDismiss?: any;
    joinEvent: any;
    leaveEvent: any;
    instanceName: string;
    isModal?: boolean;
}

interface IState {
    isLoading: boolean;
    event?: any;
    owner?: any;
    displayUnsavedChangesPopup: boolean;
    displayEmailModal?: boolean;
    hasMirroredToOutlook?: boolean;
}

class CalendarEventView extends Component<IProps, IState> {
    private isGroup = !!this.props.groupId;
    private isUnit = !!this.props.unitId;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            displayUnsavedChangesPopup: false,
        };
    }

    public componentDidMount() {
        this.fetchEvent();
    }

    public componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.setState(
                {
                    owner: undefined,
                },
                this.fetchEvent
            );
        }
    }

    private fetchEvent = async () => {
        await this.props.fetchCalendarEvent({ id: this.props.id });
        const owner = await UsersAPI.fetchUser(this.props.event.owner);

        this.setState({
            owner,
        });
    };

    private joinEvent = async () => {
        const { event } = this.props;

        await CalendarAPI.joinEvent(this.props.currentUserId, event.id, true);

        // TODO: don't reload event?
        this.fetchEvent();
    };

    private leaveEvent = async () => {
        const { event } = this.props;

        await CalendarAPI.leaveEvent(this.props.currentUserId, event.id);

        // TODO: don't reload event?
        this.fetchEvent();
    };

    private deleteEvent = async () => {
        this.props.setConfirmPopup({
            isOpen: true,
            title: localize("ArDuSakerPaAttDuVillTaBortDennaPost") + "?",
            onConfirm: async () => {
                this.props.toggleCalendarEventDeleted(this.props.event);
                this.closeEvent();
            },
        });
    };

    private toggleFavourite = async () => {
        const { event } = this.props;

        if (event.isFavourite) {
            this.props.removeFavorite(event.id);
        } else {
            this.props.addToFavorite(event.id);
        }
        this.props.setShouldReloadFavourites(true);
    };

    private toggleFollow = async () => {
        const { event } = this.props;

        if (event.isFollowing) {
            this.props.stopFollowingObject(event.id);
        } else {
            this.props.startFollowingObject(event.id);
        }
    };

    private getPlaceString = (event) => (event.place && event.place !== "-" ? `, ${event.place}` : ``);

    private renderInfoPanel = () => {
        const { owner } = this.state;
        const { event } = this.props;

        const hasJoined = event.attendees.some(
            (attendee) => attendee.id === this.props.currentUserId && attendee.status === 1
        );

        return (
            <PageInfoPanel
                customItems={[
                    {
                        id: 700,
                        title: localize("SkapadAv"),
                        render: () => {
                            return (
                                <>
                                    <Label size="body-3">
                                        <Link to={`/profile/${owner.id}`}>
                                            <UserHovercard userId={owner.id}>{owner.name}</UserHovercard>
                                        </Link>
                                        , {moment(event.created).fromNow()}
                                    </Label>

                                    {/* <Label as="span" size="small-1">
                                        
                                    </Label> */}
                                </>
                            );
                        },
                    },
                    {
                        id: 701,
                        title: `${localize("Tid")} & ${localize("Plats")}`,
                        render: () => {
                            return (
                                <>
                                    <Label size="body-3">
                                        {getDateString(event)}
                                        {this.getPlaceString(event)}
                                    </Label>
                                </>
                            );
                        },
                    },
                    ...(event.seats
                        ? [
                            {
                                id: 702,
                                title: localize("AntalPlatser"),
                                render: () => {
                                    return (
                                        <>
                                            <Label size="body-3">{event.seats}</Label>
                                        </>
                                    );
                                },
                            },
                        ]
                        : []),
                    ...(event.registrationClose
                        ? [
                            {
                                id: 703,
                                title: localize("CALENDAR_ACTIVITY_LAST_REGISTRATION"),
                                render: () => {
                                    return (
                                        <>
                                            <Label size="body-3">
                                                {moment(event.registrationClose).format("L")}{" "}
                                                {moment(event.registrationClose).format("LT")}
                                            </Label>
                                        </>
                                    );
                                },
                            },
                        ]
                        : []),
                    {
                        id: 704,
                        title: `${localize("Deltagare")} ${getAvailableSeatsString(event)}`,
                        render: () => {
                            return (
                                <>
                                    {event.participants
                                        .filter((participant) => participant.id !== -1)
                                        .map((participant) => (
                                            <div key={participant.id}>
                                                <Link to={`/profile/${participant.id}`} className="left">
                                                    <Label as="p" size="body-3">
                                                        <UserHovercard userId={participant.id}>
                                                            {participant.name}
                                                        </UserHovercard>
                                                    </Label>
                                                </Link>

                                                <Label as="p" size="body-3">
                                                    {localize(
                                                        participant.status === 0
                                                            ? "Inbjuden"
                                                            : participant.status === 1
                                                                ? "Kommer"
                                                                : "KommerInte"
                                                    )}
                                                </Label>
                                            </div>
                                        ))}
                                </>
                            );
                        },
                    },
                ]}
                uberId={event.id}
            />
        );

        // {event.participants.length === 0 && <Label size="h6">{localize("IngaInbjudningarFinnsNu")}</Label>}

        /* {!hasJoined && isRegistrationAvailable(event) && (
                    <PrimaryButton onClick={this.joinEvent} text={localize("GaMedEvent")} />
                )} */
    };

    private closeEvent = () => {
        if (isAnythingDirty()) {
            return this.setState({
                displayUnsavedChangesPopup: true,
            });
        }

        this.props.onDismiss();
    };

    private toggleEmailAttendeesPopup = () => {
        this.setState((prevState) => ({
            displayEmailModal: !prevState.displayEmailModal,
        }));
    }

    private renderAttendingButtons = (userId, event) => {
        const hasJoined = event.attendees.some((attendee) => attendee.id === userId && attendee.status === 1);

        return (
            <div className="attending-buttons">
                <FormControl>
                    <DefaultButton
                        styles={{ icon: { color: "green" }, root: { marginRight: "6px" } }}
                        disabled={!hasJoined}
                        iconProps={!hasJoined && { iconName: "Accept" }}
                        onClick={async () => {
                            const response = await CalendarAPI.leaveEvent(userId, event.id);
                            this.props.leaveEvent(event.id, userId, response);
                            this.leaveEvent();
                        }}
                    >
                        {localize("KommerInte")}
                    </DefaultButton>
                </FormControl>
                <FormControl>
                    <DefaultButton
                        styles={{ icon: { color: "green" } }}
                        iconProps={hasJoined && { iconName: "Accept" }}
                        disabled={hasJoined || !isRegistrationAvailable(event)}
                        onClick={async () => {
                            const response = await CalendarAPI.joinEvent(userId, event.id, true);
                            this.props.joinEvent(event.id, userId, response);
                            this.joinEvent();
                        }}
                    >
                        {localize("Kommer")}
                    </DefaultButton>
                </FormControl>
                {(!hasJoined || !event.isMirrored) ? null : (
                    <FormControl>
                        <DefaultButton
                            className="outlook-button"
                            disabled={this.state.hasMirroredToOutlook}
                            iconProps={{
                                iconName: "OutlookLogoInverse",
                            }}
                            onClick={async () => {
                                const result = await CalendarAPI.mirrorToOutlook(event.id);

                                if (!result) {
                                    return;
                                }

                                this.setState((state) => ({
                                    ...state,
                                    hasMirroredToOutlook: true,
                                }));
                            }}
                        >
                            {localize("MIRROR_TO_OUTLOOK")}
                        </DefaultButton>
                    </FormControl>
                )}
            </div>
        );
    };

    private renderInner = () => {
        const { owner } = this.state;
        const { isLoading, event } = this.props;

        if (isLoading || !owner) {
            return <Loader />;
        }

        const hasJoined = event.attendees.some(
            (attendee) => attendee.id === this.props.currentUserId && attendee.status === 1
        );
        const allowInvites = event.calendarType !== SpintrTypes.CalendarType.Project && hasJoined;
        const showLeave = hasJoined;
        const showJoin = !hasJoined;

        console.log(event);

        return (
            <div className="container spintr-modal">
                {
                    !this.props.isModal && (
                        <div>
                            <Stack horizontal verticalAlign="center">
                                <StackItem grow={1}>
                                    <Label size="h2">
                                        {localize("Kalenderhandelse")}
                                    </Label>
                                </StackItem>
                                <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.closeEvent}>
                                    <Visage2Icon icon="close-circle" />
                                </UnstyledButton>
                            </Stack>
                            <Separator />
                        </div>
                    )
                }
                {this.state.displayEmailModal && (
                   <CalendarEmailAttendeesPopup event={event} dismiss={this.toggleEmailAttendeesPopup} />
                )}
                {event.imageUrl && <img alt="" className="headerImage" src={event.imageUrl} />}
                <ContentWithInfoPanel template={1} renderInfoPanel={this.renderInfoPanel}>
                    <div className="content">
                        <Stack horizontal>
                            <StackItem grow={1}>
                                <Label size="h2">{event.title}</Label>
                            </StackItem>
                            <StackItem>
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize(
                                                        event.isFavourite ? "TaBortFranFavoriter" : "LaggTillIFavoriter"
                                                    ),
                                                    onClick: this.toggleFavourite,
                                                    icon: event.isFavourite ? "FavoriteStarFill" : "FavoriteStar",
                                                },
                                                {
                                                    text: localize(
                                                        event.isFollowing ? "StangAvNotiser" : "AktiveraNotiser"
                                                    ),
                                                    onClick: this.toggleFollow,
                                                    icon: "Flag",
                                                },
                                                {
                                                    text: localize("Skrivutsidan"),
                                                    onClick: () => {
                                                        print(this.props.instanceName, event.title);
                                                    },
                                                },
                                                { text: localize("Stang"), onClick: this.closeEvent },
                                                ...(showLeave
                                                    ? [
                                                        {
                                                            text: localize("Lamna"),
                                                            onClick: this.leaveEvent,
                                                        },
                                                    ]
                                                    : []),
                                                ...(showJoin && isRegistrationAvailable(event)
                                                    ? [
                                                        {
                                                            text: localize("GaMedEvent"),
                                                            onClick: this.joinEvent,
                                                        },
                                                    ]
                                                    : []),
                                                {
                                                    text: localize("Kopiera"),
                                                    onClick: () => {
                                                        const copiedEvent = formatEventCopy(
                                                            event,
                                                            this.props.currentUserId
                                                        );

                                                        this.props.setCalendarPopup({
                                                            isOpen: true,
                                                            event: copiedEvent,
                                                            eventId: 0,
                                                            editMode: true,
                                                        });
                                                    },
                                                },
                                            ],
                                        },
                                        ...(
                                            !event.isMirrored &&
                                            (this.props.isEditor ||
                                            this.props.isAdmin ||
                                            this.props.currentUserId === event.owner)
                                            ? [
                                                {
                                                    title: localize("Redaktor"),
                                                    items: [
                                                        {
                                                            // or calendar.allowEdit?
                                                            text: localize("Redigera"),
                                                            onClick: () => {
                                                                this.props.setCalendarPopup({
                                                                    isOpen: true,
                                                                    event,
                                                                    eventId: event.id,
                                                                    editMode: true,
                                                                });
                                                            },
                                                        },
                                                        ...(event.attendees.filter(
                                                            (attendee) => attendee.id !== this.props.currentUserId
                                                        ).length > 0
                                                            ? [
                                                                  {
                                                                      text: localize("SkickaEpostTillDeltagare"),
                                                                      onClick: this.toggleEmailAttendeesPopup,
                                                                  },
                                                              ]
                                                            : []),
                                                        {
                                                            text: localize("TaBort"),
                                                            onClick: this.deleteEvent,
                                                        },
                                                    ],
                                                },
                                            ]
                                            : []),
                                    ]}
                                />
                            </StackItem>
                        </Stack>

                        <div className="print-container">
                            <TinyFormattedContent content={event.text} />
                        </div>
                    </div>
                </ContentWithInfoPanel>
                <form>
                    {this.renderAttendingButtons(this.props.currentUserId, event)}
                    <SocialBlock uberId={event.id} />
                </form>

                {renderUnsavedChangesDialog(
                    this.state.displayUnsavedChangesPopup,
                    () => {
                        this.setState({
                            displayUnsavedChangesPopup: false,
                        });
                    },
                    () => {
                        this.setState(
                            {
                                displayUnsavedChangesPopup: false,
                            },
                            () => {
                                this.props.onDismiss();
                            }
                        );
                    }
                )}
            </div>
        );
    };

    public render() {
        return (
            <div
                className={classnames("calendar-event-view", {
                    isSmallViewMode: this.props.isSmallViewMode,
                })}
            >
                <Helmet>
                    <title>{localize("Kalender")}</title>
                </Helmet>
                {this.renderInner()}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        currentUserId: state.profile.active.id,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        isSmallViewMode: state.ui.isSmallViewMode,
        isLoading: state.calendar.event.isLoading,
        event: state.calendar.event.event,
        instanceName: state.instance.get("name"),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...dispatch,
        ...bindActionCreators(
            {
                setConfirmPopup,
                toggleCalendarEventDeleted,
                setCalendarPopup,
                fetchCalendarEvent,
                joinEvent,
                leaveEvent,
                setShouldReloadFavourites,
                addToFavorite,
                removeFavorite,
                startFollowingObject,
                stopFollowingObject,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEventView);
