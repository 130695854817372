import classNames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import "src/groups/views/planning/GroupPlanningView.scss";
import PlanningCardPopup from "src/groups/views/planning/PlanningCardPopup";
import { Icon, Label, SpintrUser, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    card: any;
    dispatch: any;
    categoryId: number;
    index: any;
    groupId: number;
}

class PlanningCard extends Component<IProps> {
    state = {
        editing: false,
        displayPlanningCardPopup: window.location.href.indexOf("/groups/" + this.props.groupId + "/planning/" + this.props.card.id) > -1
    };

    onClick() {
        this.setState({
            displayPlanningCardPopup: true
        });
    }

    render() {
        const displayRightInfo = (!!this.props.card.targets && this.props.card.targets.length > 0) ||
            !!this.props.card.date;

        let classes = ["Planning-Card"];

        if (displayRightInfo) {
            classes.push("Planning-Card-HasRightInfo");
        }

        return (
            <Draggable draggableId={this.props.card.id.toString()} index={this.props.index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classes.join(" ")}
                        tabIndex={-1}
                    >
                        <UnstyledButton className="Planning-CardClickable" onClick={this.onClick.bind(this)}>
                            <div className="Planning-CardInner" style={{
                                borderLeftWidth: 2,
                                borderLeftColor: this.props.card.color,
                                borderLeftStyle: "solid"
                            }}>
                                <div className="Planning-CardInnerLeft">
                                    <Label as="p">
                                        {
                                            this.props.card.title
                                        }
                                    </Label>
                                    {
                                        !!this.props.card.description ?
                                            <Label as="p" size="body-2" color="dark-grey">
                                                {
                                                    this.props.card.description
                                                }
                                            </Label> :
                                            null
                                    }
                                    {
                                        this.props.card.todos.length > 0 || this.props.card.files.length > 0 ?
                                            <div>
                                                {
                                                    this.props.card.todos.length > 0 ?
                                                        <div className="Planning-SmallCountWrapper">
                                                            <div className="Planning-CardSmallCountLeft">
                                                                <Visage2Icon icon="check" color={this.props.card.todos.filter(t => !t.done).length === 0 ? "light-blue" : "dark-grey"} />
                                                            </div>
                                                            <Label
                                                                className="Planning-CardSmallCountRight"
                                                                as="span"
                                                                size="body-3"
                                                                color={this.props.card.todos.filter(t => !t.done).length === 0 ? "light-blue" : "dark-grey"}>
                                                                {
                                                                    this.props.card.todos.filter(t => t.done).length
                                                                }
                                                                /
                                                                {
                                                                    this.props.card.todos.length
                                                                }
                                                            </Label>
                                                        </div> :
                                                        null
                                                }
                                                {
                                                    this.props.card.files.length > 0 ?
                                                        <div className="Planning-SmallCountWrapper">
                                                            <div className="Planning-CardSmallCountLeft">
                                                                <Visage2Icon icon="folder" />
                                                            </div>
                                                            <Label
                                                                className="Planning-CardSmallCountRight"
                                                                as="span"
                                                                size="body-3"
                                                                color={"dark-grey"}>
                                                                {
                                                                    this.props.card.files.length
                                                                }
                                                            </Label>
                                                        </div> :
                                                        null
                                                }
                                            </div> :
                                            null
                                    }
                                </div>
                                {
                                    displayRightInfo ?
                                        <div className="Planning-CardInnerRight">
                                            {
                                                !!this.props.card.targets &&
                                                this.props.card.targets.length > 0 ?
                                                    <div className="Planning-CardInnerRightTargets">
                                                        {
                                                            this.props.card.targets.map(t => {
                                                                return (
                                                                    <div key={t.id} className="Planning-CardInnerRightTarget">
                                                                        <SpintrUser
                                                                            id={t.id}
                                                                            name={t.name}
                                                                            imageUrl={t.image}
                                                                            size={24}
                                                                            hideText={true}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div> :
                                                    null
                                            }
                                            {
                                                !!this.props.card.date ?
                                                    <Label color="dark-grey" size="body-3" className={classNames("Planning-CardInnerRightDate", {
                                                        ["Overdue"]: this.props.card.date && moment(this.props.card.date).isBefore(moment().subtract(1, "days"))
                                                    })}>
                                                        {
                                                            moment(this.props.card.date).format("LL")
                                                        }
                                                    </Label> :
                                                    null
                                            }
                                        </div> :
                                        null
                                }
                            </div>
                        </UnstyledButton>
                        {
                            this.state.displayPlanningCardPopup ?
                                <PlanningCardPopup
                                    onClose={() => {
                                        this.setState({
                                            displayPlanningCardPopup: false
                                        });
                                    }}
                                    groupId={this.props.groupId}
                                    cardId={this.props.card.id} /> :
                                null
                        }
                    </div>
                )}
            </Draggable>
        );
    }
}

export default connect()(PlanningCard);
