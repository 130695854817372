import { AxiosResponse } from "axios";
import moment from "moment";
import { CommandBar } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Action } from "redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui";
import { SpintrUser, UnstyledButton } from "src/ui/components";
import { setCurrentFolderId } from "../folder-actions";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { getTimeOrDateFromDate } from "src/utils";

interface IProps {
    id: number;
    source: Spintr.FolderRootSource;
    dispatch: (action: Action) => void;
    group?: any;
}

interface IState {
    isLoading: boolean;
    file: any;
}

class FileDetailsOffice365View extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            file: null,
        };
    }

    componentDidMount() {
        this.fetchFile();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.fetchFile();
        }
    }

    fetchFile = () => {
        this.setState({
            isLoading: true
        });

        api
            .get(`/api/files/office365/${this.props.id}`, {
                params: {
                    source: this.props.source,
                    externalId: this.props.group?.externalId,
                },
            })
            .then((response: AxiosResponse) => {
                var file = response.data;

                var fileTypes = SpintrTypes.FileType;
                var canShowFileTypes = [fileTypes.Pdf, fileTypes.Jpg, fileTypes.Png, fileTypes.Gif];

                this.setState({
                    isLoading: false,
                    file: file,
                });

                this.props.dispatch(setCurrentFolderId(response.data.parentReference.id));
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    renderCommandBar = () => {
        const { file } = this.state;
        return (
            <CommandBar
                items={[
                    ...(file.downloadUrl
                        ? [
                              {
                                  key: "download",
                                  text: localize("LaddaNed"),
                                  onClick: () => {
                                      window.open(file.downloadUrl, "_blank");
                                  },
                                  iconProps: { iconName: "Download" },
                                  onRenderIcon: () => {
                                    return (
                                        <Visage2Icon
                                            icon={"document-upload"} />
                                    )
                                  }
                              },
                          ]
                        : []),
                    ...(file.webUrl
                        ? [
                              {
                                  key: "open",
                                  text: localize("Oppna"),
                                  onClick: () => {
                                      window.open(file.webUrl, "_blank");
                                  },
                                  iconProps: { iconName: "OpenInNewTab" },
                                  onRenderIcon: () => {
                                    return (
                                        <Visage2Icon
                                            icon={"arrow-right-1"} />
                                    )
                                  }
                              },
                          ]
                        : []),
                ]}
            />
        );
    };

    renderInfoPart(title: string, content?: string, renderContent?) {
        return (
            <div className="FileDetailsView-InfoPart">
                <Label size="body-2" color="mid-grey">{title}</Label>
                <div className="FileDetailsView-InfoPartContent">
                    {!!content && <Label color="dark-grey" size="body-2">{content}</Label>}
                    {!content && !!renderContent && (
                        <div className="FileDetailsView-InfoPartContent-inner">
                            {renderContent()}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderDataBox = (file: any) => {
        return (
            <div className="data-box">
                {this.renderInfoPart(localize("Titel"), file.name)}
                {!!file.type && file.type !== "Undefined" && this.renderInfoPart(localize("Typ"), file.type)}
                {!!file.size && this.renderInfoPart(localize("Storlek"), file.sizeStr)}
                {!!file.createdBy && this.renderInfoPart(localize("UppladdadAv"), undefined, () => {
                    return (
                        <SpintrUser
                            name={file.createdBy.displayName}
                            imageUrl={file.createdBy.photo}
                            subText={moment(file.createdDateTime).format("LLL")} />
                    )
                })}
                {!!file.lastModifiedBy && this.renderInfoPart(localize("SenastAndrad"), undefined, () => {
                    return (
                        <SpintrUser
                        name={file.lastModifiedBy.displayName}
                        imageUrl={file.lastModifiedBy.photo}
                        subText={moment(file.lastModifiedDateTime).format("LLL")} />
                    )
                })}
            </div>
        );
    };

    renderImage = () => {
        return (
            <div className="preview">
                <img src={this.state.file.thumbnailBig} alt="" />
            </div>
        )
    }

    renderInner = () => {
        const state = this.state;
        const { file } = state;

        return (
            <>
                <div>
                    {!file ? (
                        <>
                            <Label>{localize("TeknisktFel")}</Label>
                            <UnstyledButton onClick={this.fetchFile}>
                                <Label className="primaryFGColor">{localize("RELOAD")}</Label>
                            </UnstyledButton>
                        </>
                    ) : (
                        <>
                            {file.thumbnailBig && this.renderImage()}
                            {this.renderCommandBar()}
                            {this.renderDataBox(file)}
                        </>
                    )}
                </div>
            </>
        );
    };

    render() {
        const state = this.state;

        return <div className="file-details">{state.isLoading ? <Loader /> : this.renderInner()}</div>;
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
    };
};

export default withRouter(connect(mapStateToProps)(FileDetailsOffice365View));
