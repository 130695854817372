import { produce } from "immer";
import { ReactElement } from "react";

import { SET_CONFIRM_POPUP, SET_INFO_POPUP } from "./action-types";

export interface IPopupState {
    confirmPopup: IConfirmPopupState;
    infoPopup: IInfoPopupState;
}

export interface IConfirmPopupState {
    isOpen: boolean;
    title?: string;
    message?: string;
    content?: ReactElement;
    onConfirm?: () => void;
    onDismiss?: () => void;
}

export interface IInfoPopupState {
    isOpen: boolean;
    message?: string;
    onDismiss?: () => void;
}

const initialState: IPopupState = {
    confirmPopup: {} as IConfirmPopupState,
    infoPopup: {} as IInfoPopupState,
};

const popupReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_CONFIRM_POPUP:
                draft.confirmPopup = {
                    ...action.props,
                };

                break;
            case SET_INFO_POPUP:
                draft.infoPopup = {
                    ...action.props,
                };

                break;

            default:
                return state;
        }
    });

export default popupReducer;
