import React, { useState } from "react";
import "./SocialPostTypeContent.scss";
import { visage2SocialPostTypes } from "src/social-feed/types";
import { SpintrTypes } from "src/typings";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import { localize } from "src/l10n";
import { UsersListPopup } from "src/users/views";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ReactSVG } from 'react-svg';
import SocialPostTypeContentInput from "./SocialPostTypeContentInput";
import SocialPostTypeContentConfetti from "./SocialPostTypeContentConfetti";

interface IProps {
    post: any;
    editMode?: boolean;
    onUpdate?: any;
    goToEditUsers?: any;
}

const UserLink = ({id, children, editMode}) => {
    if (editMode) {
        return (
            <div>
                {children}
            </div>
        )
    }

    return (
        <a href={"/users/" + id}>
            {children}
        </a>
    )
}

const SocialPostTypeContentKudos = (props: IProps) => {
    const [hideLikersPopup, setHideLikersPopup] = useState(true);

    const users : Spintr.IUser[] = props.post.targetUsers || [];

    const textColor = "white";

    return (
        <div className={"user-count-" + (users.length < 3 ? users.length : "many")}>
            {
                props.post.displayDecorations && (
                    <SocialPostTypeContentConfetti />
                )
            }
            <div className="content-wrapper">
                {
                    !props.editMode && (
                        <Label color="white" size="h4" weight="medium">{props.post.comment || ""}</Label>
                    )
                }
                {
                    props.editMode && (
                        <SocialPostTypeContentInput
                            big
                            value={props.post.comment || ""}
                            placeholder={localize("SkrivNagot")}
                            onChange={(text) => {
                                props.onUpdate({
                                    ...props.post,
                                    comment: text
                                })
                            }} />
                    )
                }
            </div>
            {users.length > 0 && (
                <div className="users">
                    {users.length === 1 && (
                        <>
                            <UserLink id={users[0].id} editMode>
                                <SpintrUser hideText imageUrl={users[0].image} name={users[0].name} />
                            </UserLink>
                            <UserLink id={users[0].id} editMode>
                                <Label size="h3" weight="medium" color={textColor}>{users[0].name}</Label>
                            </UserLink>
                        </>
                    )}
                    {users.length === 2 && (
                        <>
                            <div className="user">
                                <UserLink id={users[0].id} editMode>
                                    <Label color={textColor}>{users[0].name.split(" ")[0]}</Label>
                                </UserLink>
                                <UserLink id={users[0].id} editMode>
                                    <SpintrUser hideText imageUrl={users[0].image} name={users[0].name} />
                                </UserLink>
                            </div>
                            <div className="user">
                                <UserLink id={users[1].id} editMode>
                                    <SpintrUser hideText imageUrl={users[1].image} name={users[1].name} />
                                </UserLink>
                                <UserLink id={users[1].id} editMode>
                                    <Label color={textColor}>{users[1].name.split(" ")[0]}</Label>
                                </UserLink>
                            </div>
                        </>
                    )}
                    {users.length > 2 && (
                        <>
                            <UnstyledButton
                                disabled={props.editMode}
                                onClick={() => {
                                    setHideLikersPopup(false);
                                }}>
                                <div className="images">
                                    {users.map((user: any, index: number) => {
                                        return (
                                            <SpintrUser key={index} hideText imageUrl={user.image} name={user.name} />
                                        )
                                    })}
                                </div>
                                <Label color={textColor} size="body-2">{users[0].name + " " + localize("Och_small") + " " + (users.length - 1) + " " + localize("till_more")}</Label>
                            </UnstyledButton>
                        </>
                    )}
                </div>
            )}
            {users.length === 0 && props.editMode && (
                <div className="add-button-big">
                    <UnstyledButton
                        onClick={() => {
                            props.goToEditUsers();
                        }}>
                        <Visage2Icon icon="add" color="white" size="small" />
                        <Label size="body-2" color="white">{localize("LaggTill")}</Label>
                    </UnstyledButton>
                </div>
            )}
            {users.length > 0 && props.editMode && (
                <div className="add-button-small">
                    <UnstyledButton
                        onClick={() => {
                            props.goToEditUsers();
                        }}
                        title={localize("CHAT_ADD_PEOPLE")}>
                        <Visage2Icon icon="edit" color="white" />
                    </UnstyledButton>
                </div>
            )}
            <UsersListPopup
                users={users}
                title={props.post.comment || localize("THANK_YOU")}
                popupIsHidden={hideLikersPopup}
                closePopup={() => {
                    setHideLikersPopup(true);
                }} />
        </div>
    )
}

export default SocialPostTypeContentKudos;