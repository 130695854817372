import moment from "moment";
import { Icon, TooltipHost } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import {
    addToFavorite,
    AddToFavoriteHandler,
    deleteObject,
    DeleteObjectHandler, hideObject,
    HideObjectHandler, pinObject, PinObjectHandler, removeFavorite,
    RemoveFavoriteHandler,
    startFollowingObject,
    StartFollowingObjectHandler,
    stopFollowingObject,
    StopFollowingObjectHandler,
} from "src/spintr/actions";
import { SpintrTypes } from "src/typings";
import { Label, setShouldReloadFavourites, SpintrUser, UserHovercard } from "src/ui";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import { getHexFromSpintrColor, circleLarge, circleMedium } from "src/ui/helpers/style";
import { SocialPostSeenBy } from "../SocialPostSeenBy";
import "./SocialPostHeader.scss";
import { verifyQuestion, VerifyQuestionHandler } from "src/social-feed/actions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import UpdatingDate from "src/interactions/components/UpdatingDate";
import { visage2SocialPostTypes } from "src/social-feed/types";

interface IDispatchProps {
    addToFavorite: AddToFavoriteHandler;
    deleteObject: DeleteObjectHandler;
    pinObject: PinObjectHandler;
    verifyQuestion: VerifyQuestionHandler;
    removeFavorite: RemoveFavoriteHandler;
    startFollowingObject: StartFollowingObjectHandler;
    stopFollowingObject: StopFollowingObjectHandler;
    setShouldReloadFavourites: any;
    hideObject: HideObjectHandler;
    setConfirmPopup: any;
}

interface IOwnProps {
    post: Spintr.ISocialPostBase;
    onEditClick: any;
}

interface IStateProps {
    elevatedPrivileges: boolean;
    userId: number;
    canModerateFeed: boolean;
    filters?: any;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    showShareModal: boolean;
}

// TODO: Add menu items
const SocialPostHeader: FunctionComponent<Props> = (props) => {
    const [state, setState] = useState<IState>({ showShareModal: false });
    const dispatch = useDispatch();
    const onShareDismiss = useCallback((): void => {
        setState({ ...state, showShareModal: false });
    }, []);

    const profileUrl: string = `/profile/${props.post.UserId}`;
    const departmentUrl: string = `/organisation/v/${props.post.feedOwnerId}`;
    const postUrl: string =
        props.post.feedType === SpintrTypes.FeedType.Group
            ? `/groups/${props.post.feedOwnerId}/feed/${props.post.Id}`
            : `/posts/${props.post.Id}`;

    const isOwner = props.userId === props.post.UserId;
    const hasRightsToEdit = isOwner || props.canModerateFeed;

    const typeCanBeEdited =
        props.post.Type === SpintrTypes.UberType.Image ||
        props.post.Type === SpintrTypes.UberType.ImagePost ||
        (typeof props.post.StatusType !== undefined && props.post.StatusType === SpintrTypes.StatusType.Bookmark) ||
        (typeof props.post.StatusType !== undefined && props.post.StatusType === SpintrTypes.StatusType.Question) ||
        props.post.Type === SpintrTypes.UberType.Status;

    const removableTypes = [
        SpintrTypes.UberType.Image,
        SpintrTypes.UberType.ImagePost,
        SpintrTypes.UberType.Poll,
        SpintrTypes.UberType.PollAnswer,
        SpintrTypes.UberType.PollOption,
        SpintrTypes.UberType.Status,
    ];

    const typeCanBeDeleted = removableTypes.indexOf(props.post.Type) > -1;

    let units = [];

    for (var office of (props.filters?.units || [])) {
        units.push(office);

        for (var department of (office.departments || [])) {
            units.push(department);
        }
    }

    const targetDepartment = units.find((x: any) => x.id === props.post.feedOwnerId);

    const hidableTypes = [
        SpintrTypes.UberType.Status,
        SpintrTypes.UberType.File,
        SpintrTypes.UberType.TextPage,
        SpintrTypes.UberType.NewsArticle,
        SpintrTypes.UberType.Image,
        SpintrTypes.UberType.ImageTag,
        SpintrTypes.UberType.WikiArticle,
        SpintrTypes.UberType.BlogPost,
        SpintrTypes.UberType.Group,
        SpintrTypes.UberType.TextPageUpdate,
        SpintrTypes.UberType.ImagePost,
    ];

    const canHide = hidableTypes.includes(props.post.Type) && hasRightsToEdit;

    const renderSecondaryText = () => (
        <div className="meta">
            <Link to={postUrl} style={{ color: "inherit" }}>
                <UpdatingDate date={props.post.Date} />
            </Link>
            {props.post.sourceUrl && (
                <a href={props.post.sourceUrl} target="_blank" style={{ color: "inherit" }}>
                    {props.post.sourceName}
                </a>
            )}
            <SocialPostSeenBy post={props.post} />
        </div>
    );

    const getTarget = () => {
        if (props.post.TargetUserId &&
            props.post.TargetUserName) {
            return {
                name: props.post.TargetUserName,
                url: "/goto/" + props.post.TargetUserId
            }
        }

        if (props.post.targetUberId &&
            props.post.targetUberName &&
            window.location.pathname.indexOf("/groups/") !== 0) {
            return {
                name: props.post.targetUberName,
                url: "/goto/" + props.post.targetUberId
            }
        }

        if (targetDepartment) {
            return {
                name: targetDepartment.name,
                url: departmentUrl
            }
        }

        return null;
    }

    const renderTypeIndicator = () => {
        const foundType = visage2SocialPostTypes.find(x => x.type === props.post.StatusType);

        if (!foundType) {
            return null;
        }

        return (
            <div className="visage2-feed-icon">
                <TooltipHost
                    content={localize(foundType.titleTag)}
                    className="marketplace-tooltip"
                    calloutProps={{ beakWidth: 8 }}>
                    <div className="visage2-icon-wrapper" style={{
                        backgroundColor: foundType.bgColor
                    }}>
                            <Visage2Icon
                                icon={foundType.icon}
                                size="small"
                                type="custom"
                                hexColor={foundType.iconColor} />
                            <Label size="body-2">{localize(foundType.titleTag)}</Label>
                    </div>
                </TooltipHost>
            </div>
        )
    }

    const getTypeText = () => {
        const foundType = visage2SocialPostTypes.find(x => x.type === props.post.StatusType);

        if (!foundType) {
            return null;
        }

        return (
            <span className="type-text">
                {" " + localize(foundType.postHeaderTextTag)}
            </span>
        )
    }

    const renderPrimaryText = () => {
        const nameLink = props.post.instagramName
            ? props.post.sourceUrl
            : props.post.postedByDepartment
                ? departmentUrl
                : profileUrl;
        const isExternalLink = /^https?:\/\//.test(nameLink);
        const name = props.post.instagramName || props.post.UserName;
        const target = getTarget();

        return (
            <div>
                <div>
                    {
                        isExternalLink && (
                            <a href={nameLink} style={{ color: "inherit" }} target={"_blank"}>
                                <span>
                                    {
                                        name
                                    }
                                </span>
                            </a>
                        )
                    }
                    {
                        !isExternalLink && (
                            <span>
                                <Link to={nameLink} style={{ color: "inherit" }}>
                                    <UserHovercard userId={props.post.UserId}>
                                        <span>
                                            {
                                                name
                                            }
                                        </span>
                                    </UserHovercard>
                                </Link>
                                {getTypeText()}
                            </span>
                        )
                    }
                    {
                        !!target && (
                            <span className="target">
                                <Visage2Icon
                                    size="extra-small"
                                    icon="arrow-right-3" />
                                <Link to={target.url} style={{ color: "inherit" }} >
                                    {target.name}
                                </Link>
                            </span>
                        )
                    }
                </div>
            </div>
        )
    }

    const deletePost = useCallback((): void => {
        props.setConfirmPopup({
            isOpen: true,
            title: localize("ArDuSakerAttDuVillRaderaDennaPost"),
            onConfirm: () => {
                props.deleteObject(props.post.Id);
            },
        });
    }, [props.post.Id]);

    const onPinClick = useCallback((): void => {
        props.setConfirmPopup({
            isOpen: true,
            title: props.post.isPinned ? localize("RELEASE_POST_CONFIRM_MESSAGE") : localize("PIN_POST_CONFIRM_MESSAGE"),
            onConfirm: () => {
                props.pinObject(props.post.Id);
            },
        });
    }, [props.post.Id]);

    const hidePost = useCallback((): void => {
        props.setConfirmPopup({
            isOpen: true,
            title: localize("ArDuSakerPaAttDuVillGommaDennaPost") + "?",
            onConfirm: () => {
                props.hideObject(props.post.Id);
            },
        });
    }, [props.post.Id]);

    return (
        <div className="SocialPostHeader">
            <div className="wrapper">
                <div className="SocialPostHeader-left">
                    <SpintrUser
                        name={props.post.instagramName || props.post.UserName}
                        nameLink={
                            props.post.instagramName
                                ? props.post.sourceUrl
                                : props.post.postedByDepartment
                                    ? departmentUrl
                                    : profileUrl
                        }
                        bigCoin={true}
                        size={circleMedium}
                        textColor={getHexFromSpintrColor("dark-grey")}
                        secondaryTextColor={getHexFromSpintrColor("mid-grey")}
                        imageUrl={props.post.instagramName ? "" : props.post.ImageUrl}
                        onRenderSecondaryText={renderSecondaryText}
                        onRenderPrimaryText={renderPrimaryText}
                        state={props.post.userState}
                    />
                </div>
                <div className="SocialPostHeader-right">
                    <StandardActionMenu
                        canAddToFavourites={true}
                        canFollow={true}
                        canShare={true}
                        canReport={true}
                        canDelete={hasRightsToEdit && typeCanBeDeleted}
                        canEdit={hasRightsToEdit && typeCanBeEdited}
                        canPin={props.elevatedPrivileges}
                        canHide={canHide}
                        objectId={props.post.Id}
                        onEditClick={props.onEditClick}
                        onDeleteClick={deletePost}
                        onPinClick={onPinClick}
                        onHideClick={hidePost}
                        isFollowing={props.post.isFollowing}
                        isFavourite={props.post.isFavourited}
                        isPinned={props.post.isPinned}
                        customItems={isOwner && (props.post.StatusType === SpintrTypes.StatusType.Question || props.post.StatusType === SpintrTypes.StatusType.Text) ? [{
                            items: [
                                {
                                    text: localize(props.post.StatusType === SpintrTypes.StatusType.Question ? "UNMARK_AS_QUESTION" : "MARK_AS_QUESTION"),
                                    onClick: async () => {
                                        props.verifyQuestion(props.post.Id, props.post.StatusType !== SpintrTypes.StatusType.Question)
                                    },
                                    icon: "message-question",
                                    onRenderIcon: () => {
                                        return (
                                            <Visage2Icon icon={"message-question"} color="mid-grey" size="small" />
                                        )
                                    }
                                },
                            ],
                        }] : []}
                    />
                </div>
            </div>
        </div>
    );
};

export default connect<IStateProps, IDispatchProps, IOwnProps, Spintr.AppState>(
    (state) => ({
        elevatedPrivileges: state.profile.active.roles.some((role) => role === "administrators" || role === "editor"),
        userId: state.profile.active.id,
        canModerateFeed: state.profile.active.rights.canModerateFeed,
        filters: state.socialFeed.filters,
    }),
    {
        addToFavorite,
        deleteObject,
        removeFavorite,
        pinObject,
        verifyQuestion,
        startFollowingObject,
        stopFollowingObject,
        setShouldReloadFavourites,
        setConfirmPopup,
        hideObject
    }
)(SocialPostHeader);
