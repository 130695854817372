import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action, Dispatch } from "redux";
import { fetchUserFavorites } from "src/favorites/redux";
import { setShouldReloadFavourites } from "src/ui/actions";

const FavoriteLoader: FunctionComponent = () => {
    const [initialLoad, setInitialLoad] = useState(true);
    const dispatch = useDispatch<Dispatch<Action>>();

    const stateProps = useSelector(
        (state: Spintr.AppState) => ({
            isLoading: state.favorites.isLoading,
            shouldReload: state.ui.shouldReloadFavourites,
        })
    );

    useEffect(() => {
        if (!stateProps.shouldReload && !initialLoad) {
            return;
        }

        dispatch(fetchUserFavorites());
        dispatch(setShouldReloadFavourites(false));

        if (initialLoad) {
            setInitialLoad(false);
        }
    }, [stateProps.shouldReload]);

    return null;
};

export default FavoriteLoader;
