import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { localize } from 'src/l10n';
import { Label, Loader, SpintrUser } from "src/ui/components";
import "./TwoColumnListBox.scss";

interface IProps {
    isLoading?: boolean;
    isLoadingMore?: boolean;
    renderCommandBar?: any;
    items: any[];
    hasMore?: boolean;
    onLoadMoreClick?: any;
}

class TwoColumnListBox extends Component<IProps> {
    render() {
        return (
            <div className="TwoColumnListBox">
                <div className="TwoColumnListBox-inner">
                    {
                        this.props.renderCommandBar && (
                            <div>
                                {
                                    this.props.renderCommandBar()
                                }
                            </div>
                        )
                    }
                    {
                        this.props.items && this.props.items.length > 0 && (
                            <div className="TwoColumnListBox-items">
                                {
                                    this.props.items.map(item => {
                                        let className = "TwoColumnListBox-item";

                                        if (item.deleted) {
                                            className += " TwoColumnListBox-item-deleted"
                                        }

                                        let name = item.name + (item.deleted ? (" (" + localize("Borttagen") + ")") : "");

                                        return (
                                            <div className={className} key={item.id}>
                                                <Link to={item.url}>
                                                    <div className="TwoColumnListBox-item-inner">
                                                        <SpintrUser
                                                            imageUrl={item.imageUrl}
                                                            iconToDisplayInsteadOfInitials={item.icon}
                                                            name={name}
                                                            subText={item.description}
                                                            onRenderPrimaryText={() => {
                                                                return (
                                                                    <Label size="body-2" weight="medium" as="span">
                                                                        {
                                                                            name
                                                                        }
                                                                    </Label>
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </Link>
                                                {
                                                    item.renderActionMenu && item.renderActionMenu()
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    {
                        !this.props.isLoading && (!this.props.items || this.props.items.length === 0) && (
                            <div className="spintr-list-empty-list">
                                <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                                    {localize("IngaPoster")}
                                </Label>
                            </div>
                        )
                    }
                    {
                        this.props.isLoading && (
                            <Loader />
                        )
                    }
                </div>
            </div>
        )
    }
}

export default TwoColumnListBox;
