import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { Label, Scrollable, SpintrUser } from "src/ui/components";
import { Style } from "src/ui/helpers";
import { uniqueId } from "src/utils";
import "./ActivityLogPopupView.scss";
import { getActivityText } from "./ActivityLogUtil";

interface Props {
    currentUser: any;
    isInTeamsTools?: boolean;
}

interface State {
    isLoading: boolean;
    isLoadingMore: boolean;
    feed: any[];
    skip: number;
}

class ActivityLogPopupView extends Component<Props, State> {
    ref = React.createRef<HTMLDivElement>();
    state = {
        isLoading: true,
        isLoadingMore: false,
        feed: [],
        skip: 0,
    };

    formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    fetch = () => {
        const userId = this.props.currentUser.id
        const language = this.props.currentUser.settings.language;
        const { skip } = this.state;

        if (skip) {
            this.setState({ isLoadingMore: true }, () => {
                api
                    .get(`/api/v1/activitylog/${userId}?checkHasMore=true&filter=0&skip=${skip}&take=15&ubertype=0`)
                    .then((response) => {
                        response.data.forEach((item) => {
                            item.Text = getActivityText(language, item);
                            item.Name = item.UserId === userId ? localize("Du") : item.Name;
                            item.Created = this.formatDate(item.Created);
                        });
                        this.setState({
                            feed: [...this.state.feed, ...response.data],
                            isLoadingMore: false,
                            skip: this.state.skip + 15,
                        });
                    });
            });
        } else {
            this.setState({ isLoading: true }, () => {
                api
                    .get(`/api/v1/activitylog/${userId}?checkHasMore=true&filter=0&skip=${skip}&take=15&ubertype=0`)
                    .then((response) => {
                        response.data.forEach((item) => {
                            item.Text = getActivityText(language, item);
                            item.Name = item.UserId === userId ? localize("Du") : item.Name;
                            item.Created = this.formatDate(item.Created);
                        });
                        this.setState({
                            feed: [...this.state.feed, ...response.data],
                            isLoading: false,
                            skip: this.state.skip + 15,
                        }, () => {
                            this.ref.current.focus()
                            console.log("Focusing", this.ref)
                        });
                    });
            });
        }
    };
    componentDidMount = () => {
        this.fetch();
    };

    render() {
        const { isLoading, feed } = this.state;

        if (isLoading) {
            return (
                <div className="SpintrLoader-wrapper">
                    <Loader />
                </div>
            );
        }

        return (
            <div style={{ width: "320px", maxWidth: "100%", boxSizing: "border-box" }} tabIndex={0} ref={this.ref}>
                {
                    !this.props.isInTeamsTools && (
                        <Label as="h5" size="h5" style={{ margin: Style.getSpacing(3) }}>
                            {localize("SenasteHandelser")}
                        </Label>
                    )
                }
                <div style={{ height: this.props.isInTeamsTools ? 259 : 198 }}>
                    <Scrollable
                        displayLoader={false}
                        onEndReached={() => {
                            this.fetch();
                        }}
                    >
                        {feed.map((i, idx) => {
                            return (
                                <div key={uniqueId()} style={{ display: "flex", position: "relative", padding: Style.getSpacing(2) }}>
                                    <div style={{  flexGrow: 1, display: "flex" }}>
                                        <div style={{display: "inline-block", marginRight: Style.getSpacing(3) }}>
                                            <SpintrUser size={24} name={i.Name} imageUrl={i.ImageUrl} hideText={true} />
                                        </div>
                                        <div className="activityItem">
                                            <span>{i.Name} </span>
                                            {i.Text()}
                                        </div>
                                    </div>

                                    <div
                                        className="feedItemDate"
                                        style={{
                                            fontSize: "12px",
                                            color: Style.getHexFromSpintrColor("dark-grey"),
                                            flexShrink: 0
                                        }}
                                    >
                                        <span className="date">{i.Created}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </Scrollable>
                </div>
                {
                    !this.props.isInTeamsTools && (
                        <div className="viewAcivity primaryFGColor" style={{ textAlign: "center", padding: Style.getSpacing(3) }}>
                            <Link to={`/activitylog`}>{localize("VisaAktivitetslogg")}</Link>
                        </div>
                    )
                }
            </div>
        );
    }
}
const mapStateToProps = (state, props) => ({
    ...props,

    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(ActivityLogPopupView);
