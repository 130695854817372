import axios from "axios";
import classnames from "classnames";
import dompurify from "dompurify";
import React, { Component, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Breadcrumbs, Loader, PageHeader } from "src/ui";
import SpintrPagination from "src/ui/components/Pagination/SpintrPagination";
import { fromNow } from "src/utils";
import NewsSpotlightItem from "../../news/components/Spotlight/NewsSpotlightItem";
import { Label } from "../../ui";
import { IBlogPostQueryParams, loadBlogPostFeed, loadPrioritizedBlogs } from "../api-actions";
import BlogsActionMenu from "./BlogsActionMenu";
import "./BlogsCatalogueView.scss";
import { getSubMenu } from "src/sidebar/utils";

interface IPageRouteParams {
    path: string;
    tag: string;
    date: string;
    blogSlug: string;
    postList: string;
}

interface IBlogsCatalogueViewProps extends RouteChildrenProps<IPageRouteParams> {
    isSmallViewMode: boolean;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    dispatch?: any;
}

interface IBlogsCatalogueViewState {
    blogPosts: Spintr.IBlogPost[];
    isLoadingList: boolean;
    isLoadingPrioritized: boolean;
    prioritizedPosts: Spintr.IBlogPost[];
    pageSize: number;
    activePage: number;
    activeBlogPage: number;
    totalCount: number;
    totalBlogCount: number;
    skip: number;
    params: any;
    showBlogs: boolean;
    blogs: any;
    allBlogs: boolean;
    tags: any;
    date: string;
    heading: string;
}

class BlogsCatalogueView extends Component<IBlogsCatalogueViewProps, IBlogsCatalogueViewState> {
    private _isMounted: boolean = false;

    constructor(props: IBlogsCatalogueViewProps) {
        super(props);

        this.state = {
            blogPosts: [],
            isLoadingList: true,
            isLoadingPrioritized: false,
            prioritizedPosts: [],
            pageSize: 10,
            activePage: 0,
            activeBlogPage: 0,
            totalCount: 0,
            totalBlogCount: 0,
            skip: 0,
            params: {},
            showBlogs: false,
            blogs: [],
            allBlogs: false,
            tags: [],
            date: "",
            heading: "",
        };
    }

    public updateTag = () => {
        let { tag } = this.props.match.params;
        this.setState({ isLoadingList: true }, () => {
            api.get("/api/v1/blogs/gettags/").then((response) => {
                let tagId = response.data.find((r) => {
                    return r.text === tag;
                }).id;

                this.setState(
                    {
                        skip: 0,
                        showBlogs: false,
                        allBlogs: false,
                        params: { tagId, orderByColumn: "publishDate" },
                    },
                    () => {
                        this.standardFetch();
                    }
                );
            });
        });
    };

    public updateHistory = () => {
        let { date } = this.props.match.params;
        this.setState({ date, isLoadingList: true, heading: "Historik" }, () => {
            this.standardFetch();
        });
    };

    public componentDidUpdate = (prevProps) => {
        if (this.props.match.params["tag"] !== prevProps.match.params["tag"] && this.props.match.params["tag"]) {
            this.updateTag();
        }

        if (this.props.match.params["date"] !== prevProps.match.params["date"]) {
            this.updateHistory();
        }
    };

    updateSideBar() {
        getSubMenu("blogtop", this.props.dispatch, null, null).then((items: IVisageSidebarMenuItem[]) => {
            this.props.dispatch(setSidebarItems(items, "blogtop"));
        }).catch(() => { });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    public componentDidMount(): void {
        this._isMounted = true;
        this.updateSideBar();

        const mode = this.props.location.pathname.split("/")[2];
        const postList = this.props.location.pathname.split("/")[3];
        const { blogSlug } = this.props.match.params;

        if (blogSlug && !postList) {
            // E.g. /blogs/foobar
            this.setState({ isLoadingList: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular", blogId: blog.id, state: 2 },
                            isLoadingList: true,
                            heading: "Utkast",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                });
            });
        }

        if (postList === "archive") {
            this.setState({ isLoadingList: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular", blogId: blog.id },
                            isLoadingList: true,
                            heading: "Arkiv",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                });
            });
        } else if (postList === "drafts") {
            this.setState({ isLoadingList: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular", blogId: blog.id, state: 2 },
                            isLoadingList: true,
                            heading: "Utkast",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                });
            });
        } else if (postList === "published") {
            this.setState({ isLoadingList: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular", blogId: blog.id, state: 1 },
                            isLoadingList: true,
                            heading: "Publicerade",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                });
            });
        } else if (postList === "planned") {
            this.setState({ isLoadingList: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular", blogId: blog.id, state: 16 },
                            isLoadingList: true,
                            heading: "Planerade",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                });
            });
        } else if (postList === "expired") {
            this.setState({ isLoadingList: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular", blogId: blog.id, state: 8 },
                            isLoadingList: true,
                            heading: "Forfallet",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                });
            });
        } else if (postList === "deleted") {
            this.setState({ isLoadingList: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular", blogId: blog.id, state: 4 },
                            isLoadingList: true,
                            heading: "Borttagna",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                });
            });
        } else {
            switch (mode) {
                case "popular":
                    this.setState(
                        {
                            showBlogs: false,
                            allBlogs: false,
                            params: { orderByColumn: "popular" },
                            isLoadingList: true,
                            heading: "Popularaste",
                        },
                        () => {
                            this.standardFetch();
                        }
                    );
                    break;
                case "tags":
                    this.updateTag();
                    break;
                case "history":
                    this.updateHistory();
                    break;
                case "my":
                    this.setState({ isLoadingList: true, showBlogs: true, allBlogs: false }, () => {
                        api.get("/api/v1/blogs/userblogs").then((response) => {
                            this.setState({ blogs: response.data, isLoadingList: false });
                        });
                    });
                    break;
                case "list":
                    this.setState({ isLoadingList: true, showBlogs: true, allBlogs: true }, () => {
                        axios
                            .get(
                                "/api/v1/blogs?blogId=0&includeDeleted=false&isAscending=true&orderByColumn=name&skip=0&take=10"
                            )
                            .then((response) => {
                                this.setState({
                                    skip: 0,
                                    totalBlogCount: response.data.totalCount,
                                    blogs: response.data.blogs,
                                    isLoadingList: false,
                                });
                            });
                    });
                    break;

                case undefined:
                    this.setState({ isLoadingPrioritized: true, heading: "SenastPublicerat" }, () => {
                        loadPrioritizedBlogs().then((response) =>
                            this.setState({
                                isLoadingPrioritized: false,
                                prioritizedPosts: response.data.posts,
                            })
                        );
                    });

                    this.standardFetch();
                    break;
            }

            api.get("/api/v1/blogs/gettags").then((response) => {
                this.setState({ tags: response.data });
            });
        }
    }

    private standardFetch = () => {
        const listParams: IBlogPostQueryParams = {
            blogId: this.state.params.blogId ? this.state.params.blogId : 0,
            includeDeleted: false,
            isAscending: false,
            skip: this.state.skip,
            take: 10,
            ...(this.state.params.orderByColumn && { orderByColumn: this.state.params.orderByColumn }),
            ...(this.props.match.params.date && { date: this.props.match.params.date }),
            ...(this.state.params.tagId && { tagId: this.state.params.tagId }),
            ...(this.state.params.state && { state: this.state.params.state }),
        };

        loadBlogPostFeed(listParams).then((response) =>
            this.setState({
                blogPosts: response.data.posts,
                isLoadingList: false,
                totalCount: response.data.totalCount,
            })
        );
    };

    private onPageChange = (index: number): void => {
        let skip = index * this.state.pageSize;
        this.setState(
            {
                activePage: index,
                skip,
            },
            () => this.standardFetch()
        );
    };

    private onBlogPageChange = (index: number): void => {
        let skip = index * this.state.pageSize;

        this.setState(
            {
                activeBlogPage: index,
                isLoadingList: true,
            },
            () => {
                axios
                    .get(
                        `/api/v1/blogs?blogId=0&includeDeleted=false&isAscending=true&orderByColumn=name&skip=${skip}&take=10`
                    )
                    .then((response) => {
                        this.setState({
                            totalBlogCount: response.data.totalCount,
                            blogs: response.data.blogs,
                            isLoadingList: false,
                        });
                    });
            }
        );
    };

    public render(): ReactNode {
        const componentClassName = "BlogsCatalogueView";

        if (this.state.isLoadingList || this.state.isLoadingPrioritized) {
            return (
                <div className={componentClassName}>
                    <Loader />
                </div>
            );
        }

        return (
            <div className={componentClassName}>
                <Helmet>
                    <title>{localize("Bloggar")}</title>
                </Helmet>
                <div>
                    <Breadcrumbs
                        displayInstance
                        items={[
                            {
                                text: localize("Bloggar"),
                                key: 0,
                                link: "/blogs",
                            },
                        ]}
                    />
                    <PageHeader title={localize("Bloggar")} />
                    {this.state.params.orderByColumn || this.state.showBlogs ? null : (
                        <div className="prioritized">{this.renderPrioritized()}</div>
                    )}

                    <div className="stories"></div>

                    {this.state.showBlogs ? (
                        <>
                            <div className="blog">
                                {this.state.blogs.map((b) => {
                                    return this.renderBlog(b);
                                })}
                                {this.state.blogs.length === 0 && (
                                    <div className="spintr-list-empty-list">
                                        <Label
                                            className="spintr-list-empty-list-label"
                                            as="p"
                                            size="body-2"
                                            color="dark-grey"
                                        >
                                            {localize("IngaPoster")}
                                        </Label>
                                    </div>
                                )}
                            </div>

                            {this.state.allBlogs && (
                                <SpintrPagination
                                    activePage={this.state.activeBlogPage}
                                    totalCount={this.state.totalBlogCount}
                                    pageSize={this.state.pageSize}
                                    onPageChange={this.onBlogPageChange}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <div className="feed">
                                <Label as="h3" style={{ marginTop: 0 }}>
                                    {localize(this.state.heading)}
                                </Label>
                                {this.state.blogPosts.map((p) => {
                                    return this.renderBlogPost(p);
                                })}
                            </div>
                            <SpintrPagination
                                activePage={this.state.activePage}
                                totalCount={this.state.totalCount}
                                pageSize={this.state.pageSize}
                                onPageChange={this.onPageChange}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }

    public truncate = (str, toLength) => {
        return str.length > toLength ? str.substring(0, toLength - 3) + "..." : str;
    };

    public renderBlog = (blog) => {
        const key = `blogs.catalogueView.blog${blog.id}`;
        const { isAdmin, isEditor, currentUserId } = this.props;
        const isAuthor = blog.author && blog.author.id === currentUserId;
        const isCoauthor = blog.coauthors && blog.coauthors.some((ca) => ca.id === currentUserId);
        const { blogSlug } = this.props.match.params;

        return (
            <div className="BlogInList" key={key}>
                {blog.image && (
                    <div className="postImage" style={{ background: `url(${blog.image}) no-repeat center center` }}>
                        <div className="gradient"></div>
                    </div>
                )}

                <div className="postText">
                    <Label as="h2" size="h4" className="blog-title">
                        <Link to={"/blogs/" + blog.url.split("/")[2]}>{blog.name}</Link>
                    </Label>
                    {
                        blog.description && (
                            <Label as="p" size="body-2" className="description">
                                {blog.description}
                            </Label>
                        )
                    }
                </div>

                <div className="actionMenuPosition">
                    <ActionMenu
                        categories={[
                            {
                                items: [
                                    // TODO: Implement share
                                    // {
                                    //     text: localize("Dela"),
                                    //     onClick: () => {},
                                    //     icon: "mdiShare",
                                    // },
                                ],
                            },

                            ...(isAdmin || isEditor || isAuthor || isCoauthor
                                ? [
                                    {
                                        title: localize("Atgarder"),
                                        items: [
                                            {
                                                text: localize("NyBloggpost"),
                                                onClick: () => {
                                                    this.props.history.push({
                                                        pathname: `${blog.url}/create`,
                                                    });
                                                },
                                            },
                                            ...(isAdmin || isEditor || isAuthor
                                                ? [
                                                    {
                                                        text: localize("RedigeraBlogg"),
                                                        onClick: () => {
                                                            this.props.history.push({
                                                                pathname: `${blog.url}/edit`,
                                                            });
                                                        },
                                                    },
                                                ]
                                                : []),
                                            ...(isAdmin || isEditor || isAuthor
                                                ? [
                                                    {
                                                        text: localize("TaBort"),
                                                        onClick: () => {
                                                            const fn = () => {
                                                                this.setState({
                                                                    isLoadingList: true
                                                                }, () => {
                                                                    api.delete("/api/blogs/" + blog.id).then(() => {
                                                                        this.componentDidMount();
                                                                    });
                                                                });
                                                            }

                                                            this.props.dispatch(setConfirmPopup({
                                                                isOpen: true,
                                                                title: (
                                                                    false ?
                                                                        localize("VillDuVerkligenAterstallaX") :
                                                                        localize("VillDuVerkligenTaBortX")
                                                                ).replace("{{X}}", blog.name),
                                                                onConfirm: fn,
                                                            }));
                                                        },
                                                    },
                                                ]
                                                : []),
                                        ],
                                    },
                                ]
                                : []),
                        ]}
                    />
                </div>
            </div>
        );
    };

    public renderBlogPost = (post) => {
        const sanitizer = dompurify.sanitize;
        const key = `blogs.catalogueView.blogPost${post.id}`;
        const blogSlug = post.url.split("/")[2];
        const postSlug = post.url.split("/")[3];
        const blogLink = "/blogs/" + blogSlug;
        const postLink = "/blogs/" + blogSlug + "/" + postSlug;

        return (
            <div className="blogPost" key={key}>
                {post.image && (
                    <div className="postImage" style={{ background: `url(${post.image}) no-repeat center center` }}>
                        <div className="gradient"></div>
                    </div>
                )}
                <div className={post.image ? "postText has-image" : "postText"}>
                    <Label as="p" size="small-2" className="blog-title">
                        <Link to={blogLink}>{post.blog.name}</Link>
                    </Label>
                    <BlogsActionMenu
                        post={post}
                        callBack={(type) => {
                            var blogPosts = [...this.state.blogPosts];
                            let p = blogPosts.find((p) => p.id === post.id);
                            switch (type) {
                                case "follow":
                                case "unfollow":
                                    p.isFollowing = !p.isFollowing;
                                    break;
                                case "favourite":
                                case "unfavourite":
                                    p.isFavourite = !p.isFavourite;
                                    break;
                                case "delete":
                                    this.standardFetch();
                            }
                            this.setState({ blogPosts });
                        }}
                    />
                    <Label size="h4" as="h4" className="headline">
                        <Link to={postLink}>{post.headline}</Link>
                    </Label>

                    <div className="post-meta">
                        <Label size="body-3" color="visageGray3" title={""}>
                            {fromNow(post.publishDate, true)}
                        </Label>
                        <Label as="span" color="visageGray3" className="date-readtime-separator">|</Label>
                        <Label size="body-3" color="visageGray3" title={localize("READ_TIME")}>
                            {post.readTimeMinutes + " min " + localize("READ_TIME").toLowerCase()}
                        </Label>
                    </div>

                    {
                        post.preamble ?
                            (
                                <Label as="p" className="preamble">
                                    <Link to={postLink}>{post.preamble}</Link>
                                </Label>
                            ) :
                            post.text ?
                                (
                                    <Link to={postLink}>
                                        <Label size="body-2">
                                            <p
                                                className="preamble"
                                                dangerouslySetInnerHTML={{
                                                    __html: this.truncate(sanitizer(post.text, { ALLOWED_TAGS: [] }), 250),
                                                }}
                                            ></p>
                                        </Label>
                                    </Link>
                                ) :
                                null
                    }
                    <SocialBlock
                        uberId={post.id}
                        interactionsBarCommentPreview={post.commentPreview}
                        staticCommentCount={post.commentCount}
                        comments={[]}
                        likers={post.likers}
                        onlyDisplayInteractionsBar={true}
                        onCommentIconClick={() => {
                            this.props.history.push({
                                pathname: `/blogs/${blogSlug}/${postSlug}`,
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    public renderCarousel = (items) => {
        return (
            <div className="items">
                {items.map((item) => {
                    const key = `blogs.catalogueView.prioritized${item.id}`;
                    let article = item;
                    article.title = item.headline;
                    article.imageUrl = item.image;
                    article.author = item.blog.name;
                    article.attachedBackground = 2;
                    article.date = item.publishDate;

                    return (
                        <div
                            className={classnames("item", { ["full"]: this.props.isSmallViewMode })}
                            key={key}>
                            <NewsSpotlightItem article={article} isBlogpost />
                        </div>
                    )
                })}
            </div>
        );
    };

    public renderPrioritized(): ReactNode {
        const prioritized = this.state.prioritizedPosts;
        return this.renderCarousel(prioritized);
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default withRouter(connect(mapStateToProps)(BlogsCatalogueView));
