import {
    DatePicker,
    DayOfWeek,
    DefaultButton,
    Dropdown, IDropdownOption, Pivot, PivotItem, PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import classNames from "classnames";
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CompetenceView from 'src/admin/views/CompetenceView';
import { getTypeName, localize } from 'src/l10n';
import { IActiveUserProfile } from 'src/profile/reducer';
import { SocialFeedType } from 'src/social-feed';
import api from "src/spintr/SpintrApi";
import { RemoveFavoriteHandler, removeFavorite } from 'src/spintr/actions';
import { SpintrSocialFeed } from 'src/spintr/components';
import { IApplicationState } from 'src/spintr/reducer';
import { ActionMenu, ImageCore, Label, Loader, SpintrUser, UnstyledButton, setShouldReloadFavourites } from 'src/ui';
import { FormControl } from 'src/ui/components/Forms';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import { listActionMenuWidth } from "src/ui/helpers/style";
import { capitalizeFirstLetter, decodeHtmlEntities } from 'src/utils';
import formatDatePickerDate from 'src/utils/formatDatePickerDate';
import getDatePickerLanguageStrings from 'src/utils/getDatePickerLanguageStrings';
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import './ProfileView.scss';
import ProfilePosition from './components/ProfilePosition';

interface IPageRouteParams {
    path: string;
    id: any;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    currentUser: IActiveUserProfile;
    instance: any;
    userGalleryId: number;
    isUserManager: boolean;
    setShouldReloadFavourites: any;
    removeFavourite: RemoveFavoriteHandler;
    isSmallViewMode: boolean;
    enableCompanyLevel?: boolean;
    displayCustom1?: boolean;
    forceCompanyFeed?: boolean;
}

interface IState {
    isLoading: boolean;
    isLoadingInformation: boolean;
    isLoadingFavorites: boolean;
    profile: any;
    favorites: any;
    images: any;
    educations: any;
    showEducationForm: boolean;
    education: any;
    employers: any;
    showEmployerForm: boolean;
    employer: any;
    competences: any;
    showCompetenceForm: boolean;
    competence: any;
    interests: any;
    showInterestForm: boolean;
    interest: any;
    family: string;
    showFamilyForm: boolean;
    isOwnPage: boolean;
    hideUserEmailAddresses: boolean;
}

const removeUberTypeFromUrl = (url) => {
    url = url.split("/");
    url.splice(2, 1);
    return url.join("/");
};

const sortFavoritesIntoCategories = (favorites) => {
    let categories = [];

    favorites.map((fav) => {
        let category = categories.find((cat) => {
            return cat.id == fav.type;
        });

        if (category) {
            category.favorites.push(fav);
            return;
        }

        category = {
            id: fav.type,
            name: getTypeName(fav.type, {
                case: "none",
            }),
            favorites: [fav],
        };
        categories.push(category);
    });
    return categories;
};

class ProfileView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isLoadingInformation: false,
            isLoadingFavorites: false,
            profile: undefined,
            favorites: undefined,
            images: [],
            educations: [],
            showEducationForm: false,
            education: {},
            employers: [],
            showEmployerForm: false,
            employer: {},
            competences: [],
            showCompetenceForm: false,
            competence: {},
            interests: [],
            showInterestForm: false,
            interest: {},
            family: "",
            showFamilyForm: false,
            isOwnPage: this.props.currentUser.id == this.props.match.params["id"],
            hideUserEmailAddresses: this.props.instance.get("hideUserEmailAddresses"),
        };
    }

    formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({ isOwnPage: this.props.currentUser.id == this.props.match.params["id"] });
            this.fetch();
        }
    };

    fetchFavorites = () => {
        const { id } = this.props.match.params;

        api.get(`/api/v1/favorites/?userId=${id}`).then((response) => {
            this.setState({ favorites: sortFavoritesIntoCategories(response.data), isLoadingFavorites: false });
        });
    };

    fetch = () => {
        const { id } = this.props.match.params;
        this.fetchInformation();

        this.fetchFavorites();

        api.get(`/api/v1/users/${id}/images`).then((response) => {
            this.setState({ images: response.data });
        });

        this.setState({ isLoading: true }, () => {
            api.get(`/api/v1/profiles/${id}`).then((response) => {
                this.setState({ profile: response.data, isLoading: false });
            }).catch((e => {
                this.props.history.push("/404");
            }));
        });
    };

    componentDidMount = () => {
        this.fetch();
    };

    header = () => {
        const { profile, isOwnPage } = this.state;

        return (
            <div className="header">
                <div className="artwork">
                    <div className="overlay"></div>
                    {!!profile.CoverArt && (
                        <img alt="" src={profile.CoverArt} />
                    )}
                </div>
                <div className="user-info">
                    <SpintrUser
                        name={profile.Name}
                        imageUrl={profile.ProfileImage}
                        size={!this.props.isSmallViewMode ? 100 : 72}
                        big
                        subText={profile.Role}
                        onRenderOptionalText={() => {
                            return isOwnPage ? (
                                <>
                                    <span>{profile.followers.length}</span>{" "}
                                    <span>{localize("Foljare").toLowerCase()}</span>
                                    <span> - {localize("Foljer").toLowerCase()} </span>
                                    <span>{profile.following.length}</span>
                                </>
                            ) : (
                                <>
                                    {/* TODO: Implement this:
                                    <span>{localize("SkickaMeddelande")} - </span>
                                    <span>{localize("Folj")} - </span> */}
                                    <span>{profile.followers.length}</span>{" "}
                                    <span>{localize("Foljare").toLowerCase()}</span>
                                    <span> - {localize("Foljer").toLowerCase()} </span>
                                    <span>{profile.following.length}</span>
                                </>
                            );
                        }}
                    />
                </div>
                {this.state.isOwnPage && (
                    <ActionMenu
                        categories={[
                            {
                                title: "",
                                items: [
                                    {
                                        text: localize("AndraProfilBild"),
                                        onClick: () => {
                                            this.props.history.push(`/profile-settings`);
                                        },
                                    },
                                    {
                                        text: localize("AndraOmslagsBild"),
                                        onClick: () => {
                                            this.props.history.push(`/profile-settings`);
                                        },
                                    },
                                    {
                                        text: localize("Profilinstallningar"),
                                        onClick: () => {
                                            this.props.history.push(`/profile-settings`);
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                )}
            </div>
        );
    };

    feed = () => {
        return (
            <div role="region">
                <SpintrSocialFeed
                    feedId={this.state.profile.feedId}
                    feedType={SocialFeedType.Profile}
                    userId={this.props.match.params.id}
                    galleryId={this.props.userGalleryId}
                />
            </div>

        );
    };

    summary = () => {
        const { profile } = this.state;
        return (
            <div className="summary">
                <div className="row">
                    <div className="left-column">
                        <div className="details">
                            <Label as="h3" size="h3">
                                <b>{profile.Name}</b>
                            </Label>
                            {profile.Role && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("Roll")}
                                    </Label>
                                    {profile.Role}
                                </>
                            )}

                            {this.props.enableCompanyLevel && profile.Company && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("Foretag")}
                                    </Label>
                                    {profile.Company}
                                </>
                            )}

                            <>
                                <Label as="h4" size="h4">
                                    {localize("Kontor")}
                                </Label>
                                {[profile.Office].join(", ")}
                            </>
                            <>
                                <Label as="h4" size="h4">
                                    {localize("Avdelning")}
                                </Label>
                                {[profile.Department].join(", ")}
                            </>
                            {this.props.displayCustom1 && profile.custom1 && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("CUSTOM1_TEXT")}
                                    </Label>
                                    {profile.custom1}
                                </>
                            )}
                        </div>
                        <div className="contact-info">
                            <Label as="h3" size="h3">
                                <b>{localize("Kontaktinformation")}</b>
                            </Label>
                            {!this.state.hideUserEmailAddresses && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("Email")}
                                    </Label>
                                    <a className="" href={`mailto:${profile.EmailAddress}`}>
                                        {profile.EmailAddress}
                                    </a>

                                    {profile.PersonalEmail && (
                                        <>
                                            <Label as="h4" size="h4">
                                                {localize("PersonligEpost")}
                                            </Label>
                                            <a className="" href={`mailto:${profile.PersonalEmail}`}>
                                                {profile.PersonalEmail}
                                            </a>
                                        </>
                                    )}
                                </>
                            )}
                            {profile.Phone && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("Telefonnummer")}
                                    </Label>
                                    {profile.Phone}
                                </>
                            )}
                            {profile.Cellphone && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("Mobilnummer")}
                                    </Label>
                                    {profile.Cellphone}
                                </>
                            )}
                            {profile.PersonalPhone && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("PersonligTelefon")}
                                    </Label>
                                    {profile.PersonalPhone}
                                </>
                            )}
                            {profile.Family && (
                                <>
                                    <Label as="h4" size="h4">
                                        {localize("Familj")}
                                    </Label>
                                    {profile.Family}
                                </>
                            )}
                        </div>
                        {/* {!isOwnPage && (
                            <PrimaryButton
                                onClick={() => {
                                    alert("Not implemented.");
                                }}
                            >
                                {localize("SkickaMeddelande")}
                            </PrimaryButton>
                        )} */}
                        {
                            profile.Duties && profile.Duties.length > 0 && (
                                <div className="duties">
                                    <Label as="h3" size="h3">
                                        <b>{localize("Arbetsuppgifter")}</b>
                                    </Label>
                                    {profile.Duties}
                                </div>
                            )
                        }
                        <div className="Address">
                            <Label as="h4" size="h4">
                                <b>{localize("Adress")}</b>
                            </Label>
                            {profile.Address && (
                                <>
                                    <span>{profile.Address}</span>
                                    <br />
                                </>
                            )}
                            {profile.Address2 && (
                                <>
                                    <span>{profile.Address2}</span>
                                    <br />
                                </>
                            )}
                            {profile.ZipCode && (
                                <>
                                    <span>{profile.ZipCode}</span>
                                    <br />
                                </>
                            )}
                            {profile.City && (
                                <>
                                    <span>{profile.City}</span>
                                    <br />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="right-column">
                        <div className="latest-content">
                            <Label as="h3" size="h3">
                                <b>{capitalizeFirstLetter(localize("SenasteInnehall"))}</b>
                            </Label>
                            {profile.LatestContent.Blogs.length > 0 && (
                                <>
                                    <Label as="h4" size="h4">
                                        {capitalizeFirstLetter(localize("Ubertype21_1_0"))}
                                    </Label>
                                    <ul className="content-list">
                                        {profile.LatestContent.Blogs &&
                                            profile.LatestContent.Blogs.map((b, idx) => {
                                                return (
                                                    <li key={idx}>
                                                        <a className="" href={`/${b.Url}`}>
                                                            {b.Blog} - {b.Title}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </>
                            )}

                            {profile.LatestContent.Wikis.length > 0 && (
                                <>
                                    <Label as="h4" size="h4">
                                        {capitalizeFirstLetter(localize("Wikis"))}
                                    </Label>
                                    <ul className="content-list">
                                        {profile.LatestContent.Wikis.map((w, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <a className="" href={`/${w.Url}`}>
                                                        {w.Wiki} - {w.Title}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {this.state.images && this.state.images.length > 0 && (
                    <div className="latest-images">
                        <Label as="h3" size="h3">
                            <b>{capitalizeFirstLetter(localize("SenasteBilder"))}</b>
                        </Label>
                        <div className="images">
                            {this.state.images.slice(0, 3).map((f) => {
                                return <ImageCore key={f.id} alt="" openPhotoBoxOnClick={true} src={f.imageUrl} imageId={f.id} />;
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    development = () => {
        return <CompetenceView userId={this.state.profile.Id} isInProfileView={true} />;
    };

    removeFavorite = (id) => {
        this.setState({ isLoadingFavorites: true }, () => {
            // FIXME: Implement favorites list in Redux
            // this.props.removeFavourite(id).then((resp) => { }

            api.delete(`/api/v1/favorites/${id}`).then((resp) => {
                this.fetchFavorites();
                this.props.setShouldReloadFavourites(true);
            });
        });
    };

    favorites = () => {
        const { favorites, isLoadingFavorites } = this.state;

        if (isLoadingFavorites) return <Loader />;

        return (
            <div className="favorites">
                <div className="content-list">
                    {favorites && favorites.length > 0 ? (
                        favorites.map((category) => {
                            return (
                                <div className="favorite-category" key={category.id}>
                                    <Label as="h2" color="grey" size="small-2" uppercase>
                                        {capitalizeFirstLetter(category.name)}
                                    </Label>
                                    {category.favorites.map((favorite) => {
                                        return (
                                            <div className="favorite" key={favorite.id}>
                                                <Link className="" to={removeUberTypeFromUrl(favorite.url)}>
                                                    {decodeHtmlEntities(favorite.title)}
                                                </Link>
                                                <UnstyledButton
                                                    ariaLabel={localize("TaBort")}
                                                    onClick={() => {
                                                        this.removeFavorite(favorite.id);
                                                    }}
                                                >
                                                    <Visage2Icon icon="close-circle" />
                                                </UnstyledButton>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    ) : (
                        <p>{localize("DuHarIngaFavoriter")}</p>
                    )}
                </div>
            </div>
        );
    };

    images = () => {
        const { images } = this.state;

        return (
            <div className="images-tab">
                {images && images.length > 0 ? (
                    images.map((f) => {
                        return <ImageCore key={f.id} openPhotoBoxOnClick={true} alt="" imageId={f.id} src={f.imageUrl} />;
                    })
                ) : (
                    <p>
                        {this.state.profile.Name} {localize("HarInteLaddatUppNagraBilder")}
                    </p>
                )}
            </div>
        );
    };

    education = () => {
        return (
            <SpintrList
                disableCommandBar
                disablePagination
                disableSort
                fetch={() => { }}
                data={{ data: this.state.educations, totalCount: 100 }}
                isLoading={false}
                columns={[
                    {
                        name: localize("Skola"),
                        fieldName: "school",
                        maxWidth: 180,
                    },
                    {
                        name: localize("Fran"),
                        fieldName: "started",
                        maxWidth: 120,
                        onRender: (item) => {
                            return <span>{item.started && this.formatDate(item.started)}</span>;
                        },
                    },
                    {
                        name: localize("Till"),
                        fieldName: "graduated",
                        maxWidth: 120,
                        onRender: (item) => {
                            return <span>{item.graduated && this.formatDate(item.graduated)}</span>;
                        },
                    },
                    {
                        name: localize("Notering"),
                        fieldName: "description",
                        minWidth: 120,
                        isMultiline: true,
                    },
                    {
                        name: "",
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        onRender: (item: any) => {
                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.setState({ education: item, showEducationForm: true });
                                                    },
                                                },
                                                {
                                                    text: localize("TaBort"),
                                                    onClick: () => {
                                                        this.deleteEducation(item.id);
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn={"date"}
            />
        );
    };

    employers = () => {
        return (
            <SpintrList
                disableCommandBar
                disablePagination
                disableSort
                fetch={() => { }}
                data={{ data: this.state.employers, totalCount: 100 }}
                isLoading={false}
                columns={[
                    {
                        name: localize("Foretag"),
                        fieldName: "name",
                        maxWidth: 180,
                    },
                    {
                        name: localize("Fran"),
                        fieldName: "started",
                        maxWidth: 120,
                        onRender: (item) => {
                            return <span>{item.started && this.formatDate(item.started)}</span>;
                        },
                    },
                    {
                        name: localize("Till"),
                        fieldName: "left",
                        maxWidth: 120,
                        onRender: (item) => {
                            return <span>{item.left && this.formatDate(item.left)}</span>;
                        },
                    },
                    {
                        name: localize("Notering"),
                        fieldName: "jobDescription",
                        isMultiline: true,
                        minWidth: 120,
                    },
                    {
                        name: "",
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        onRender: (item: any) => {
                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.setState({ employer: item, showEmployerForm: true });
                                                    },
                                                },
                                                {
                                                    text: localize("TaBort"),
                                                    onClick: () => {
                                                        this.deleteEmployer(item.id);
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn={"date"}
            />
        );
    };

    competences = () => {
        return (
            <SpintrList
                disableCommandBar
                disablePagination
                disableSort
                fetch={() => { }}
                data={{ data: this.state.competences, totalCount: 100 }}
                isLoading={false}
                columns={[
                    {
                        name: localize("Kompetens"),
                        fieldName: "name",
                        maxWidth: 180,
                    },
                    {
                        name: localize("Niva"),
                        fieldName: "proficiency",
                        maxWidth: 60,
                    },
                    {
                        name: localize("Notering"),
                        fieldName: "description",
                        isMultiline: true,
                    },
                    {
                        name: "",
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        onRender: (item: any) => {
                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.setState({ competence: item, showCompetenceForm: true });
                                                    },
                                                },
                                                {
                                                    text: localize("TaBort"),
                                                    onClick: () => {
                                                        this.deleteCompetence(item.id);
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn={"date"}
            />
        );
    };

    interests = () => {
        return (
            <SpintrList
                disableCommandBar
                disablePagination
                disableSort
                fetch={() => { }}
                data={{ data: this.state.interests, totalCount: 100 }}
                isLoading={false}
                columns={[
                    {
                        name: localize("Intresse"),
                        fieldName: "name",
                        maxWidth: 180,
                    },
                    {
                        name: localize("Notering"),
                        fieldName: "description",
                        isMultiline: true,
                    },
                    {
                        name: "",
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        onRender: (item: any) => {
                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () => {
                                                        this.setState({ interest: item, showInterestForm: true });
                                                    },
                                                },
                                                {
                                                    text: localize("TaBort"),
                                                    onClick: () => {
                                                        this.deleteInterest(item.id);
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn={"date"}
            />
        );
    };

    educationForm = () => {
        const { education } = this.state;
        return (
            <Stack>
                <TextField
                    label={localize("Skola")}
                    value={education.school}
                    onChange={(_e, school) => {
                        this.setState((state) => ({ education: { ...state.education, school } }));
                    }}
                />
                <FormControl>
                    <DatePicker
                        label={localize("Fran")}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={getDatePickerLanguageStrings()}
                        formatDate={formatDatePickerDate}
                        placeholder={localize("ValjDatum")}
                        ariaLabel={localize("ValjDatum")}
                        value={education.started}
                        onSelectDate={(started: Date) => {
                            this.setState((state) => ({
                                education: {
                                    ...state.education,
                                    started,
                                },
                            }));
                        }}
                    />
                    <DatePicker
                        label={localize("Fran")}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={getDatePickerLanguageStrings()}
                        formatDate={formatDatePickerDate}
                        placeholder={localize("ValjDatum")}
                        ariaLabel={localize("ValjDatum")}
                        value={education.graduated}
                        onSelectDate={(graduated: Date) => {
                            this.setState((state) => ({
                                education: {
                                    ...state.education,
                                    graduated,
                                },
                            }));
                        }}
                    />
                </FormControl>
                <TextField
                    label={localize("Notering")}
                    value={education.description}
                    onChange={(_e, description) => {
                        this.setState((state) => ({ education: { ...state.education, description } }));
                    }}
                />
                <FormControl>
                    <DefaultButton
                        onClick={() => {
                            this.setState({ showEducationForm: false, education: {} });
                        }}
                    >
                        {localize("Avbryt")}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            this.saveEducation();
                        }}
                    >
                        {localize("Spara")}
                    </PrimaryButton>
                </FormControl>
            </Stack>
        );
    };

    employerForm = () => {
        const { employer } = this.state;
        return (
            <Stack>
                <TextField
                    label={localize("Foretagsnamn")}
                    value={employer.name}
                    onChange={(_e, name) => {
                        this.setState((state) => ({ employer: { ...state.employer, name } }));
                    }}
                />
                <FormControl>
                    <DatePicker
                        label={localize("Fran")}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={getDatePickerLanguageStrings()}
                        formatDate={formatDatePickerDate}
                        placeholder={localize("ValjDatum")}
                        ariaLabel={localize("ValjDatum")}
                        value={employer.started}
                        onSelectDate={(started: Date) => {
                            this.setState((state) => ({
                                employer: {
                                    ...state.employer,
                                    started,
                                },
                            }));
                        }}
                    />
                    <DatePicker
                        label={localize("Fran")}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={getDatePickerLanguageStrings()}
                        formatDate={formatDatePickerDate}
                        placeholder={localize("ValjDatum")}
                        ariaLabel={localize("ValjDatum")}
                        value={employer.left}
                        onSelectDate={(left: Date) => {
                            this.setState((state) => ({
                                employer: {
                                    ...state.employer,
                                    left,
                                },
                            }));
                        }}
                    />
                </FormControl>
                <TextField
                    label={localize("Notering")}
                    value={employer.jobDescription}
                    onChange={(_e, jobDescription) => {
                        this.setState((state) => ({ employer: { ...state.employer, jobDescription } }));
                    }}
                />
                <FormControl>
                    <DefaultButton
                        onClick={() => {
                            this.setState({ showEmployerForm: false, employer: {} });
                        }}
                    >
                        {localize("Avbryt")}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            this.saveEmployer();
                        }}
                    >
                        {localize("Spara")}
                    </PrimaryButton>
                </FormControl>
            </Stack>
        );
    };

    competenceForm = () => {
        const { competence } = this.state;
        return (
            <Stack>
                <TextField
                    label={localize("Kompetens")}
                    value={competence.name}
                    onChange={(_e, name) => {
                        this.setState((state) => ({ competence: { ...state.competence, name } }));
                    }}
                />
                <Dropdown
                    selectedKey={competence.proficiency}
                    label={localize("Niva")}
                    onChange={(event: React.FormEvent<HTMLDivElement>, i: IDropdownOption) => {
                        this.setState((state) => ({
                            competence: { ...state.competence, proficiency: i.key },
                        }));
                    }}
                    options={[
                        { key: 1, text: "1" },
                        { key: 2, text: "2" },
                        { key: 3, text: "3" },
                        { key: 4, text: "4" },
                        { key: 5, text: "5" },
                    ]}
                />

                <TextField
                    label={localize("Notering")}
                    value={competence.description}
                    onChange={(_e, description) => {
                        this.setState((state) => ({ competence: { ...state.competence, description } }));
                    }}
                />
                <FormControl>
                    <DefaultButton
                        onClick={() => {
                            this.setState({ showCompetenceForm: false, competence: {} });
                        }}
                    >
                        {localize("Avbryt")}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            this.saveCompetence();
                        }}
                    >
                        {localize("Spara")}
                    </PrimaryButton>
                </FormControl>
            </Stack>
        );
    };

    interestForm = () => {
        const { interest } = this.state;
        return (
            <Stack>
                <TextField
                    label={localize("Intresse")}
                    value={interest.name}
                    onChange={(_e, name) => {
                        this.setState((state) => ({ interest: { ...state.interest, name } }));
                    }}
                />

                <TextField
                    label={localize("Notering")}
                    value={interest.description}
                    onChange={(_e, description) => {
                        this.setState((state) => ({ interest: { ...state.interest, description } }));
                    }}
                />
                <FormControl>
                    <DefaultButton
                        onClick={() => {
                            this.setState({ showInterestForm: false, interest: {} });
                        }}
                    >
                        {localize("Avbryt")}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            this.saveInterest();
                        }}
                    >
                        {localize("Spara")}
                    </PrimaryButton>
                </FormControl>
            </Stack>
        );
    };

    familyForm = () => {
        const { family } = this.state;
        return (
            <Stack>
                <TextField
                    label={localize("Familj")}
                    value={family}
                    onChange={(_e, family) => {
                        this.setState({ family });
                    }}
                    multiline={true}
                />
                <FormControl>
                    <DefaultButton
                        onClick={() => {
                            this.setState({ showFamilyForm: false });
                        }}
                    >
                        {localize("Avbryt")}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            this.saveFamily();
                        }}
                    >
                        {localize("Spara")}
                    </PrimaryButton>
                </FormControl>
            </Stack>
        );
    };

    fetchInformation = () => {
        const { id } = this.props.match.params;

        this.setState({ isLoadingInformation: true }, () => {
            api.get(`/api/v1/profiles/${id}/information`).then((response) => {
                let { data } = response;

                for (var i = 0; i < data.education.length; i++) {
                    if (data.education[i].started) data.education[i].started = new Date(data.education[i].started);
                    if (data.education[i].graduated)
                        data.education[i].graduated = new Date(data.education[i].graduated);
                }

                for (var i = 0; i < data.employers.length; i++) {
                    if (data.employers[i].started) data.employers[i].started = new Date(data.employers[i].started);
                    if (data.employers[i].left) data.employers[i].left = new Date(data.employers[i].left);
                }

                this.setState({
                    educations: data.education,
                    employers: data.employers,
                    competences: data.skills,
                    interests: data.interests,
                    family: data.family,
                    isLoadingInformation: false,
                });
            });
        });
    };

    deleteEducation = (id) => {
        this.setState({ isLoadingInformation: true }, () => {
            api.delete(`/api/v1/usereducations/${id}`).then((response) => {
                this.setState({ isLoadingInformation: false, showEducationForm: false }, () => {
                    this.fetchInformation();
                });
            });
        });
    };

    deleteEmployer = (id) => {
        this.setState({ isLoadingInformation: true }, () => {
            api.delete(`/api/v1/useremployers/${id}`).then((response) => {
                this.setState({ isLoadingInformation: false, showEmployerForm: false }, () => {
                    this.fetchInformation();
                });
            });
        });
    };

    deleteCompetence = (id) => {
        this.setState({ isLoadingInformation: true }, () => {
            api.delete(`/api/v1/userskills/${id}`).then((response) => {
                this.setState({ isLoadingInformation: false, showCompetenceForm: false }, () => {
                    this.fetchInformation();
                });
            });
        });
    };

    deleteInterest = (id) => {
        this.setState({ isLoadingInformation: true }, () => {
            api.delete(`/api/v1/userinterests/${id}`).then((response) => {
                this.setState({ isLoadingInformation: false, showInterestForm: false }, () => {
                    this.fetchInformation();
                });
            });
        });
    };

    saveEducation = () => {
        let { education } = this.state;
        education.userId = this.props.match.params.id;

        if (education.id) {
            this.setState({ isLoadingInformation: true }, () => {
                api.put(`/api/v1/usereducations/${education.id}`, education).then((response) => {
                    this.setState({ isLoadingInformation: false, showEducationForm: false, education: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        } else {
            this.setState({ isLoadingInformation: true }, () => {
                api.post(`/api/v1/usereducations`, education).then((response) => {
                    this.setState({ isLoadingInformation: false, showEducationForm: false, education: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        }
    };

    saveEmployer = () => {
        let { employer } = this.state;
        employer.userId = this.props.match.params.id;

        if (employer.id) {
            this.setState({ isLoadingInformation: true }, () => {
                api.put(`/api/v1/useremployers/${employer.id}`, employer).then((response) => {
                    this.setState({ isLoadingInformation: false, showEmployerForm: false, employer: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        } else {
            this.setState({ isLoadingInformation: true }, () => {
                api.post(`/api/v1/useremployers`, employer).then((response) => {
                    this.setState({ isLoadingInformation: false, showEmployerForm: false, employer: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        }
    };

    saveCompetence = () => {
        let { competence } = this.state;
        competence.userId = this.props.match.params.id;

        if (competence.id) {
            this.setState({ isLoadingInformation: true }, () => {
                api.put(`/api/v1/userskills/${competence.id}`, competence).then((response) => {
                    this.setState({ isLoadingInformation: false, showCompetenceForm: false, competence: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        } else {
            this.setState({ isLoadingInformation: true }, () => {
                api.post(`/api/v1/userskills`, competence).then((response) => {
                    this.setState({ isLoadingInformation: false, showCompetenceForm: false, competence: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        }
    };

    saveInterest = () => {
        let { interest } = this.state;
        interest.userId = this.props.match.params.id;

        if (interest.id) {
            this.setState({ isLoadingInformation: true }, () => {
                api.put(`/api/v1/userinterests/${interest.id}`, interest).then((response) => {
                    this.setState({ isLoadingInformation: false, showInterestForm: false, interest: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        } else {
            this.setState({ isLoadingInformation: true }, () => {
                api.post(`/api/v1/userinterests`, interest).then((response) => {
                    this.setState({ isLoadingInformation: false, showInterestForm: false, interest: {} }, () => {
                        this.fetchInformation();
                    });
                });
            });
        }
    };

    saveFamily = () => {
        const { family } = this.state;
        let model = { userId: this.props.match.params.id, family };
        this.setState({ isLoadingInformation: true }, () => {
            api.put(`/api/v1/users/family`, model).then((response) => {
                this.setState({ isLoadingInformation: false, showFamilyForm: false }, () => {
                    this.fetchInformation();
                });
            });
        });
    };

    family = () => {
        return <p>{this.state.family}</p>;
    };

    information = () => {
        if (this.state.isLoadingInformation) return <Loader />;
        const canAdd = this.state.isOwnPage || this.props.isUserManager || this.props.currentUser.isAdmin;

        return (
            <div className="informationContent">
                <div className="label-and-menu">
                    <Label as="h4" size="h4">
                        {localize("Utbildning")}
                    </Label>

                    {canAdd && (
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("LaggTillNy"),
                                            onClick: () => this.setState({ showEducationForm: true }),
                                        },
                                    ],
                                },
                            ]}
                        />
                    )}
                </div>

                <div className="education">
                    {this.education()}
                    {this.state.showEducationForm && this.educationForm()}
                </div>

                <div className="label-and-menu">
                    <Label as="h4" size="h4">
                        {localize("Arbetsgivare")}
                    </Label>
                    {canAdd && (
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("LaggTillNy"),
                                            onClick: () => this.setState({ showEmployerForm: true }),
                                        },
                                    ],
                                },
                            ]}
                        />
                    )}
                </div>
                <div className="employers">
                    {this.employers()}
                    {this.state.showEmployerForm && this.employerForm()}
                </div>

                <div className="label-and-menu">
                    <Label as="h4" size="h4">
                        {localize("Kompetenser")}
                    </Label>

                    {canAdd && (
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("LaggTillNy"),
                                            onClick: () => this.setState({ showCompetenceForm: true }),
                                        },
                                    ],
                                },
                            ]}
                        />
                    )}
                </div>
                <div className="competences">
                    {this.competences()}
                    {this.state.showCompetenceForm && this.competenceForm()}
                </div>

                <div className="label-and-menu">
                    <Label as="h4" size="h4">
                        {localize("Intressen")}
                    </Label>
                    {canAdd && (
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("LaggTillNy"),
                                            onClick: () => this.setState({ showInterestForm: true }),
                                        },
                                    ],
                                },
                            ]}
                        />
                    )}
                </div>

                <div className="interests">
                    {this.interests()}
                    {this.state.showInterestForm && this.interestForm()}
                </div>

                <div className="label-and-menu">
                    <Label as="h4" size="h4">
                        {localize("Familj")}
                    </Label>

                    {canAdd && (
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("Redigera"),
                                            onClick: () => this.setState({ showFamilyForm: true }),
                                        },
                                    ],
                                },
                            ]}
                        />
                    )}
                </div>
                <div className="family">
                    {this.state.showFamilyForm && this.familyForm()}
                    {!this.state.showFamilyForm && this.family()}
                </div>
            </div>
        );
    };

    renderPosition = () => {
        return (
            <ProfilePosition userId={this.props.match.params.id} />
        )
    }

    tabs = () => {
        const canAdd = this.state.isOwnPage || this.props.isUserManager || this.props.currentUser.isAdmin;

        return (
            <div role="main">
                <Pivot overflowBehavior={"menu"} defaultSelectedKey="summary">
                    {this.props.isSmallViewMode && (!this.props.forceCompanyFeed || this.state.profile.CompanyId === this.props.currentUser.department.office.company.id) && <PivotItem headerText={localize("Handelser")}>{this.feed()}</PivotItem>}
                    <PivotItem headerText={localize("Sammanfattning")} itemKey="summary">{this.summary()}</PivotItem>
                    {canAdd && <PivotItem headerText={localize("Utveckling")}>{this.development()}</PivotItem> }
                    <PivotItem headerText={localize("Bilder")}>{this.images()}</PivotItem>
                    <PivotItem headerText={localize("Information")}>{this.information()}</PivotItem>
                    <PivotItem headerText={localize("Position")}>{this.renderPosition()}</PivotItem>
                    {this.state.isOwnPage && <PivotItem headerText={localize("Favoriter")}>{this.favorites()}</PivotItem>}
                </Pivot>
            </div>

        );
    };

    render() {
        if (this.state.isLoading) return <Loader />;

        return (
            <div className="profile-view">
                <Helmet><title>{this.state.profile.Name}</title></Helmet>
                {this.header()}

                {this.props.isSmallViewMode ? (
                    this.tabs()
                ) : (
                    <div className="feed-and-tabs">
                        {!this.props.isSmallViewMode && (!this.props.forceCompanyFeed || this.state.profile.CompanyId === this.props.currentUser.department.office.company.id) && <div className="socialFeed">{this.feed()}</div>}
                        <div className={classNames("tabs", { "hide-feed": this.props.forceCompanyFeed && this.state.profile.CompanyId !== this.props.currentUser.department.office.company.id})}>{this.tabs()}</div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => {
    const user = state.profile.active;
    const roles = user.roles;

    return {
        currentUser: user,
        enableCompanyLevel: state.instance.get("enableCompanyLevel"),
        displayCustom1: state.instance.get("displayCustom1OnProfile"),
        instance: state.instance,
        isSmallViewMode: state.ui.isSmallViewMode,
        isUserManager: roles.includes("usermanager") && !user.isAdmin,
        sidebarIsExpanded: state.ui.sidebarExpanded,
        userGalleryId: state.profile.active.galleryId,
        forceCompanyFeed: false, //state.instance.get("forceCompanyFeed"),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...dispatch,
        ...bindActionCreators(
            {
                setShouldReloadFavourites,
                removeFavorite,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
