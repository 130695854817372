import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { SocialHidden } from "../SocialHidden";
import { SocialPostEdit } from "../SocialPostEdit";
import { SocialPostHeader } from "../SocialPostHeader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import SocialPostText from "../SocialPostText/SocialPostText";
import { SocialPostContent } from "../SocialPostContent";
import "./SocialPost.scss";
import SocialPostTypeContent from "../SocialPostTypeContent/SocialPostTypeContent";
import { SpintrTypes } from "src/typings";
import SocialPostShare from "../SocialPostShare/SocialPostShare";
import Visage2Composer from "src/spintr/components/Visage2Composer/Visage2Composer";
import { visage2SocialPostTypes } from "src/social-feed/types";

interface ISocialPostProps {
    isShare?: boolean;
    post: Spintr.ISocialPostBase;
    onDoneEditing?: any;
}

interface ISocialPostState {
    isEditing: boolean;
}

class SocialPost extends Component<ISocialPostProps, ISocialPostState> {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false
        }
    }

    toggleIsEditing = () => {
        this.setState((prevState) => ({
            isEditing: !prevState.isEditing,
        }));
    }

    renderEditView(): ReactNode {
        let mainType;

        const editPost = {
            ...this.props.post,
            content: this.props.post.content.filter((c) => c.StatusType != SpintrTypes.StatusType.File),
        };

        for (const content of editPost.content) {
            if (content.Type == SpintrTypes.UberType.BusinessDeal) {
                content.StatusType = SpintrTypes.StatusType.BusinessDeal;
            } else if (content.Type == SpintrTypes.UberType.Kudos) {
                content.StatusType = SpintrTypes.StatusType.Kudos;
            } else if (content.Type == SpintrTypes.UberType.SomethingFun) {
                content.StatusType = SpintrTypes.StatusType.SomethingFun;

                if (content.somethingFunImageUrl) {
                    content.photos = [
                        // @ts-ignore
                        {
                            id: 0,
                            thumbnailUrl: content.somethingFunImageUrl,
                            description: "",
                            videoThumbnailUrl: "",
                        },
                    ];
                }
            } else if (content.Type == SpintrTypes.UberType.Bookmark) {
                content.BookmarkImages = [];
            } else if (content.Type === SpintrTypes.UberType.ImagePost || content.Type === SpintrTypes.UberType.Image) {
                if (!content.photos) {
                    content.Video = {
                        name: "",
                        VideoThumbnail: content.VideoThumbnail,
                    };
                } else {
                    content.photos = content.photos.map((p) => ({
                        ...p,
                        thumbnailUrl: p.photoUrl,
                    }));
                }
            }

            if (content.background) {
                content.color = { from: content.background.start.color, to: content.background.stop.color };
                editPost.color = { from: content.background.start.color, to: content.background.stop.color };
            }

            const visage2Type = visage2SocialPostTypes.find((type) => content.Type == type.uberType);
            if (visage2Type) {
                mainType = visage2Type;
            }
        }

        if (editPost.background) {
            editPost.color = { from: editPost.background.start.color, to: editPost.background.stop.color };
        }

        return (
            <Visage2Composer
                type={mainType}
                post={editPost}
                onClosing={() => {
                    this.toggleIsEditing();

                    this.props.onDoneEditing?.();
                }}
                editMode
            />
        );
    }

    renderTypeContent() {
        const supportedTypes = [SpintrTypes.UberType.BusinessDeal, SpintrTypes.UberType.Kudos, SpintrTypes.UberType.SomethingFun];
        const child = this.props.post.content.find(x => supportedTypes.indexOf(x.Type) > -1);

        if (!child) {
            return null;
        }

        return (
            <SocialPostTypeContent post={{
                ...child,
                color: this.props.post.color
            }} />
        )
    }

    public render(): ReactNode {
        if (this.props.post.hidden) {
            return <SocialHidden post={this.props.post} />
        }

        return (
            <div className="SocialPost">
                {this.props.post.isPinned && (
                    <Label
                        className="pin-header"
                        color="visageGray2"
                        size="body-2"
                        weight="medium"
                    >
                        <span>
                            <Visage2Icon icon="ticket-star" color="visageGray2" />
                        </span>
                        <span
                        >
                            {localize("PINED_POST")}
                        </span>
                    </Label>
                )}
                <SocialPostHeader
                    post={this.props.post}
                    onEditClick={this.toggleIsEditing}
                />
                <SocialPostText post={this.props.post} />
                {this.renderTypeContent()}
                <SocialPostContent post={this.props.post} isShare />
                {this.props.post.Share && <SocialPostShare post={this.props.post} />}
                {this.state.isEditing && this.renderEditView()}
            </div>
        );
    }
}

export default SocialPost;