import { DatePicker, DayOfWeek, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from "react-router";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { ActionMenu, Label, Loader } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormTokenizedObjectInput } from "src/ui/components/Forms";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import "./TodoitemView.scss";
import api from "src/spintr/SpintrApi";

interface Props extends RouteComponentProps {
    match: any;
    history: any;
}

interface State {
    isLoading: boolean;
    todo: any;
    showInfoDialog: boolean;
}

class TodoItemView extends Component<Props, State> {
    state = {
        isLoading: true,
        todo: {} as any,
        showInfoDialog: false,
    };

    componentDidMount = () => {
        const { id } = this.props.match.params;

        this.setState({ isLoading: true }, () => {
            api.get(`/api/v1/todos/${id}`).then((response) => {
                if (response.data.dueDate) response.data.dueDate = new Date(response.data.dueDate);
                this.setState({ todo: response.data, isLoading: false });
            });
        });
    };

    deleteItemAndGoBack = (id) => {
        const { listId } = this.props.match.params;

        api.post(`/api/v1/todos/toggledeleted/${id}`).then((response) => {
            this.props.history.push({
                pathname: `/todo/list/${listId}`,
            });
        });
    };

    render() {
        const { todo, isLoading, showInfoDialog } = this.state;
        const { listId, id } = this.props.match.params;

        if (isLoading) return <Loader />;

        return (
            <div className="toDoContent">
                <Helmet>
                    <title>{localize("AttGora")}</title>
                </Helmet>
                {showInfoDialog && (
                    <CustomDialog
                        message={localize("ArDuSakerAttDuVillUtforaDennaAtgard")}
                        show={showInfoDialog}
                        onDismiss={() => {
                            this.setState({ showInfoDialog: false });
                        }}
                        onConfirm={() => {
                            this.setState({ showInfoDialog: false }, () => {
                                this.deleteItemAndGoBack(id);
                            });
                        }}
                    />
                )}
                <div className="fullWidthForm">
                    <Label as="h1" size="h2">
                        {todo.name}
                    </Label>
                    <div id="top-right-actionmenu" style={{ position: "absolute", top: "53px", right: "47px" }}>
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("Redigera"),
                                            onClick: () => {
                                                this.props.history.push({
                                                    pathname: `/todo/list/${listId}/${id}/edit`,
                                                });
                                            },
                                        },
                                        {
                                            text: localize("TaBort"),
                                            onClick: () => {
                                                this.setState({ showInfoDialog: true });
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />
                    </div>
                    <TextField label={localize("Lista")} disabled={true} value={todo.list.name} />
                    <FormTokenizedObjectInput
                        types={[SpintrTypes.UberType.User]}
                        items={[todo.user]}
                        disabled={true}
                        label={localize("Ansvarig")}
                        onChange={() => {}}
                    />
                    <DatePicker
                        disabled={true}
                        label={localize("Datum")}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={getDatePickerLanguageStrings()}
                        formatDate={formatDatePickerDate}
                        value={todo.dueDate}
                        onSelectDate={(dueDate: Date) => {
                            this.setState((state) => ({
                                todo: {
                                    ...state.todo,
                                    dueDate,
                                },
                            }));
                        }}
                    />
                    <div style={{ marginTop: "1em", marginBottom: "1em" }}>
                        <TinyFormattedContent content={todo.text} />
                    </div>
                    <SocialBlock
                        uberId={todo.id}
                        // staticCommentCount={post.commentCount}
                        // likers={post.likers}
                    />
                </div>
            </div>
        );
    }
}

export default TodoItemView;
