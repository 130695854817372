import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { fetchImportantArticles } from "./actions";
import { ReactSVG } from 'react-svg';
import { Label, UnstyledButton } from "src/ui";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./ImportantArticles.scss";
import { Modal } from "@fluentui/react";
import PopupHeader from "src/ui/components/PopupHeader";
import InformationFeedEntry from "./InformationFeedEntry";

interface IProps { 
    importantArticles: any[],
    hasFetchedImportantArticles: boolean;
    cropHeaderImagesAfterUpload: boolean;
    navigation: any;
    instanceColor: string;
}

interface IState {
    step: number;
}

const ImportantArticles = (props: IProps) => {
    const [step, setStep] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [showNeedToKnowModal, setShowNeedToKnowModal] = useState(false);
    const [showNiceToKnowModal, setShowNiceToKnowModal] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setShowNeedToKnowModal(false);
        setShowNiceToKnowModal(false);
    }
    
    useEffect(() => {
        dispatch(fetchImportantArticles());
    }, []);
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (isPaused) {
                return;
            }

            const count = [...props.importantArticles].filter(x => x.priorityLevel === 5).length;

            if (count === 0) {
                return;
            }

            setStep(s => s === (count - 1) ? 0 : (s + 1));
        }, 5000);

        return () => clearInterval(interval);
    }, [props.importantArticles, isPaused]);

    if (!props.hasFetchedImportantArticles ||
        !props.importantArticles ||
        props.importantArticles.length === 0) {
        return null;
    }

    const needToKnowArticles = [...props.importantArticles].filter(x => x.priorityLevel === 5);
    const niceToKnowArticles = [...props.importantArticles].filter(x => x.priorityLevel === 4);

    const getNumberOfArticlesText = (count: number) => {
        const langTag = count === 1 ? "X_ARTICLES_TO_READ_SINGULAR" : "X_ARTICLES_TO_READ";

        return localize(langTag).replace("{0}", count.toString());
    }

    const renderImage = (entry: any) => {
        return (
            <div className="entry-image" style={{
                backgroundImage: `url(${entry.imageUrl})`,
                backgroundColor: props.instanceColor
            }} />
        )
    }

    return (
        <div className="ImportantArticles">
            {needToKnowArticles.length > 0 && (
                <div className="need-to-know">
                    <Link to={"/goto/" + needToKnowArticles[step].id} className="need-to-know-preview" onClick={() => {
                        console.log("LOL");
                    }}>
                        {renderImage(needToKnowArticles[step])}
                        <div className="preview-content">
                            <div className={"spotlight-gradient" + (!needToKnowArticles[step].imageUrl ? " no-image" : "")}>
                                <div className="preview-content-inner">
                                    <div className="box-wrapper">
                                        <div className="box">
                                            <ReactSVG src={"/images/need-to-know.svg"} />
                                            <Label color="white" size="body-2">{localize("NEED_TO_KNOW")}</Label>
                                        </div>
                                    </div>
                                    <Label color="white" size="h3">{needToKnowArticles[step].title}</Label>
                                    <div className="indicators">
                                        {needToKnowArticles.map((item: any, index: number) => {
                                            return (
                                                <UnstyledButton
                                                    onClick={() => {
                                                        console.log("LOL 2");
                                                        setStep(index);
                                                        setIsPaused(true);
                                                    }}
                                                    key={index}
                                                    className={"indicator" + (index === step ? " active" : "")} />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <UnstyledButton
                        onClick={() => {
                            setShowNeedToKnowModal(true);
                        }}
                        className="need-to-know-footer">
                        <div className="text-wrapper">
                            <Label weight="medium" color="white">{getNumberOfArticlesText(needToKnowArticles.length)}</Label>
                        </div>
                        <div className="icon-wrapper">
                            <Visage2Icon icon="arrow-right-3" color="white" />
                        </div>
                    </UnstyledButton>
                </div>
            )}
            {niceToKnowArticles.length > 0 && (
                <UnstyledButton
                    onClick={() => {
                        setShowNiceToKnowModal(true);
                    }}
                    className="nice-to-know">
                    <div className="icon-wrapper">
                        <ReactSVG src={"/images/nice-to-know.svg"} />
                    </div>
                    <div className="text-wrapper">
                        <Label weight="medium">{localize("NICE_TO_KNOW")}</Label>
                        <Label size="body-2" color="mid-grey">{getNumberOfArticlesText(niceToKnowArticles.length)}</Label>
                    </div>
                    <Visage2Icon icon="arrow-right-3" />
                </UnstyledButton>
            )}
            {(showNeedToKnowModal || showNiceToKnowModal) && (
                <Modal
                    className="spintr-modal modalWithPopupHeader"
                    allowTouchBodyScroll
                    isOpen={showNeedToKnowModal || showNiceToKnowModal}
                    styles={{
                        main: {
                            padding: 20
                        }
                    }}
                    onDismiss={closeModal}>
                    <PopupHeader
                        text={localize(showNeedToKnowModal ? "NEED_TO_KNOW" : "NICE_TO_KNOW")}
                        onClose={closeModal} />
                    {(showNeedToKnowModal ? needToKnowArticles : niceToKnowArticles).map((item: any, index: number) => {
                        return (
                            <div
                                key={index}
                                className="popup-article">
                                <InformationFeedEntry entry={item} />
                            </div>
                        )
                    })}
                </Modal>
            )}
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
        importantArticles: state.informationFeed.importantArticles,
        hasFetchedImportantArticles: state.informationFeed.hasFetchedImportantArticles,
        //cropHeaderImagesAfterUpload: state.instance.get("cropHeaderImagesAfterUpload"),
        cropHeaderImagesAfterUpload: false,
        instanceColor: state.instance.get("color")
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(ImportantArticles));
