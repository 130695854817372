import { Action } from "redux";
import { SpintrTypes } from "src/typings";

import * as ActionTypes from "./action-types";
import { IReactionPickerConfig } from "./reducer";


export interface IGroupHistoryExpandedAction extends Action {
    isExpanded: boolean;
}

export type SetHeaderSearchBarModeHandler = (mode: SpintrTypes.HeaderSearchBarMode) => ActionTypes.ISetHeaderSearchBarModeAction;
export const setHeaderSearchBarMode: SetHeaderSearchBarModeHandler = (mode) => ({
    mode,
    type: ActionTypes.UiActionTypes.SetHeaderSearchBarMode, 
});

export const toggleGreyBackground = (value: boolean) : ActionTypes.IToggleGreyBackground => ({
    displayGreyBackground: value,
    type: ActionTypes.UiActionTypes.ToggleGreyBackground
});

export const clearUploads = (): ActionTypes.IClearUploads => ({
    type: ActionTypes.UiActionTypes.ClearUploads
})

export const removeUpload = (uploadId: any): ActionTypes.IRemoveUpload => ({
    uploadId,
    type: ActionTypes.UiActionTypes.RemoveUpload
})

export const addUploads = (uploads: any[]): ActionTypes.IAddUploads => ({
    uploads,
    type: ActionTypes.UiActionTypes.AddUploads
})

export const updateUpload = (upload: any): ActionTypes.IUpdateUpload => ({
    upload,
    type: ActionTypes.UiActionTypes.UpdateUpload
})

export const setSidebarExpanded = (isExpanded: boolean): ActionTypes.ISidebarExpandedAction => ({
    isExpanded,
    type: ActionTypes.UiActionTypes.SetSidebarExpanded
})

export const setIsSmallViewMode = (isSmallViewMode: boolean): ActionTypes.IIsSmallViewModeAction => ({
    isSmallViewMode,
    type: ActionTypes.UiActionTypes.SetIsSmallViewMode
})

export const setIsTinyInModal = (isTinyInModal: boolean): ActionTypes.ISetIsTinyInModalAction => ({
    isTinyInModal,
    type: ActionTypes.UiActionTypes.SetIsTinyInModal
})

export const incrementStartViewKey = (): ActionTypes.IIncrementStartViewKey => ({
    type: ActionTypes.UiActionTypes.IncrementStartViewKey
})

export const setViewMode = (viewMode: Spintr.ViewMode): ActionTypes.ISetViewModeAction => ({
    type: ActionTypes.UiActionTypes.SetViewMode,
    viewMode,
});

export const setActiveActionMenu = (activeActionMenu: string): ActionTypes.IActiveActionMenuAction => ({
    activeActionMenu,
    type: ActionTypes.UiActionTypes.SET_ACTIVE_ACTION_MENU
})

export const setGroupHistoryExpanded = (isExpanded: boolean): ActionTypes.IGroupHistoryExpandedAction => ({
    isExpanded,
    type: ActionTypes.UiActionTypes.SetGroupHistoryExpanded
})

export const setDisplayAsOfflineInChat = (displayAsOfflineInChat: boolean): ActionTypes.ISetDisplayAsOfflineInChatAction => ({
    displayAsOfflineInChat,
    type: ActionTypes.UiActionTypes.SetDisplayAsOfflineInChat
})

export const setActiveSidebarTab = (activeSidebarTab: number): ActionTypes.ISetActiveSidebarTabAction => ({
    activeSidebarTab,
    type: ActionTypes.UiActionTypes.SetActiveSidebarTab
})

export const setRightColumnVisible = (visible: boolean): ActionTypes.ISetRightColumnVisibleAction => ({
    type: ActionTypes.UiActionTypes.SetRightColumnVisible,
    visible
});

export const setResponsiveMenuVisibleAction = (visible: boolean): ActionTypes.ISetResponsiveMenuVisibleAction => ({
    type: ActionTypes.UiActionTypes.SetResponsiveMenuVisible,
    visible,
});

export const setResponsiveProfileVisible = (visible: boolean): ActionTypes.ISetResponsiveProfileVisibleAction => ({
    type: ActionTypes.UiActionTypes.SetResponsiveProfileVisible,
    visible,
});

export const setResponsiveSearchVisible = (visible: boolean): ActionTypes.ISetResponsiveSearchVisibleAction => ({
    type: ActionTypes.UiActionTypes.SetResponsiveSearchVisible,
    visible,
});

export const setResponsiveSubmenuVisible = (visible: boolean): ActionTypes.ISetResponsiveSubmenuVisibleAction => ({
    type: ActionTypes.UiActionTypes.SetResponsiveSubmenuVisible,
    visible,
})

export const setShouldReloadFavourites = (shouldReload: boolean): ActionTypes.IShouldReloadFavourites => ({
    shouldReload,
    type: ActionTypes.UiActionTypes.SetShouldReloadFavourites
});

export const setCurrentlyShowingTeaserboxes = (teaserboxes: number[]): ActionTypes.ISetCurrentlyShowingTeaserboxes => ({
    teaserboxes,
    type: ActionTypes.UiActionTypes.setCurrentlyShowingTeaserboxes
})

export const setFocusCommentFieldForUberId = (focusCommentFieldForUberId?: number): ActionTypes.ISetFocusCommentFieldForUberId => ({
    focusCommentFieldForUberId,
    type: ActionTypes.UiActionTypes.SetFocusCommentFieldForUberId
})

export const setFormFooterBarVisible = (visible: boolean): ActionTypes.ISetFormFooterBarVisibleAction => ({
    type: ActionTypes.UiActionTypes.SetFormFooterBarVisible,
    visible,
})

export const setFormFooterBarTop = (top: boolean): ActionTypes.ISetFormFooterBarTopAction => ({
    type: ActionTypes.UiActionTypes.SetFormFooterBarTop,
    top,
})

export const setIsInTeamsApp = (isInTeamsApp: boolean): ActionTypes.ISetIsInTeamsApp => ({
    isInTeamsApp,
    type: ActionTypes.UiActionTypes.SetIsInTeamsApp,
})

export const setAppMode = (appMode: boolean): ActionTypes.ISetAppMode => ({
    appMode,
    type: ActionTypes.UiActionTypes.SetAppMode,
})

export const setVisage2FullscreenMode = (visage2FullscreenMode : boolean): ActionTypes.ISetVisage2FullscreenMode => ({
    visage2FullscreenMode,
    type: ActionTypes.UiActionTypes.SetVisage2FullscreenMode,
})

export const setVisage2FullscreenHeaderVisible = (visage2FullscreenHeaderVisible : boolean): ActionTypes.ISetVisage2FullscreenHeaderVisible => ({
    visage2FullscreenHeaderVisible,
    type: ActionTypes.UiActionTypes.SetVisage2FullscreenHeaderVisible,
})

export const setGlobalBreadcrumbs = (value : Spintr.IBreadcrumbItem[]): ActionTypes.ISetGlobalBreadcrumbs => ({
    value,
    type: ActionTypes.UiActionTypes.SetGlobalBreadcrumbs,
})

export const setPublic360UnitLevel1 = (unit: any): ActionTypes.ISetPublic360SelectedUnitLevel1 => ({
    unit,
    type: ActionTypes.UiActionTypes.SetPublic360SelectedUnitLevel1,
})

export const setPublic360UnitLevel2 = (unit: any): ActionTypes.ISetPublic360SelectedUnitLevel2 => ({
    unit,
    type: ActionTypes.UiActionTypes.SetPublic360SelectedUnitLevel2,
})

export const setOverlayId = (value: string): ActionTypes.ISetOverlayId => ({
    value,
    type: ActionTypes.UiActionTypes.SetOverlayId
})

export type SetDisplayHeaderSearch = (value: boolean) => ActionTypes.ISetDisplayHeaderSearch;
export const setDisplayHeaderSearch = (value: boolean): ActionTypes.ISetDisplayHeaderSearch => ({
    type: ActionTypes.UiActionTypes.SetDisplayHeaderSearch,
    value,
})

export const setUseDocumentWidthMode = (value: boolean): ActionTypes.ISetUseDocumentWidthMode => ({
    type: ActionTypes.UiActionTypes.SetUseDocumentWidthMode,
    value,
})

export const setUseNarrowFormWidthMode = (value: boolean): ActionTypes.ISetUseNarrowFormWidthMode => ({
    type: ActionTypes.UiActionTypes.SetUseNarrowFormWidthMode,
    value,
})

export const setDisableWrapperBackground = (value: boolean): ActionTypes.ISetDisableWrapperBackground => ({
    type: ActionTypes.UiActionTypes.SetDisableWrapperBackground,
    value,
})

export const setReactionPickerConfig = (value: IReactionPickerConfig): ActionTypes.ISetReactionPickerConfig => ({
    type: ActionTypes.UiActionTypes.SetReactionPickerConfig,
    value,
})

export const setStartPageBuilderMode = (value: SpintrTypes.StartPageBuilderMode) : ActionTypes.ISetStartPageBuilderMode => ({
    type: ActionTypes.UiActionTypes.SetStartPageBuilderMode,
    value,
});


export const setAdminNoPadding = (value: boolean) : ActionTypes.ISetAdminNoPadding => ({
    type: ActionTypes.UiActionTypes.SetAdminNoPadding,
    value,
});