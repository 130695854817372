import moment from "moment";
import React, { FunctionComponent } from "react";
import { Label } from "src/ui";
import { isSameDate } from "src/utils";
import { IDateGroup } from "../ChatTabMessageContainer/types";
import { ChatTabUserGroup } from "../ChatTabUserGroup";
import "./ChatTabDateGroup.scss";

interface IProps {
    dateGroup: IDateGroup
    lastRead: { [messageId: number]: number[] };
    onRespondToMessage: any;
}

const getDateString = (date: Date): string => {
    if (isSameDate(date, new Date())) {
        return moment(date).format("HH:mm");
    }

    return moment(date).format("YYYY-MM-DD HH:mm");
}

const ChatTabDateGroup: FunctionComponent<IProps> = (props) => (
    <div className="ChatTabDateGroup">
        <div className="date">
            <Label as="span" size="small-2" color="mid-grey">
                {getDateString(props.dateGroup.from)}
            </Label>
        </div>
        <div className="users">
            {props.dateGroup.userGroups.map((ug => {
                const key = "ug." + ug.user.id + "." + ug.messages.length + "." + ug.messages[0].id;

                return (
                    <ChatTabUserGroup
                        key={key}
                        lastRead={props.lastRead}
                        userGroup={ug}
                        onRespondToMessage={props.onRespondToMessage}
                    />
                );
            }))}
        </div>
    </div>
);

export default ChatTabDateGroup;
