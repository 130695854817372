import { produce } from "immer";

import {
    FETCH_TEASERBOXES_FULFILLED,
    FETCH_TEASERBOXES_PENDING,
    FETCH_TEASERBOXES_REJECTED,
} from './action-types'

export interface ITeaserBoxState {
    failed: boolean;
    items: any[];
    loading: boolean;
    fetchHasBeenInitialized: boolean;
}

const initialState: ITeaserBoxState = {
    failed: false,
    items: [],
    loading: false,
    fetchHasBeenInitialized: false
};

export const teaserboxesReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case FETCH_TEASERBOXES_PENDING:
            draft.loading = true;
            draft.fetchHasBeenInitialized = true;
            break;

        case FETCH_TEASERBOXES_REJECTED:
            draft.failed = true;
            draft.loading = false;
            break;

        case FETCH_TEASERBOXES_FULFILLED:
            draft.loading = false;
            draft.failed = false;
            draft.items = action.payload.data;
            break;

        default:
            return state
    }
});

export default teaserboxesReducer
