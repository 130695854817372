import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Label } from "../../../ui";
import "./MiniTitle.scss";
import classNames from "classnames";
import { fromNow } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    text: string;
    icon?: string;
    readTimeMinutes?: number;
}

const MiniTitle = (props: IProps) => {
    // const items = [
    //     ...props.uberCategories,
    // ];

    // if (props.fallBackString) {
    //     items.push()
    // }

    return (
        <div className="mini-title-wrapper">
            <div className="mini-title">
                <div className="mini-title-item">
                    {
                        props.icon && <Visage2Icon icon={props.icon} size="small" />
                    }
                    <Label size="small-2" weight="normal" color="dark-grey">{props.text}</Label>
                </div>
                {props.readTimeMinutes && (
                    <Label size="small-2" weight="normal" color="dark-grey">
                        {props.readTimeMinutes + " min " + localize("READ_TIME").toLowerCase()}
                    </Label>
                )}
            </div>
        </div>
    )
}

// class MiniTitle extends Component<IProps> {
//     render() {
//         const hasFallBackString = !!this.props.fallBackString;
//         const hasUberCategories =
//             !!this.props.uberCategories && this.props.uberCategories.filter((uc) => !uc.isDeleted).length > 0;
//         const hasReadTime = this.props.readTimeMinutes != undefined && this.props.readTimeMinutes > 0;
//         const title =
//             !!hasFallBackString && !hasUberCategories
//                 ? this.props.fallBackString
//                 : !!hasUberCategories && this.props.uberCategories.filter((uc) => !uc.isDeleted)[0].name;

//         if (!hasFallBackString && !hasUberCategories) {
//             return null;
//         }

//         return (
//             <div
//                 className={classNames("mini-title", {
//                     InformationFeedEntry: this.props.informationFeedEntry,
//                     "white-mode": this.props.makeItAllWhite,
//                 })}
//             >
//                 <Label
//                     as="h4"
//                     size="body-3"
//                     weight="medium"
//                     uppercase
//                     className={this.props.makeItAllWhite ? "" : "primaryFGColor"}
//                     title={title}
//                 >
//                     {title}
//                 </Label>
//                 {!!this.props.date && (
//                     <>
//                         <Label size="body-3" className="mini-title-date" title={""}>
//                             {fromNow(this.props.date, true)}
//                         </Label>
//                     </>
//                 )}
//                 {!!this.props.date && !!hasReadTime && <div className="mini-title-pipe">|</div>}
//                 {!!hasReadTime && (
//                     <>
//                         <Label size="body-3" className="mini-title-readtime" title={localize("READ_TIME")}>
//                             {this.props.readTimeMinutes + " min " + localize("READ_TIME").toLowerCase()}
//                         </Label>
//                     </>
//                 )}
//                 {!!this.props.isUnread && (
//                     <Label size="body-3" className="mini-title-unread" title={localize("olast")} color="light-grey">
//                         {localize("olast")}
//                     </Label>
//                 )}
//             </div>
//         );
//     }
// }



export default MiniTitle;
