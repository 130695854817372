import { SpintrTypes } from "src/typings";

export const contexts = {
    start: {
        tag: "Startsida",
        routes: ["/"],
    },

    // absence: {
    //     tag: "Franvaro",
    //     routes: ["/absence"],
    //     appId: SpintrTypes.SpintrApp.Absence
    // },

    // activitylog: {
    //     tag: "Aktivitetslogg",
    //     routes: ["/activitylog"],
    //     appId: SpintrTypes.SpintrApp.ActivityLog
    // },

    // bookings: {
    //     tag: "Resursbokning",
    //     routes: ["/booking"],
    //     appId: SpintrTypes.SpintrApp.Booking
    // },

    // calendar: {
    //     tag: "Kalender",
    //     routes: ["/calendar"],
    //     appId: SpintrTypes.SpintrApp.Calendars
    // },

    // TODO: allow zoltor on group? how?
    // groups: {
    //     tag: "Grupper",
    //     routes: ["/groups"],
    // },

    // TODO: allow zoltor on group? how?
    // group: {
    //     parent: "groups",
    //     tag: "Grupp",
    //     routes: ["/group"],
    // },

    // messages: {
    //     tag: "Meddelanden",
    //     routes: ["/chat"],
    // },

    // news: {
    //     tag: "Nyheter",
    //     routes: ["/news"],
    //     appId: SpintrTypes.SpintrApp.News
    // },

    // notes: {
    //     tag: "appNotes",
    //     routes: ["/notes"],
    //     appId: SpintrTypes.SpintrApp.Notes
    // },

    // notifications: {
    //     tag: "Notiser",
    //     routes: ["/notifications"],
    // },

    // operatinginfo: {
    //     tag: "appOperatingInfo",
    //     routes: ["/operatinginfo"],
    //     appId: SpintrTypes.SpintrApp.OperatingInfo
    // },

    // qa: {
    //     tag: "FragorOchSvar",
    //     routes: ["/qa"],
    //     appId: SpintrTypes.SpintrApp.QA
    // },

    // sitemap: {
    //     tag: "Intranatskarta",
    //     routes: ["/sitemap", "/tagmap"],
    // },

    // TODO: allow zoltor on textpage? how?
    // textpage: {
    //     tag: "Textsida",
    //     routes: [],
    // },

    // units: {
    //     tag: "KontorOchAvdelningar",
    //     routes: ["/organisation"],
    //     appId: SpintrTypes.SpintrApp.Organisation
    // },

    // TODO: allow zoltor on textpage? how?
    // unit: {
    //     parent: "units",
    //     tag: "Sida2",
    //     routes: ["/organisation"],
    // },

    // users: {
    //     tag: "Profil",
    //     routes: ["/profile"],
    // },
};

export const nameTags = [
    {
        key: "operatinginfo",
        text: "Driftinformation",
        appId: SpintrTypes.SpintrApp.OperatingInfo
    },
    {
        key: "dashboard",
        text: "Dashboard",
        appId: SpintrTypes.SpintrApp.Dashboard
    },
    {
        key: "todo",
        text: "AttGora",
        appId: SpintrTypes.SpintrApp.Todos
    },
    // {
    //     key: "shortcuts",
    //     text: "Genvagar",
    // },
    {
        key: "teaserbox",
        text: "Textpuff",
    },
    // {
    //     key: "favourites",
    //     text: "Favoriter",
    // },
    {
        key: "birthdays",
        text: "appBirthdays",
        appId: SpintrTypes.SpintrApp.Birthdays
    },
    // {
    //     key: "groups",
    //     text: "Grupper",
    // },
];
