import { Modal } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/index";
import { Loader } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import PopupHeader from "src/ui/components/PopupHeader";
import { isAnythingDirty } from "src/utils";
import { ISetCalendarPopupParams } from "../action-types";
import { clearErrorList, setCalendarPopup } from "../actions";
import { fetchEvent } from "../calendar-api";
import "./CalendarEventPopup.scss";
import CalendarEventPopupForm from "./CalendarEventPopupForm";
import CalendarEventPopupView from "./CalendarEventPopupView";

interface IProps {
    isOpen?: boolean;
    eventId?: number;
    startDate?: Date;
    endDate?: Date;
    editMode?: boolean;
    event?: any;
    preSelectedCalendarId?: number;
    hideCalendarSelector?: boolean;
    setCalendarPopup?: (params: ISetCalendarPopupParams) => void;
    clearErrorList?: () => void;
}

interface IState {
    event?: Spintr.ICalendarEvent;
    isEditing: boolean;
    isLoading: boolean;
    displayUnsavedChangesPopup: boolean;
}

const initialState: IState = {
    isEditing: false,
    isLoading: false,
    displayUnsavedChangesPopup: false
};

class CalendarEventPopup extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.onDismissPopup = this.onDismissPopup.bind(this);

        this.state = initialState;
    }

    public componentDidUpdate(prevProps: IProps) {
        if (!this.props.isOpen && this.state.event) {
            this.setState({
                event: undefined,
                isEditing: false,
            });
            return;
        }

        if (prevProps.eventId === this.props.eventId && prevProps.event === this.props.event) {
            return;
        }

        if (this.props.editMode && !this.state.isEditing) {
            this.setState({
                isEditing: true,
                event: this.props.event,
            });
        }

        if ((!this.props.eventId && !this.props.event) || this.props.eventId === 0) {
            this.setState({
                isEditing: true,
                isLoading: false,
            });
            return;
        }

        this.setState({ isLoading: true }, () => this.loadEvent());
    }

    renderUnsavedChangesDialog() {
        return (
            <CustomDialog
                message={localize("UnsavedChangesWarning")}
                show={!!this.state.displayUnsavedChangesPopup}
                confirmMessage={localize("Ok")}
                onDismiss={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    });
                }}
                onConfirm={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    }, () => {
                        this.dismissPopup();
                    });
                }} />
        )
    }

    public render(): ReactNode {
        if (this.props.isOpen !== true) {
            return null;
        }

        return (
            <Modal
                allowTouchBodyScroll
                isClickableOutsideFocusTrap={true}
                forceFocusInsideTrap={false}
                isBlocking={this.state.isEditing}
                containerClassName="calendar-event-popup"
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.props.isOpen}
                onDismiss={this.onDismissPopup}
            >
                <PopupHeader
                    text={!this.props.event || this.props.event.id === 0 ? localize("SkapaKalenderpost") : localize("RedigeraKalenderpost")}
                    onClose={this.onDismissPopup} />
                <>
                    {this.state.isLoading && <Loader />}
                    {!this.state.isLoading && (
                        <div className="popup-inner">
                            {this.state.isEditing || !this.state.event ? (
                                <CalendarEventPopupForm
                                    event={this.state.event}
                                    startDate={this.props.startDate}
                                    endDate={this.props.endDate}
                                    dismiss={this.onDismissPopup}
                                    preSelectedCalendarId={
                                        (this.state.event && this.state.event.calendarId) ||
                                        this.props.preSelectedCalendarId
                                    }
                                    hideCalendarSelector={this.props.hideCalendarSelector}
                                />
                            ) : (
                                <CalendarEventPopupView event={this.state.event} dismiss={this.onDismissPopup} />
                            )}
                        </div>
                    )}
                    {
                        this.renderUnsavedChangesDialog()
                    }
                </>
            </Modal>
        );
    }

    protected async loadEvent(): Promise<void> {
        try {
            const event = await fetchEvent(this.props.event);

            this.setState({
                event,
                isLoading: false,
            });
        } catch (err) {
            console.log(err);
        }
    }

    dismissPopup() {
        this.props.setCalendarPopup({
            isOpen: false
        });

        this.setState({
            isEditing: false
        });
    }

    protected onDismissPopup() {
        if (isAnythingDirty()) {
            this.setState({
                displayUnsavedChangesPopup: true
            });
        } else {
            this.dismissPopup();
        }
    }
}

const stateMapper = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    ...state.calendar.calendarPopup,
});

const dispatchActions = {
    setCalendarPopup,
    clearErrorList,
};

export default connect(stateMapper, dispatchActions)(CalendarEventPopup);
