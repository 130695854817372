import { Icon } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { ChatTabContent } from "src/chat/components/ChatTabContent";
import { ChatTabContext, IChatTabContext } from "src/chat/components/ChatTabContext";
import { Conversation } from "src/chat/conversation-types";
import { MessageFetchType } from "src/chat/message-types";
import {
    BoundQueryMessagesHandler, markAsRead, MarkAsReadHandler, queryMessages,
    QueryMessagesHandler
} from "src/chat/redux";
import { SpintrUser, UnstyledButton } from "src/ui";
import { circleSmall } from "src/ui/helpers/style";
import "./ConversationView.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IRouteParams {
    conversationId: string;
}

interface IDispatchProps {
    markAsRead: MarkAsReadHandler;
    queryMessages: QueryMessagesHandler | BoundQueryMessagesHandler;
}

interface IOwnProps extends RouteComponentProps<IRouteParams> { }

interface IStateProps {
    conversation: Conversation;
    currentUser: Spintr.IUser;
    lastRead: { [messageId: number]: number[] };
    oldestMessageId?: number;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    isLoading: boolean;
}

class ConversationView extends Component<Props, IState> {
    private chatContext: IChatTabContext;

    constructor(props: Props) {
        super(props);

        this.onReturnClick = this.onReturnClick.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.loadMore = this.loadMore.bind(this);

        this.chatContext = {
            clearUnread: this.markAsRead,
            currentUser: this.props.currentUser,
            lastRead: this.props.lastRead,
            loadMore: this.loadMore,
        };

        this.state = {
            isLoading: false,
        };
    }

    public componentDidUpdate(): void {
        if (this.chatContext.lastRead !== this.props.lastRead) {
            this.chatContext.lastRead = this.props.lastRead;
        }
    }

    public render(): ReactNode {
        const conversationId = parseInt(this.props.match.params.conversationId, 10);
        if (!conversationId || isNaN(conversationId)) {
            return <Redirect to="/messages" />;
        }

        if (!this.props.conversation) {
            return null;
        }

        let imageUrl = "";

        if (this.props.conversation.imageUrl && this.props.conversation.imageUrl.length > 0) {
            imageUrl = this.props.conversation.imageUrl[0];
        }

        return (
            <div id="ConversationView" key={conversationId}>
                <div className="header">
                    <UnstyledButton
                        className="return-button"
                        onClick={this.onReturnClick}
                    >
                        <Visage2Icon icon="arrow-left" />
                    </UnstyledButton>
                    <div className="conversation-info">
                        <div className="image">
                            <SpintrUser
                                name={this.props.conversation.title}
                                imageUrl={imageUrl}
                                size={circleSmall}
                            />
                        </div>
                    </div>
                </div>
                <ChatTabContext.Provider
                    value={this.chatContext}
                >
                    <ChatTabContent
                        conversationId={conversationId}
                        lastRead={this.props.lastRead}
                        minimized={false}
                        pageSize={20}
                    />
                </ChatTabContext.Provider>
            </div>
        );
    }

    protected loadMore(): void {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true }, async () => {
            try {
                const promise = (this.props.queryMessages as BoundQueryMessagesHandler)({
                    conversationId: this.props.conversation.id,
                    fetchType: MessageFetchType.Messages,
                    take: 10,
                    maxId: this.props.oldestMessageId,
                });

                await promise;
            } catch (err) {
                console.log(err);
            } finally {
                this.setState({ isLoading: false });
            }
        });
    }

    protected markAsRead(): void {
        if (!this.props.conversation.unread) {
            return;
        }

        this.props.markAsRead(
            this.props.conversation.id,
            this.props.conversation.unread,
        );
    }

    protected onReturnClick(): void {
        this.props.history.push("/messages");
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, props) => {
        const conversation = state.chat.conversations.items.find(
            (c) => c.id === parseInt(props.match.params.conversationId, 10),
        );

        return {
            conversation,

            currentUser: {
                id: state.profile.active.id,
                imageUrl: state.profile.active.images.Message,
                info: "",
                name: state.profile.active.name,
                type: 1,
                typeName: "",
            },

            lastRead: !conversation ? {} : Object.keys(conversation.lastRead)
                .filter((k) => !isNaN(+k))
                .map((k) => parseInt(k, 10))
                .reduce((acc, userId) => {
                    const messageId = conversation.lastRead[userId];
                    if (!messageId) {
                        return acc;
                    }

                    acc[messageId] = acc[messageId] || [];
                    acc[messageId].push(userId);
                    return acc;
                }, {} as { [messageId: number]: number[] }),

            oldestMessageId: !conversation ? undefined : state.chat.messages
                .filter(m => m.conversationId === conversation.id)
                .reduce<number | undefined>((acc, m) => {
                    if (!acc) {
                        return m.id;
                    }

                    return m.id < acc ? m.id : acc;
                }, undefined),
        };
    };

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    markAsRead,
    queryMessages
};

const ConversationViewWithRouter = withRouter(ConversationView);
const ConnectedConversationViewWithRouter = connect(
    mapStateToProps, mapDispatchToProps,
)(ConversationViewWithRouter);

export default ConnectedConversationViewWithRouter;