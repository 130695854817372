import { Icon, ISearchBox, SearchBox } from "@fluentui/react";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { Label, Loader, UnstyledButton } from "src/ui";
import { Scrollable } from "src/ui/components";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import { debounce } from "src/utils";
import "./HelpCenter.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props {
    currentUser: any;
    isInTeamsTools?: any;
}

interface State {
    isLoading: boolean;
    isLoadingMore: boolean;
    articles: any;
    showArticle: number;
    currentLanguage: string;
    searchText: string;
    nextPage: string;
    skip: number;
    exhausted: boolean;
    initialLoad: boolean;
}

class HelpCenter extends Component<Props, State> {
    instance = axios.create();
    private _searchBoxRef = React.createRef<ISearchBox>();

    constructor(props) {
        super(props);

        this.instance.defaults.headers.common = {};
        this.instance.defaults.headers.common.accept = "application/json";
    }

    state = {
        isLoading: true,
        isLoadingMore: false,
        articles: {} as any,
        showArticle: undefined,
        currentLanguage: "sv",
        searchText: "",
        nextPage: "",
        skip: 0,
        exhausted: false,
        initialLoad: true,
    };

    fetch = () => {
        const { currentLanguage, nextPage, exhausted } = this.state;
        const url = "https://support.spintr.me/api/v2/help_center/" + currentLanguage + "/articles.json";

        if (nextPage && !exhausted) {
            this.setState({ isLoadingMore: true }, () => {
                this.instance.get(nextPage, { params: { per_page: 15 } }).then((response) => {
                    let exhausted = false;
                    if (!response.data.next_page) {
                        exhausted = true;
                    }
                    this.setState({
                        articles: [...this.state.articles, ...response.data.articles],
                        isLoadingMore: false,
                        nextPage: response.data.next_page,
                        exhausted,
                    });
                });
            });
        } else if (!exhausted) {
            this.setState({ isLoading: true }, () => {
                this.instance.get(url, { params: { per_page: 15 } }).then((response) => {
                    let exhausted = false;
                    if (!response.data.next_page) {
                        exhausted = true;
                    }
                    this.setState(
                        {
                            articles: response.data.articles,
                            isLoading: false,
                            nextPage: response.data.next_page,
                            exhausted,
                        },
                        () => {
                            if (this.state.initialLoad) {
                                this._searchBoxRef.current.focus();
                                this.setState({ initialLoad: false });
                            }
                        }
                    );
                });
            });
        }
    };

    componentDidMount = () => {
        let lang = this.props.currentUser.settings.language;

        let currentLanguage =
            lang === "1"
                ? "sv"
                : lang === "2"
                ? "no"
                : lang === "3"
                ? "en"
                : lang === "4"
                ? "de"
                : lang === "5"
                ? "fi"
                : "sv";

        this.setState({ currentLanguage }, () => {
            this.fetch();
        });
    };

    showArticle = (id) => {
        const article = this.state.articles.find((a) => a.id === id);
        const parser = new DOMParser();
        let content = parser.parseFromString(article.body, "text/html");
        const iframes = content.getElementsByTagName("iframe");

        Array.from(iframes).forEach((i) => {
            i.width = "280";
            i.height = "200";
        });

        return (
            <div style={{ padding: "20px", width: "320px", boxSizing: "border-box" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <UnstyledButton
                        onClick={() => {
                            this.setState({ showArticle: undefined });
                        }}
                    >
                        <Label as="h4" size="h4" style={{ margin: 0 }}>
                            <Visage2Icon icon="arrow-left-3" />
                            <span style={{ verticalAlign: "top" }}>{localize("GaTillbaka")}</span>
                        </Label>
                    </UnstyledButton>
                </div>
                <div>
                    <Label as="h3" size="h3">
                        <a rel="noopener noreferrer" target="_blank" href={article.html_url}>
                            {article.title}
                        </a>
                    </Label>
                </div>
                <div>
                    <TinyFormattedContent content={content.body.innerHTML} />
                </div>
            </div>
        );
    };

    search = () => {
        const { currentLanguage, searchText } = this.state;
        let url =
            "https://support.spintr.me/api/v2/help_center/articles/search.json?query=" +
            searchText +
            "&locale=" +
            currentLanguage;
        this.setState({ isLoading: true }, () => {
            this.instance.get(url).then((response) => {
                this.setState({ articles: response.data.results, isLoading: false });
            });
        });
    };

    debouncedSearch = debounce(() => this.search(), 700);

    renderResults = () => {
        const { articles } = this.state;

        return (
            <div
                className="scrollableWrapper"
                style={{ height: this.props.isInTeamsTools ? 207 : 280, paddingLeft: "20px" }}
            >
                <Scrollable
                    displayLoader={false}
                    onEndReached={() => {
                        this.fetch();
                    }}
                >
                    <div style={{ paddingRight: "10px" }}>
                        {articles &&
                            articles.map((a, idx) => {
                                return (
                                    <div key={idx} style={{ marginTop: 4 }} className="helpcenterItem">
                                        <UnstyledButton
                                            onClick={() => {
                                                this.setState({ showArticle: a.id });
                                            }}
                                        >
                                            {a.title}
                                        </UnstyledButton>
                                    </div>
                                );
                            })}
                    </div>
                </Scrollable>
            </div>
        );
    };
    render() {
        const { isLoading, showArticle, searchText, nextPage } = this.state;

        if (showArticle) return this.showArticle(showArticle);
        else
            return (
                <div style={{ padding: "0px", width: "320px", boxSizing: "border-box" }}>
                    <div className="searchFieldWrapper" style={{ padding: "20px", paddingBottom: "4px" }}>
                        <SearchBox
                            role={"search"}
                            componentRef={this._searchBoxRef}
                            value={searchText}
                            onChange={(event: React.ChangeEvent, searchText: string) => {
                                // searchText = searchText.trim();
                                this.setState({ searchText }, () => {
                                    if (searchText && searchText.trim()) this.debouncedSearch();
                                    else this.fetch();
                                });
                            }}
                            placeholder={localize("VadBehoverDuHjalpMed") + "?"}
                        />
                    </div>

                    {isLoading ? (
                        <div className="SpintrLoader-wrapper">
                            <Loader />
                        </div>
                    ) : (
                        this.renderResults()
                    )}

                    {!this.props.isInTeamsTools && (
                        <div className="helpcenterBtn">
                            <a rel="noopener noreferrer" target="_blank" href="https://support.spintr.me">
                                {localize("GaTillHjalpcenter")}
                            </a>
                        </div>
                    )}
                </div>
            );
    }
}
const mapStateToProps = (state, props) => ({
    ...props,

    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(HelpCenter);
