import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import SocialComposerTarget from "src/social-feed/components/SocialComposerTarget/SocialComposerTarget";
import { Label, SpintrUser } from "src/ui";
import SocialComposerTextInput, {
    ISocialComposerTextInputProps
} from "./SocialComposerTextInput";

import "./SocialComposerUserHeader.scss";
import { circleLarge, circleMedium } from "src/ui/helpers/style";

interface ISocialComposerHeaderProps extends ISocialComposerTextInputProps {
    expanded: boolean;
    imageUrl: string;
    userName: string;
    hideTextInput?: boolean;
    onInputBlur?: any;
    isStartPage?: boolean;
    setFeedId: any;
}

class SocialComposerHeader extends Component<ISocialComposerHeaderProps> {
    
    public socialComposerTarget = () => {
        if (this.props.expanded && this.props.isStartPage) {
            return <SocialComposerTarget setFeedId={this.props.setFeedId} />
        }
        else { return null; }  
    }

    public render(): ReactNode {
        const classNames: string[] = ["SocialComposerUserHeader"];
        if (this.props.expanded) {
            classNames.push("expanded");
        }

        const textInputProps: ISocialComposerTextInputProps = this.props;

        return (
            <div className={classNames.join(" ")}>
                <div className="poster-info">
                    <div className="profile-image">
                        <SpintrUser 
                            name={this.props.userName} 
                            imageUrl={this.props.imageUrl} 
                            onRenderSecondaryText={this.socialComposerTarget}
                            size={circleLarge}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SocialComposerHeader;
