import {
    Checkbox, ChoiceGroup, DefaultButton, Dropdown,
    Label as FabricLabel,
    IComboBox,
    IComboBoxOption, Image,
    PrimaryButton,
    Stack, StackItem, TextField
} from "@fluentui/react";
import React, { Component, FormEvent, MouseEvent, ReactNode, RefObject, createRef } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { FileSelector } from "src/spintr/components";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import { validateRequiredTextField } from "src/utils";
import { MandatoryText } from "../../ui/components/Forms";
import { clearErrorList, createCalendarEvent, fetchCalendar, updateCalendarEvent } from "../actions";
import CalendarAPI from "../calendar-api";
import "./CalendarEventPopupForm.scss";
import CalendarEventRecurringSelector from "./CalendarEventRecurringSelector";

interface IProps {
    event?: any;
    startDate?: Date;
    endDate?: Date;
    hideCalendarSelector?: boolean;
    errorList?: string[];
    isLoading?: boolean;
    preSelectedCalendarId?: number;
    currentUserId?: number;
    enableExchangeCalendar?: boolean;
    office365Connected?: boolean;
    office365Enabled?: boolean;
    smallViewMode: boolean;
    // TODO: preselect calendar
    dispatch: (action: Action) => void;
    dismiss: () => void;
}

interface IState {
    event: any;
    //isLoading: boolean;
    calendars: Spintr.ICurrentUserCalendar[];
    activeCalendar?: Spintr.ICurrentUserCalendar;
    showImageSelector: boolean;
    base64: string;
    newAttendees: any[];
    imageTicket: any;
    enableFieldValidation?: boolean;
}

const emptyEvent: Spintr.ICalendarEvent = {
    acceptedCount: 0,
    allDay: false,
    attendeeCount: 0,
    attendees: [],
    calendarId: -5,
    calendarType: -5 as SpintrTypes.CalendarType,
    commentCount: 0,
    description: "",
    end: new Date(),
    eventUrl: "",
    exchangeId: "",
    googleId: "",
    hasAccepted: false,
    hasDeclined: false,
    id: 0,
    imageUrl: "",
    invited: [],
    invitesOnly: false,
    isOrganizer: true,
    location: "", // unuseD?
    place: "",
    rsvp: false,
    sourceName: "",
    start: new Date(),
    syncedWithCalendar: -5,
    title: "",
    userIsRegistered: true,
    seats: undefined,
    registrationClose: undefined,
};

class CalendarEventPopupForm extends Component<IProps, IState> {
    protected editorRef: RefObject<any>;
    private isNew = !!!this.props.event || this.props.event.id === 0;

    constructor(props: IProps) {
        super(props);

        const start = props.startDate || new Date();
        var end = props.endDate || new Date();

        if (!props.startDate) {
            start.setMinutes(0);
            start.setSeconds(0);
            start.setHours(start.getHours() + 1);
        }

        if (!props.endDate) {
            end.setTime(start.getTime() + 30 * 60 * 1000);
        }

        if (props.event && props.event.start > props.event.end) {
            // timezone issue from outlook?
            props.event.end = new Date(props.event.start);
            props.event.end.setHours(props.event.start.getHours() + 1);
        }

        this.state = {
            event: props.event
                ? { ...props.event }
                : {
                    ...emptyEvent,
                    end,
                    start,
                    allDay: start === end,
                },

            calendars: [],
            showImageSelector: false,
            base64: "",
            imageTicket: { remove: false },
            newAttendees: this.isNew && props.event ? props.event.newAttendees : [],
        };

        //if (!props.hideCalendarSelector) {
        // Get calendar from props?
        this.fetchUserCalendars();
        //}

        this.editorRef = createRef();
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onTimeChanged = this.onTimeChanged.bind(this);
    }

    fetchUserCalendars = async () => {
        let calendars = await CalendarAPI.fetchUserCalendars();

        this.setState({
            calendars: calendars,
        });

        if (!this.state.activeCalendar && calendars.length > 0) {
            let index = calendars.findIndex((c) => c.id == this.props.preSelectedCalendarId);
            index = index > -1 ? index : 0;

            this.setState((prevState) => ({
                activeCalendar: calendars[index],
            }));
        }
    };

    onChangeText = (ev, val) => {
        const name = ev.target.name;
        this.setState((prevState) => ({
            event: {
                ...prevState.event,
                [name]: val,
            },
        }));
    };

    onChangeCalendar = (ev, option) => {
        this.setState((prevState) => ({
            activeCalendar: this.state.calendars.find((c) => c.id == option.key),
        }));
    };

    private saveToExchangeOptions = [
        {
            text: localize("SparaTillOutlook"),
            key: "exchange",
            onRenderField: (props, render) => {
                return (
                    <>
                        {render(props)}
                        <Label size="body-2">{localize("EVENT_FORM_OUTLOOK_INFO")}</Label>
                    </>
                );
            },
        },
        {
            text: localize("SparaEnbartPaIntranatet"),
            key: "intranet",
            onRenderField: (props, render) => {
                return (
                    <>
                        {render(props)}
                        <Label size="body-2">{localize("EVENT_FORM_INTRANET_INFO")}</Label>
                    </>
                );
            },
        },
    ];

    private onChangeSaveToExchange = (ev, option) => {
        this.setState((prevState) => ({
            event: {
                ...prevState.event,
                saveToExchange: option.key === "exchange",
            },
        }));
    };

    public render(): ReactNode {
        const titleText = localize("Rubrik");
        const locationText = localize("Plats");
        const seatsText = localize("AntalPlatser");

        const { event, calendars } = this.state;

        if (!this.state.activeCalendar || this.props.isLoading) {
            return <Loader />;
        }

        var showOpenInvite = false;
        var hasJoined = false;
        var hasInvite = false;

        if (event.attendees) {
            hasJoined = event.attendees.some((attendee) => attendee.id === this.props.currentUserId);
        }
        if (event.invited) {
            hasInvite = event.invited.some((invited) => invited.id === this.props.currentUserId);
        }

        if (event.calendarType === SpintrTypes.CalendarType.Project) {
            showOpenInvite = false;
        } else {
            if (hasInvite) {
                showOpenInvite = false;
            } else {
                if (event.invitesOnly) {
                    if (this.props.currentUserId === event.owner) {
                        showOpenInvite = true;
                    } else {
                        showOpenInvite = false;
                    }
                } else {
                    if (hasJoined) {
                        showOpenInvite = true;
                    } else {
                        showOpenInvite = false;
                    }
                }

                if (event.exchangeId || event.googleId) {
                    if (event.isOrganizer) {
                        showOpenInvite = false;
                    } else if (event.hasAccepted) {
                        showOpenInvite = false;
                    } else {
                        showOpenInvite = false;
                    }
                }
            }
        }

        const exchangeCalendarEnabled =
            (this.props.enableExchangeCalendar || (this.props.office365Connected && this.props.office365Enabled)) &&
            (this.state.activeCalendar.type === SpintrTypes.CalendarType.Project ||
                (this.state.activeCalendar.outlookName && this.state.activeCalendar.outlookName !== ""));

        return (
            <div className="CalendarEventPopupForm">
                <form>
                    {/* <Stack horizontal verticalAlign="center">
                        <StackItem grow={1}>
                            <Label as="h2"  size="h2">
                                {localize(this.isNew ? "SkapaKalenderpost" : "RedigeraKalenderpost")}
                            </Label>
                        </StackItem>
                        <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.props.dismiss}>
                            <FontIcon iconName="ChromeClose" />
                        </UnstyledButton>
                    </Stack>
                    <Separator /> */}
                    <MandatoryText />
                    {this.props.errorList && this.props.errorList.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.props.errorList}
                            onDismiss={() => {
                                this.props.dispatch(clearErrorList());
                            }}
                        />
                    )}
                    {this.isNew && !this.props.hideCalendarSelector && (
                        <FormControl>
                            <Dropdown
                                label={localize("Kalender")}
                                selectedKey={this.state.activeCalendar.id}
                                onChange={this.onChangeCalendar}
                                options={calendars.map((calendar) => ({
                                    key: calendar.id,
                                    text: calendar.name,
                                }))}
                            />
                        </FormControl>
                    )}

                    {exchangeCalendarEnabled && this.isNew && (
                        <FormSection title={localize("SparaTill")}>
                            <FormControl>
                                <ChoiceGroup
                                    defaultSelectedKey={"intranet"}
                                    options={this.saveToExchangeOptions}
                                    value={this.state.event.saveToExchange}
                                    onChange={this.onChangeSaveToExchange}
                                />
                            </FormControl>
                        </FormSection>
                    )}

                    {this.state.activeCalendar.type === SpintrTypes.CalendarType.Outlook && (
                        <Label>{localize("AndringarnaSparasTillOutlook")}</Label>
                    )}

                    {this.state.activeCalendar.type === SpintrTypes.CalendarType.Google && (
                        <Label>{localize("AndringarnaSparasTillGoogle")}</Label>
                    )}

                    <FormControl>
                        <TextField
                            label={titleText}
                            name="title"
                            placeholder={titleText}
                            value={this.state.event.title}
                            onChange={this.onChangeText}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    <FormControl
                        label={localize("Tid")}>
                        <Stack
                            horizontal={!this.props.smallViewMode}
                            tokens={{ childrenGap: 6 }}
                            verticalAlign="center"
                            className="date-stack"
                        >
                            <StackItem>
                                <SpintrDatePicker
                                    hideTimeSelect={this.state.event.allDay}
                                    value={this.state.event.start}
                                    onChangeHandler={(date, event2) => {
                                        this.setState((prevState) => ({
                                            event: {
                                                ...prevState.event,
                                                start: date,
                                            },
                                        }));
                                    }}
                                />
                            </StackItem>
                            <StackItem>
                                <FabricLabel htmlFor="dateEnd">{localize("Till").toLowerCase()}</FabricLabel>
                            </StackItem>
                            <StackItem>
                                <SpintrDatePicker
                                    hideTimeSelect={this.state.event.allDay}
                                    value={this.state.event.end}
                                    onChangeHandler={(date, event2) => {
                                        this.setState((prevState) => ({
                                            event: {
                                                ...prevState.event,
                                                end: date,
                                            },
                                        }));
                                    }}
                                />
                            </StackItem>




                        </Stack>

                    </FormControl>
                    <FormControl>
                        <Checkbox
                            label={localize("Heldag")}
                            checked={this.state.event.allDay}
                            name="allDay"
                            onChange={this.onChangeText}
                        />
                    </FormControl>
                    {/*
                        this.state.activeCalendar.type !== SpintrTypes.CalendarType.Outlook &&
                        this.state.activeCalendar.type !== SpintrTypes.CalendarType.Google &&
                        !this.state.event.saveToExchange &&
                        !this.state.event.exchangeId &&
                        !this.state.event.googleId && (
                            <FormControl>
                                <CalendarEventRecurringSelector
                                    config={this.state.event.recurringConfig}
                                    defaultStartDate={this.state.event.start}
                                    onChange={(config) => {
                                        this.setState({
                                            event: {
                                                ...this.state.event,
                                                recurringConfig: config
                                            }
                                        });
                                    }} />
                            </FormControl>
                        )
                    */}
                    <FormControl>
                        <TextField
                            label={locationText}
                            name="place"
                            onChange={this.onChangeText}
                            value={this.state.event.place}
                            placeholder={locationText}
                        />
                    </FormControl>
                    {/* TODO: I don't think saveToExchange is ever set on the server? Broken in angular */}
                    {!this.state.event.saveToExchange && (
                        <>
                            <FormControl>
                                <TextField
                                    label={seatsText}
                                    name="seats"
                                    placeholder={seatsText}
                                    onChange={this.onChangeText}
                                    value={this.state.event.seats}
                                    type="number"
                                />
                                <Label style={{marginTop: 8}} size="body-3" as="p">{localize("EVENT_FORM_SEATS_INFO")}</Label>
                            </FormControl>

                            <FormControl>
                                <SpintrDatePicker
                                    label={localize("CALENDAR_ACTIVITY_LAST_REGISTRATION")}
                                    value={this.state.event.registrationClose as Date}
                                    onChangeHandler={(date, event2) => {
                                        this.setState((prevState) => ({
                                            event: {
                                                ...prevState.event,
                                                registrationClose: date,
                                            },
                                        }));
                                    }}
                                />
                            </FormControl>
                        </>
                    )}
                    {!this.state.event.eventHasExpired &&
                        ((this.isNew && this.state.activeCalendar.type !== SpintrTypes.CalendarType.Project) ||
                            (!this.isNew &&
                                showOpenInvite &&
                                this.state.activeCalendar.type !== SpintrTypes.CalendarType.Project)) && (
                            <>
                                <FormControl>
                                    <FabricLabel>{localize("BjudIn")}</FabricLabel>
                                    <FormTokenizedObjectInput
                                        items={this.state.newAttendees}
                                        //types={[SpintrTypes.UberType.User]}
                                        onChange={(users) => {
                                            this.setState((prevState) => ({
                                                newAttendees: users.map((u) => ({
                                                    ...u,
                                                    text: u.name,
                                                    image: u.imageUrl,
                                                    info: u.subText,
                                                })),
                                            }));
                                        }}
                                    />
                                </FormControl>
                            </>
                        )}
                    {this.isNew &&
                        this.state.activeCalendar.type !== SpintrTypes.CalendarType.Outlook &&
                        this.state.activeCalendar.type !== SpintrTypes.CalendarType.Google &&
                        this.state.activeCalendar.type !== SpintrTypes.CalendarType.Personal &&
                        this.state.activeCalendar.type !== SpintrTypes.CalendarType.Project && (
                            <FormControl>
                                <Checkbox
                                    label={localize("EndastInbjudningar")}
                                    checked={this.state.event.eventInvitesOnly}
                                    name="eventInvitesOnly"
                                />
                            </FormControl>
                        )}

                    {this.state.activeCalendar.type !== SpintrTypes.CalendarType.Outlook &&
                        this.state.activeCalendar.type !== SpintrTypes.CalendarType.Google &&
                        !this.state.event.saveToExchange &&
                        !this.state.event.exchangeId &&
                        !this.state.event.googleId && (
                            <FormControl>
                                <Checkbox
                                    label={localize("SkickaNotifikationerTillSkaparenNarAnvandareTackarJaTillInbjudan")}
                                    checked={this.state.event.notificateCreatorOnJoin}
                                    name="notificateCreatorOnJoin"
                                    onChange={this.onChangeText}
                                />
                            </FormControl>
                        )}

                    <FormControl>
                        <TinyEditorComponent
                            content={this.state.event.text}
                            id="CalendarEventPopupDescription"
                            autofocus={false}
                            label={localize("Beskrivning")}
                            ref={this.editorRef}
                            isInModal
                        />
                    </FormControl>
                    {/* TODO: image picker */}
                    {(!this.state.event.saveToExchange &&
                        this.state.activeCalendar.type >= 0 &&
                        this.state.activeCalendar.type <= 4) && (
                            <>
                                <FormControl>
                                    <FabricLabel>{localize("Bild")}</FabricLabel>
                                    {!this.state.base64 && !this.state.event.imageUrl && (
                                        <DefaultButton
                                            onClick={() => {
                                                this.setState({ showImageSelector: true });
                                            }}
                                            text={localize("ValjBild")}
                                        />
                                    )}
                                </FormControl>


                                {(this.state.base64 || this.state.event.imageUrl) && (
                                    <FormControl>
                                        <Image className="event-image" src={this.state.base64 || this.state.event.imageUrl} />

                                        <DefaultButton
                                            className="remove-image-button"
                                            onClick={() => {
                                                this.setState((prevState) => ({
                                                    event: { ...prevState.event, imageUrl: "" },
                                                    imageTicket: { remove: true },
                                                    base64: undefined,
                                                }));
                                            }}
                                            text={localize("TaBortBild")}
                                        />
                                    </FormControl>
                                )}
                            </>
                        )}

                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                        <DefaultButton onClick={this.props.dismiss} text={localize("Avbryt")} />
                        <PrimaryButton onClick={this.onSaveClicked} text={localize("Spara")} />
                    </Stack>

                    {this.state.showImageSelector && (
                        <FileSelector
                            cropImage
                            cropAspect={712 / 200}
                            renderSizeString={() => {
                                return (
                                    `${localize(
                                        "OptimalBildStorlek"
                                    )}: 710x200px <br />`
                                )
                            }}
                            fileTypesString="image/png, image/jpeg"
                            fileTypes={["png", "jpg", "jpeg"]}
                            allowMultipleFiles={false}
                            onClose={() => {
                                this.setState({
                                    showImageSelector: false,
                                });
                            }}
                            sourcesToDisplay={[0]}
                            returnBase64={(data) => {
                                this.setState({
                                    showImageSelector: false,
                                    imageTicket: { remove: false, data: data.split(",")[1] },
                                    base64: data,
                                });
                            }}
                        />
                    )}
                </form>
            </div>
        );
    }

    protected onSaveClicked(ev: MouseEvent<HTMLElement>): void {
        this.setState({
            enableFieldValidation: true,
        });

        const content = this.editorRef.current.getContent();
        const { event } = this.state;

        console.log({ content });
        let model = {
            id: 0,
            calendarId: this.state.activeCalendar.id,
            calendarType: this.state.activeCalendar.type,
            exchangeId: event.exchangeId,
            start: event.start,
            end: event.end,
            title: event.title,
            text: content,
            place: event.place === "-" ? "" : event.place,
            seats: event.seats < 1 ? undefined : event.seats,
            registrationClose: event.registrationClose,
            newInvites: this.state.newAttendees.map((a) => a.key),
            attendees: [], // verkar behövas för outlook, dubbelkolla så det nte skriver över om tom,
            notificateCreatorOnJoin: event.notificateCreatorOnJoin,
            imageTicket: this.state.imageTicket,
            eventInvitesOnly: event.eventInvitesOnly,
            allDay: event.allDay,
            saveToExchange: event.saveToExchange,
        };

        if (this.isNew) {
            this.props.dispatch(createCalendarEvent(model));
        } else {
            model.id = event.id;
            this.props.dispatch(updateCalendarEvent(model));
        }
    }

    protected onTimeChanged(event: FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) {
        if (option) {
            return;
        }

        event.persist();
        console.log(event);
        console.log(index);
        console.log(value);
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        errorList: state.calendar.calendarPopup.errorList,
        isLoading: state.calendar.calendarPopup.isLoading,
        currentUserId: state.profile.active.id,
        smallViewMode: state.ui.isSmallViewMode,
        enableExchangeCalendar: state.instance.get("enableExchangeCalendar"),
        office365Connected: state.profile.active.office365Connected,
        office365Enabled: state.instance.get("office365Enabled"),
    };
};

export default connect(mapStateToProps)(CalendarEventPopupForm);
