import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react";
import { AxiosRequestConfig } from "axios";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { SpintrTypes} from "src/typings";
import LoadMoreButton from "src/ui/components/Buttons/LoadMoreButton/LoadMoreButton";
import ConfirmDialog from "src/ui/components/Forms/ConfirmDialog";
import { fetchInformationFeed, setInformationFeedLoading } from "./actions";
import InformationFeedContainer from "./InformationFeedContainer";

interface IInformationFeedLoaderProps {
    includeBlogPosts?: boolean;
    includeNews?: boolean;
    latest?: boolean;
    take: number;
    unitId?: number;
    isRss?: boolean;
    identity?: any;
    dispatch?: any;
    items?: any;
    isLoading?: any;
    hasMore?: any;
    isLoadingMore?: any;
    categoryId?: number;
    template?: SpintrTypes.NewsFeedType;
}

interface IInformationFeedLoaderState {
    showConfirmDialog: boolean;
    deletePost?: { id: number; isBlogPost: boolean; };
}

class InformationFeedLoader extends Component<IInformationFeedLoaderProps, IInformationFeedLoaderState> {
    public static defaultProps: IInformationFeedLoaderProps = {
        includeBlogPosts: true,
        includeNews: true,
        latest: true,
        take: 9,
        unitId: null,
    };

    constructor(props: IInformationFeedLoaderProps) {
        super(props);

        this.state = {
            showConfirmDialog: false
        }
    }

    public componentDidMount(): void {
        this.query(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.includeBlogPosts !== this.props.includeBlogPosts ||
            prevProps.includeNews !== this.props.includeNews ||
            prevProps.isRss !== this.props.isRss ||
            prevProps.categoryId !== this.props.categoryId) {
            this.query(true);
        }
    }

    public query(reFetch?: boolean): void {
        const config: AxiosRequestConfig = {
            params: {
                skip: reFetch ? 0 : this.props.items.length,
                take: this.props.take,
                includeAuthor: true
            }
        };

        if (!this.props.isRss) {
            config.params.includeBlogPosts = this.props.includeBlogPosts;
            config.params.includeNews = this.props.includeNews;
            config.params.latest = this.props.latest;
            config.params.unitId = this.props.unitId;
            config.params.categoryId = this.props.categoryId;
        }

        this.props.dispatch(fetchInformationFeed(this.props.isRss, config));
    }

    confirmDelete = (id, isBlogPost) => {
        this.setState({ deletePost: { id, isBlogPost }, showConfirmDialog: true })
    }

    onDelete() {
        const { isBlogPost, id } = this.state.deletePost
        if (id) {
            this.props.dispatch(setInformationFeedLoading());

            const url = isBlogPost ?
                ("/api/blogposts/" + id) :
                ("/api/news/" + id);

            api.delete(url).then(() => {
                this.query(true);
            });
        }
    }

    private handleLoadMoreClick = () => {
        this.query();
    }

    getCustomElements1() {
        return (
            <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                    { type: ShimmerElementType.line, width: '100%', height: 6, },
                    { type: ShimmerElementType.line, width: '100%', height: 6 },
                    { type: ShimmerElementType.line, width: '100%', height: 6 },
                    { type: ShimmerElementType.line, width: '100%', height: 6 },
                    { type: ShimmerElementType.line, width: '100%', height: 6 },
                    { type: ShimmerElementType.line, width: '20%', height: 6 },
                    { type: ShimmerElementType.gap, width: '80%', height: 12 },
                    { type: ShimmerElementType.gap, width: '100%', height: 12 },
                ]}
            />
        );
    }

    private ShimmerLoader() {
        return (
            <div className="InformationFeedContainer">
                {[...Array(12)].map((_, index) => {
                    return (
                        <div key={`shimmer-${index}`} className="information-feed-entry" style={{
                            backgroundColor: "#FFF",
                        }}>
                            <div className="InformationFeedEntry  start-page-flexible-padding">
                                <Shimmer
                                    className="ShimmerLoader"
                                    customElementsGroup={this.getCustomElements1()}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    public render(): ReactNode {

        if (this.props.isLoading && this.props.items.length === 0) {
            return this.ShimmerLoader();
        }

        return (
            <div>
                <ConfirmDialog
                    show={this.state.showConfirmDialog}
                    title={localize("RaderaInnehall")}
                    message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase())}
                    onDismiss={() => {
                        this.setState({ showConfirmDialog: false });
                    }}
                    onConfirm={() => {
                        this.setState({ showConfirmDialog: false }, () => {
                            this.onDelete()
                        });
                    }}
                />
                <InformationFeedContainer
                    identity={this.props.identity}
                    items={this.props.items}
                    template={this.props.template}
                    onDelete={this.confirmDelete} />
                {
                    this.props.isLoadingMore && (
                        <div style={{ marginTop: 24 }}>
                            {this.ShimmerLoader()}
                        </div>
                    )
                }
                {
                    !this.props.isLoading &&
                    !this.props.isLoadingMore &&
                    this.props.hasMore && (
                        <LoadMoreButton
                            text={localize("LaddaInFlera") + " " + localize("Poster").toLowerCase()}
                            onClick={this.handleLoadMoreClick}
                            visageStyle />
                    )
                }
            </div>
        );
    }
}


const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,

    items: state.informationFeed.items,
    hasMore: state.informationFeed.hasMore,
    isLoading: state.informationFeed.isLoading,
    isLoadingMore: state.informationFeed.isLoadingMore,
});

export default connect(mapStateToProps)(InformationFeedLoader);